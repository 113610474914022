(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.dk.bl === region.dS.bl)
	{
		return 'on line ' + region.dk.bl;
	}
	return 'on lines ' + region.dk.bl + ' through ' + region.dS.bl;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.ec,
		impl.fD,
		impl.ft,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		eu: func(record.eu),
		dl: record.dl,
		df: record.df
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.eu;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.dl;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.df) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.ec,
		impl.fD,
		impl.ft,
		function(sendToApp, initialModel) {
			var view = impl.fG;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.ec,
		impl.fD,
		impl.ft,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.dj && impl.dj(sendToApp)
			var view = impl.fG;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.aA);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.fw) && (_VirtualDom_doc.title = title = doc.fw);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.eG;
	var onUrlRequest = impl.eH;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		dj: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.eV === next.eV
							&& curr.d4 === next.d4
							&& curr.eS.a === next.eS.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		ec: function(flags)
		{
			return A3(impl.ec, flags, _Browser_getUrl(), key);
		},
		fG: impl.fG,
		fD: impl.fD,
		ft: impl.ft
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { gp: 'hidden', fW: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { gp: 'mozHidden', fW: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { gp: 'msHidden', fW: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { gp: 'webkitHidden', fW: 'webkitvisibilitychange' }
		: { gp: 'hidden', fW: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		e9: _Browser_getScene(),
		fH: {
			fL: _Browser_window.pageXOffset,
			fM: _Browser_window.pageYOffset,
			fK: _Browser_doc.documentElement.clientWidth,
			b2: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		fK: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		b2: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			e9: {
				fK: node.scrollWidth,
				b2: node.scrollHeight
			},
			fH: {
				fL: node.scrollLeft,
				fM: node.scrollTop,
				fK: node.clientWidth,
				b2: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			e9: _Browser_getScene(),
			fH: {
				fL: x,
				fM: y,
				fK: _Browser_doc.documentElement.clientWidth,
				b2: _Browser_doc.documentElement.clientHeight
			},
			gd: {
				fL: x + rect.left,
				fM: y + rect.top,
				fK: rect.width,
				b2: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.gg.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.gg.b, xhr)); });
		$elm$core$Maybe$isJust(request.bd) && _Http_track(router, xhr, request.bd.a);

		try {
			xhr.open(request.aF, request.fE, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.fE));
		}

		_Http_configureRequest(xhr, request);

		request.aA.a && xhr.setRequestHeader('Content-Type', request.aA.a);
		xhr.send(request.aA.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.F; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.au.a || 0;
	xhr.responseType = request.gg.d;
	xhr.withCredentials = request.fQ;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		fE: xhr.responseURL,
		gV: xhr.status,
		gW: xhr.statusText,
		F: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			gS: event.loaded,
			cH: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			gM: event.loaded,
			cH: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}



// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.gB) { flags += 'm'; }
	if (options.fV) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $author$project$Main$ChangedUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$ClickedLink = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$GT = 2;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.m) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.p),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.p);
		} else {
			var treeLen = builder.m * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.r) : builder.r;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.m);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.p) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.p);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{r: nodeList, m: (len / $elm$core$Array$branchFactor) | 0, p: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {c3: fragment, d4: host, gG: path, eS: port_, eV: protocol, eY: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$App$Cred = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$App$credDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'refresh_token',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'access_token',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$App$Cred)));
var $author$project$App$decoderFromCred = function (decoder) {
	return A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (fromCred, cred) {
				return fromCred(cred);
			}),
		decoder,
		$author$project$App$credDecoder);
};
var $author$project$App$storageDecoder = function (viewerDecoder) {
	return A2(
		$elm$json$Json$Decode$field,
		'user',
		$author$project$App$decoderFromCred(viewerDecoder));
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$App$application = F2(
	function (viewerDecoder, config) {
		var init = F3(
			function (flags, url, navKey) {
				var maybeViewer = $elm$core$Result$toMaybe(
					A2(
						$elm$core$Result$andThen,
						$elm$json$Json$Decode$decodeString(
							$author$project$App$storageDecoder(viewerDecoder)),
						A2(
							$elm$json$Json$Decode$decodeValue,
							A2($elm$json$Json$Decode$field, 'viewer', $elm$json$Json$Decode$string),
							flags)));
				var endpoint = A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$Result$toMaybe(
						A2(
							$elm$json$Json$Decode$decodeValue,
							A2($elm$json$Json$Decode$field, 'endpoint', $elm$json$Json$Decode$string),
							flags)));
				return A3(
					config.ec,
					_Utils_Tuple2(maybeViewer, endpoint),
					url,
					navKey);
			});
		return $elm$browser$Browser$application(
			{ec: init, eG: config.eG, eH: config.eH, ft: config.ft, fD: config.fD, fG: config.fG});
	});
var $author$project$Viewer$Viewer = $elm$core$Basics$identity;
var $author$project$Viewer$decoder = $elm$json$Json$Decode$succeed($elm$core$Basics$identity);
var $author$project$Main$CompletedRefreshToken = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$Init = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Route$Login = {$: 5};
var $author$project$Route$Logout = {$: 6};
var $author$project$Route$Reservation = {$: 4};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters)));
	});
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$decodeBody = F2(
	function (decoder, body) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, decoder, body);
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$core$Result$Ok(value);
		} else {
			var err = _v0.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadBody(
					$elm$json$Json$Decode$errorToString(err)));
		}
	});
var $author$project$Api$decodeResponse = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.gV));
			default:
				var body = response.b;
				if ($elm$core$String$isEmpty(body)) {
					var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, '{}');
					if (!_v1.$) {
						var value = _v1.a;
						return $elm$core$Result$Ok(value);
					} else {
						return A2($author$project$Api$decodeBody, decoder, body);
					}
				} else {
					return A2($author$project$Api$decodeBody, decoder, body);
				}
		}
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $author$project$Api$jsonResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		$author$project$Api$decodeResponse(decoder));
};
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{fQ: false, aA: r.aA, gg: r.gO, F: r.F, aF: r.aF, au: r.au, bd: $elm$core$Maybe$Nothing, fE: r.fE});
};
var $author$project$Api$task = function (_v0) {
	var req = _v0;
	return $elm$http$Http$task(
		{
			aA: req.aA,
			F: req.F,
			aF: req.aF,
			gO: $author$project$Api$jsonResolver(req.bh),
			au: req.au,
			fE: A3($elm$url$Url$Builder$crossOrigin, req.aS, req.bq, req.br)
		});
};
var $author$project$App$callApiTask = function (req) {
	if (!req.$) {
		var r = req.a;
		return $author$project$Api$task(r);
	} else {
		var r = req.a;
		return $author$project$Api$task(r);
	}
};
var $author$project$App$callApi = F2(
	function (toMsg, req) {
		return A2(
			$elm$core$Task$attempt,
			toMsg,
			$author$project$App$callApiTask(req));
	});
var $author$project$Viewer$cred = function (_v0) {
	var val = _v0;
	return val;
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Session$viewer = function (session) {
	if (!session.$) {
		var val = session.b;
		return $elm$core$Maybe$Just(val);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Session$cred = function (session) {
	return A2(
		$elm$core$Maybe$map,
		$author$project$Viewer$cred,
		$author$project$Session$viewer(session));
};
var $author$project$Session$Guest = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Session$LoggedIn = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Session$fromInitViewer = F3(
	function (key, maybeViewer, globalVal) {
		if (!maybeViewer.$) {
			var viewerVal = maybeViewer.a;
			return A3(
				$author$project$Session$LoggedIn,
				key,
				viewerVal,
				{f2: globalVal.f2, ge: globalVal.ge, gn: globalVal.gn, gv: false, gN: _List_Nil});
		} else {
			return A2(
				$author$project$Session$Guest,
				key,
				{f2: globalVal.f2, ge: globalVal.ge, gn: _List_Nil, gv: false, gN: _List_Nil});
		}
	});
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {aD: frag, aH: params, av: unvisited, be: value, aO: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.av;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.be);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.be);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.gG),
					$elm$url$Url$Parser$prepareQuery(url.eY),
					url.c3,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$Gallery = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$Home = {$: 0};
var $author$project$Route$MagicLink = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Profile = {$: 3};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.aO;
		var unvisited = _v0.av;
		var params = _v0.aH;
		var frag = _v0.aD;
		var value = _v0.be;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.aO;
			var unvisited = _v1.av;
			var params = _v1.aH;
			var frag = _v1.aD;
			var value = _v1.be;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aO;
		var unvisited = _v0.av;
		var params = _v0.aH;
		var frag = _v0.aD;
		var value = _v0.be;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.aO;
			var unvisited = _v0.av;
			var params = _v0.aH;
			var frag = _v0.aD;
			var value = _v0.be;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Gallery,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('gallery'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Profile,
			$elm$url$Url$Parser$s('profile')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Reservation,
			$elm$url$Url$Parser$s('reservation')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Login,
			$elm$url$Url$Parser$s('login')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$MagicLink,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('magic'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Logout,
			$elm$url$Url$Parser$s('logout'))
		]));
var $author$project$Route$fromUrl = function (url) {
	return A2(
		$elm$url$Url$Parser$parse,
		$author$project$Route$parser,
		_Utils_update(
			url,
			{
				c3: $elm$core$Maybe$Nothing,
				gG: A2($elm$core$Maybe$withDefault, '', url.c3)
			}));
};
var $author$project$Session$navKey = function (session) {
	if (!session.$) {
		var key = session.a;
		return key;
	} else {
		var key = session.a;
		return key;
	}
};
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Route$routeToPieces = function (page) {
	switch (page.$) {
		case 0:
			return _List_Nil;
		case 1:
			return _List_Nil;
		case 2:
			var shootingDateLabel = page.a;
			return _List_fromArray(
				['gallery', shootingDateLabel]);
		case 3:
			return _List_fromArray(
				['profile']);
		case 4:
			return _List_fromArray(
				['reservation']);
		case 5:
			return _List_fromArray(
				['login']);
		case 6:
			return _List_fromArray(
				['logout']);
		default:
			var token = page.a;
			return _List_fromArray(
				['magic', token]);
	}
};
var $author$project$Route$routeToString = function (page) {
	return '#/' + A2(
		$elm$core$String$join,
		'/',
		$author$project$Route$routeToPieces(page));
};
var $author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Session$endpoint = function (session) {
	if (!session.$) {
		var g = session.c;
		return g.ge;
	} else {
		var g = session.b;
		return g.ge;
	}
};
var $author$project$Api$Request = $elm$core$Basics$identity;
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$Api$headers = $elm$core$List$filterMap(
	function (_v0) {
		var key = _v0.a;
		var value = _v0.b;
		return A2(
			$elm$core$Maybe$map,
			$elm$http$Http$header(key),
			value);
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Api$interpolatePath = F2(
	function (rawPath, pathParams) {
		var interpolate = F2(
			function (_v0, path) {
				var name = _v0.a;
				var value = _v0.b;
				return A3($elm$core$String$replace, '{' + (name + '}'), value, path);
			});
		return A2(
			$elm$core$List$drop,
			1,
			A2(
				$elm$core$String$split,
				'/',
				A3($elm$core$List$foldl, interpolate, rawPath, pathParams)));
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Api$queries = $elm$core$List$filterMap(
	function (_v0) {
		var key = _v0.a;
		var value = _v0.b;
		return A2(
			$elm$core$Maybe$map,
			$elm$url$Url$Builder$string(key),
			value);
	});
var $author$project$Api$request = F7(
	function (method, path, pathParams, queryParams, headerParams, body, decoder) {
		return {
			aS: 'http://127.0.0.1:8000/mypage',
			aA: A2(
				$elm$core$Maybe$withDefault,
				$elm$http$Http$emptyBody,
				A2($elm$core$Maybe$map, $elm$http$Http$jsonBody, body)),
			bh: decoder,
			F: $author$project$Api$headers(headerParams),
			aF: method,
			bq: A2($author$project$Api$interpolatePath, path, pathParams),
			br: $author$project$Api$queries(queryParams),
			au: $elm$core$Maybe$Nothing,
			bd: $elm$core$Maybe$Nothing
		};
	});
var $author$project$Api$Data$Token = F3(
	function (accessToken, refreshToken, tokenType) {
		return {dp: accessToken, e_: refreshToken, fz: tokenType};
	});
var $author$project$Api$Data$decodeChain = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $author$project$Api$Data$decode = F2(
	function (key, decoder) {
		return $author$project$Api$Data$decodeChain(
			A2($elm$json$Json$Decode$field, key, decoder));
	});
var $author$project$Api$Data$tokenDecoder = A3(
	$author$project$Api$Data$decode,
	'token_type',
	$elm$json$Json$Decode$string,
	A3(
		$author$project$Api$Data$decode,
		'refresh_token',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'access_token',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Api$Data$Token))));
var $author$project$Api$Request$Default$refreshToken = A7($author$project$Api$request, 'POST', '/refresh_token', _List_Nil, _List_Nil, _List_Nil, $elm$core$Maybe$Nothing, $author$project$Api$Data$tokenDecoder);
var $author$project$App$RequestWithRefreshToken = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$withBearerToken = F2(
	function (token, _v0) {
		var req = _v0;
		return _Utils_update(
			req,
			{
				F: A2(
					$elm$core$List$cons,
					A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token),
					req.F)
			});
	});
var $author$project$App$reqWithMaybeRefreshToken = F2(
	function (cred, req) {
		if (!cred.$) {
			var _v1 = cred.a;
			var t = _v1.b;
			return $author$project$App$RequestWithRefreshToken(
				A2($author$project$Api$withBearerToken, t, req));
		} else {
			return $author$project$App$RequestWithRefreshToken(req);
		}
	});
var $author$project$Api$withBasePath = F2(
	function (basePath, _v0) {
		var req = _v0;
		return _Utils_update(
			req,
			{aS: basePath});
	});
var $author$project$Session$toRefreshToeknRequest = function (session) {
	var req = A2(
		$author$project$Api$withBasePath,
		$author$project$Session$endpoint(session),
		$author$project$Api$Request$Default$refreshToken);
	return A2(
		$author$project$App$reqWithMaybeRefreshToken,
		$author$project$Session$cred(session),
		req);
};
var $author$project$Main$init = F3(
	function (_v0, url, navKey) {
		var maybeViewer = _v0.a;
		var endpoint = _v0.b;
		var session = A3(
			$author$project$Session$fromInitViewer,
			navKey,
			maybeViewer,
			{f2: url.eY, ge: endpoint, gn: _List_Nil, gv: false, gN: _List_Nil});
		var route = $author$project$Route$fromUrl(url);
		var cred = $author$project$Session$cred(session);
		var initConfigureCmd = function () {
			if (A2(
				$elm$core$String$startsWith,
				'/magic',
				A2($elm$core$Maybe$withDefault, '', url.c3))) {
				if (!route.$) {
					var r = route.a;
					return A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						r);
				} else {
					return A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Logout);
				}
			} else {
				if (cred.$ === 1) {
					return A2(
						$elm$core$String$startsWith,
						'/reservation',
						A2($elm$core$Maybe$withDefault, '', url.c3)) ? A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Reservation) : A2(
						$author$project$Route$replaceUrl,
						$author$project$Session$navKey(session),
						$author$project$Route$Login);
				} else {
					return $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$author$project$App$callApi,
								$author$project$Main$CompletedRefreshToken,
								$author$project$Session$toRefreshToeknRequest(session))
							]));
				}
			}
		}();
		return _Utils_Tuple2(
			A2($author$project$Main$Init, session, url),
			initConfigureCmd);
	});
var $author$project$Main$DelaiedRefreshingTokenTrigger = {$: 7};
var $author$project$Main$GotGalleryMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Main$GotHomeMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Main$GotLoginMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$GotProfileMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Main$GotReservationMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Main$GotSession = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$VisibilityChange = function (a) {
	return {$: 14, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Session$fromViewer = F3(
	function (key, maybeViewer, session) {
		var globalVal = function () {
			if (!session.$) {
				var gv = session.c;
				return gv;
			} else {
				var gv = session.b;
				return gv;
			}
		}();
		if (!maybeViewer.$) {
			var viewerVal = maybeViewer.a;
			return A3(
				$author$project$Session$LoggedIn,
				key,
				viewerVal,
				{f2: globalVal.f2, ge: globalVal.ge, gn: globalVal.gn, gv: false, gN: _List_Nil});
		} else {
			return A2(
				$author$project$Session$Guest,
				key,
				{f2: globalVal.f2, ge: globalVal.ge, gn: _List_Nil, gv: false, gN: _List_Nil});
		}
	});
var $author$project$App$decodeFromChange = F2(
	function (viewerDecoder, val) {
		return $elm$core$Result$toMaybe(
			A2(
				$elm$json$Json$Decode$decodeValue,
				$author$project$App$storageDecoder(viewerDecoder),
				val));
	});
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$App$onStoreChange = _Platform_incomingPort('onStoreChange', $elm$json$Json$Decode$value);
var $author$project$App$viewerChanges = F2(
	function (toMsg, decoder) {
		return $author$project$App$onStoreChange(
			function (value) {
				return toMsg(
					A2($author$project$App$decodeFromChange, decoder, value));
			});
	});
var $author$project$Session$changes = F3(
	function (toMsg, key, session) {
		return A2(
			$author$project$App$viewerChanges,
			function (maybeViewer) {
				return toMsg(
					A3($author$project$Session$fromViewer, key, maybeViewer, session));
			},
			$author$project$Viewer$decoder);
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$browser$Browser$Events$Document = 0;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {eM: pids, fr: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {dU: event, el: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.eM,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.el;
		var event = _v0.dU;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.fr);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$Hidden = 1;
var $elm$browser$Browser$Events$Visible = 0;
var $elm$browser$Browser$Events$withHidden = F2(
	function (func, isHidden) {
		return func(
			isHidden ? 1 : 0);
	});
var $elm$browser$Browser$Events$onVisibilityChange = function (func) {
	var info = _Browser_visibilityInfo(0);
	return A3(
		$elm$browser$Browser$Events$on,
		0,
		info.fW,
		A2(
			$elm$json$Json$Decode$map,
			$elm$browser$Browser$Events$withHidden(func),
			A2(
				$elm$json$Json$Decode$field,
				'target',
				A2($elm$json$Json$Decode$field, info.gp, $elm$json$Json$Decode$bool))));
};
var $author$project$Page$Gallery$GotSession = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Gallery$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A3(
				$author$project$Session$changes,
				$author$project$Page$Gallery$GotSession,
				$author$project$Session$navKey(model.j),
				model.j)
			]));
};
var $author$project$Page$Home$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Home$Tick = function (a) {
	return {$: 5, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {eU: processes, fu: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.eU;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.fu);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Page$Home$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A3(
				$author$project$Session$changes,
				$author$project$Page$Home$GotSession,
				$author$project$Session$navKey(model.j),
				model.j),
				A2($elm$time$Time$every, 4 * 1000, $author$project$Page$Home$Tick)
			]));
};
var $author$project$Page$Login$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Login$subscriptions = function (model) {
	return A3(
		$author$project$Session$changes,
		$author$project$Page$Login$GotSession,
		$author$project$Session$navKey(model.j),
		model.j);
};
var $author$project$Page$Profile$GotSession = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Profile$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A3(
				$author$project$Session$changes,
				$author$project$Page$Profile$GotSession,
				$author$project$Session$navKey(model.j),
				model.j)
			]));
};
var $author$project$Page$Reservation$GotSession = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Reservation$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A3(
				$author$project$Session$changes,
				$author$project$Page$Reservation$GotSession,
				$author$project$Session$navKey(model.j),
				model.j)
			]));
};
var $author$project$Page$Gallery$toSession = function (model) {
	return model.j;
};
var $author$project$Page$Home$toSession = function (model) {
	return model.j;
};
var $author$project$Page$Login$toSession = function (model) {
	return model.j;
};
var $author$project$Page$Profile$toSession = function (model) {
	return model.j;
};
var $author$project$Page$Reservation$toSession = function (model) {
	return model.j;
};
var $author$project$Main$toSession = function (page) {
	switch (page.$) {
		case 1:
			var session = page.a;
			return session;
		case 0:
			var session = page.a;
			return session;
		case 2:
			var session = page.a;
			return session;
		case 3:
			var home = page.a;
			return $author$project$Page$Home$toSession(home);
		case 4:
			var gallery = page.a;
			return $author$project$Page$Gallery$toSession(gallery);
		case 5:
			var profile = page.a;
			return $author$project$Page$Profile$toSession(profile);
		case 6:
			var login = page.a;
			return $author$project$Page$Login$toSession(login);
		default:
			var reservation = page.a;
			return $author$project$Page$Reservation$toSession(reservation);
	}
};
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$elm$browser$Browser$Events$onVisibilityChange(
				function (v) {
					return $author$project$Main$VisibilityChange(v);
				}),
				function () {
				switch (model.$) {
					case 2:
						return $elm$core$Platform$Sub$none;
					case 1:
						var session = $author$project$Main$toSession(model);
						return A3(
							$author$project$Session$changes,
							$author$project$Main$GotSession,
							$author$project$Session$navKey(session),
							session);
					case 0:
						var session = $author$project$Main$toSession(model);
						return A3(
							$author$project$Session$changes,
							$author$project$Main$GotSession,
							$author$project$Session$navKey(session),
							session);
					case 6:
						var login = model.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotLoginMsg,
							$author$project$Page$Login$subscriptions(login));
					case 3:
						var home = model.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotHomeMsg,
							$author$project$Page$Home$subscriptions(home));
					case 4:
						var gallery = model.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotGalleryMsg,
							$author$project$Page$Gallery$subscriptions(gallery));
					case 5:
						var profile = model.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotProfileMsg,
							$author$project$Page$Profile$subscriptions(profile));
					default:
						var reservation = model.a;
						return A2(
							$elm$core$Platform$Sub$map,
							$author$project$Main$GotReservationMsg,
							$author$project$Page$Reservation$subscriptions(reservation));
				}
			}(),
				$author$project$App$onStoreChange(
				function (_v1) {
					return $author$project$Main$DelaiedRefreshingTokenTrigger;
				})
			]));
};
var $author$project$Main$CompletedTakeGalleryInfo = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$Gallery = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$Home = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$Login = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$NotFound = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$Profile = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$Redirect = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$Reservation = function (a) {
	return {$: 7, a: a};
};
var $author$project$Route$Root = {$: 1};
var $author$project$Main$CompletedLogin = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Gallery$DlNop = {$: 3};
var $author$project$Page$Gallery$Loading = {$: 0};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $author$project$Page$Gallery$CompletedLoadGallery = function (a) {
	return {$: 10, a: a};
};
var $author$project$Api$Data$MypageGallery = F5(
	function (uid, name, dateStr, titleImage, sortedPublishedImages) {
		return {bP: dateStr, i: name, fk: sortedPublishedImages, cL: titleImage, c: uid};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Api$Data$MypageImage = F8(
	function (uid, filename, isPublish, origImageUrl, mypageImageSrcset, mypageSlideImageSrcset, mypageSingleImageDownloadUrl, blurhash) {
		return {bI: blurhash, b$: filename, ei: isPublish, ey: mypageImageSrcset, ez: mypageSingleImageDownloadUrl, eA: mypageSlideImageSrcset, eL: origImageUrl, c: uid};
	});
var $author$project$Api$Data$mypageImageDecoder = A3(
	$author$project$Api$Data$decode,
	'blurhash',
	$elm$json$Json$Decode$string,
	A3(
		$author$project$Api$Data$decode,
		'mypage_single_image_download_url',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'mypage_slide_image_srcset',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'mypage_image_srcset',
				$elm$json$Json$Decode$string,
				A3(
					$author$project$Api$Data$decode,
					'orig_image_url',
					$elm$json$Json$Decode$string,
					A3(
						$author$project$Api$Data$decode,
						'is_publish',
						$elm$json$Json$Decode$bool,
						A3(
							$author$project$Api$Data$decode,
							'filename',
							$elm$json$Json$Decode$string,
							A3(
								$author$project$Api$Data$decode,
								'uid',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageImage)))))))));
var $author$project$Api$Data$MypageTitleImage = F3(
	function (src, srcset, blurhash) {
		return {bI: blurhash, fn: src, fo: srcset};
	});
var $author$project$Api$Data$mypageTitleImageDecoder = A3(
	$author$project$Api$Data$decode,
	'blurhash',
	$elm$json$Json$Decode$string,
	A3(
		$author$project$Api$Data$decode,
		'srcset',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'src',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageTitleImage))));
var $author$project$Api$Data$mypageGalleryDecoder = A3(
	$author$project$Api$Data$decode,
	'sorted_published_images',
	$elm$json$Json$Decode$list($author$project$Api$Data$mypageImageDecoder),
	A3(
		$author$project$Api$Data$decode,
		'title_image',
		$author$project$Api$Data$mypageTitleImageDecoder,
		A3(
			$author$project$Api$Data$decode,
			'date_str',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'name',
				$elm$json$Json$Decode$string,
				A3(
					$author$project$Api$Data$decode,
					'uid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageGallery))))));
var $author$project$Api$Request$Default$getGallery = function (uid_path) {
	return A7(
		$author$project$Api$request,
		'GET',
		'/gallery/{uid}',
		_List_fromArray(
			[
				_Utils_Tuple2(
				'uid',
				$elm$core$Basics$identity(uid_path))
			]),
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Nothing,
		$author$project$Api$Data$mypageGalleryDecoder);
};
var $author$project$App$RequestWithAccessToken = function (a) {
	return {$: 0, a: a};
};
var $author$project$App$reqWithMaybeAccessToken = F2(
	function (cred, req) {
		if (!cred.$) {
			var _v1 = cred.a;
			var t = _v1.a;
			return $author$project$App$RequestWithAccessToken(
				A2($author$project$Api$withBearerToken, t, req));
		} else {
			return $author$project$App$RequestWithAccessToken(req);
		}
	});
var $author$project$Session$toRequest = F2(
	function (session, req) {
		return A2(
			$author$project$App$reqWithMaybeAccessToken,
			$author$project$Session$cred(session),
			A2(
				$author$project$Api$withBasePath,
				$author$project$Session$endpoint(session),
				req));
	});
var $author$project$Page$Gallery$fetchGallery = F2(
	function (session, uid) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Gallery$CompletedLoadGallery,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$getGallery(uid)));
	});
var $author$project$Page$Gallery$init = F2(
	function (session, uid) {
		return _Utils_Tuple2(
			{B: 0, ao: $author$project$Page$Gallery$DlNop, w: $author$project$Page$Gallery$Loading, bk: false, ap: false, H: $elm$core$Set$empty, j: session},
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($author$project$Page$Gallery$fetchGallery, session, uid)
					])));
	});
var $author$project$Page$Home$Start = 0;
var $author$project$Page$Home$CompletedLoadBanners = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$Data$BannerWithImageUrl = F6(
	function (uid, title, url, publishDatetime, closingDatetime, imageUrl) {
		return {dz: closingDatetime, C: imageUrl, eW: publishDatetime, fw: title, c: uid, fE: url};
	});
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {dA: col, f1: contextStack, eT: problem, aK: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.aK, s.dA, x, s.f));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.fn),
			s.d) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.fn);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.d, offset) < 0,
					0,
					{dA: col, f: s0.f, h: s0.h, d: offset, aK: row, fn: s0.fn});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.d, s.aK, s.dA, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.d, s1.d, s0.fn),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.d, s.fn);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{dA: 1, f: s.f, h: s.h, d: s.d + 1, aK: s.aK + 1, fn: s.fn}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{dA: s.dA + 1, f: s.f, h: s.h, d: newOffset, aK: s.aK, fn: s.fn}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.d, s.aK, s.dA, s.fn);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{dA: newCol, f: s.f, h: s.h, d: newOffset, aK: newRow, fn: s.fn});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {dA: col, eT: problem, aK: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.aK, p.dA, p.eT);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{dA: 1, f: _List_Nil, h: 1, d: 0, aK: 1, fn: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$Api$Time$decodeDateTimeIsoString = function (str) {
	var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
	if (!_v0.$) {
		var posix = _v0.a;
		return $elm$json$Json$Decode$succeed(posix);
	} else {
		return $elm$json$Json$Decode$fail('Invalid ISO date: ' + str);
	}
};
var $author$project$Api$Time$dateTimeDecoder = A2($elm$json$Json$Decode$andThen, $author$project$Api$Time$decodeDateTimeIsoString, $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $author$project$Api$Data$maybeField = F3(
	function (key, decoder, fallback) {
		var valueDecoder = $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
					$elm$json$Json$Decode$null(fallback)
				]));
		var fieldDecoder = A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value);
		var decodeObject = function (rawObject) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, fieldDecoder, rawObject);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2($elm$json$Json$Decode$decodeValue, valueDecoder, rawValue);
				if (!_v1.$) {
					var value = _v1.a;
					return $elm$json$Json$Decode$succeed(value);
				} else {
					var error = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(error));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, decodeObject, $elm$json$Json$Decode$value);
	});
var $author$project$Api$Data$maybeDecode = F3(
	function (key, decoder, fallback) {
		return $author$project$Api$Data$decodeChain(
			A3($author$project$Api$Data$maybeField, key, decoder, fallback));
	});
var $author$project$Api$Data$bannerWithImageUrlDecoder = A3(
	$author$project$Api$Data$decode,
	'image_url',
	$elm$json$Json$Decode$string,
	A4(
		$author$project$Api$Data$maybeDecode,
		'closing_datetime',
		$author$project$Api$Time$dateTimeDecoder,
		$elm$core$Maybe$Nothing,
		A4(
			$author$project$Api$Data$maybeDecode,
			'publish_datetime',
			$author$project$Api$Time$dateTimeDecoder,
			$elm$core$Maybe$Nothing,
			A3(
				$author$project$Api$Data$decode,
				'url',
				$elm$json$Json$Decode$string,
				A3(
					$author$project$Api$Data$decode,
					'title',
					$elm$json$Json$Decode$string,
					A3(
						$author$project$Api$Data$decode,
						'uid',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Api$Data$BannerWithImageUrl)))))));
var $author$project$Api$Request$Default$getBanners = A7(
	$author$project$Api$request,
	'GET',
	'/banner',
	_List_Nil,
	_List_Nil,
	_List_Nil,
	$elm$core$Maybe$Nothing,
	$elm$json$Json$Decode$list($author$project$Api$Data$bannerWithImageUrlDecoder));
var $author$project$Page$Home$fetchBanners = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Page$Home$CompletedLoadBanners,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getBanners));
};
var $author$project$Page$Home$CompletedLoadMovies = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$Data$MypageMovie = F5(
	function (uid, size, spriteImage, validUntil, shootingDateText) {
		return {fb: shootingDateText, cH: size, fm: spriteImage, c: uid, fF: validUntil};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Api$Data$mypageMovieDecoder = A3(
	$author$project$Api$Data$decode,
	'shooting_date_text',
	$elm$json$Json$Decode$string,
	A3(
		$author$project$Api$Data$decode,
		'valid_until',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'sprite_image',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'size',
				$elm$json$Json$Decode$int,
				A3(
					$author$project$Api$Data$decode,
					'uid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageMovie))))));
var $author$project$Api$Request$Default$getMovies = A7(
	$author$project$Api$request,
	'GET',
	'/movies',
	_List_Nil,
	_List_Nil,
	_List_Nil,
	$elm$core$Maybe$Nothing,
	$elm$json$Json$Decode$list($author$project$Api$Data$mypageMovieDecoder));
var $author$project$Page$Home$fetchMovies = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Page$Home$CompletedLoadMovies,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getMovies));
};
var $author$project$Page$Home$init = function (session) {
	return _Utils_Tuple2(
		{aR: 0, aw: _List_Nil, bi: $elm$core$Maybe$Nothing, ep: $elm$core$Set$empty, bn: _List_Nil, j: session},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Page$Home$fetchBanners(session),
					$author$project$Page$Home$fetchMovies(session)
				])));
};
var $author$project$Page$Login$Login = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Page$Login$init = function (session) {
	return _Utils_Tuple2(
		{
			a$: $author$project$Page$Login$Login(
				{bU: ''}),
			bm: _List_Nil,
			aI: _List_Nil,
			j: session
		},
		$elm$core$Platform$Cmd$none);
};
var $author$project$Page$Profile$Loading = {$: 0};
var $author$project$Page$Profile$CompletedLoadCustomer = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$Data$MypageCustomerWithSortedFamilyMembers = F9(
	function (uid, phoneNumber, postalCode, prefecture, address1, address2, isAcceptDm, representativeId, sortedFamilyMembers) {
		return {bF: address1, bG: address2, b7: isAcceptDm, aq: phoneNumber, cv: postalCode, cw: prefecture, cB: representativeId, fj: sortedFamilyMembers, c: uid};
	});
var $author$project$Api$Data$MypageFamilyMember = function (uid) {
	return function (customerId) {
		return function (relationship) {
			return function (firstName) {
				return function (lastName) {
					return function (firstNameFurigana) {
						return function (lastNameFurigana) {
							return function (birthday) {
								return function (emails) {
									return function (height) {
										return function (shoeSize) {
											return {bH: birthday, am: customerId, aY: emails, aZ: firstName, a_: firstNameFurigana, b2: height, a3: lastName, a4: lastNameFurigana, cz: relationship, cF: shoeSize, c: uid};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$Time$decodeDateIsoString = function (str) {
	var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str + 'T00:00:00.000Z');
	if (!_v0.$) {
		var posix = _v0.a;
		return $elm$json$Json$Decode$succeed(posix);
	} else {
		return $elm$json$Json$Decode$fail('Invalid calendar date: ' + str);
	}
};
var $author$project$Api$Time$dateDecoder = A2($elm$json$Json$Decode$andThen, $author$project$Api$Time$decodeDateIsoString, $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Api$Data$mypageFamilyMemberDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'shoe_size',
	$elm$json$Json$Decode$float,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'height',
		$elm$json$Json$Decode$float,
		$elm$core$Maybe$Nothing,
		A4(
			$author$project$Api$Data$maybeDecode,
			'emails',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$author$project$Api$Data$maybeDecode,
				'birthday',
				$author$project$Api$Time$dateDecoder,
				$elm$core$Maybe$Nothing,
				A4(
					$author$project$Api$Data$maybeDecode,
					'last_name_furigana',
					$elm$json$Json$Decode$string,
					$elm$core$Maybe$Nothing,
					A4(
						$author$project$Api$Data$maybeDecode,
						'first_name_furigana',
						$elm$json$Json$Decode$string,
						$elm$core$Maybe$Nothing,
						A4(
							$author$project$Api$Data$maybeDecode,
							'last_name',
							$elm$json$Json$Decode$string,
							$elm$core$Maybe$Nothing,
							A4(
								$author$project$Api$Data$maybeDecode,
								'first_name',
								$elm$json$Json$Decode$string,
								$elm$core$Maybe$Nothing,
								A4(
									$author$project$Api$Data$maybeDecode,
									'relationship',
									$elm$json$Json$Decode$string,
									$elm$core$Maybe$Nothing,
									A3(
										$author$project$Api$Data$decode,
										'customer_id',
										$elm$json$Json$Decode$string,
										A3(
											$author$project$Api$Data$decode,
											'uid',
											$elm$json$Json$Decode$string,
											$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageFamilyMember))))))))))));
var $author$project$Api$Data$mypageCustomerWithSortedFamilyMembersDecoder = A3(
	$author$project$Api$Data$decode,
	'sorted_family_members',
	$elm$json$Json$Decode$list($author$project$Api$Data$mypageFamilyMemberDecoder),
	A4(
		$author$project$Api$Data$maybeDecode,
		'representative_id',
		$elm$json$Json$Decode$string,
		$elm$core$Maybe$Nothing,
		A4(
			$author$project$Api$Data$maybeDecode,
			'is_accept_dm',
			$elm$json$Json$Decode$bool,
			$elm$core$Maybe$Nothing,
			A4(
				$author$project$Api$Data$maybeDecode,
				'address2',
				$elm$json$Json$Decode$string,
				$elm$core$Maybe$Nothing,
				A4(
					$author$project$Api$Data$maybeDecode,
					'address1',
					$elm$json$Json$Decode$string,
					$elm$core$Maybe$Nothing,
					A4(
						$author$project$Api$Data$maybeDecode,
						'prefecture',
						$elm$json$Json$Decode$string,
						$elm$core$Maybe$Nothing,
						A4(
							$author$project$Api$Data$maybeDecode,
							'postal_code',
							$elm$json$Json$Decode$string,
							$elm$core$Maybe$Nothing,
							A4(
								$author$project$Api$Data$maybeDecode,
								'phone_number',
								$elm$json$Json$Decode$string,
								$elm$core$Maybe$Nothing,
								A3(
									$author$project$Api$Data$decode,
									'uid',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageCustomerWithSortedFamilyMembers))))))))));
var $author$project$Api$Request$Default$getCustomer = A7($author$project$Api$request, 'GET', '/customer', _List_Nil, _List_Nil, _List_Nil, $elm$core$Maybe$Nothing, $author$project$Api$Data$mypageCustomerWithSortedFamilyMembersDecoder);
var $author$project$Page$Profile$fetchCustomer = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Page$Profile$CompletedLoadCustomer,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getCustomer));
};
var $author$project$Page$Profile$SetSystemTime = function (a) {
	return {$: 9, a: a};
};
var $elm$time$Time$here = _Time_here(0);
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Page$Profile$setSystemTime = A2(
	$elm$core$Task$perform,
	$author$project$Page$Profile$SetSystemTime,
	A3($elm$core$Task$map2, $elm$core$Tuple$pair, $elm$time$Time$here, $elm$time$Time$now));
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Page$Profile$init = function (session) {
	return _Utils_Tuple2(
		{
			dG: $author$project$Page$Profile$Loading,
			dT: _List_Nil,
			bm: _List_Nil,
			cu: $elm$time$Time$millisToPosix(0),
			j: session,
			cO: $elm$time$Time$utc
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Page$Profile$fetchCustomer(session),
					$author$project$Page$Profile$setSystemTime
				])));
};
var $author$project$Page$Reservation$InitStep = 0;
var $author$project$Page$Reservation$Loading = {$: 0};
var $author$project$Page$Reservation$New = 0;
var $author$project$Page$Reservation$ShowReservation = 13;
var $author$project$Page$Reservation$Start = 0;
var $author$project$Page$Reservation$Update = 1;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$App$scrollIntoView = _Platform_outgoingPort(
	'scrollIntoView',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'alignToTop',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$bool, $);
					}($.cQ)),
					_Utils_Tuple2(
					'behavior',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.cS)),
					_Utils_Tuple2(
					'block',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.cT)),
					_Utils_Tuple2(
					'elementId',
					$elm$json$Json$Encode$string($.c$)),
					_Utils_Tuple2(
					'inline',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.c6))
				]));
	});
var $author$project$App$doScrollIntoView = function (scrollTo) {
	if (scrollTo.$ === 1) {
		return $elm$core$Platform$Cmd$none;
	} else {
		var v = scrollTo.a;
		return $author$project$App$scrollIntoView(
			{
				cQ: $elm$core$Maybe$Nothing,
				cS: $elm$core$Maybe$Just('smooth'),
				cT: $elm$core$Maybe$Nothing,
				c$: v,
				c6: $elm$core$Maybe$Nothing
			});
	}
};
var $author$project$Page$Profile$emptyCustomer = {bF: '', bG: '', dW: _List_Nil, b7: false, aq: '', cv: '', cw: '', cB: '__new__', c: '__new__'};
var $author$project$Api$Data$ReservationStatusTemporary = 0;
var $author$project$Page$Reservation$emptyReservationOtherInfo = function (session) {
	return {
		dr: '',
		ds: false,
		d3: !_Utils_eq(
			$elm$core$Maybe$Nothing,
			$author$project$Session$viewer(session)),
		e2: 0
	};
};
var $author$project$Page$Reservation$emptyReservationSchedule = {ca: false, A: $elm$core$Maybe$Nothing, aj: $elm$core$Maybe$Nothing, v: $elm$core$Maybe$Nothing, ae: $elm$core$Maybe$Nothing, Q: $elm$core$Maybe$Nothing};
var $author$project$Page$Reservation$emptyReservationPlan = {aU: $elm$core$Array$empty, eO: $elm$core$Maybe$Nothing, eR: $elm$core$Array$empty, as: $elm$core$Maybe$Nothing, g: $author$project$Page$Reservation$emptyReservationSchedule, fe: $elm$core$Maybe$Nothing, c: '__new__'};
var $author$project$Page$Reservation$emptyWatingList = {
	dH: '',
	dI: '',
	dK: A2(
		$elm$core$List$map,
		function (_v0) {
			return {a8: '', bb: ''};
		},
		A2($elm$core$List$range, 0, 2)),
	bU: '',
	aa: '',
	aq: '',
	bc: '80データ',
	c: '__new__'
};
var $author$project$Page$Reservation$CompletedLoadCustomer = function (a) {
	return {$: 19, a: a};
};
var $author$project$Page$Reservation$fetchCustomer = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Page$Reservation$CompletedLoadCustomer,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getCustomer));
};
var $author$project$Page$Reservation$CompletedLoadPurposeOfUse = function (a) {
	return {$: 20, a: a};
};
var $author$project$Api$Data$MypagePurposeOfUse = F4(
	function (uid, name, canMultipleReservations, planCategories) {
		return {dw: canMultipleReservations, i: name, eN: planCategories, c: uid};
	});
var $author$project$Api$Data$MypagePlanCategory = F6(
	function (uid, name, displayOrder, imageUrl, isActive, shootingPlans) {
		return {W: displayOrder, C: imageUrl, b8: isActive, i: name, ff: shootingPlans, c: uid};
	});
var $author$project$Api$Data$MypageShootingPlan = function (uid) {
	return function (categoryName) {
		return function (displayName) {
			return function (displayOrder) {
				return function (timeRequired) {
					return function (price) {
						return function (options) {
							return function (customFields) {
								return function (planCategory) {
									return function (imageUrl) {
										return function (note) {
											return {bK: categoryName, aU: customFields, aV: displayName, W: displayOrder, C: imageUrl, aa: note, cq: options, eO: planCategory, cx: price, bc: timeRequired, c: uid};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$Data$CustomField = F4(
	function (name, isRequired, fieldType, optionItemsText) {
		return {b_: fieldType, cb: isRequired, i: name, cp: optionItemsText};
	});
var $author$project$Api$Data$FieldTypeCheckbox = 2;
var $author$project$Api$Data$FieldTypeDate = 4;
var $author$project$Api$Data$FieldTypeRadio = 1;
var $author$project$Api$Data$FieldTypeSelect = 3;
var $author$project$Api$Data$FieldTypeText = 0;
var $author$project$Api$Data$fieldTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		switch (value) {
			case 'text':
				return $elm$json$Json$Decode$succeed(0);
			case 'radio':
				return $elm$json$Json$Decode$succeed(1);
			case 'checkbox':
				return $elm$json$Json$Decode$succeed(2);
			case 'select':
				return $elm$json$Json$Decode$succeed(3);
			case 'date':
				return $elm$json$Json$Decode$succeed(4);
			default:
				var other = value;
				return $elm$json$Json$Decode$fail('Unknown type: ' + other);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Data$customFieldDecoder = A3(
	$author$project$Api$Data$decode,
	'option_items_text',
	$elm$json$Json$Decode$string,
	A3(
		$author$project$Api$Data$decode,
		'field_type',
		$author$project$Api$Data$fieldTypeDecoder,
		A3(
			$author$project$Api$Data$decode,
			'is_required',
			$elm$json$Json$Decode$bool,
			A3(
				$author$project$Api$Data$decode,
				'name',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Api$Data$CustomField)))));
var $author$project$Api$Data$PlanCategory = F5(
	function (uid, name, displayOrder, imageUrl, isActive) {
		return {W: displayOrder, C: imageUrl, b8: isActive, i: name, c: uid};
	});
var $author$project$Api$Data$planCategoryDecoder = A3(
	$author$project$Api$Data$decode,
	'is_active',
	$elm$json$Json$Decode$bool,
	A3(
		$author$project$Api$Data$decode,
		'image_url',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'display_order',
			$elm$json$Json$Decode$int,
			A3(
				$author$project$Api$Data$decode,
				'name',
				$elm$json$Json$Decode$string,
				A3(
					$author$project$Api$Data$decode,
					'uid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Api$Data$PlanCategory))))));
var $author$project$Api$Data$PlanOption = F7(
	function (uid, name, displayName, isRequired, fieldType, optionItemsText, optionItems) {
		return {aV: displayName, b_: fieldType, cb: isRequired, i: name, eI: optionItems, cp: optionItemsText, c: uid};
	});
var $author$project$Api$Data$OptionItem = F2(
	function (name, unitPrice) {
		return {i: name, fC: unitPrice};
	});
var $author$project$Api$Data$optionItemDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'unit_price',
	$elm$json$Json$Decode$int,
	$elm$core$Maybe$Nothing,
	A3(
		$author$project$Api$Data$decode,
		'name',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Api$Data$OptionItem)));
var $author$project$Api$Data$planOptionDecoder = A3(
	$author$project$Api$Data$decode,
	'option_items',
	$elm$json$Json$Decode$list($author$project$Api$Data$optionItemDecoder),
	A3(
		$author$project$Api$Data$decode,
		'option_items_text',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'field_type',
			$author$project$Api$Data$fieldTypeDecoder,
			A3(
				$author$project$Api$Data$decode,
				'is_required',
				$elm$json$Json$Decode$bool,
				A3(
					$author$project$Api$Data$decode,
					'display_name',
					$elm$json$Json$Decode$string,
					A3(
						$author$project$Api$Data$decode,
						'name',
						$elm$json$Json$Decode$string,
						A3(
							$author$project$Api$Data$decode,
							'uid',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$Api$Data$PlanOption))))))));
var $author$project$Api$Data$mypageShootingPlanDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'note',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'image_url',
		$elm$json$Json$Decode$string,
		$elm$core$Maybe$Nothing,
		A3(
			$author$project$Api$Data$decode,
			'plan_category',
			$author$project$Api$Data$planCategoryDecoder,
			A3(
				$author$project$Api$Data$decode,
				'custom_fields',
				$elm$json$Json$Decode$list($author$project$Api$Data$customFieldDecoder),
				A3(
					$author$project$Api$Data$decode,
					'options',
					$elm$json$Json$Decode$list($author$project$Api$Data$planOptionDecoder),
					A3(
						$author$project$Api$Data$decode,
						'price',
						$elm$json$Json$Decode$int,
						A3(
							$author$project$Api$Data$decode,
							'time_required',
							$elm$json$Json$Decode$int,
							A3(
								$author$project$Api$Data$decode,
								'display_order',
								$elm$json$Json$Decode$int,
								A3(
									$author$project$Api$Data$decode,
									'display_name',
									$elm$json$Json$Decode$string,
									A3(
										$author$project$Api$Data$decode,
										'category_name',
										$elm$json$Json$Decode$string,
										A3(
											$author$project$Api$Data$decode,
											'uid',
											$elm$json$Json$Decode$string,
											$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageShootingPlan))))))))))));
var $author$project$Api$Data$mypagePlanCategoryDecoder = A3(
	$author$project$Api$Data$decode,
	'shooting_plans',
	$elm$json$Json$Decode$list($author$project$Api$Data$mypageShootingPlanDecoder),
	A3(
		$author$project$Api$Data$decode,
		'is_active',
		$elm$json$Json$Decode$bool,
		A3(
			$author$project$Api$Data$decode,
			'image_url',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'display_order',
				$elm$json$Json$Decode$int,
				A3(
					$author$project$Api$Data$decode,
					'name',
					$elm$json$Json$Decode$string,
					A3(
						$author$project$Api$Data$decode,
						'uid',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Api$Data$MypagePlanCategory)))))));
var $author$project$Api$Data$mypagePurposeOfUseDecoder = A3(
	$author$project$Api$Data$decode,
	'plan_categories',
	$elm$json$Json$Decode$list($author$project$Api$Data$mypagePlanCategoryDecoder),
	A3(
		$author$project$Api$Data$decode,
		'can_multiple_reservations',
		$elm$json$Json$Decode$bool,
		A3(
			$author$project$Api$Data$decode,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'uid',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Api$Data$MypagePurposeOfUse)))));
var $author$project$Api$Request$Default$getPurposeOfUse = A7(
	$author$project$Api$request,
	'GET',
	'/purpose_of_use',
	_List_Nil,
	_List_Nil,
	_List_Nil,
	$elm$core$Maybe$Nothing,
	$elm$json$Json$Decode$list($author$project$Api$Data$mypagePurposeOfUseDecoder));
var $author$project$Page$Reservation$fetchPurposeOfUseList = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Page$Reservation$CompletedLoadPurposeOfUse,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getPurposeOfUse));
};
var $author$project$Page$Reservation$CompletedLoadReservation = function (a) {
	return {$: 21, a: a};
};
var $author$project$Api$Data$Reservation = function (uid) {
	return function (reservationStatus) {
		return function (customerId) {
			return function (studioId) {
				return function (resourceId) {
					return function (resourceItemId) {
						return function (shootingDate) {
							return function (dayOfWeek) {
								return function (startTime) {
									return function (endTime) {
										return function (purposeOfUseId) {
											return function (planCategoryId) {
												return function (shootingPlanId) {
													return function (planInfo) {
														return function (shopName) {
															return function (studioName) {
																return function (isEditable) {
																	return function (phoneNumber) {
																		return function (firstName) {
																			return function (lastName) {
																				return function (firstNameFurigana) {
																					return function (lastNameFurigana) {
																						return function (emails) {
																							return {am: customerId, bQ: dayOfWeek, aY: emails, bW: endTime, aZ: firstName, a_: firstNameFurigana, eg: isEditable, a3: lastName, a4: lastNameFurigana, aq: phoneNumber, ct: planCategoryId, eP: planInfo, eX: purposeOfUseId, e2: reservationStatus, e4: resourceId, cD: resourceItemId, a8: shootingDate, cG: shootingPlanId, fi: shopName, bb: startTime, af: studioId, fq: studioName, c: uid};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$Data$PlanInfo = F6(
	function (shootingPlan, planOptions, customFields, agreeToPrivacyPolicyAndTerms, additionalComments, hasUsedBefore) {
		return {dr: additionalComments, ds: agreeToPrivacyPolicyAndTerms, aU: customFields, d3: hasUsedBefore, eR: planOptions, fe: shootingPlan};
	});
var $author$project$Api$Data$CustomFieldForm = F2(
	function (value, customField) {
		return {dF: customField, be: value};
	});
var $author$project$Api$Data$customFieldFormDecoder = A3(
	$author$project$Api$Data$decode,
	'custom_field',
	$author$project$Api$Data$customFieldDecoder,
	A3(
		$author$project$Api$Data$decode,
		'value',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Api$Data$CustomFieldForm)));
var $author$project$Api$Data$PlanOptionsForm = F2(
	function (value, planOption) {
		return {eQ: planOption, be: value};
	});
var $author$project$Api$Data$planOptionsFormDecoder = A3(
	$author$project$Api$Data$decode,
	'plan_option',
	$author$project$Api$Data$planOptionDecoder,
	A3(
		$author$project$Api$Data$decode,
		'value',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Api$Data$PlanOptionsForm)));
var $author$project$Api$Data$ShootingPlanForm = F9(
	function (uid, categoryName, name, displayOrder, price, options, customFields, imageUrl, note) {
		return {bK: categoryName, aU: customFields, W: displayOrder, C: imageUrl, i: name, aa: note, cq: options, cx: price, c: uid};
	});
var $author$project$Api$Data$shootingPlanFormDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'note',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'image_url',
		$elm$json$Json$Decode$string,
		$elm$core$Maybe$Nothing,
		A3(
			$author$project$Api$Data$decode,
			'custom_fields',
			$elm$json$Json$Decode$list($author$project$Api$Data$customFieldDecoder),
			A3(
				$author$project$Api$Data$decode,
				'options',
				$elm$json$Json$Decode$list($author$project$Api$Data$planOptionDecoder),
				A3(
					$author$project$Api$Data$decode,
					'price',
					$elm$json$Json$Decode$int,
					A3(
						$author$project$Api$Data$decode,
						'display_order',
						$elm$json$Json$Decode$int,
						A3(
							$author$project$Api$Data$decode,
							'name',
							$elm$json$Json$Decode$string,
							A3(
								$author$project$Api$Data$decode,
								'category_name',
								$elm$json$Json$Decode$string,
								A3(
									$author$project$Api$Data$decode,
									'uid',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Api$Data$ShootingPlanForm))))))))));
var $author$project$Api$Data$planInfoDecoder = A3(
	$author$project$Api$Data$decode,
	'has_used_before',
	$elm$json$Json$Decode$bool,
	A3(
		$author$project$Api$Data$decode,
		'additional_comments',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'agree_to_privacy_policy_and_terms',
			$elm$json$Json$Decode$bool,
			A3(
				$author$project$Api$Data$decode,
				'custom_fields',
				$elm$json$Json$Decode$list($author$project$Api$Data$customFieldFormDecoder),
				A3(
					$author$project$Api$Data$decode,
					'plan_options',
					$elm$json$Json$Decode$list($author$project$Api$Data$planOptionsFormDecoder),
					A3(
						$author$project$Api$Data$decode,
						'shooting_plan',
						$author$project$Api$Data$shootingPlanFormDecoder,
						$elm$json$Json$Decode$succeed($author$project$Api$Data$PlanInfo)))))));
var $author$project$Api$Data$ReservationStatusCanceled = 3;
var $author$project$Api$Data$ReservationStatusConfirmed = 1;
var $author$project$Api$Data$ReservationStatusConflict = 2;
var $author$project$Api$Data$reservationStatusDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (value) {
		switch (value) {
			case 'temporary':
				return $elm$json$Json$Decode$succeed(0);
			case 'confirmed':
				return $elm$json$Json$Decode$succeed(1);
			case 'conflict':
				return $elm$json$Json$Decode$succeed(2);
			case 'canceled':
				return $elm$json$Json$Decode$succeed(3);
			default:
				var other = value;
				return $elm$json$Json$Decode$fail('Unknown type: ' + other);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$Data$reservationDecoder = A3(
	$author$project$Api$Data$decode,
	'emails',
	$elm$json$Json$Decode$string,
	A3(
		$author$project$Api$Data$decode,
		'last_name_furigana',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'first_name_furigana',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'last_name',
				$elm$json$Json$Decode$string,
				A3(
					$author$project$Api$Data$decode,
					'first_name',
					$elm$json$Json$Decode$string,
					A3(
						$author$project$Api$Data$decode,
						'phone_number',
						$elm$json$Json$Decode$string,
						A3(
							$author$project$Api$Data$decode,
							'is_editable',
							$elm$json$Json$Decode$bool,
							A3(
								$author$project$Api$Data$decode,
								'studio_name',
								$elm$json$Json$Decode$string,
								A3(
									$author$project$Api$Data$decode,
									'shop_name',
									$elm$json$Json$Decode$string,
									A3(
										$author$project$Api$Data$decode,
										'plan_info',
										$author$project$Api$Data$planInfoDecoder,
										A3(
											$author$project$Api$Data$decode,
											'shooting_plan_id',
											$elm$json$Json$Decode$string,
											A3(
												$author$project$Api$Data$decode,
												'plan_category_id',
												$elm$json$Json$Decode$string,
												A3(
													$author$project$Api$Data$decode,
													'purpose_of_use_id',
													$elm$json$Json$Decode$string,
													A3(
														$author$project$Api$Data$decode,
														'end_time',
														$elm$json$Json$Decode$string,
														A3(
															$author$project$Api$Data$decode,
															'start_time',
															$elm$json$Json$Decode$string,
															A3(
																$author$project$Api$Data$decode,
																'day_of_week',
																$elm$json$Json$Decode$int,
																A3(
																	$author$project$Api$Data$decode,
																	'shooting_date',
																	$author$project$Api$Time$dateDecoder,
																	A3(
																		$author$project$Api$Data$decode,
																		'resource_item_id',
																		$elm$json$Json$Decode$string,
																		A3(
																			$author$project$Api$Data$decode,
																			'resource_id',
																			$elm$json$Json$Decode$string,
																			A3(
																				$author$project$Api$Data$decode,
																				'studio_id',
																				$elm$json$Json$Decode$string,
																				A3(
																					$author$project$Api$Data$decode,
																					'customer_id',
																					$elm$json$Json$Decode$string,
																					A3(
																						$author$project$Api$Data$decode,
																						'reservation_status',
																						$author$project$Api$Data$reservationStatusDecoder,
																						A3(
																							$author$project$Api$Data$decode,
																							'uid',
																							$elm$json$Json$Decode$string,
																							$elm$json$Json$Decode$succeed($author$project$Api$Data$Reservation))))))))))))))))))))))));
var $author$project$Api$Request$Default$getReservation = A7(
	$author$project$Api$request,
	'GET',
	'/reservation',
	_List_Nil,
	_List_Nil,
	_List_Nil,
	$elm$core$Maybe$Nothing,
	$elm$json$Json$Decode$list($author$project$Api$Data$reservationDecoder));
var $author$project$Page$Reservation$fetchReservation = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Page$Reservation$CompletedLoadReservation,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getReservation));
};
var $author$project$Page$Reservation$CompletedLoadResources = function (a) {
	return {$: 23, a: a};
};
var $author$project$Api$Data$ResourceSchedule = F5(
	function (uid, studioId, name, resourceItems, resourceReservations) {
		return {i: name, e5: resourceItems, e6: resourceReservations, af: studioId, c: uid};
	});
var $author$project$Api$Data$ResourceItem = F6(
	function (uid, name, imageUrl, note, instagramUrl, iconImageSrcset) {
		return {b5: iconImageSrcset, C: imageUrl, b6: instagramUrl, i: name, aa: note, c: uid};
	});
var $author$project$Api$Data$resourceItemDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'icon_image_srcset',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'instagram_url',
		$elm$json$Json$Decode$string,
		$elm$core$Maybe$Nothing,
		A4(
			$author$project$Api$Data$maybeDecode,
			'note',
			$elm$json$Json$Decode$string,
			$elm$core$Maybe$Nothing,
			A4(
				$author$project$Api$Data$maybeDecode,
				'image_url',
				$elm$json$Json$Decode$string,
				$elm$core$Maybe$Nothing,
				A3(
					$author$project$Api$Data$decode,
					'name',
					$elm$json$Json$Decode$string,
					A3(
						$author$project$Api$Data$decode,
						'uid',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Api$Data$ResourceItem)))))));
var $author$project$Api$Data$ResourceReservation = F7(
	function (uid, resourceItemId, startAt, endAt, openingTime, closingTime, isDelete) {
		return {aT: closingTime, bV: endAt, ef: isDelete, a5: openingTime, cD: resourceItemId, cI: startAt, c: uid};
	});
var $author$project$Api$Data$resourceReservationDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'is_delete',
	$elm$json$Json$Decode$bool,
	$elm$core$Maybe$Just(false),
	A3(
		$author$project$Api$Data$decode,
		'closing_time',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'opening_time',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'end_at',
				$author$project$Api$Time$dateDecoder,
				A3(
					$author$project$Api$Data$decode,
					'start_at',
					$author$project$Api$Time$dateDecoder,
					A3(
						$author$project$Api$Data$decode,
						'resource_item_id',
						$elm$json$Json$Decode$string,
						A3(
							$author$project$Api$Data$decode,
							'uid',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$Api$Data$ResourceReservation))))))));
var $author$project$Api$Data$resourceScheduleDecoder = A3(
	$author$project$Api$Data$decode,
	'resource_reservations',
	$elm$json$Json$Decode$list($author$project$Api$Data$resourceReservationDecoder),
	A3(
		$author$project$Api$Data$decode,
		'resource_items',
		$elm$json$Json$Decode$list($author$project$Api$Data$resourceItemDecoder),
		A3(
			$author$project$Api$Data$decode,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'studio_id',
				$elm$json$Json$Decode$string,
				A3(
					$author$project$Api$Data$decode,
					'uid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Api$Data$ResourceSchedule))))));
var $author$project$Api$Request$Default$getResourceSchedules = A7(
	$author$project$Api$request,
	'GET',
	'/resource_schedules',
	_List_Nil,
	_List_Nil,
	_List_Nil,
	$elm$core$Maybe$Nothing,
	$elm$json$Json$Decode$list($author$project$Api$Data$resourceScheduleDecoder));
var $author$project$Page$Reservation$fetchResources = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Page$Reservation$CompletedLoadResources,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getResourceSchedules));
};
var $author$project$Page$Reservation$CustomFieldForm = F2(
	function (value, customField) {
		return {dF: customField, be: value};
	});
var $author$project$Page$Reservation$mapCustomField = function (data) {
	return A2($author$project$Page$Reservation$CustomFieldForm, data.be, data.dF);
};
var $author$project$Page$Reservation$PlanOptionsForm = F2(
	function (value, planOption) {
		return {eQ: planOption, be: value};
	});
var $author$project$Page$Reservation$mapPlanOption = function (data) {
	return A2($author$project$Page$Reservation$PlanOptionsForm, data.be, data.eQ);
};
var $author$project$Page$Reservation$mapShootingPlanForm = function (data) {
	return {bK: data.bK, aU: data.aU, W: data.W, C: data.C, i: data.i, aa: data.aa, cq: data.cq, cx: data.cx, c: data.c};
};
var $author$project$Page$Reservation$mapReservation = function (data) {
	return {
		am: data.am,
		bQ: data.bQ,
		aY: data.aY,
		bW: data.bW,
		aZ: data.aZ,
		a_: data.a_,
		eg: data.eg,
		a3: data.a3,
		a4: data.a4,
		aq: data.aq,
		ct: data.ct,
		eP: {
			dr: data.eP.dr,
			ds: data.eP.ds,
			aU: A2($elm$core$List$map, $author$project$Page$Reservation$mapCustomField, data.eP.aU),
			d3: data.eP.d3,
			eR: A2($elm$core$List$map, $author$project$Page$Reservation$mapPlanOption, data.eP.eR),
			fe: $author$project$Page$Reservation$mapShootingPlanForm(data.eP.fe)
		},
		eX: data.eX,
		e2: data.e2,
		e4: data.e4,
		cD: data.cD,
		a8: data.a8,
		cG: data.cG,
		fi: data.fi,
		bb: data.bb,
		af: data.af,
		fq: data.fq,
		c: data.c
	};
};
var $author$project$Session$reservations = function (session) {
	if (!session.$) {
		var g = session.c;
		return g.gN;
	} else {
		return _List_Nil;
	}
};
var $author$project$Page$Reservation$SetSystemTime = function (a) {
	return {$: 37, a: a};
};
var $author$project$Page$Reservation$setSystemTime = A2(
	$elm$core$Task$perform,
	$author$project$Page$Reservation$SetSystemTime,
	A3($elm$core$Task$map2, $elm$core$Tuple$pair, $elm$time$Time$here, $elm$time$Time$now));
var $author$project$Page$Reservation$init = function (session) {
	var reservations = A2(
		$elm$core$List$map,
		$author$project$Page$Reservation$mapReservation,
		$author$project$Session$reservations(session));
	var mode = function () {
		if (!reservations.b) {
			return 0;
		} else {
			return 1;
		}
	}();
	var doFetchReservation = function () {
		if (!session.$) {
			return $author$project$Page$Reservation$fetchReservation(session);
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	}();
	var customer = _Utils_update(
		$author$project$Page$Profile$emptyCustomer,
		{cB: '__new__'});
	return _Utils_Tuple2(
		{
			aR: 0,
			aw: _List_Nil,
			dG: customer,
			dT: _List_Nil,
			a1: false,
			a2: false,
			bm: _List_Nil,
			aG: mode,
			cu: $elm$time$Time$millisToPosix(0),
			a7: $author$project$Page$Reservation$Loading,
			G: $author$project$Page$Reservation$emptyReservationOtherInfo(session),
			b: $author$project$Page$Reservation$emptyReservationPlan,
			gN: reservations,
			bs: $author$project$Page$Reservation$Loading,
			at: $author$project$Page$Reservation$Loading,
			j: session,
			bt: $author$project$Page$Reservation$Loading,
			e: (!mode) ? 0 : 13,
			R: $elm$core$Maybe$Nothing,
			s: $author$project$Page$Reservation$emptyWatingList,
			cO: $elm$time$Time$utc
		},
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$App$doScrollIntoView(
					$elm$core$Maybe$Just('Top')),
					$author$project$Page$Reservation$fetchPurposeOfUseList(session),
					$author$project$Page$Reservation$fetchResources(session),
					$author$project$Page$Reservation$fetchCustomer(session),
					$author$project$Page$Reservation$setSystemTime,
					doFetchReservation
				])));
};
var $author$project$App$storeCache = _Platform_outgoingPort(
	'storeCache',
	function ($) {
		return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$core$Basics$identity, $);
	});
var $author$project$App$logout = $author$project$App$storeCache($elm$core$Maybe$Nothing);
var $elm$browser$Browser$Navigation$reload = _Browser_reload(false);
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Main$updateWith = F4(
	function (toModel, toMsg, model, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			toModel(subModel),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Api$Data$encode = F3(
	function (key, encoder, value) {
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(
				key,
				encoder(value)));
	});
var $author$project$Api$Data$encodeMagiclinkTokenPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'token', $elm$json$Json$Encode$string, model.fy)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeObject = A2(
	$elm$core$Basics$composeL,
	$elm$json$Json$Encode$object,
	$elm$core$List$filterMap($elm$core$Basics$identity));
var $author$project$Api$Data$encodeMagiclinkToken = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeMagiclinkTokenPairs);
var $author$project$Api$Request$Default$validateMagiclink = function (magiclinkToken_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/magic/validate',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeMagiclinkToken(magiclinkToken_body)),
		$author$project$Api$Data$tokenDecoder);
};
var $author$project$Main$changeRouteTo = F2(
	function (maybeRoute, model) {
		var session = $author$project$Main$toSession(model);
		if ((!maybeRoute.$) && (maybeRoute.a.$ === 7)) {
			var token = maybeRoute.a.a;
			return _Utils_Tuple2(
				model,
				A2(
					$author$project$App$callApi,
					$author$project$Main$CompletedLogin,
					A2(
						$author$project$Session$toRequest,
						session,
						$author$project$Api$Request$Default$validateMagiclink(
							{fy: token}))));
		} else {
			var _v1 = $author$project$Session$viewer(session);
			if (_v1.$ === 1) {
				if ((!maybeRoute.$) && (maybeRoute.a.$ === 4)) {
					var _v3 = maybeRoute.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Reservation,
						$author$project$Main$GotReservationMsg,
						model,
						$author$project$Page$Reservation$init(session));
				} else {
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Login,
						$author$project$Main$GotLoginMsg,
						model,
						$author$project$Page$Login$init(session));
				}
			} else {
				if (maybeRoute.$ === 1) {
					return _Utils_Tuple2(
						$author$project$Main$NotFound(session),
						$elm$core$Platform$Cmd$none);
				} else {
					switch (maybeRoute.a.$) {
						case 1:
							var _v5 = maybeRoute.a;
							return _Utils_Tuple2(
								model,
								A2(
									$author$project$Route$replaceUrl,
									$author$project$Session$navKey(session),
									$author$project$Route$Home));
						case 0:
							var _v6 = maybeRoute.a;
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Home,
								$author$project$Main$GotHomeMsg,
								model,
								$author$project$Page$Home$init(session));
						case 2:
							var uid = maybeRoute.a.a;
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Gallery,
								$author$project$Main$GotGalleryMsg,
								model,
								A2($author$project$Page$Gallery$init, session, uid));
						case 3:
							var _v7 = maybeRoute.a;
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Profile,
								$author$project$Main$GotProfileMsg,
								model,
								$author$project$Page$Profile$init(session));
						case 4:
							var _v8 = maybeRoute.a;
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Reservation,
								$author$project$Main$GotReservationMsg,
								model,
								$author$project$Page$Reservation$init(session));
						case 5:
							var _v9 = maybeRoute.a;
							return A4(
								$author$project$Main$updateWith,
								$author$project$Main$Login,
								$author$project$Main$GotLoginMsg,
								model,
								$author$project$Page$Login$init(session));
						case 7:
							var token = maybeRoute.a.a;
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						default:
							var _v10 = maybeRoute.a;
							return _Utils_Tuple2(
								model,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[$author$project$App$logout, $elm$browser$Browser$Navigation$reload])));
					}
				}
			}
		}
	});
var $author$project$Session$closeNavbarBurger = function (session) {
	if (!session.$) {
		var k = session.a;
		var v = session.b;
		var g = session.c;
		return A3(
			$author$project$Session$LoggedIn,
			k,
			v,
			_Utils_update(
				g,
				{gv: false}));
	} else {
		var k = session.a;
		var g = session.b;
		return A2(
			$author$project$Session$Guest,
			k,
			_Utils_update(
				g,
				{gv: false}));
	}
};
var $author$project$Main$closeNavbarBurger = function (model) {
	var newSession = $author$project$Session$closeNavbarBurger(
		$author$project$Main$toSession(model));
	var newModel = function () {
		switch (model.$) {
			case 1:
				var url = model.b;
				return A2($author$project$Main$Init, newSession, url);
			case 0:
				var s = model.a;
				return $author$project$Main$Redirect(newSession);
			case 2:
				var s = model.a;
				return $author$project$Main$NotFound(newSession);
			case 3:
				var home = model.a;
				return $author$project$Main$Home(
					_Utils_update(
						home,
						{j: newSession}));
			case 4:
				var gallery = model.a;
				return $author$project$Main$Gallery(
					_Utils_update(
						gallery,
						{j: newSession}));
			case 5:
				var profile = model.a;
				return $author$project$Main$Profile(
					_Utils_update(
						profile,
						{j: newSession}));
			case 6:
				var login = model.a;
				return $author$project$Main$Login(
					_Utils_update(
						login,
						{j: newSession}));
			default:
				var reservation = model.a;
				return $author$project$Main$Reservation(
					_Utils_update(
						reservation,
						{j: newSession}));
		}
	}();
	return newModel;
};
var $author$project$Main$DelaiedRefreshingToken = function (a) {
	return {$: 6, a: a};
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $simonh1000$elm_jwt$Jwt$TokenDecodeError = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $simonh1000$elm_jwt$Jwt$TokenHeaderError = {$: 2};
var $simonh1000$elm_jwt$Jwt$TokenProcessingError = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $simonh1000$elm_jwt$Jwt$fixlength = function (s) {
	var _v0 = A2(
		$elm$core$Basics$modBy,
		4,
		$elm$core$String$length(s));
	switch (_v0) {
		case 0:
			return $elm$core$Result$Ok(s);
		case 2:
			return $elm$core$Result$Ok(
				$elm$core$String$concat(
					_List_fromArray(
						[s, '=='])));
		case 3:
			return $elm$core$Result$Ok(
				$elm$core$String$concat(
					_List_fromArray(
						[s, '='])));
		default:
			return $elm$core$Result$Err(
				$simonh1000$elm_jwt$Jwt$TokenProcessingError('Wrong length'));
	}
};
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$string = function (n) {
	return _Bytes_read_string(n);
};
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $elm$bytes$Bytes$BE = 1;
var $danfishgold$base64_bytes$Encode$isValidChar = function (c) {
	if ($elm$core$Char$isAlphaNum(c)) {
		return true;
	} else {
		switch (c) {
			case '+':
				return true;
			case '/':
				return true;
			default:
				return false;
		}
	}
};
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$bytes$Bytes$Encode$Seq = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$getWidths = F2(
	function (width, builders) {
		getWidths:
		while (true) {
			if (!builders.b) {
				return width;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$width = width + $elm$bytes$Bytes$Encode$getWidth(b),
					$temp$builders = bs;
				width = $temp$width;
				builders = $temp$builders;
				continue getWidths;
			}
		}
	});
var $elm$bytes$Bytes$Encode$sequence = function (builders) {
	return A2(
		$elm$bytes$Bytes$Encode$Seq,
		A2($elm$bytes$Bytes$Encode$getWidths, 0, builders),
		builders);
};
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$Basics$ge = _Utils_ge;
var $danfishgold$base64_bytes$Encode$unsafeConvertChar = function (_char) {
	var key = $elm$core$Char$toCode(_char);
	if ((key >= 65) && (key <= 90)) {
		return key - 65;
	} else {
		if ((key >= 97) && (key <= 122)) {
			return (key - 97) + 26;
		} else {
			if ((key >= 48) && (key <= 57)) {
				return ((key - 48) + 26) + 26;
			} else {
				switch (_char) {
					case '+':
						return 62;
					case '/':
						return 63;
					default:
						return -1;
				}
			}
		}
	}
};
var $elm$bytes$Bytes$Encode$U16 = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$unsignedInt16 = $elm$bytes$Bytes$Encode$U16;
var $elm$bytes$Bytes$Encode$U8 = function (a) {
	return {$: 3, a: a};
};
var $elm$bytes$Bytes$Encode$unsignedInt8 = $elm$bytes$Bytes$Encode$U8;
var $danfishgold$base64_bytes$Encode$encodeCharacters = F4(
	function (a, b, c, d) {
		if ($danfishgold$base64_bytes$Encode$isValidChar(a) && $danfishgold$base64_bytes$Encode$isValidChar(b)) {
			var n2 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(b);
			var n1 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(a);
			if ('=' === d) {
				if ('=' === c) {
					var n = (n1 << 18) | (n2 << 12);
					var b1 = n >> 16;
					return $elm$core$Maybe$Just(
						$elm$bytes$Bytes$Encode$unsignedInt8(b1));
				} else {
					if ($danfishgold$base64_bytes$Encode$isValidChar(c)) {
						var n3 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(c);
						var n = ((n1 << 18) | (n2 << 12)) | (n3 << 6);
						var combined = n >> 8;
						return $elm$core$Maybe$Just(
							A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, combined));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			} else {
				if ($danfishgold$base64_bytes$Encode$isValidChar(c) && $danfishgold$base64_bytes$Encode$isValidChar(d)) {
					var n4 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(d);
					var n3 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(c);
					var n = ((n1 << 18) | (n2 << 12)) | ((n3 << 6) | n4);
					var combined = n >> 8;
					var b3 = n;
					return $elm$core$Maybe$Just(
						$elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, combined),
									$elm$bytes$Bytes$Encode$unsignedInt8(b3)
								])));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $danfishgold$base64_bytes$Encode$encodeChunks = F2(
	function (input, accum) {
		encodeChunks:
		while (true) {
			var _v0 = $elm$core$String$toList(
				A2($elm$core$String$left, 4, input));
			_v0$4:
			while (true) {
				if (!_v0.b) {
					return $elm$core$Maybe$Just(accum);
				} else {
					if (_v0.b.b) {
						if (_v0.b.b.b) {
							if (_v0.b.b.b.b) {
								if (!_v0.b.b.b.b.b) {
									var a = _v0.a;
									var _v1 = _v0.b;
									var b = _v1.a;
									var _v2 = _v1.b;
									var c = _v2.a;
									var _v3 = _v2.b;
									var d = _v3.a;
									var _v4 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, c, d);
									if (!_v4.$) {
										var enc = _v4.a;
										var $temp$input = A2($elm$core$String$dropLeft, 4, input),
											$temp$accum = A2($elm$core$List$cons, enc, accum);
										input = $temp$input;
										accum = $temp$accum;
										continue encodeChunks;
									} else {
										return $elm$core$Maybe$Nothing;
									}
								} else {
									break _v0$4;
								}
							} else {
								var a = _v0.a;
								var _v5 = _v0.b;
								var b = _v5.a;
								var _v6 = _v5.b;
								var c = _v6.a;
								var _v7 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, c, '=');
								if (_v7.$ === 1) {
									return $elm$core$Maybe$Nothing;
								} else {
									var enc = _v7.a;
									return $elm$core$Maybe$Just(
										A2($elm$core$List$cons, enc, accum));
								}
							}
						} else {
							var a = _v0.a;
							var _v8 = _v0.b;
							var b = _v8.a;
							var _v9 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, '=', '=');
							if (_v9.$ === 1) {
								return $elm$core$Maybe$Nothing;
							} else {
								var enc = _v9.a;
								return $elm$core$Maybe$Just(
									A2($elm$core$List$cons, enc, accum));
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
			return $elm$core$Maybe$Nothing;
		}
	});
var $danfishgold$base64_bytes$Encode$encoder = function (string) {
	return A2(
		$elm$core$Maybe$map,
		A2($elm$core$Basics$composeR, $elm$core$List$reverse, $elm$bytes$Bytes$Encode$sequence),
		A2($danfishgold$base64_bytes$Encode$encodeChunks, string, _List_Nil));
};
var $danfishgold$base64_bytes$Encode$toBytes = function (string) {
	return A2(
		$elm$core$Maybe$map,
		$elm$bytes$Bytes$Encode$encode,
		$danfishgold$base64_bytes$Encode$encoder(string));
};
var $danfishgold$base64_bytes$Base64$toBytes = $danfishgold$base64_bytes$Encode$toBytes;
var $elm$bytes$Bytes$width = _Bytes_width;
var $danfishgold$base64_bytes$Base64$toString = function (b64String) {
	var _v0 = $danfishgold$base64_bytes$Base64$toBytes(b64String);
	if (_v0.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		var b64Bytes = _v0.a;
		return A2(
			$elm$bytes$Bytes$Decode$decode,
			$elm$bytes$Bytes$Decode$string(
				$elm$bytes$Bytes$width(b64Bytes)),
			b64Bytes);
	}
};
var $elm$core$String$map = _String_map;
var $simonh1000$elm_jwt$Jwt$unurl = function () {
	var fix = function (c) {
		switch (c) {
			case '-':
				return '+';
			case '_':
				return '/';
			default:
				return c;
		}
	};
	return $elm$core$String$map(fix);
}();
var $simonh1000$elm_jwt$Jwt$getTokenParts = function (token) {
	var verifyJson = F2(
		function (errorHandler, str) {
			return A2(
				$elm$core$Result$mapError,
				errorHandler,
				A2(
					$elm$core$Result$map,
					function (_v8) {
						return str;
					},
					A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, str)));
		});
	var processor = A2(
		$elm$core$Basics$composeR,
		$simonh1000$elm_jwt$Jwt$unurl,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split('.'),
			$elm$core$List$map($simonh1000$elm_jwt$Jwt$fixlength)));
	var _v0 = processor(token);
	_v0$1:
	while (true) {
		if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.b.b)) {
			if (!_v0.a.$) {
				if (!_v0.b.a.$) {
					var header = _v0.a.a;
					var _v1 = _v0.b;
					var body = _v1.a.a;
					var _v2 = _v1.b;
					var header_ = A2(
						$elm$core$Result$andThen,
						verifyJson(
							function (_v3) {
								return $simonh1000$elm_jwt$Jwt$TokenHeaderError;
							}),
						A2(
							$elm$core$Result$fromMaybe,
							$simonh1000$elm_jwt$Jwt$TokenHeaderError,
							$danfishgold$base64_bytes$Base64$toString(header)));
					var body_ = A2(
						$elm$core$Result$andThen,
						verifyJson($simonh1000$elm_jwt$Jwt$TokenDecodeError),
						A2(
							$elm$core$Result$fromMaybe,
							$simonh1000$elm_jwt$Jwt$TokenProcessingError('Invalid UTF-16'),
							$danfishgold$base64_bytes$Base64$toString(body)));
					return A3(
						$elm$core$Result$map2,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						header_,
						body_);
				} else {
					break _v0$1;
				}
			} else {
				if (_v0.b.a.$ === 1) {
					break _v0$1;
				} else {
					var err = _v0.a.a;
					var _v6 = _v0.b;
					var _v7 = _v6.b;
					return $elm$core$Result$Err(err);
				}
			}
		} else {
			return $elm$core$Result$Err(
				$simonh1000$elm_jwt$Jwt$TokenProcessingError('Token has invalid shape'));
		}
	}
	var _v4 = _v0.b;
	var err = _v4.a.a;
	var _v5 = _v4.b;
	return $elm$core$Result$Err(err);
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $simonh1000$elm_jwt$Jwt$decodeToken = F2(
	function (dec, token) {
		return A2(
			$elm$core$Result$andThen,
			A2(
				$elm$core$Basics$composeR,
				$elm$json$Json$Decode$decodeString(dec),
				$elm$core$Result$mapError($simonh1000$elm_jwt$Jwt$TokenDecodeError)),
			A2(
				$elm$core$Result$map,
				$elm$core$Tuple$second,
				$simonh1000$elm_jwt$Jwt$getTokenParts(token)));
	});
var $simonh1000$elm_jwt$Jwt$getTokenExpirationMillis = function (token) {
	var decodeExp = $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$int,
				A2($elm$json$Json$Decode$map, $elm$core$Basics$round, $elm$json$Json$Decode$float)
			]));
	return A2(
		$elm$core$Result$map,
		function (exp) {
			return 1000 * exp;
		},
		A2(
			$simonh1000$elm_jwt$Jwt$decodeToken,
			A2($elm$json$Json$Decode$field, 'exp', decodeExp),
			token));
};
var $author$project$App$expire = function (_v0) {
	var token = _v0.a;
	var _v1 = $simonh1000$elm_jwt$Jwt$getTokenExpirationMillis(token);
	if (!_v1.$) {
		var v = _v1.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Session$expire = function (session) {
	return A2(
		$elm$core$Maybe$andThen,
		$author$project$App$expire,
		$author$project$Session$cred(session));
};
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Main$second = 1000;
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Main$tokenExpiryTask = function (timeout) {
	var safeInterval = 30 * $author$project$Main$second;
	var delay = F2(
		function (posixBy, posixNow) {
			var now = $elm$time$Time$posixToMillis(posixNow);
			var by = posixBy;
			return A2(
				$elm$core$Basics$max,
				0,
				A2($elm$core$Basics$max, ((by - now) / 2) | 0, (by - now) - safeInterval));
		});
	return A2(
		$elm$core$Task$andThen,
		function (now) {
			return $elm$core$Process$sleep(
				A2(delay, timeout, now));
		},
		$elm$time$Time$now);
};
var $author$project$Main$delaiedRefreshingTokenTrigger = function (session) {
	var expire = A2(
		$elm$core$Maybe$withDefault,
		0,
		$author$project$Session$expire(session));
	return A2(
		$elm$core$Task$attempt,
		function (_v0) {
			return $author$project$Main$DelaiedRefreshingToken(expire);
		},
		$author$project$Main$tokenExpiryTask(expire));
};
var $author$project$Main$delaiedRefreshingToken = F2(
	function (expire, session) {
		var newexpire = A2(
			$elm$core$Maybe$withDefault,
			0,
			$author$project$Session$expire(session));
		var window = newexpire - expire;
		var cred = $author$project$Session$cred(session);
		if (cred.$ === 1) {
			return $elm$core$Platform$Cmd$none;
		} else {
			return (_Utils_cmp(window, 60 * $author$project$Main$second) < 0) ? A2(
				$author$project$App$callApi,
				$author$project$Main$CompletedRefreshToken,
				$author$project$Session$toRefreshToeknRequest(session)) : $author$project$Main$delaiedRefreshingTokenTrigger(session);
		}
	});
var $author$project$Main$CompletedLoadReservation = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$fetchReservation = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Main$CompletedLoadReservation,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getReservation));
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Api$Data$MypageGalleriesResult = function (galleries) {
	return {dZ: galleries};
};
var $author$project$Api$Data$MypageGalleryInfo = F4(
	function (uid, name, dateStr, titleImage) {
		return {bP: dateStr, i: name, cL: titleImage, c: uid};
	});
var $author$project$Api$Data$mypageGalleryInfoDecoder = A3(
	$author$project$Api$Data$decode,
	'title_image',
	$author$project$Api$Data$mypageTitleImageDecoder,
	A3(
		$author$project$Api$Data$decode,
		'date_str',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'uid',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageGalleryInfo)))));
var $author$project$Api$Data$mypageGalleriesResultDecoder = A3(
	$author$project$Api$Data$decode,
	'galleries',
	$elm$json$Json$Decode$list($author$project$Api$Data$mypageGalleryInfoDecoder),
	$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageGalleriesResult));
var $author$project$Api$Request$Default$searchGallery = A7($author$project$Api$request, 'GET', '/gallery', _List_Nil, _List_Nil, _List_Nil, $elm$core$Maybe$Nothing, $author$project$Api$Data$mypageGalleriesResultDecoder);
var $author$project$App$storeCredWith = function (_v0) {
	var accessToken = _v0.a;
	var refreshToken_ = _v0.b;
	var json = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'user',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'refresh_token',
							$elm$json$Json$Encode$string(refreshToken_)),
							_Utils_Tuple2(
							'access_token',
							$elm$json$Json$Encode$string(accessToken))
						])))
			]));
	return $author$project$App$storeCache(
		$elm$core$Maybe$Just(json));
};
var $author$project$Viewer$store = function (_v0) {
	var credVal = _v0;
	return $author$project$App$storeCredWith(credVal);
};
var $author$project$Main$toActiveSession = function (model) {
	return $author$project$Main$toSession(model);
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.eV;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.c3,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.eY,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.eS,
					_Utils_ap(http, url.d4)),
				url.gG)));
};
var $author$project$App$createCredWithToken = function (token) {
	return A2($author$project$App$Cred, token.dp, token.e_);
};
var $author$project$Viewer$toViewer = function (token) {
	return $author$project$App$createCredWithToken(token);
};
var $author$project$Session$toggleNavbarBurger = function (session) {
	if (!session.$) {
		var k = session.a;
		var v = session.b;
		var g = session.c;
		return A3(
			$author$project$Session$LoggedIn,
			k,
			v,
			_Utils_update(
				g,
				{gv: !g.gv}));
	} else {
		var k = session.a;
		var g = session.b;
		return A2(
			$author$project$Session$Guest,
			k,
			_Utils_update(
				g,
				{gv: !g.gv}));
	}
};
var $author$project$Main$toggleNavbarBurger = function (model) {
	var newSession = $author$project$Session$toggleNavbarBurger(
		$author$project$Main$toSession(model));
	var newModel = function () {
		switch (model.$) {
			case 1:
				var s = model.a;
				return $author$project$Main$Redirect(newSession);
			case 0:
				var s = model.a;
				return $author$project$Main$Redirect(newSession);
			case 2:
				var s = model.a;
				return $author$project$Main$NotFound(newSession);
			case 3:
				var home = model.a;
				return $author$project$Main$Home(
					_Utils_update(
						home,
						{j: newSession}));
			case 4:
				var gallery = model.a;
				return $author$project$Main$Gallery(
					_Utils_update(
						gallery,
						{j: newSession}));
			case 5:
				var profile = model.a;
				return $author$project$Main$Profile(
					_Utils_update(
						profile,
						{j: newSession}));
			case 6:
				var login = model.a;
				return $author$project$Main$Login(
					_Utils_update(
						login,
						{j: newSession}));
			default:
				var reservation = model.a;
				return $author$project$Main$Reservation(
					_Utils_update(
						reservation,
						{j: newSession}));
		}
	}();
	return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
};
var $author$project$Page$Gallery$CompletedGetImageArchiveDownloadUrl = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Gallery$CompletedGetSingleImageDownloadUrl = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Gallery$DlFailed = {$: 2};
var $author$project$Page$Gallery$DlLoaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Gallery$DlLoading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Gallery$Failed = {$: 3};
var $author$project$Page$Gallery$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Gallery$NotifyImageArchiveStatus = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Gallery$UnloadedSlideImage = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$Gallery$decrementIndex = F2(
	function (size, i) {
		return (0 < i) ? (i - 1) : ((size - i) - 1);
	});
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $author$project$Page$Gallery$clickLeftChevron = F2(
	function (model, form) {
		return _Utils_update(
			model,
			{
				B: A2(
					$author$project$Page$Gallery$decrementIndex,
					$elm$core$Array$length(form.l),
					model.B)
			});
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$Page$Gallery$clickPhoto = F2(
	function (model, photoLoc) {
		return model.ap ? _Utils_update(
			model,
			{
				H: A2($elm$core$Set$member, photoLoc, model.H) ? A2($elm$core$Set$remove, photoLoc, model.H) : A2($elm$core$Set$insert, photoLoc, model.H)
			}) : _Utils_update(
			model,
			{B: photoLoc, bk: true});
	});
var $author$project$Page$Gallery$incrementIndex = F2(
	function (size, i) {
		return (i + 1) % size;
	});
var $author$project$Page$Gallery$clickRightChevron = F2(
	function (model, form) {
		return _Utils_update(
			model,
			{
				B: A2(
					$author$project$Page$Gallery$incrementIndex,
					$elm$core$Array$length(form.l),
					model.B)
			});
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Page$Gallery$clickSelectAll = F2(
	function (model, form) {
		return _Utils_update(
			model,
			{
				H: $elm$core$Set$fromList(
					A2(
						$elm$core$List$range,
						0,
						$elm$core$Array$length(form.l)))
			});
	});
var $author$project$Page$Gallery$clickSelectButton = function (m) {
	return _Utils_update(
		m,
		{ap: !m.ap, H: $elm$core$Set$empty});
};
var $author$project$Page$Gallery$clickUnselectAll = function (model) {
	return _Utils_update(
		model,
		{H: $elm$core$Set$empty});
};
var $author$project$Page$Gallery$closePhotoViewer = function (model) {
	return _Utils_update(
		model,
		{bk: false});
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Api$Data$maybeEncode = F2(
	function (key, encoder) {
		return $elm$core$Maybe$map(
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Tuple$pair(key),
				encoder));
	});
var $author$project$Api$Data$encodeMypageImageArchiveStatusPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'subscription_name', $elm$json$Json$Encode$string, model.fs),
			A3($author$project$Api$Data$encode, 'max_value', $elm$json$Json$Encode$int, model.et),
			A3($author$project$Api$Data$encode, 'value', $elm$json$Json$Encode$int, model.be),
			A3($author$project$Api$Data$maybeEncode, 'url', $elm$json$Json$Encode$string, model.fE),
			A3($author$project$Api$Data$maybeEncode, 'size', $elm$json$Json$Encode$int, model.cH),
			A3($author$project$Api$Data$maybeEncode, 'dlsize', $elm$json$Json$Encode$string, model.dP)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeMypageImageArchiveStatus = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeMypageImageArchiveStatusPairs);
var $author$project$Api$Data$MypageImageArchiveStatus = F6(
	function (subscriptionName, maxValue, value, url, size, dlsize) {
		return {dP: dlsize, et: maxValue, cH: size, fs: subscriptionName, fE: url, be: value};
	});
var $author$project$Api$Data$mypageImageArchiveStatusDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'dlsize',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'size',
		$elm$json$Json$Decode$int,
		$elm$core$Maybe$Nothing,
		A4(
			$author$project$Api$Data$maybeDecode,
			'url',
			$elm$json$Json$Decode$string,
			$elm$core$Maybe$Nothing,
			A3(
				$author$project$Api$Data$decode,
				'value',
				$elm$json$Json$Decode$int,
				A3(
					$author$project$Api$Data$decode,
					'max_value',
					$elm$json$Json$Decode$int,
					A3(
						$author$project$Api$Data$decode,
						'subscription_name',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageImageArchiveStatus)))))));
var $author$project$Api$Request$Default$getImageArchiveStat = function (mypageImageArchiveStatus_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/images/arch_stat',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeMypageImageArchiveStatus(mypageImageArchiveStatus_body)),
		$author$project$Api$Data$mypageImageArchiveStatusDecoder);
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $author$project$ProgressiveImage$Loaded = function (a) {
	return {$: 1, a: a};
};
var $author$project$ProgressiveImage$toLoadedImage = function (image) {
	if (!image.$) {
		var m = image.a;
		return $author$project$ProgressiveImage$Loaded(m);
	} else {
		var m = image.a;
		return $author$project$ProgressiveImage$Loaded(m);
	}
};
var $author$project$Page$Gallery$loadedSlideImage = F2(
	function (model, idx) {
		var _v0 = model.w;
		if (_v0.$ === 2) {
			var gf = _v0.a;
			var imgf = A2($elm$core$Array$get, idx, gf.l);
			if (!imgf.$) {
				var v = imgf.a;
				return _Utils_update(
					model,
					{
						w: $author$project$Page$Gallery$Loaded(
							_Utils_update(
								gf,
								{
									l: A3(
										$elm$core$Array$set,
										idx,
										_Utils_update(
											v,
											{
												aL: $author$project$ProgressiveImage$toLoadedImage(v.aL)
											}),
										gf.l)
								}))
					});
			} else {
				return model;
			}
		} else {
			return model;
		}
	});
var $author$project$Page$Gallery$loadedThumImage = F2(
	function (model, idx) {
		var _v0 = model.w;
		if (_v0.$ === 2) {
			var gf = _v0.a;
			var imgf = A2($elm$core$Array$get, idx, gf.l);
			if (!imgf.$) {
				var v = imgf.a;
				return _Utils_update(
					model,
					{
						w: $author$project$Page$Gallery$Loaded(
							_Utils_update(
								gf,
								{
									l: A3(
										$elm$core$Array$set,
										idx,
										_Utils_update(
											v,
											{
												bw: $author$project$ProgressiveImage$toLoadedImage(v.bw)
											}),
										gf.l)
								}))
					});
			} else {
				return model;
			}
		} else {
			return model;
		}
	});
var $author$project$Api$Data$encodeMypageDownloadImageInfoPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'image_id', $elm$json$Json$Encode$string, model.d8),
			A3($author$project$Api$Data$encode, 'filename', $elm$json$Json$Encode$string, model.b$)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeMypageDownloadImageInfo = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeMypageDownloadImageInfoPairs);
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Api$Data$encodeMypageImageIdsPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3(
			$author$project$Api$Data$maybeEncode,
			'image_ids',
			$elm$json$Json$Encode$list($elm$json$Json$Encode$string),
			model.d9),
			A3(
			$author$project$Api$Data$maybeEncode,
			'image_info',
			$elm$json$Json$Encode$list($author$project$Api$Data$encodeMypageDownloadImageInfo),
			model.ea)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeMypageImageIds = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeMypageImageIdsPairs);
var $author$project$Api$Request$Default$makeImageArchive = function (mypageImageIds_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/images/make_arch',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeMypageImageIds(mypageImageIds_body)),
		$author$project$Api$Data$mypageImageArchiveStatusDecoder);
};
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{r: nodeList, m: nodeListSize, p: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $author$project$ProgressiveImage$ImageSet = F3(
	function (src, srcset, blurhash) {
		return {bI: blurhash, fn: src, fo: srcset};
	});
var $author$project$ProgressiveImage$PreLoad = function (a) {
	return {$: 0, a: a};
};
var $author$project$ProgressiveImage$initProgressiveImage = function (imageSet) {
	return $author$project$ProgressiveImage$PreLoad(imageSet);
};
var $author$project$Page$Gallery$mapImage = function (m) {
	return {
		b$: m.b$,
		ey: m.ey,
		ez: m.ez,
		eA: m.eA,
		eL: m.eL,
		aL: $author$project$ProgressiveImage$initProgressiveImage(
			A3($author$project$ProgressiveImage$ImageSet, m.eL, m.eA, m.bI)),
		bw: $author$project$ProgressiveImage$initProgressiveImage(
			A3($author$project$ProgressiveImage$ImageSet, m.eL, m.ey, m.bI)),
		c: m.c
	};
};
var $author$project$Page$Gallery$mapImages = function (il) {
	return A2(
		$elm$core$List$map,
		function (m) {
			return $author$project$Page$Gallery$mapImage(m);
		},
		il);
};
var $author$project$Page$Gallery$mapGallery = function (m) {
	return {
		bP: m.bP,
		l: $elm$core$Array$fromList(
			$author$project$Page$Gallery$mapImages(m.fk)),
		i: m.i,
		c: m.c
	};
};
var $author$project$Api$Data$MypageSingleImageDownloadUrl = function (dlUrl) {
	return {aW: dlUrl};
};
var $author$project$Api$Data$mypageSingleImageDownloadUrlDecoder = A3(
	$author$project$Api$Data$decode,
	'dl_url',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageSingleImageDownloadUrl));
var $author$project$Api$Request$Default$singleImageDownloadUrl = function (uid_path) {
	return A7(
		$author$project$Api$request,
		'GET',
		'/images/{uid}/download_url',
		_List_fromArray(
			[
				_Utils_Tuple2(
				'uid',
				$elm$core$Basics$identity(uid_path))
			]),
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Nothing,
		$author$project$Api$Data$mypageSingleImageDownloadUrlDecoder);
};
var $author$project$ProgressiveImage$toPreLoadImage = function (image) {
	if (!image.$) {
		var m = image.a;
		return $author$project$ProgressiveImage$PreLoad(m);
	} else {
		var m = image.a;
		return $author$project$ProgressiveImage$PreLoad(m);
	}
};
var $author$project$Page$Gallery$unloadedSlideImage = F2(
	function (model, idx) {
		var _v0 = model.w;
		if (_v0.$ === 2) {
			var gf = _v0.a;
			var imgf = A2($elm$core$Array$get, idx, gf.l);
			if (!imgf.$) {
				var v = imgf.a;
				return _Utils_update(
					model,
					{
						w: $author$project$Page$Gallery$Loaded(
							_Utils_update(
								gf,
								{
									l: A3(
										$elm$core$Array$set,
										idx,
										_Utils_update(
											v,
											{
												aL: $author$project$ProgressiveImage$toPreLoadImage(v.aL)
											}),
										gf.l)
								}))
					});
			} else {
				return model;
			}
		} else {
			return model;
		}
	});
var $elm$file$File$Download$url = function (href) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$never,
		_File_downloadUrl(href));
};
var $author$project$Page$Gallery$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 9:
					var session = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j: session}),
						$elm$core$Platform$Cmd$none);
				case 0:
					return _Utils_Tuple2(
						$author$project$Page$Gallery$clickSelectButton(model),
						$elm$core$Platform$Cmd$none);
				case 1:
					var photoLoc = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Gallery$clickPhoto, model, photoLoc),
						$elm$core$Platform$Cmd$none);
				case 2:
					var $temp$msg = $author$project$Page$Gallery$UnloadedSlideImage(model.B),
						$temp$model = $author$project$Page$Gallery$closePhotoViewer(model);
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 4:
					var _v1 = model.w;
					if (_v1.$ === 2) {
						var form = _v1.a;
						var $temp$msg = $author$project$Page$Gallery$UnloadedSlideImage(model.B),
							$temp$model = A2($author$project$Page$Gallery$clickLeftChevron, model, form);
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 5:
					var _v2 = model.w;
					if (_v2.$ === 2) {
						var form = _v2.a;
						var $temp$msg = $author$project$Page$Gallery$UnloadedSlideImage(model.B),
							$temp$model = A2($author$project$Page$Gallery$clickRightChevron, model, form);
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 6:
					var _v3 = model.w;
					if (_v3.$ === 2) {
						var form = _v3.a;
						return _Utils_Tuple2(
							A2($author$project$Page$Gallery$clickSelectAll, model, form),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 3:
					var g = msg.a;
					var payload = {
						d9: $elm$core$Maybe$Nothing,
						ea: $elm$core$Maybe$Just(
							A2(
								$elm$core$List$filterMap,
								function (_v4) {
									var idx = _v4.a;
									var img = _v4.b;
									return A2($elm$core$Set$member, idx, model.H) ? $elm$core$Maybe$Just(
										{
											b$: $elm$core$String$fromInt(idx + 1) + '.jpg',
											d8: img.c
										}) : $elm$core$Maybe$Nothing;
								},
								A2(
									$elm$core$List$indexedMap,
									$elm$core$Tuple$pair,
									$elm$core$Array$toList(g.l))))
					};
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ao: $author$project$Page$Gallery$DlLoading($elm$core$Maybe$Nothing)
							}),
						A2(
							$author$project$App$callApi,
							$author$project$Page$Gallery$NotifyImageArchiveStatus,
							A2(
								$author$project$Session$toRequest,
								$author$project$Page$Gallery$toSession(model),
								$author$project$Api$Request$Default$makeImageArchive(payload))));
				case 7:
					if (!msg.a.$) {
						var form = msg.a.a;
						return _Utils_Tuple2(
							model,
							A2(
								$author$project$App$callApi,
								$author$project$Page$Gallery$CompletedGetSingleImageDownloadUrl,
								A2(
									$author$project$Session$toRequest,
									$author$project$Page$Gallery$toSession(model),
									$author$project$Api$Request$Default$singleImageDownloadUrl(form.c))));
					} else {
						var _v5 = msg.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 8:
					return _Utils_Tuple2(
						$author$project$Page$Gallery$clickUnselectAll(model),
						$elm$core$Platform$Cmd$none);
				case 10:
					if (!msg.a.$) {
						var gallery = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									w: $author$project$Page$Gallery$Loaded(
										$author$project$Page$Gallery$mapGallery(gallery))
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{w: $author$project$Page$Gallery$Failed}),
							$elm$core$Platform$Cmd$none);
					}
				case 11:
					if (!msg.a.$) {
						var res = msg.a.a;
						var dlUrl = res.aW;
						return _Utils_Tuple2(
							model,
							$elm$file$File$Download$url(dlUrl));
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{w: $author$project$Page$Gallery$Failed}),
							$elm$core$Platform$Cmd$none);
					}
				case 12:
					var res = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ao: $author$project$Page$Gallery$DlLoaded(
									$elm$core$Maybe$Just(res))
							}),
						$elm$core$Platform$Cmd$none);
				case 13:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ao: $author$project$Page$Gallery$DlNop}),
						$elm$core$Platform$Cmd$none);
				case 14:
					var idx = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Gallery$loadedSlideImage, model, idx),
						$elm$core$Platform$Cmd$none);
				case 15:
					var idx = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Gallery$loadedThumImage, model, idx),
						$elm$core$Platform$Cmd$none);
				case 16:
					if (!msg.a.$) {
						var res = msg.a.a;
						var _v6 = res.fE;
						if (!_v6.$) {
							var s = _v6.a;
							var $temp$msg = $author$project$Page$Gallery$CompletedGetImageArchiveDownloadUrl(res),
								$temp$model = model;
							msg = $temp$msg;
							model = $temp$model;
							continue update;
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ao: $author$project$Page$Gallery$DlLoading(
											$elm$core$Maybe$Just(res))
									}),
								A2(
									$elm$core$Task$attempt,
									$author$project$Page$Gallery$NotifyImageArchiveStatus,
									A2(
										$elm$core$Task$andThen,
										function (_v7) {
											return $author$project$App$callApiTask(
												A2(
													$author$project$Session$toRequest,
													$author$project$Page$Gallery$toSession(model),
													$author$project$Api$Request$Default$getImageArchiveStat(res)));
										},
										$elm$core$Process$sleep(500))));
						}
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ao: $author$project$Page$Gallery$DlFailed}),
							$elm$core$Platform$Cmd$none);
					}
				default:
					var idx = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Gallery$unloadedSlideImage, model, idx),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Page$Home$DownlodMovieUrlFailed = {$: 0};
var $author$project$Page$Home$DownlodMovieUrlSuccess = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Home$CompletedDownloadMovieUrl = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$Data$MypageMovieDownloadUrl = function (dlUrl) {
	return {aW: dlUrl};
};
var $author$project$Api$Data$mypageMovieDownloadUrlDecoder = A3(
	$author$project$Api$Data$decode,
	'dl_url',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$Data$MypageMovieDownloadUrl));
var $author$project$Api$Request$Default$getMovieDownloadUrl = function (uid_path) {
	return A7(
		$author$project$Api$request,
		'GET',
		'/movies/{uid}/download_url',
		_List_fromArray(
			[
				_Utils_Tuple2(
				'uid',
				$elm$core$Basics$identity(uid_path))
			]),
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Nothing,
		$author$project$Api$Data$mypageMovieDownloadUrlDecoder);
};
var $author$project$Page$Home$doDownloadMovie = F2(
	function (session, uid) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Home$CompletedDownloadMovieUrl,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$getMovieDownloadUrl(uid)));
	});
var $author$project$Session$loadedGalleryTitleImage = F2(
	function (session, key) {
		var loaded = function (g) {
			return A2(
				$elm$core$List$map,
				function (m) {
					return _Utils_eq(m.c, key) ? _Utils_update(
						m,
						{
							cL: $author$project$ProgressiveImage$toLoadedImage(m.cL)
						}) : m;
				},
				g);
		};
		if (!session.$) {
			var k = session.a;
			var v = session.b;
			var g = session.c;
			return A3(
				$author$project$Session$LoggedIn,
				k,
				v,
				_Utils_update(
					g,
					{
						gn: loaded(g.gn)
					}));
		} else {
			return session;
		}
	});
var $author$project$Page$Home$Reset = 1;
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Home$slideBanner = function (model) {
	var _v0 = model.aR;
	if (!_v0) {
		return _Utils_update(
			model,
			{
				aR: 1,
				aw: _Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						$elm$core$List$tail(model.aw)),
					function () {
						var _v1 = $elm$core$List$head(model.aw);
						if (!_v1.$) {
							var h = _v1.a;
							return _List_fromArray(
								[h]);
						} else {
							return _List_Nil;
						}
					}())
			});
	} else {
		return _Utils_update(
			model,
			{aR: 0});
	}
};
var $author$project$Page$Home$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: session}),
					$elm$core$Platform$Cmd$none);
			case 1:
				if (!msg.a.$) {
					var movie = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bi: $elm$core$Maybe$Just(
									$author$project$Page$Home$DownlodMovieUrlSuccess(movie.aW))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errors = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bi: $elm$core$Maybe$Just($author$project$Page$Home$DownlodMovieUrlFailed)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				if (!msg.a.$) {
					var banners = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aw: _Utils_ap(banners, banners)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errors = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (!msg.a.$) {
					var movies = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bn: movies}),
						$elm$core$Platform$Cmd$none);
				} else {
					var errors = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var uid = msg.a;
				return _Utils_Tuple2(
					model,
					A2($author$project$Page$Home$doDownloadMovie, model.j, uid));
			case 5:
				return _Utils_Tuple2(
					$author$project$Page$Home$slideBanner(model),
					$elm$core$Platform$Cmd$none);
			default:
				var key = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							j: A2($author$project$Session$loadedGalleryTitleImage, model.j, key)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Login$Complete = {$: 1};
var $author$project$Page$Login$CompletedSendMagiclink = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Login$ServerError = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$App$decodeErrors = function (error) {
	switch (error.$) {
		case 4:
			var response = error.a;
			return A2(
				$elm$core$Result$withDefault,
				_List_fromArray(
					['エラーが発生しました(' + (response + ')')]),
				A2(
					$elm$json$Json$Decode$decodeString,
					A2(
						$elm$json$Json$Decode$field,
						'detail',
						A2(
							$elm$json$Json$Decode$field,
							'message',
							A2(
								$elm$json$Json$Decode$field,
								'errors',
								$elm$json$Json$Decode$list(
									A2($elm$json$Json$Decode$field, 'body', $elm$json$Json$Decode$string))))),
					response));
		case 0:
			var url = error.a;
			return _List_fromArray(
				['接続先のURLが不正です' + url]);
		case 1:
			return _List_fromArray(
				['タイムアウトしました']);
		case 2:
			return _List_fromArray(
				['サーバーに接続できませんでした']);
		default:
			var code = error.a;
			return _List_fromArray(
				[
					'Server error ' + $elm$core$String$fromInt(code)
				]);
	}
};
var $author$project$Api$Data$encodeMagiclinkRequestPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'email', $elm$json$Json$Encode$string, model.bU)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeMagiclinkRequest = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeMagiclinkRequestPairs);
var $author$project$Api$Data$Message = function (message) {
	return {eu: message};
};
var $author$project$Api$Data$messageDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'message',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	$elm$json$Json$Decode$succeed($author$project$Api$Data$Message));
var $author$project$Api$Request$Default$sendMagiclinkMail = function (magiclinkRequest_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/magic/send',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeMagiclinkRequest(magiclinkRequest_body)),
		$author$project$Api$Data$messageDecoder);
};
var $author$project$Page$Login$sendMagiclink = function (_v0) {
	var form = _v0;
	var email = form.bU;
	return $author$project$Api$Request$Default$sendMagiclinkMail(
		{bU: email});
};
var $author$project$Page$Login$updateForm = F2(
	function (form, model) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					a$: $author$project$Page$Login$Login(form)
				}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Login$Email = 0;
var $author$project$Page$Login$fieldsToValidate = _List_fromArray(
	[0]);
var $author$project$Page$Login$Trimmed = $elm$core$Basics$identity;
var $elm$core$String$trim = _String_trim;
var $author$project$Page$Login$trimFields = function (form) {
	return {
		bU: $elm$core$String$trim(form.bU)
	};
};
var $author$project$Page$Login$InvalidEntry = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Login$validateField = F2(
	function (_v0, field) {
		var form = _v0;
		return A2(
			$elm$core$List$map,
			$author$project$Page$Login$InvalidEntry(field),
			$elm$core$String$isEmpty(form.bU) ? _List_fromArray(
				['メールアドレスを入力してください']) : _List_Nil);
	});
var $author$project$Page$Login$validate = function (form) {
	var trimmedForm = $author$project$Page$Login$trimFields(form);
	var _v0 = A2(
		$elm$core$List$concatMap,
		$author$project$Page$Login$validateField(trimmedForm),
		$author$project$Page$Login$fieldsToValidate);
	if (!_v0.b) {
		return $elm$core$Result$Ok(trimmedForm);
	} else {
		var problems = _v0;
		return $elm$core$Result$Err(problems);
	}
};
var $author$project$Page$Login$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var _v1 = model.a$;
				if (!_v1.$) {
					var form = _v1.a;
					var _v2 = $author$project$Page$Login$validate(form);
					if (!_v2.$) {
						var validForm = _v2.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aI: _List_Nil}),
							A2(
								$author$project$App$callApi,
								$author$project$Page$Login$CompletedSendMagiclink,
								A2(
									$author$project$Session$toRequest,
									$author$project$Page$Login$toSession(model),
									$author$project$Page$Login$sendMagiclink(validForm))));
					} else {
						var problems = _v2.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aI: problems}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var form = msg.a;
				var v = msg.b;
				return A2(
					$author$project$Page$Login$updateForm,
					_Utils_update(
						form,
						{bU: v}),
					model);
			case 3:
				if (msg.a.$ === 1) {
					var error = msg.a.a;
					var serverErrors = A2(
						$elm$core$List$map,
						$author$project$Page$Login$ServerError,
						$author$project$App$decodeErrors(error));
					var err = _Utils_Tuple2($elm$core$Result$Err, error);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aI: A2($elm$core$List$append, model.aI, serverErrors)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var message = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a$: $author$project$Page$Login$Complete,
								bm: _List_fromArray(
									['マジックリンクを送信しました。メールボックスを確認してください。'])
							}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: session}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Profile$Failed = {$: 3};
var $author$project$Page$Profile$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Profile$NoOp = {$: 12};
var $author$project$Page$Profile$asLoadedCustomerIn = F2(
	function (model, customer) {
		return _Utils_update(
			model,
			{
				dG: $author$project$Page$Profile$Loaded(customer)
			});
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $elm_community$list_extra$List$Extra$setAt = F2(
	function (index, value) {
		return A2(
			$elm_community$list_extra$List$Extra$updateAt,
			index,
			$elm$core$Basics$always(value));
	});
var $author$project$Page$Profile$asSelectMemberIn = F3(
	function (model, idx, member) {
		var _v0 = _Utils_Tuple2(member, model.dG);
		if (_v0.a.$ === 1) {
			var _v1 = _v0.a;
			return model;
		} else {
			if (_v0.b.$ === 2) {
				var m = _v0.a.a;
				var c = _v0.b.a;
				var customer = _Utils_update(
					c,
					{
						dW: A3($elm_community$list_extra$List$Extra$setAt, idx, m, c.dW)
					});
				return _Utils_update(
					model,
					{
						dG: $author$project$Page$Profile$Loaded(customer)
					});
			} else {
				return model;
			}
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$Page$Profile$setAddress1 = F2(
	function (value, customer) {
		return _Utils_update(
			customer,
			{bF: value});
	});
var $author$project$Page$Profile$setAddress2 = F2(
	function (value, customer) {
		return _Utils_update(
			customer,
			{bG: value});
	});
var $author$project$Page$Profile$setBirthday = F2(
	function (_v0, member) {
		var yy = _v0.a;
		var mm = _v0.b;
		var dd = _v0.c;
		if (!member.$) {
			var m = member.a;
			return $elm$core$Maybe$Just(
				_Utils_update(
					m,
					{ax: dd, ay: mm, az: yy}));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Page$Profile$setFamilyMemberValue = F3(
	function (func, value, member) {
		if (!member.$) {
			var m = member.a;
			return $elm$core$Maybe$Just(
				A2(func, m, value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Page$Profile$setEmails = F2(
	function (value, member) {
		return A3(
			$author$project$Page$Profile$setFamilyMemberValue,
			F2(
				function (m, v) {
					return _Utils_update(
						m,
						{aY: v});
				}),
			value,
			member);
	});
var $author$project$Page$Profile$setFirstName = F2(
	function (value, member) {
		return A3(
			$author$project$Page$Profile$setFamilyMemberValue,
			F2(
				function (m, v) {
					return _Utils_update(
						m,
						{aZ: v});
				}),
			value,
			member);
	});
var $author$project$Page$Profile$setFirstNameFurigana = F2(
	function (value, member) {
		return A3(
			$author$project$Page$Profile$setFamilyMemberValue,
			F2(
				function (m, v) {
					return _Utils_update(
						m,
						{a_: v});
				}),
			value,
			member);
	});
var $author$project$Page$Profile$setHeight = F2(
	function (value, member) {
		return A3(
			$author$project$Page$Profile$setFamilyMemberValue,
			F2(
				function (m, v) {
					return _Utils_update(
						m,
						{b2: v});
				}),
			value,
			member);
	});
var $author$project$Page$Profile$setIsAcceptDm = F2(
	function (value, customer) {
		return _Utils_update(
			customer,
			{b7: value});
	});
var $author$project$Page$Profile$setLastNema = F2(
	function (value, member) {
		return A3(
			$author$project$Page$Profile$setFamilyMemberValue,
			F2(
				function (m, v) {
					return _Utils_update(
						m,
						{a3: v});
				}),
			value,
			member);
	});
var $author$project$Page$Profile$setLastNemaFurigana = F2(
	function (value, member) {
		return A3(
			$author$project$Page$Profile$setFamilyMemberValue,
			F2(
				function (m, v) {
					return _Utils_update(
						m,
						{a4: v});
				}),
			value,
			member);
	});
var $author$project$Page$Profile$setPhoneNumber = F2(
	function (value, customer) {
		return _Utils_update(
			customer,
			{aq: value});
	});
var $author$project$Page$Profile$setPostalCode = F2(
	function (value, customer) {
		return _Utils_update(
			customer,
			{cv: value});
	});
var $author$project$Page$Profile$setPrefecture = F2(
	function (value, customer) {
		return _Utils_update(
			customer,
			{cw: value});
	});
var $author$project$Page$Profile$setRelationship = F2(
	function (value, member) {
		return A3(
			$author$project$Page$Profile$setFamilyMemberValue,
			F2(
				function (m, v) {
					return _Utils_update(
						m,
						{cz: v});
				}),
			value,
			member);
	});
var $author$project$Page$Profile$setRepresentativeId = F2(
	function (value, customer) {
		return _Utils_update(
			customer,
			{cB: value});
	});
var $author$project$Page$Profile$setShoeSize = F2(
	function (value, member) {
		return A3(
			$author$project$Page$Profile$setFamilyMemberValue,
			F2(
				function (m, v) {
					return _Utils_update(
						m,
						{cF: v});
				}),
			value,
			member);
	});
var $author$project$Page$Profile$applyInputValue = F2(
	function (model, field) {
		var applyValueForSelectMember = $author$project$Page$Profile$asSelectMemberIn(model);
		var applyValueForLoadedCustomer = $author$project$Page$Profile$asLoadedCustomerIn(model);
		var _v0 = model.dG;
		if (_v0.$ === 2) {
			var c = _v0.a;
			var selectMember = function (idx) {
				return A2($elm_community$list_extra$List$Extra$getAt, idx, c.dW);
			};
			var customer = c;
			switch (field.$) {
				case 10:
					var value = field.a;
					return applyValueForLoadedCustomer(
						A2($author$project$Page$Profile$setPhoneNumber, value, c));
				case 11:
					var value = field.a;
					return applyValueForLoadedCustomer(
						A2($author$project$Page$Profile$setPostalCode, value, c));
				case 12:
					var value = field.a;
					return applyValueForLoadedCustomer(
						A2($author$project$Page$Profile$setPrefecture, value, c));
				case 0:
					var value = field.a;
					return applyValueForLoadedCustomer(
						A2($author$project$Page$Profile$setAddress1, value, c));
				case 1:
					var value = field.a;
					return applyValueForLoadedCustomer(
						A2($author$project$Page$Profile$setAddress2, value, c));
				case 7:
					var value = field.a;
					return applyValueForLoadedCustomer(
						A2($author$project$Page$Profile$setIsAcceptDm, value, c));
				case 14:
					var value = field.a;
					return applyValueForLoadedCustomer(
						A2($author$project$Page$Profile$setRepresentativeId, value, c));
				case 13:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setRelationship,
							value,
							selectMember(idx)));
				case 8:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setLastNema,
							value,
							selectMember(idx)));
				case 9:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setLastNemaFurigana,
							value,
							selectMember(idx)));
				case 4:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setFirstName,
							value,
							selectMember(idx)));
				case 5:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setFirstNameFurigana,
							value,
							selectMember(idx)));
				case 2:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setBirthday,
							value,
							selectMember(idx)));
				case 6:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setHeight,
							value,
							selectMember(idx)));
				case 15:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setShoeSize,
							value,
							selectMember(idx)));
				default:
					var idx = field.a;
					var value = field.b;
					return A2(
						applyValueForSelectMember,
						idx,
						A2(
							$author$project$Page$Profile$setEmails,
							value,
							selectMember(idx)));
			}
		} else {
			return model;
		}
	});
var $author$project$Api$Data$MypageCustomer = F9(
	function (uid, phoneNumber, postalCode, prefecture, address1, address2, isAcceptDm, representativeId, familyMembers) {
		return {bF: address1, bG: address2, dW: familyMembers, b7: isAcceptDm, aq: phoneNumber, cv: postalCode, cw: prefecture, cB: representativeId, c: uid};
	});
var $author$project$Page$Profile$Address1TooLonger = {$: 7};
var $arowM$elm_form_decoder$Form$Decoder$Decoder = $elm$core$Basics$identity;
var $arowM$elm_form_decoder$Form$Decoder$custom = $elm$core$Basics$identity;
var $arowM$elm_form_decoder$Form$Decoder$run = F2(
	function (_v0, a) {
		var f = _v0;
		return f(a);
	});
var $arowM$elm_form_decoder$Form$Decoder$assert = F2(
	function (v, _v0) {
		var f = _v0;
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			function (a) {
				return A2(
					$elm$core$Result$andThen,
					function (x) {
						return A2(
							$elm$core$Result$map,
							function (_v1) {
								return x;
							},
							A2($arowM$elm_form_decoder$Form$Decoder$run, v, x));
					},
					f(a));
			});
	});
var $arowM$elm_form_decoder$Form$Decoder$identity = $arowM$elm_form_decoder$Form$Decoder$custom($elm$core$Result$Ok);
var $arowM$elm_form_decoder$Form$Decoder$map = F2(
	function (f, _v0) {
		var g = _v0;
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Result$map(f),
				g));
	});
var $arowM$elm_form_decoder$Form$Decoder$maxLength = F2(
	function (err, bound) {
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			function (str) {
				return (_Utils_cmp(
					$elm$core$String$length(str),
					bound) < 1) ? $elm$core$Result$Ok(0) : $elm$core$Result$Err(
					_List_fromArray(
						[err]));
			});
	});
var $author$project$Page$Profile$decoderAddress1 = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$Address1TooLonger, 70),
		$arowM$elm_form_decoder$Form$Decoder$identity));
var $author$project$Page$Profile$Address2TooLonger = {$: 8};
var $author$project$Page$Profile$decoderAddress2 = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$Address2TooLonger, 70),
		$arowM$elm_form_decoder$Form$Decoder$identity));
var $author$project$Page$Profile$FamilyMembersHasError = function (a) {
	return {$: 33, a: a};
};
var $author$project$Api$Data$FamilyMember = function (uid) {
	return function (customerId) {
		return function (relationship) {
			return function (firstName) {
				return function (lastName) {
					return function (firstNameFurigana) {
						return function (lastNameFurigana) {
							return function (birthday) {
								return function (emails) {
									return function (height) {
										return function (shoeSize) {
											return {bH: birthday, am: customerId, aY: emails, aZ: firstName, a_: firstNameFurigana, b2: height, a3: lastName, a4: lastNameFurigana, cz: relationship, cF: shoeSize, c: uid};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Profile$BirthdayParseError = {$: 12};
var $author$project$Page$Profile$BirthdayRequired = {$: 13};
var $elm$core$String$endsWith = _String_endsWith;
var $author$project$Page$Profile$isChild = function (form) {
	var relationship = form.cz;
	return A2($elm$core$String$endsWith, '男', relationship) || A2($elm$core$String$endsWith, '女', relationship);
};
var $author$project$Page$Profile$decoderBirthday = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (d) {
		var v = A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[d.az, d.ay, d.ax]));
		if (!$author$project$Page$Profile$isChild(d)) {
			return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
		} else {
			if (v === '----------') {
				return $elm$core$Result$Err(
					_List_fromArray(
						[$author$project$Page$Profile$BirthdayRequired]));
			} else {
				var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(v);
				if (!_v0.$) {
					var r = _v0.a;
					return $elm$core$Result$Ok(
						$elm$core$Maybe$Just(r));
				} else {
					var e = _v0.a;
					return $elm$core$Result$Err(
						_List_fromArray(
							[$author$project$Page$Profile$BirthdayParseError]));
				}
			}
		}
	});
var $author$project$Page$Profile$decoderCustomerId = $arowM$elm_form_decoder$Form$Decoder$identity;
var $author$project$Page$Profile$decoderEmails = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (v) {
		return $elm$core$Result$Ok(
			$elm$core$Maybe$Just(
				A2(
					$elm$core$List$map,
					$elm$core$String$trim,
					A2(
						$elm$core$String$split,
						',',
						A3($elm$core$String$replace, '，', ',', v)))));
	});
var $author$project$Page$Profile$FirstNameRequired = {$: 16};
var $author$project$Page$Profile$FirstNameTooLonger = {$: 17};
var $arowM$elm_form_decoder$Form$Decoder$minLength = F2(
	function (err, bound) {
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			function (str) {
				return (_Utils_cmp(
					$elm$core$String$length(str),
					bound) > -1) ? $elm$core$Result$Ok(0) : $elm$core$Result$Err(
					_List_fromArray(
						[err]));
			});
	});
var $author$project$Page$Profile$decoderFirstName = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$FirstNameTooLonger, 30),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$assert,
			A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Profile$FirstNameRequired, 1),
			$arowM$elm_form_decoder$Form$Decoder$identity)));
var $author$project$Page$Profile$FirstNameFuriganaRequired = {$: 18};
var $author$project$Page$Profile$FirstNameFuriganaTooLonger = {$: 19};
var $author$project$Page$Profile$decoderFirstNameFurigana = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$FirstNameFuriganaTooLonger, 30),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$assert,
			A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Profile$FirstNameFuriganaRequired, 1),
			$arowM$elm_form_decoder$Form$Decoder$identity)));
var $author$project$Page$Profile$HeightInvalidFloatValue = {$: 27};
var $author$project$Page$Profile$HeightTooTaller = {$: 28};
var $arowM$elm_form_decoder$Form$Decoder$always = function (a) {
	return $arowM$elm_form_decoder$Form$Decoder$custom(
		function (_v0) {
			return $elm$core$Result$Ok(a);
		});
};
var $arowM$elm_form_decoder$Form$Decoder$andThen = F2(
	function (f, _v0) {
		var g = _v0;
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			function (a) {
				var _v1 = g(a);
				if (_v1.$ === 1) {
					var err = _v1.a;
					return $elm$core$Result$Err(err);
				} else {
					var x = _v1.a;
					return A2(
						$arowM$elm_form_decoder$Form$Decoder$run,
						f(x),
						a);
				}
			});
	});
var $arowM$elm_form_decoder$Form$Decoder$float = function (err) {
	return $arowM$elm_form_decoder$Form$Decoder$custom(
		A2(
			$elm$core$Basics$composeL,
			$elm$core$Result$fromMaybe(
				_List_fromArray(
					[err])),
			$elm$core$String$toFloat));
};
var $arowM$elm_form_decoder$Form$Decoder$maxBound = F2(
	function (err, bound) {
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			function (n) {
				return (_Utils_cmp(n, bound) < 1) ? $elm$core$Result$Ok(0) : $elm$core$Result$Err(
					_List_fromArray(
						[err]));
			});
	});
var $author$project$Page$Profile$decoderHeight = A2(
	$arowM$elm_form_decoder$Form$Decoder$andThen,
	function (v) {
		return (v === '') ? $arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing) : A2(
			$arowM$elm_form_decoder$Form$Decoder$map,
			$elm$core$Maybe$Just,
			A2(
				$arowM$elm_form_decoder$Form$Decoder$assert,
				A2($arowM$elm_form_decoder$Form$Decoder$maxBound, $author$project$Page$Profile$HeightTooTaller, 272.0),
				$arowM$elm_form_decoder$Form$Decoder$float($author$project$Page$Profile$HeightInvalidFloatValue)));
	},
	$arowM$elm_form_decoder$Form$Decoder$identity);
var $author$project$Page$Profile$LastNameRequired = {$: 20};
var $author$project$Page$Profile$LastNameTooLonger = {$: 21};
var $author$project$Page$Profile$decoderLastName = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$LastNameTooLonger, 30),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$assert,
			A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Profile$LastNameRequired, 1),
			$arowM$elm_form_decoder$Form$Decoder$identity)));
var $author$project$Page$Profile$LastNameFuriganaRequired = {$: 22};
var $author$project$Page$Profile$LastNameFuriganaTooLonger = {$: 23};
var $author$project$Page$Profile$decoderLastNameFurigana = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$LastNameFuriganaTooLonger, 30),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$assert,
			A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Profile$LastNameFuriganaRequired, 1),
			$arowM$elm_form_decoder$Form$Decoder$identity)));
var $author$project$Page$Profile$RelationshipRequired = {$: 24};
var $author$project$Page$Profile$RelationshipTooLonger = {$: 25};
var $author$project$Page$Profile$decoderRelationship = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$RelationshipTooLonger, 4),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$assert,
			A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Profile$RelationshipRequired, 1),
			$arowM$elm_form_decoder$Form$Decoder$identity)));
var $author$project$Page$Profile$ShoeSiseInvalidFloatValue = {$: 30};
var $author$project$Page$Profile$ShoesizeTooBigger = {$: 32};
var $author$project$Page$Profile$ShoesizeTooSmaller = {$: 31};
var $arowM$elm_form_decoder$Form$Decoder$minBound = F2(
	function (err, bound) {
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			function (n) {
				return (_Utils_cmp(n, bound) > -1) ? $elm$core$Result$Ok(0) : $elm$core$Result$Err(
					_List_fromArray(
						[err]));
			});
	});
var $author$project$Page$Profile$decoderShoeSize = A2(
	$arowM$elm_form_decoder$Form$Decoder$andThen,
	function (v) {
		return (v === '') ? $arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing) : A2(
			$arowM$elm_form_decoder$Form$Decoder$map,
			$elm$core$Maybe$Just,
			A2(
				$arowM$elm_form_decoder$Form$Decoder$assert,
				A2($arowM$elm_form_decoder$Form$Decoder$maxBound, $author$project$Page$Profile$ShoesizeTooBigger, 30.0),
				A2(
					$arowM$elm_form_decoder$Form$Decoder$assert,
					A2($arowM$elm_form_decoder$Form$Decoder$minBound, $author$project$Page$Profile$ShoesizeTooSmaller, 8.0),
					$arowM$elm_form_decoder$Form$Decoder$float($author$project$Page$Profile$ShoeSiseInvalidFloatValue))));
	},
	$arowM$elm_form_decoder$Form$Decoder$identity);
var $author$project$Page$Profile$decoderUid = $arowM$elm_form_decoder$Form$Decoder$identity;
var $arowM$elm_form_decoder$Form$Decoder$field = F2(
	function (_v0, _v1) {
		var f = _v0;
		var g = _v1;
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			function (i) {
				var _v2 = _Utils_Tuple2(
					g(i),
					f(i));
				if (!_v2.a.$) {
					var h = _v2.a.a;
					var res = _v2.b;
					return A2($elm$core$Result$map, h, res);
				} else {
					if (_v2.b.$ === 1) {
						var gErr = _v2.a.a;
						var fErr = _v2.b.a;
						return $elm$core$Result$Err(
							_Utils_ap(gErr, fErr));
					} else {
						var gErr = _v2.a.a;
						return $elm$core$Result$Err(gErr);
					}
				}
			});
	});
var $arowM$elm_form_decoder$Form$Decoder$lift = F2(
	function (f, _v0) {
		var g = _v0;
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			A2($elm$core$Basics$composeL, g, f));
	});
var $arowM$elm_form_decoder$Form$Decoder$top = function (f) {
	return $arowM$elm_form_decoder$Form$Decoder$custom(
		function (_v0) {
			return $elm$core$Result$Ok(f);
		});
};
var $author$project$Page$Profile$decoderFamilyMember = function () {
	var toIsoDate = function (d) {
		return A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[d.az, d.ay, d.ax]));
	};
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		A2(
			lift,
			function ($) {
				return $.cF;
			},
			$author$project$Page$Profile$decoderShoeSize),
		A2(
			field,
			A2(
				lift,
				function ($) {
					return $.b2;
				},
				$author$project$Page$Profile$decoderHeight),
			A2(
				field,
				A2(
					lift,
					function ($) {
						return $.aY;
					},
					$author$project$Page$Profile$decoderEmails),
				A2(
					field,
					$author$project$Page$Profile$decoderBirthday,
					A2(
						field,
						A2(
							lift,
							function ($) {
								return $.a4;
							},
							$author$project$Page$Profile$decoderLastNameFurigana),
						A2(
							field,
							A2(
								lift,
								function ($) {
									return $.a_;
								},
								$author$project$Page$Profile$decoderFirstNameFurigana),
							A2(
								field,
								A2(
									lift,
									function ($) {
										return $.a3;
									},
									$author$project$Page$Profile$decoderLastName),
								A2(
									field,
									A2(
										lift,
										function ($) {
											return $.aZ;
										},
										$author$project$Page$Profile$decoderFirstName),
									A2(
										field,
										A2(
											lift,
											function ($) {
												return $.cz;
											},
											$author$project$Page$Profile$decoderRelationship),
										A2(
											field,
											A2(
												lift,
												function ($) {
													return $.am;
												},
												$author$project$Page$Profile$decoderCustomerId),
											A2(
												field,
												A2(
													lift,
													function ($) {
														return $.c;
													},
													$author$project$Page$Profile$decoderUid),
												$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$FamilyMember))))))))))));
}();
var $arowM$elm_form_decoder$Form$Decoder$appendListResult = F2(
	function (r1, r2) {
		var _v0 = _Utils_Tuple2(r1, r2);
		if (_v0.a.$ === 1) {
			if (_v0.b.$ === 1) {
				var err = _v0.a.a;
				var errs = _v0.b.a;
				return $elm$core$Result$Err(
					_Utils_ap(err, errs));
			} else {
				var err = _v0.a.a;
				return $elm$core$Result$Err(err);
			}
		} else {
			if (_v0.b.$ === 1) {
				var errs = _v0.b.a;
				return $elm$core$Result$Err(errs);
			} else {
				var b = _v0.a.a;
				var bs = _v0.b.a;
				return $elm$core$Result$Ok(
					A2($elm$core$List$cons, b, bs));
			}
		}
	});
var $arowM$elm_form_decoder$Form$Decoder$runWithTag = F3(
	function (tag, d, a) {
		return A2(
			$elm$core$Result$mapError,
			$elm$core$List$map(
				function (err) {
					return _Utils_Tuple2(tag, err);
				}),
			A2($arowM$elm_form_decoder$Form$Decoder$run, d, a));
	});
var $arowM$elm_form_decoder$Form$Decoder$listOf = function (d) {
	return $arowM$elm_form_decoder$Form$Decoder$custom(
		function (ls) {
			return A3(
				$elm$core$List$foldr,
				$arowM$elm_form_decoder$Form$Decoder$appendListResult,
				$elm$core$Result$Ok(_List_Nil),
				A2(
					$elm$core$List$indexedMap,
					function (n) {
						return A2($arowM$elm_form_decoder$Form$Decoder$runWithTag, n, d);
					},
					ls));
		});
};
var $arowM$elm_form_decoder$Form$Decoder$mapError = F2(
	function (f, _v0) {
		var g = _v0;
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Result$mapError(
					$elm$core$List$map(f)),
				g));
	});
var $author$project$Page$Profile$decoderFamilyMembers = A2(
	$arowM$elm_form_decoder$Form$Decoder$mapError,
	$author$project$Page$Profile$FamilyMembersHasError,
	$arowM$elm_form_decoder$Form$Decoder$listOf($author$project$Page$Profile$decoderFamilyMember));
var $author$project$Page$Profile$decoderIsAcceptDm = A2($arowM$elm_form_decoder$Form$Decoder$map, $elm$core$Maybe$Just, $arowM$elm_form_decoder$Form$Decoder$identity);
var $author$project$Page$Profile$PhoneNumberRequired = {$: 0};
var $author$project$Page$Profile$PhoneNumberTooLonger = {$: 1};
var $author$project$Page$Profile$decoderPhoneNumber = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$PhoneNumberTooLonger, 20),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$assert,
			A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Profile$PhoneNumberRequired, 1),
			$arowM$elm_form_decoder$Form$Decoder$identity)));
var $author$project$Page$Profile$PostalCodeTooLonger = {$: 3};
var $author$project$Page$Profile$decoderPostalCode = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$PostalCodeTooLonger, 8),
		$arowM$elm_form_decoder$Form$Decoder$identity));
var $author$project$Page$Profile$PrefectureRequired = {$: 4};
var $author$project$Page$Profile$decoderPrefecture = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$PrefectureRequired, 4),
		$arowM$elm_form_decoder$Form$Decoder$identity));
var $author$project$Page$Profile$RepresentativeIdRequired = {$: 9};
var $author$project$Page$Profile$RepresentativeIdTooLonger = {$: 10};
var $author$project$Page$Profile$decoderRepresentativeId = A2(
	$arowM$elm_form_decoder$Form$Decoder$map,
	$elm$core$Maybe$Just,
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Profile$RepresentativeIdTooLonger, 36),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$assert,
			A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Profile$RepresentativeIdRequired, 1),
			$arowM$elm_form_decoder$Form$Decoder$identity)));
var $arowM$elm_form_decoder$Form$Decoder$list = A2(
	$elm$core$Basics$composeL,
	$arowM$elm_form_decoder$Form$Decoder$mapError($elm$core$Tuple$second),
	$arowM$elm_form_decoder$Form$Decoder$listOf);
var $author$project$Page$Profile$decoderCustomer = function () {
	var list = $arowM$elm_form_decoder$Form$Decoder$list;
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		A2(
			lift,
			function ($) {
				return $.dW;
			},
			$author$project$Page$Profile$decoderFamilyMembers),
		A2(
			field,
			A2(
				lift,
				function ($) {
					return $.cB;
				},
				$author$project$Page$Profile$decoderRepresentativeId),
			A2(
				field,
				A2(
					lift,
					function ($) {
						return $.b7;
					},
					$author$project$Page$Profile$decoderIsAcceptDm),
				A2(
					field,
					A2(
						lift,
						function ($) {
							return $.bG;
						},
						$author$project$Page$Profile$decoderAddress2),
					A2(
						field,
						A2(
							lift,
							function ($) {
								return $.bF;
							},
							$author$project$Page$Profile$decoderAddress1),
						A2(
							field,
							A2(
								lift,
								function ($) {
									return $.cw;
								},
								$author$project$Page$Profile$decoderPrefecture),
							A2(
								field,
								A2(
									lift,
									function ($) {
										return $.cv;
									},
									$author$project$Page$Profile$decoderPostalCode),
								A2(
									field,
									A2(
										lift,
										function ($) {
											return $.aq;
										},
										$author$project$Page$Profile$decoderPhoneNumber),
									A2(
										field,
										A2(
											lift,
											function ($) {
												return $.c;
											},
											$author$project$Page$Profile$decoderUid),
										$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$MypageCustomer))))))))));
}();
var $author$project$Page$Profile$emptyMember = F3(
	function (customerId, lastName, lastNameFurigana) {
		return {ax: '--', ay: '--', az: '----', am: customerId, aY: '', aZ: '', a_: '', b2: '', a3: lastName, a4: lastNameFurigana, cz: '', cF: '', a9: true, c: '__new__'};
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.dk, posixMinutes) < 0) {
					return posixMinutes + era.d;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		dL: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		ev: month,
		fN: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dL;
	});
var $author$project$Page$Profile$toIntMonth = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ev;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $author$project$Page$Profile$toStrEmailList = function (emails) {
	return A2(
		$elm$core$String$join,
		', ',
		A2($elm$core$Maybe$withDefault, _List_Nil, emails));
};
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fN;
	});
var $author$project$Page$Profile$mapFamilyMembers = function (fms) {
	var birthday_yy = function (d) {
		return function (date) {
			return A2(
				$elm$core$Maybe$withDefault,
				'----',
				A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromInt,
					A2(
						$elm$core$Maybe$map,
						$elm$time$Time$toYear($elm$time$Time$utc),
						date)));
		}(d);
	};
	var birthday_mm = function (d) {
		return function (date) {
			return A2(
				$elm$core$Maybe$withDefault,
				'--',
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$String$padLeft, 2, '0'),
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromInt,
						A2(
							$elm$core$Maybe$map,
							$author$project$Page$Profile$toIntMonth,
							A2(
								$elm$core$Maybe$map,
								$elm$time$Time$toMonth($elm$time$Time$utc),
								date)))));
		}(d);
	};
	var birthday_dd = function (d) {
		return function (date) {
			return A2(
				$elm$core$Maybe$withDefault,
				'--',
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$String$padLeft, 2, '0'),
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromInt,
						A2(
							$elm$core$Maybe$map,
							$elm$time$Time$toDay($elm$time$Time$utc),
							date))));
		}(d);
	};
	return A2(
		$elm$core$List$map,
		function (m) {
			return {
				ax: birthday_dd(m.bH),
				ay: birthday_mm(m.bH),
				az: birthday_yy(m.bH),
				am: m.am,
				aY: $author$project$Page$Profile$toStrEmailList(m.aY),
				aZ: A2($elm$core$Maybe$withDefault, '', m.aZ),
				a_: A2($elm$core$Maybe$withDefault, '', m.a_),
				b2: A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromFloat, m.b2)),
				a3: A2($elm$core$Maybe$withDefault, '', m.a3),
				a4: A2($elm$core$Maybe$withDefault, '', m.a4),
				cz: A2($elm$core$Maybe$withDefault, '', m.cz),
				cF: A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $elm$core$String$fromFloat, m.cF)),
				a9: false,
				c: m.c
			};
		},
		fms);
};
var $author$project$Page$Profile$mapCustomer = function (c) {
	return {
		bF: A2($elm$core$Maybe$withDefault, '', c.bF),
		bG: A2($elm$core$Maybe$withDefault, '', c.bG),
		dW: $author$project$Page$Profile$mapFamilyMembers(c.fj),
		b7: A2($elm$core$Maybe$withDefault, false, c.b7),
		aq: A2($elm$core$Maybe$withDefault, '', c.aq),
		cv: A2($elm$core$Maybe$withDefault, '', c.cv),
		cw: A2($elm$core$Maybe$withDefault, '', c.cw),
		cB: A2($elm$core$Maybe$withDefault, '', c.cB),
		c: c.c
	};
};
var $author$project$Page$Profile$CompletedPostCustomer = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString = F2(
	function (digits, time) {
		return A3(
			$elm$core$String$padLeft,
			digits,
			'0',
			$elm$core$String$fromInt(time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime = function (time) {
	return A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		4,
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth(
			A2($elm$time$Time$toMonth, $elm$time$Time$utc, time))) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + ('T' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toHour, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toMinute, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toSecond, $elm$time$Time$utc, time)) + ('.' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		3,
		A2($elm$time$Time$toMillis, $elm$time$Time$utc, time)) + 'Z'))))))))))));
};
var $author$project$Api$Time$dateTimeToString = $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime;
var $author$project$Api$Time$dateToString = A2(
	$elm$core$Basics$composeL,
	$elm$core$String$left(10),
	$author$project$Api$Time$dateTimeToString);
var $author$project$Api$Time$encodeDate = A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$Api$Time$dateToString);
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Api$Data$encodeFamilyMemberPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'uid', $elm$json$Json$Encode$string, model.c),
			A3($author$project$Api$Data$encode, 'customer_id', $elm$json$Json$Encode$string, model.am),
			A3($author$project$Api$Data$maybeEncode, 'relationship', $elm$json$Json$Encode$string, model.cz),
			A3($author$project$Api$Data$maybeEncode, 'first_name', $elm$json$Json$Encode$string, model.aZ),
			A3($author$project$Api$Data$maybeEncode, 'last_name', $elm$json$Json$Encode$string, model.a3),
			A3($author$project$Api$Data$maybeEncode, 'first_name_furigana', $elm$json$Json$Encode$string, model.a_),
			A3($author$project$Api$Data$maybeEncode, 'last_name_furigana', $elm$json$Json$Encode$string, model.a4),
			A3($author$project$Api$Data$maybeEncode, 'birthday', $author$project$Api$Time$encodeDate, model.bH),
			A3(
			$author$project$Api$Data$maybeEncode,
			'emails',
			$elm$json$Json$Encode$list($elm$json$Json$Encode$string),
			model.aY),
			A3($author$project$Api$Data$maybeEncode, 'height', $elm$json$Json$Encode$float, model.b2),
			A3($author$project$Api$Data$maybeEncode, 'shoe_size', $elm$json$Json$Encode$float, model.cF)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeFamilyMember = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeFamilyMemberPairs);
var $author$project$Api$Data$encodeMypageCustomerPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'uid', $elm$json$Json$Encode$string, model.c),
			A3($author$project$Api$Data$maybeEncode, 'phone_number', $elm$json$Json$Encode$string, model.aq),
			A3($author$project$Api$Data$maybeEncode, 'postal_code', $elm$json$Json$Encode$string, model.cv),
			A3($author$project$Api$Data$maybeEncode, 'prefecture', $elm$json$Json$Encode$string, model.cw),
			A3($author$project$Api$Data$maybeEncode, 'address1', $elm$json$Json$Encode$string, model.bF),
			A3($author$project$Api$Data$maybeEncode, 'address2', $elm$json$Json$Encode$string, model.bG),
			A3($author$project$Api$Data$maybeEncode, 'is_accept_dm', $elm$json$Json$Encode$bool, model.b7),
			A3($author$project$Api$Data$maybeEncode, 'representative_id', $elm$json$Json$Encode$string, model.cB),
			A3(
			$author$project$Api$Data$encode,
			'family_members',
			$elm$json$Json$Encode$list($author$project$Api$Data$encodeFamilyMember),
			model.dW)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeMypageCustomer = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeMypageCustomerPairs);
var $author$project$Api$Request$Default$postCustomer = function (mypageCustomer_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/customer/',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeMypageCustomer(mypageCustomer_body)),
		$author$project$Api$Data$mypageCustomerWithSortedFamilyMembersDecoder);
};
var $author$project$Page$Profile$postCustomer = F3(
	function (session, uid, data) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Profile$CompletedPostCustomer,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$postCustomer(data)));
	});
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$Page$Profile$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: session}),
					$elm$core$Platform$Cmd$none);
			case 4:
				if (!msg.a.$) {
					var customer = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dG: $author$project$Page$Profile$Loaded(
									$author$project$Page$Profile$mapCustomer(customer)),
								dT: _List_Nil,
								bm: _List_Nil
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dG: $author$project$Page$Profile$Failed}),
						$elm$core$Platform$Cmd$none);
				}
			case 9:
				var _v1 = msg.a;
				var zone = _v1.a;
				var time = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cu: time, cO: zone}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var idx = msg.a;
				var c = msg.b;
				var toggle = F2(
					function (i, fm) {
						return _Utils_eq(idx, i) ? _Utils_update(
							fm,
							{a9: !fm.a9}) : fm;
					});
				var members = A2($elm$core$List$indexedMap, toggle, c.dW);
				var customer = _Utils_update(
					c,
					{dW: members});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dG: $author$project$Page$Profile$Loaded(customer)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var field = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$Profile$applyInputValue, model, field),
					$elm$core$Platform$Cmd$none);
			case 6:
				var field = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$Profile$applyInputValue, model, field),
					$elm$core$Platform$Cmd$none);
			case 11:
				var c = msg.a;
				var customer = function () {
					var _v2 = $elm$core$List$head(c.dW);
					if (!_v2.$) {
						var m = _v2.a;
						return _Utils_update(
							c,
							{
								dW: _Utils_ap(
									c.dW,
									_List_fromArray(
										[
											A3($author$project$Page$Profile$emptyMember, c.c, m.a3, m.a4)
										]))
							});
					} else {
						return c;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dG: $author$project$Page$Profile$Loaded(customer)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					model,
					$author$project$Page$Profile$fetchCustomer(
						$author$project$Page$Profile$toSession(model)));
			case 8:
				var customer = msg.a;
				var _v3 = A2($arowM$elm_form_decoder$Form$Decoder$run, $author$project$Page$Profile$decoderCustomer, customer);
				if (!_v3.$) {
					var data = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dT: _List_Nil}),
						A3(
							$author$project$Page$Profile$postCustomer,
							$author$project$Page$Profile$toSession(model),
							data.c,
							data));
				} else {
					var errors = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dT: errors, bm: _List_Nil}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!msg.a.$) {
					var customer = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dG: $author$project$Page$Profile$Loaded(
									$author$project$Page$Profile$mapCustomer(customer)),
								bm: _List_fromArray(
									['お客様情報が更新されました。'])
							}),
						A2(
							$elm$core$Task$perform,
							function (_v4) {
								return $author$project$Page$Profile$NoOp;
							},
							A2($elm$browser$Browser$Dom$setViewport, 0, 0)));
				} else {
					var error = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dG: $author$project$Page$Profile$Failed}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dT: _List_Nil}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bm: _List_Nil}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Reservation$ChangeScheduleStep = 12;
var $author$project$Page$Reservation$ChoiceItem = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Reservation$ClothesSection = 9;
var $author$project$Page$Reservation$CompleteReservation = 11;
var $author$project$Page$Reservation$CustomerInfo = 10;
var $author$project$Page$Reservation$Failed = {$: 3};
var $author$project$Page$Reservation$Loaded = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Reservation$Option = 3;
var $author$project$Page$Reservation$PlanCategoryStep = 1;
var $author$project$Page$Reservation$PlanStep = 2;
var $author$project$Page$Reservation$Schedule = 6;
var $author$project$Page$Reservation$ScheduleCalendar = 7;
var $author$project$Page$Reservation$ScheduleTimeslot = 8;
var $author$project$Page$Reservation$ServerError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Reservation$ShopSection = 4;
var $author$project$Page$Reservation$StudioStep = 5;
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Page$Reservation$takeRepresentative = function (model) {
	var representativeId = model.dG.cB;
	return function (v) {
		return (v.cz === '') ? _Utils_update(
			v,
			{cz: '本人'}) : v;
	}(
		A2(
			$elm$core$Maybe$withDefault,
			A2(
				$elm$core$Maybe$withDefault,
				A3($author$project$Page$Profile$emptyMember, model.dG.c, '', ''),
				$elm$core$List$head(model.dG.dW)),
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (m) {
						return _Utils_eq(m.c, representativeId);
					},
					model.dG.dW))));
};
var $author$project$Page$Reservation$appllyCustomerInfo = function (model) {
	var waitingList = model.s;
	var representative = $author$project$Page$Reservation$takeRepresentative(model);
	var customer = model.dG;
	return _Utils_update(
		waitingList,
		{dH: representative.a3 + (' ' + representative.aZ), dI: representative.a4 + (' ' + representative.a_), bU: representative.aY, aq: customer.aq});
};
var $author$project$Page$Reservation$setBirthday = F2(
	function (_v0, member) {
		var yy = _v0.a;
		var mm = _v0.b;
		var dd = _v0.c;
		return _Utils_update(
			member,
			{ax: dd, ay: mm, az: yy});
	});
var $author$project$Page$Reservation$updateCustomerForm = F2(
	function (model, fn) {
		var rf = model.dG;
		return _Utils_update(
			model,
			{
				dG: fn(rf)
			});
	});
var $author$project$Page$Reservation$updateFamilyMember = F3(
	function (model, idx, fn) {
		var index = idx;
		var fms = A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, value) {
					return _Utils_eq(i, index) ? fn(value) : value;
				}),
			model.dG.dW);
		return A2(
			$author$project$Page$Reservation$updateCustomerForm,
			model,
			function (c) {
				return _Utils_update(
					c,
					{dW: fms});
			});
	});
var $author$project$Page$Reservation$applyValuForFamilyMember = F3(
	function (model, idx, field) {
		return A3(
			$author$project$Page$Reservation$updateFamilyMember,
			model,
			idx,
			function (form) {
				switch (field.$) {
					case 0:
						var v = field.a;
						return _Utils_update(
							form,
							{a3: v});
					case 1:
						var v = field.a;
						return _Utils_update(
							form,
							{aZ: v});
					case 2:
						var v = field.a;
						return _Utils_update(
							form,
							{a4: v});
					case 3:
						var v = field.a;
						return _Utils_update(
							form,
							{a_: v});
					case 4:
						var v = field.a;
						return _Utils_update(
							form,
							{aY: v});
					case 5:
						var v = field.a;
						return _Utils_update(
							form,
							{cz: v});
					case 6:
						var v = field.a;
						return A2($author$project$Page$Reservation$setBirthday, v, form);
					case 8:
						var v = field.a;
						return _Utils_update(
							form,
							{b2: v});
					default:
						var v = field.a;
						return _Utils_update(
							form,
							{cF: v});
				}
			});
	});
var $author$project$Page$Reservation$updateRepresentative = F2(
	function (model, fn) {
		var representative = fn(
			$author$project$Page$Reservation$takeRepresentative(model));
		var representativeId = representative.c;
		var familyMembers = function () {
			var _v0 = model.dG.dW;
			if (!_v0.b) {
				return _List_fromArray(
					[representative]);
			} else {
				return A2(
					$elm$core$List$map,
					function (ff) {
						return _Utils_eq(ff.c, representativeId) ? representative : ff;
					},
					model.dG.dW);
			}
		}();
		return A2(
			$author$project$Page$Reservation$updateCustomerForm,
			model,
			function (customer) {
				return _Utils_update(
					customer,
					{dW: familyMembers});
			});
	});
var $author$project$Page$Reservation$applyValuForRepresentative = F2(
	function (model, field) {
		return A2(
			$author$project$Page$Reservation$updateRepresentative,
			model,
			function (representative) {
				switch (field.$) {
					case 0:
						var v = field.a;
						return _Utils_update(
							representative,
							{a3: v});
					case 1:
						var v = field.a;
						return _Utils_update(
							representative,
							{aZ: v});
					case 2:
						var v = field.a;
						return _Utils_update(
							representative,
							{a4: v});
					case 3:
						var v = field.a;
						return _Utils_update(
							representative,
							{a_: v});
					case 4:
						var v = field.a;
						return _Utils_update(
							representative,
							{aY: v});
					default:
						return representative;
				}
			});
	});
var $author$project$Page$Reservation$applyValueForCustomerform = F2(
	function (model, field) {
		return A2(
			$author$project$Page$Reservation$updateCustomerForm,
			model,
			function (customer) {
				switch (field.$) {
					case 0:
						var v = field.a;
						return _Utils_update(
							customer,
							{aq: v});
					case 1:
						var v = field.a;
						return _Utils_update(
							customer,
							{cv: v});
					case 2:
						var v = field.a;
						return _Utils_update(
							customer,
							{cw: v});
					case 3:
						var v = field.a;
						return _Utils_update(
							customer,
							{bF: v});
					case 4:
						var v = field.a;
						return _Utils_update(
							customer,
							{bG: v});
					default:
						var v = field.a;
						return _Utils_update(
							customer,
							{b7: v});
				}
			});
	});
var $author$project$Page$Reservation$hasUsedBefore = F2(
	function (key, checked) {
		switch (key) {
			case 'はい':
				return checked;
			case 'いいえ':
				return !checked;
			default:
				return false;
		}
	});
var $author$project$Page$Reservation$setAdditionalComments = function (value) {
	return function (form) {
		return _Utils_update(
			form,
			{dr: value});
	};
};
var $author$project$Page$Reservation$setCustomField = F2(
	function (idx, value) {
		return function (form) {
			var customFields = form.aU;
			var customField = A2($elm$core$Array$get, idx, form.aU);
			if (customField.$ === 1) {
				return form;
			} else {
				var cf = customField.a;
				return _Utils_update(
					form,
					{
						aU: A3(
							$elm$core$Array$set,
							idx,
							_Utils_update(
								cf,
								{be: value}),
							customFields)
					});
			}
		};
	});
var $author$project$Page$Reservation$setCheckedCustomField = F3(
	function (idx, key, checked) {
		return function (form) {
			var customerFiedl = A2($elm$core$Array$get, idx, form.aU);
			if (customerFiedl.$ === 1) {
				return form;
			} else {
				var cf = customerFiedl.a;
				return A3($author$project$Page$Reservation$setCustomField, idx, key, form);
			}
		};
	});
var $author$project$Page$Reservation$setPlanOption = F2(
	function (idx, value) {
		return function (form) {
			var planOptions = form.eR;
			var planOption = A2($elm$core$Array$get, idx, form.eR);
			if (planOption.$ === 1) {
				return form;
			} else {
				var po = planOption.a;
				return _Utils_update(
					form,
					{
						eR: A3(
							$elm$core$Array$set,
							idx,
							_Utils_update(
								po,
								{be: value}),
							planOptions)
					});
			}
		};
	});
var $author$project$Page$Reservation$setCheckedPlanOption = F3(
	function (idx, key, checked) {
		return function (form) {
			var planOption = A2($elm$core$Array$get, idx, form.eR);
			if (planOption.$ === 1) {
				return form;
			} else {
				var po = planOption.a;
				return A3($author$project$Page$Reservation$setPlanOption, idx, key, form);
			}
		};
	});
var $elm$core$String$lines = _String_lines;
var $author$project$Page$Reservation$setMultiCheckedCustomField = F3(
	function (idx, key, checked) {
		return function (form) {
			var customerField = A2($elm$core$Array$get, idx, form.aU);
			var applyValue = function (l) {
				return (checked ? $elm$core$List$cons(key) : $elm$core$List$filter(
					function (a) {
						return !_Utils_eq(a, key);
					}))(l);
			};
			if (customerField.$ === 1) {
				return form;
			} else {
				var cf = customerField.a;
				return A2(
					$author$project$Page$Reservation$setCustomField,
					idx,
					A2(
						$elm$core$String$join,
						'\r',
						applyValue(
							$elm$core$String$lines(cf.be))))(form);
			}
		};
	});
var $author$project$Page$Reservation$setMultiCheckedPlanOption = F3(
	function (idx, key, checked) {
		return function (form) {
			var planOption = A2($elm$core$Array$get, idx, form.eR);
			var applyValue = function (l) {
				return (checked ? $elm$core$List$cons(key) : $elm$core$List$filter(
					function (a) {
						return !_Utils_eq(a, key);
					}))(l);
			};
			if (planOption.$ === 1) {
				return form;
			} else {
				var po = planOption.a;
				return A2(
					$author$project$Page$Reservation$setPlanOption,
					idx,
					A2(
						$elm$core$String$join,
						'\r',
						applyValue(
							$elm$core$String$lines(po.be))))(form);
			}
		};
	});
var $author$project$Page$Reservation$updateReservationOtherInfo = F2(
	function (model, fn) {
		var rf = model.G;
		return _Utils_update(
			model,
			{
				G: fn(rf)
			});
	});
var $author$project$Page$Reservation$updateReservationPlan = F2(
	function (model, fn) {
		var rf = model.b;
		return _Utils_update(
			model,
			{
				b: fn(rf)
			});
	});
var $author$project$Page$Reservation$updateWaitingList = F2(
	function (model, fn) {
		var rf = model.s;
		return _Utils_update(
			model,
			{
				s: fn(rf)
			});
	});
var $author$project$Page$Reservation$updateWatingListDateTime = F3(
	function (model, idx, fn) {
		var replaceAtIndex = F3(
			function (index, list, replacement) {
				return A2(
					$elm$core$List$map,
					function (_v0) {
						var i = _v0.a;
						var item = _v0.b;
						return _Utils_eq(i, index) ? replacement(item) : item;
					},
					A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, list));
			});
		var dt = A3(replaceAtIndex, idx, model.s.dK, fn);
		return A2(
			$author$project$Page$Reservation$updateWaitingList,
			model,
			function (wl) {
				return _Utils_update(
					wl,
					{dK: dt});
			});
	});
var $author$project$Page$Reservation$applyInputValue = F2(
	function (model, field) {
		var applyValueForWaitingListDateTime = $author$project$Page$Reservation$updateWatingListDateTime(model);
		var applyValueForWaitingList = $author$project$Page$Reservation$updateWaitingList(model);
		var applyValueForReservationPlan = $author$project$Page$Reservation$updateReservationPlan(model);
		var applyValueForReservationOtherInfo = $author$project$Page$Reservation$updateReservationOtherInfo(model);
		switch (field.$) {
			case 11:
				var value = field.a;
				return applyValueForReservationOtherInfo(
					function (form) {
						return _Utils_update(
							form,
							{ds: value});
					});
			case 0:
				var idx = field.a;
				var value = field.b;
				return applyValueForReservationPlan(
					A2($author$project$Page$Reservation$setPlanOption, idx, value));
			case 1:
				var idx = field.a;
				var key = field.b;
				var checked = field.c;
				return applyValueForReservationPlan(
					A3($author$project$Page$Reservation$setCheckedPlanOption, idx, key, checked));
			case 9:
				var key = field.a;
				var checked = field.b;
				var m = applyValueForReservationOtherInfo(
					function (form) {
						var hasUB = A2($author$project$Page$Reservation$hasUsedBefore, key, checked);
						return hasUB ? _Utils_update(
							form,
							{d3: hasUB}) : _Utils_update(
							form,
							{d3: hasUB});
					});
				var c = model.dG;
				return _Utils_update(
					m,
					{
						dG: _Utils_update(
							c,
							{c: '__new__'})
					});
			case 2:
				var idx = field.a;
				var key = field.b;
				var checked = field.c;
				return applyValueForReservationPlan(
					A3($author$project$Page$Reservation$setMultiCheckedPlanOption, idx, key, checked));
			case 3:
				var idx = field.a;
				var value = field.b;
				return applyValueForReservationPlan(
					A2($author$project$Page$Reservation$setCustomField, idx, value));
			case 4:
				var idx = field.a;
				var key = field.b;
				var checked = field.c;
				return applyValueForReservationPlan(
					A3($author$project$Page$Reservation$setCheckedCustomField, idx, key, checked));
			case 5:
				var idx = field.a;
				var key = field.b;
				var checked = field.c;
				return applyValueForReservationPlan(
					A3($author$project$Page$Reservation$setMultiCheckedCustomField, idx, key, checked));
			case 6:
				var fld = field.a;
				return A2($author$project$Page$Reservation$applyValuForRepresentative, model, fld);
			case 7:
				var idx = field.a;
				var fld = field.b;
				return A3($author$project$Page$Reservation$applyValuForFamilyMember, model, idx, fld);
			case 8:
				var fld = field.a;
				return A2($author$project$Page$Reservation$applyValueForCustomerform, model, fld);
			case 10:
				var value = field.a;
				return applyValueForReservationOtherInfo(
					$author$project$Page$Reservation$setAdditionalComments(value));
			case 13:
				var v = field.a;
				return applyValueForWaitingList(
					function (form) {
						return _Utils_update(
							form,
							{dH: v});
					});
			case 14:
				var v = field.a;
				return applyValueForWaitingList(
					function (form) {
						return _Utils_update(
							form,
							{dI: v});
					});
			case 12:
				var v = field.a;
				return applyValueForWaitingList(
					function (form) {
						return _Utils_update(
							form,
							{bU: v});
					});
			case 15:
				var v = field.a;
				return applyValueForWaitingList(
					function (form) {
						return _Utils_update(
							form,
							{aq: v});
					});
			case 16:
				var v = field.a;
				return applyValueForWaitingList(
					function (form) {
						return _Utils_update(
							form,
							{aa: v});
					});
			case 17:
				var k = field.a;
				var v = field.b;
				var tr = function () {
					if (v) {
						return $elm$core$Maybe$Just(k);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				if (!tr.$) {
					var timeRequired = tr.a;
					return applyValueForWaitingList(
						function (form) {
							return _Utils_update(
								form,
								{bc: timeRequired});
						});
				} else {
					return model;
				}
			case 18:
				var idx = field.a;
				var v = field.b;
				return A2(
					applyValueForWaitingListDateTime,
					idx,
					function (form) {
						return _Utils_update(
							form,
							{a8: v});
					});
			default:
				var idx = field.a;
				var v = field.b;
				return A2(
					applyValueForWaitingListDateTime,
					idx,
					function (form) {
						return _Utils_update(
							form,
							{bb: v});
					});
		}
	});
var $author$project$Page$Reservation$mapReservationOtherInfo = function (r) {
	return {dr: r.eP.dr, ds: r.eP.ds, d3: r.eP.d3, e2: r.e2};
};
var $author$project$Page$Reservation$mapReservationSchedule = function (r) {
	return {ca: false, A: $elm$core$Maybe$Nothing, aj: $elm$core$Maybe$Nothing, v: $elm$core$Maybe$Nothing, ae: $elm$core$Maybe$Nothing, Q: $elm$core$Maybe$Nothing};
};
var $author$project$Page$Reservation$mapReservationPlan = function (r) {
	return {
		aU: $elm$core$Array$fromList(r.eP.aU),
		eO: $elm$core$Maybe$Nothing,
		eR: $elm$core$Array$fromList(r.eP.eR),
		as: $elm$core$Maybe$Nothing,
		g: $author$project$Page$Reservation$mapReservationSchedule(r),
		fe: $elm$core$Maybe$Just(r.eP.fe),
		c: r.c
	};
};
var $author$project$Page$Reservation$applyReservation = F2(
	function (model, reservation) {
		var session = $author$project$Page$Reservation$toSession(model);
		var _v0 = function () {
			if (reservation.$ === 1) {
				return _Utils_Tuple3(
					model.e,
					$author$project$Page$Reservation$emptyReservationPlan,
					$author$project$Page$Reservation$emptyReservationOtherInfo(session));
			} else {
				var r = reservation.a;
				return _Utils_Tuple3(
					13,
					$author$project$Page$Reservation$mapReservationPlan(r),
					$author$project$Page$Reservation$mapReservationOtherInfo(r));
			}
		}();
		var step = _v0.a;
		var reservationPlan = _v0.b;
		var reservationOtherInfo = _v0.c;
		return _Utils_update(
			model,
			{G: reservationOtherInfo, b: reservationPlan, e: step, R: reservation});
	});
var $author$project$Page$Reservation$CompletedCancelReservation = function (a) {
	return {$: 30, a: a};
};
var $author$project$Api$Data$DeleteStatus = function (status) {
	return {fp: status};
};
var $author$project$Api$Data$deleteStatusDecoder = A3(
	$author$project$Api$Data$decode,
	'status',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$Data$DeleteStatus));
var $author$project$Api$Data$stringFromFieldType = function (model) {
	switch (model) {
		case 0:
			return 'text';
		case 1:
			return 'radio';
		case 2:
			return 'checkbox';
		case 3:
			return 'select';
		default:
			return 'date';
	}
};
var $author$project$Api$Data$encodeFieldType = A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$Api$Data$stringFromFieldType);
var $author$project$Api$Data$encodeCustomFieldPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'name', $elm$json$Json$Encode$string, model.i),
			A3($author$project$Api$Data$encode, 'is_required', $elm$json$Json$Encode$bool, model.cb),
			A3($author$project$Api$Data$encode, 'field_type', $author$project$Api$Data$encodeFieldType, model.b_),
			A3($author$project$Api$Data$encode, 'option_items_text', $elm$json$Json$Encode$string, model.cp)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeCustomField = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeCustomFieldPairs);
var $author$project$Api$Data$encodeCustomFieldFormPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'value', $elm$json$Json$Encode$string, model.be),
			A3($author$project$Api$Data$encode, 'custom_field', $author$project$Api$Data$encodeCustomField, model.dF)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeCustomFieldForm = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeCustomFieldFormPairs);
var $author$project$Api$Data$encodeOptionItemPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'name', $elm$json$Json$Encode$string, model.i),
			A3($author$project$Api$Data$maybeEncode, 'unit_price', $elm$json$Json$Encode$int, model.fC)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeOptionItem = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeOptionItemPairs);
var $author$project$Api$Data$encodePlanOptionPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'uid', $elm$json$Json$Encode$string, model.c),
			A3($author$project$Api$Data$encode, 'name', $elm$json$Json$Encode$string, model.i),
			A3($author$project$Api$Data$encode, 'display_name', $elm$json$Json$Encode$string, model.aV),
			A3($author$project$Api$Data$encode, 'is_required', $elm$json$Json$Encode$bool, model.cb),
			A3($author$project$Api$Data$encode, 'field_type', $author$project$Api$Data$encodeFieldType, model.b_),
			A3($author$project$Api$Data$encode, 'option_items_text', $elm$json$Json$Encode$string, model.cp),
			A3(
			$author$project$Api$Data$encode,
			'option_items',
			$elm$json$Json$Encode$list($author$project$Api$Data$encodeOptionItem),
			model.eI)
		]);
	return pairs;
};
var $author$project$Api$Data$encodePlanOption = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodePlanOptionPairs);
var $author$project$Api$Data$encodePlanOptionsFormPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'value', $elm$json$Json$Encode$string, model.be),
			A3($author$project$Api$Data$encode, 'plan_option', $author$project$Api$Data$encodePlanOption, model.eQ)
		]);
	return pairs;
};
var $author$project$Api$Data$encodePlanOptionsForm = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodePlanOptionsFormPairs);
var $author$project$Api$Data$encodeShootingPlanFormPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'uid', $elm$json$Json$Encode$string, model.c),
			A3($author$project$Api$Data$encode, 'category_name', $elm$json$Json$Encode$string, model.bK),
			A3($author$project$Api$Data$encode, 'name', $elm$json$Json$Encode$string, model.i),
			A3($author$project$Api$Data$encode, 'display_order', $elm$json$Json$Encode$int, model.W),
			A3($author$project$Api$Data$encode, 'price', $elm$json$Json$Encode$int, model.cx),
			A3(
			$author$project$Api$Data$encode,
			'options',
			$elm$json$Json$Encode$list($author$project$Api$Data$encodePlanOption),
			model.cq),
			A3(
			$author$project$Api$Data$encode,
			'custom_fields',
			$elm$json$Json$Encode$list($author$project$Api$Data$encodeCustomField),
			model.aU),
			A3($author$project$Api$Data$maybeEncode, 'image_url', $elm$json$Json$Encode$string, model.C),
			A3($author$project$Api$Data$maybeEncode, 'note', $elm$json$Json$Encode$string, model.aa)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeShootingPlanForm = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeShootingPlanFormPairs);
var $author$project$Api$Data$encodePlanInfoPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'shooting_plan', $author$project$Api$Data$encodeShootingPlanForm, model.fe),
			A3(
			$author$project$Api$Data$encode,
			'plan_options',
			$elm$json$Json$Encode$list($author$project$Api$Data$encodePlanOptionsForm),
			model.eR),
			A3(
			$author$project$Api$Data$encode,
			'custom_fields',
			$elm$json$Json$Encode$list($author$project$Api$Data$encodeCustomFieldForm),
			model.aU),
			A3($author$project$Api$Data$encode, 'agree_to_privacy_policy_and_terms', $elm$json$Json$Encode$bool, model.ds),
			A3($author$project$Api$Data$encode, 'additional_comments', $elm$json$Json$Encode$string, model.dr),
			A3($author$project$Api$Data$encode, 'has_used_before', $elm$json$Json$Encode$bool, model.d3)
		]);
	return pairs;
};
var $author$project$Api$Data$encodePlanInfo = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodePlanInfoPairs);
var $author$project$Api$Data$stringFromReservationStatus = function (model) {
	switch (model) {
		case 0:
			return 'temporary';
		case 1:
			return 'confirmed';
		case 2:
			return 'conflict';
		default:
			return 'canceled';
	}
};
var $author$project$Api$Data$encodeReservationStatus = A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$Api$Data$stringFromReservationStatus);
var $author$project$Api$Data$encodeReservationPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'uid', $elm$json$Json$Encode$string, model.c),
			A3($author$project$Api$Data$encode, 'reservation_status', $author$project$Api$Data$encodeReservationStatus, model.e2),
			A3($author$project$Api$Data$encode, 'customer_id', $elm$json$Json$Encode$string, model.am),
			A3($author$project$Api$Data$encode, 'studio_id', $elm$json$Json$Encode$string, model.af),
			A3($author$project$Api$Data$encode, 'resource_id', $elm$json$Json$Encode$string, model.e4),
			A3($author$project$Api$Data$encode, 'resource_item_id', $elm$json$Json$Encode$string, model.cD),
			A3($author$project$Api$Data$encode, 'shooting_date', $author$project$Api$Time$encodeDate, model.a8),
			A3($author$project$Api$Data$encode, 'day_of_week', $elm$json$Json$Encode$int, model.bQ),
			A3($author$project$Api$Data$encode, 'start_time', $elm$json$Json$Encode$string, model.bb),
			A3($author$project$Api$Data$encode, 'end_time', $elm$json$Json$Encode$string, model.bW),
			A3($author$project$Api$Data$encode, 'purpose_of_use_id', $elm$json$Json$Encode$string, model.eX),
			A3($author$project$Api$Data$encode, 'plan_category_id', $elm$json$Json$Encode$string, model.ct),
			A3($author$project$Api$Data$encode, 'shooting_plan_id', $elm$json$Json$Encode$string, model.cG),
			A3($author$project$Api$Data$encode, 'plan_info', $author$project$Api$Data$encodePlanInfo, model.eP),
			A3($author$project$Api$Data$encode, 'shop_name', $elm$json$Json$Encode$string, model.fi),
			A3($author$project$Api$Data$encode, 'studio_name', $elm$json$Json$Encode$string, model.fq),
			A3($author$project$Api$Data$encode, 'is_editable', $elm$json$Json$Encode$bool, model.eg),
			A3($author$project$Api$Data$encode, 'phone_number', $elm$json$Json$Encode$string, model.aq),
			A3($author$project$Api$Data$encode, 'first_name', $elm$json$Json$Encode$string, model.aZ),
			A3($author$project$Api$Data$encode, 'last_name', $elm$json$Json$Encode$string, model.a3),
			A3($author$project$Api$Data$encode, 'first_name_furigana', $elm$json$Json$Encode$string, model.a_),
			A3($author$project$Api$Data$encode, 'last_name_furigana', $elm$json$Json$Encode$string, model.a4),
			A3($author$project$Api$Data$encode, 'emails', $elm$json$Json$Encode$string, model.aY)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeReservation = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeReservationPairs);
var $author$project$Api$Request$Default$cancelReservation = function (reservation_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/reservation/cancel',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeReservation(reservation_body)),
		$author$project$Api$Data$deleteStatusDecoder);
};
var $author$project$Page$Reservation$cancelReservation = F2(
	function (session, data) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Reservation$CompletedCancelReservation,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$cancelReservation(data)));
	});
var $author$project$Page$Reservation$SystemError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Reservation$AgreeToPrivacyPolicyAndTermsMustTrue = {$: 2};
var $author$project$Page$Reservation$decoderAgreeToPrivacyPolicyAndTerms = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (v) {
		return v ? $elm$core$Result$Ok(v) : $elm$core$Result$Err(
			_List_fromArray(
				[$author$project$Page$Reservation$AgreeToPrivacyPolicyAndTermsMustTrue]));
	});
var $author$project$Page$Reservation$CustomFieldFormHasError = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Reservation$CustomFieldRequired = {$: 4};
var $author$project$Page$Reservation$requiredCustomerFieldValidator = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (cf) {
		var _v0 = _Utils_Tuple2(
			cf.dF.cb,
			$elm$core$String$isEmpty(cf.be));
		if (_v0.a && _v0.b) {
			return $elm$core$Result$Err(
				_List_fromArray(
					[$author$project$Page$Reservation$CustomFieldRequired]));
		} else {
			return $elm$core$Result$Ok(0);
		}
	});
var $author$project$Page$Reservation$decoderCustomField = function () {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		$author$project$Page$Reservation$requiredCustomerFieldValidator,
		A2(
			field,
			A2(
				lift,
				function ($) {
					return $.dF;
				},
				$arowM$elm_form_decoder$Form$Decoder$identity),
			A2(
				field,
				A2(
					lift,
					function ($) {
						return $.be;
					},
					$arowM$elm_form_decoder$Form$Decoder$identity),
				$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$CustomFieldForm))));
}();
var $author$project$Page$Reservation$decoderCustomFields = A2(
	$arowM$elm_form_decoder$Form$Decoder$mapError,
	$author$project$Page$Reservation$CustomFieldFormHasError,
	$arowM$elm_form_decoder$Form$Decoder$listOf($author$project$Page$Reservation$decoderCustomField));
var $author$project$Page$Reservation$PlanOptionFormHasError = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Reservation$PlanOptionRequired = {$: 7};
var $author$project$Page$Reservation$requiredOptionValidator = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (cf) {
		var _v0 = _Utils_Tuple2(
			cf.eQ.cb,
			$elm$core$String$isEmpty(cf.be));
		if (_v0.a && _v0.b) {
			return $elm$core$Result$Err(
				_List_fromArray(
					[$author$project$Page$Reservation$PlanOptionRequired]));
		} else {
			return $elm$core$Result$Ok(0);
		}
	});
var $author$project$Page$Reservation$decoderPlanOptionsForm = function () {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		$author$project$Page$Reservation$requiredOptionValidator,
		A2(
			field,
			A2(
				lift,
				function ($) {
					return $.eQ;
				},
				$arowM$elm_form_decoder$Form$Decoder$identity),
			A2(
				field,
				A2(
					lift,
					function ($) {
						return $.be;
					},
					$arowM$elm_form_decoder$Form$Decoder$identity),
				$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$PlanOptionsForm))));
}();
var $author$project$Page$Reservation$decoderPlanOptions = A2(
	$arowM$elm_form_decoder$Form$Decoder$mapError,
	$author$project$Page$Reservation$PlanOptionFormHasError,
	$arowM$elm_form_decoder$Form$Decoder$listOf($author$project$Page$Reservation$decoderPlanOptionsForm));
var $author$project$Page$Reservation$decoderShootingPlanForm = function () {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return $arowM$elm_form_decoder$Form$Decoder$custom(
		function (input) {
			return A2(
				$arowM$elm_form_decoder$Form$Decoder$run,
				A2(
					field,
					A2(
						lift,
						function ($) {
							return $.aa;
						},
						$arowM$elm_form_decoder$Form$Decoder$identity),
					A2(
						field,
						A2(
							lift,
							function ($) {
								return $.C;
							},
							$arowM$elm_form_decoder$Form$Decoder$identity),
						A2(
							field,
							A2(
								lift,
								function ($) {
									return $.aU;
								},
								$arowM$elm_form_decoder$Form$Decoder$identity),
							A2(
								field,
								A2(
									lift,
									function ($) {
										return $.cq;
									},
									$arowM$elm_form_decoder$Form$Decoder$identity),
								A2(
									field,
									A2(
										lift,
										function ($) {
											return $.cx;
										},
										$arowM$elm_form_decoder$Form$Decoder$identity),
									A2(
										field,
										A2(
											lift,
											function ($) {
												return $.W;
											},
											$arowM$elm_form_decoder$Form$Decoder$identity),
										A2(
											field,
											A2(
												lift,
												function ($) {
													return $.i;
												},
												$arowM$elm_form_decoder$Form$Decoder$identity),
											A2(
												field,
												A2(
													lift,
													function ($) {
														return $.bK;
													},
													$arowM$elm_form_decoder$Form$Decoder$identity),
												A2(
													field,
													A2(
														lift,
														function ($) {
															return $.c;
														},
														$arowM$elm_form_decoder$Form$Decoder$identity),
													$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$ShootingPlanForm)))))))))),
				input);
		});
}();
var $author$project$Page$Reservation$decoderPlanInfo = function () {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		A2(
			lift,
			function ($) {
				return $.d3;
			},
			$arowM$elm_form_decoder$Form$Decoder$identity),
		A2(
			field,
			A2(
				lift,
				function ($) {
					return $.dr;
				},
				$arowM$elm_form_decoder$Form$Decoder$identity),
			A2(
				field,
				A2(
					lift,
					function ($) {
						return $.ds;
					},
					$author$project$Page$Reservation$decoderAgreeToPrivacyPolicyAndTerms),
				A2(
					field,
					A2(
						lift,
						function ($) {
							return $.aU;
						},
						$author$project$Page$Reservation$decoderCustomFields),
					A2(
						field,
						A2(
							lift,
							function ($) {
								return $.eR;
							},
							$author$project$Page$Reservation$decoderPlanOptions),
						A2(
							field,
							A2(
								lift,
								function ($) {
									return $.fe;
								},
								$author$project$Page$Reservation$decoderShootingPlanForm),
							$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$PlanInfo)))))));
}();
var $author$project$Page$Reservation$decoderReservation = function () {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		$arowM$elm_form_decoder$Form$Decoder$always(''),
		A2(
			field,
			$arowM$elm_form_decoder$Form$Decoder$always(''),
			A2(
				field,
				$arowM$elm_form_decoder$Form$Decoder$always(''),
				A2(
					field,
					$arowM$elm_form_decoder$Form$Decoder$always(''),
					A2(
						field,
						$arowM$elm_form_decoder$Form$Decoder$always(''),
						A2(
							field,
							$arowM$elm_form_decoder$Form$Decoder$always(''),
							A2(
								field,
								$arowM$elm_form_decoder$Form$Decoder$always(false),
								A2(
									field,
									A2(
										lift,
										function ($) {
											return $.fq;
										},
										$arowM$elm_form_decoder$Form$Decoder$identity),
									A2(
										field,
										A2(
											lift,
											function ($) {
												return $.fi;
											},
											$arowM$elm_form_decoder$Form$Decoder$identity),
										A2(
											field,
											A2(
												lift,
												function ($) {
													return $.eP;
												},
												$author$project$Page$Reservation$decoderPlanInfo),
											A2(
												field,
												A2(
													lift,
													function ($) {
														return $.cG;
													},
													$arowM$elm_form_decoder$Form$Decoder$identity),
												A2(
													field,
													A2(
														lift,
														function ($) {
															return $.ct;
														},
														$arowM$elm_form_decoder$Form$Decoder$identity),
													A2(
														field,
														A2(
															lift,
															function ($) {
																return $.eX;
															},
															$arowM$elm_form_decoder$Form$Decoder$identity),
														A2(
															field,
															A2(
																lift,
																function ($) {
																	return $.bW;
																},
																$arowM$elm_form_decoder$Form$Decoder$identity),
															A2(
																field,
																A2(
																	lift,
																	function ($) {
																		return $.bb;
																	},
																	$arowM$elm_form_decoder$Form$Decoder$identity),
																A2(
																	field,
																	A2(
																		lift,
																		function ($) {
																			return $.bQ;
																		},
																		$arowM$elm_form_decoder$Form$Decoder$identity),
																	A2(
																		field,
																		A2(
																			lift,
																			function ($) {
																				return $.a8;
																			},
																			$arowM$elm_form_decoder$Form$Decoder$identity),
																		A2(
																			field,
																			A2(
																				lift,
																				function ($) {
																					return $.cD;
																				},
																				$arowM$elm_form_decoder$Form$Decoder$identity),
																			A2(
																				field,
																				A2(
																					lift,
																					function ($) {
																						return $.e4;
																					},
																					$arowM$elm_form_decoder$Form$Decoder$identity),
																				A2(
																					field,
																					A2(
																						lift,
																						function ($) {
																							return $.af;
																						},
																						$arowM$elm_form_decoder$Form$Decoder$identity),
																					A2(
																						field,
																						A2(
																							lift,
																							function ($) {
																								return $.am;
																							},
																							$arowM$elm_form_decoder$Form$Decoder$identity),
																						A2(
																							field,
																							A2(
																								lift,
																								function ($) {
																									return $.e2;
																								},
																								$arowM$elm_form_decoder$Form$Decoder$identity),
																							A2(
																								field,
																								A2(
																									lift,
																									function ($) {
																										return $.c;
																									},
																									$arowM$elm_form_decoder$Form$Decoder$identity),
																								$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$Reservation))))))))))))))))))))))));
}();
var $author$project$Page$Reservation$ReservationScheduleForUpdate = F4(
	function (shootingDate, dayOfWeek, startTime, endTime) {
		return {bQ: dayOfWeek, bW: endTime, a8: shootingDate, bb: startTime};
	});
var $author$project$Page$Reservation$decoderRequiredValue = function (errcode) {
	return $arowM$elm_form_decoder$Form$Decoder$custom(
		function (value) {
			if (!value.$) {
				var v = value.a;
				return $elm$core$Result$Ok(v);
			} else {
				return $elm$core$Result$Err(
					_List_fromArray(
						[
							$author$project$Page$Reservation$SystemError('不正な画面遷移が行われました:' + errcode)
						]));
			}
		});
};
var $author$project$Page$Reservation$decoderReservationScheduleForUpdate = function () {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		A2(
			lift,
			function (v) {
				return A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.bW;
					},
					v.v);
			},
			$author$project$Page$Reservation$decoderRequiredValue('endTime')),
		A2(
			field,
			A2(
				lift,
				function (v) {
					return A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.bb;
						},
						v.v);
				},
				$author$project$Page$Reservation$decoderRequiredValue('startTime')),
			A2(
				field,
				A2(
					lift,
					function (v) {
						return A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.bQ;
							},
							v.A);
					},
					$author$project$Page$Reservation$decoderRequiredValue('dayOfWeek')),
				A2(
					field,
					A2(
						lift,
						function (v) {
							return A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.a8;
								},
								v.A);
						},
						$author$project$Page$Reservation$decoderRequiredValue('shootingDate')),
					$arowM$elm_form_decoder$Form$Decoder$top($author$project$Page$Reservation$ReservationScheduleForUpdate)))));
}();
var $author$project$Page$Reservation$mapReservationScheduleForUpdate = F2(
	function (reservation, rsfu) {
		return _Utils_update(
			reservation,
			{bQ: rsfu.bQ, bW: rsfu.bW, a8: rsfu.a8, bb: rsfu.bb});
	});
var $author$project$Page$Reservation$CompletedPostReservationSchedule = function (a) {
	return {$: 28, a: a};
};
var $author$project$Api$Data$RegisterReservationResult = F2(
	function (errors, reservation) {
		return {dT: errors, cC: reservation};
	});
var $author$project$Api$Data$registerReservationResultDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'reservation',
	$author$project$Api$Data$reservationDecoder,
	$elm$core$Maybe$Nothing,
	A3(
		$author$project$Api$Data$decode,
		'errors',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$Api$Data$RegisterReservationResult)));
var $author$project$Api$Request$Default$postShootingDate = function (reservation_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/reservation/update_shooting_date',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeReservation(reservation_body)),
		$author$project$Api$Data$registerReservationResultDecoder);
};
var $author$project$Page$Reservation$postReservationSchedule = F2(
	function (session, data) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Reservation$CompletedPostReservationSchedule,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$postShootingDate(data)));
	});
var $author$project$Page$Reservation$confirmChangeSchedule = function (model) {
	var rs = model.b.g;
	var r = model.R;
	if (!r.$) {
		var reservation = r.a;
		var _v1 = A2($arowM$elm_form_decoder$Form$Decoder$run, $author$project$Page$Reservation$decoderReservationScheduleForUpdate, rs);
		if (!_v1.$) {
			var data = _v1.a;
			var _v2 = A2(
				$arowM$elm_form_decoder$Form$Decoder$run,
				$author$project$Page$Reservation$decoderReservation,
				A2($author$project$Page$Reservation$mapReservationScheduleForUpdate, reservation, data));
			if (!_v2.$) {
				var d = _v2.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dT: _List_Nil}),
					A2(
						$author$project$Page$Reservation$postReservationSchedule,
						$author$project$Page$Reservation$toSession(model),
						d));
			} else {
				var err = _v2.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dT: _List_Nil}),
					$elm$core$Platform$Cmd$none);
			}
		} else {
			var err = _v1.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{dT: _List_Nil}),
				$elm$core$Platform$Cmd$none);
		}
	} else {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dT: _List_fromArray(
						[
							$author$project$Page$Reservation$SystemError('Not found reservation!')
						])
				}),
			$elm$core$Platform$Cmd$none);
	}
};
var $author$project$Page$Reservation$CustomreFormHasError = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Reservation$decoderExistingFamilyMember = function () {
	var toIsoDate = function (d) {
		return A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[d.az, d.ay, d.ax]));
	};
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		A2(
			lift,
			function ($) {
				return $.cF;
			},
			$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
		A2(
			field,
			A2(
				lift,
				function ($) {
					return $.b2;
				},
				$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
			A2(
				field,
				A2(
					lift,
					function ($) {
						return $.aY;
					},
					$author$project$Page$Profile$decoderEmails),
				A2(
					field,
					$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing),
					A2(
						field,
						A2(
							lift,
							function ($) {
								return $.a4;
							},
							$author$project$Page$Profile$decoderLastNameFurigana),
						A2(
							field,
							A2(
								lift,
								function ($) {
									return $.a_;
								},
								$author$project$Page$Profile$decoderFirstNameFurigana),
							A2(
								field,
								A2(
									lift,
									function ($) {
										return $.a3;
									},
									$author$project$Page$Profile$decoderLastName),
								A2(
									field,
									A2(
										lift,
										function ($) {
											return $.aZ;
										},
										$author$project$Page$Profile$decoderFirstName),
									A2(
										field,
										A2(
											lift,
											function ($) {
												return $.cz;
											},
											$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
										A2(
											field,
											A2(
												lift,
												function ($) {
													return $.am;
												},
												$arowM$elm_form_decoder$Form$Decoder$identity),
											A2(
												field,
												A2(
													lift,
													function ($) {
														return $.c;
													},
													$arowM$elm_form_decoder$Form$Decoder$identity),
												$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$FamilyMember))))))))))));
}();
var $author$project$Page$Reservation$decoderExistingFamilyMembers = A2(
	$arowM$elm_form_decoder$Form$Decoder$mapError,
	$author$project$Page$Profile$FamilyMembersHasError,
	$arowM$elm_form_decoder$Form$Decoder$listOf($author$project$Page$Reservation$decoderExistingFamilyMember));
var $author$project$Page$Reservation$decoderExistingCustomer = function () {
	var list = $arowM$elm_form_decoder$Form$Decoder$list;
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		A2(
			lift,
			function ($) {
				return $.dW;
			},
			$author$project$Page$Reservation$decoderExistingFamilyMembers),
		A2(
			field,
			A2(
				lift,
				function ($) {
					return $.cB;
				},
				$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
			A2(
				field,
				A2(
					lift,
					function ($) {
						return $.b7;
					},
					$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
				A2(
					field,
					A2(
						lift,
						function ($) {
							return $.bG;
						},
						$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
					A2(
						field,
						A2(
							lift,
							function ($) {
								return $.bF;
							},
							$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
						A2(
							field,
							A2(
								lift,
								function ($) {
									return $.cw;
								},
								$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
							A2(
								field,
								A2(
									lift,
									function ($) {
										return $.cv;
									},
									$arowM$elm_form_decoder$Form$Decoder$always($elm$core$Maybe$Nothing)),
								A2(
									field,
									A2(
										lift,
										function ($) {
											return $.aq;
										},
										$author$project$Page$Profile$decoderPhoneNumber),
									A2(
										field,
										A2(
											lift,
											function ($) {
												return $.c;
											},
											$arowM$elm_form_decoder$Form$Decoder$identity),
										$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$MypageCustomer))))))))));
}();
var $author$project$Page$Reservation$Reservation = function (uid) {
	return function (reservationStatus) {
		return function (customerId) {
			return function (studioId) {
				return function (resourceId) {
					return function (resourceItemId) {
						return function (shootingDate) {
							return function (dayOfWeek) {
								return function (startTime) {
									return function (endTime) {
										return function (purposeOfUseId) {
											return function (planCategoryId) {
												return function (shootingPlanId) {
													return function (planInfo) {
														return function (shopName) {
															return function (studioName) {
																return function (isEditable) {
																	return function (phoneNumber) {
																		return function (firstName) {
																			return function (lastName) {
																				return function (firstNameFurigana) {
																					return function (lastNameFurigana) {
																						return function (emails) {
																							return {am: customerId, bQ: dayOfWeek, aY: emails, bW: endTime, aZ: firstName, a_: firstNameFurigana, eg: isEditable, a3: lastName, a4: lastNameFurigana, aq: phoneNumber, ct: planCategoryId, eP: planInfo, eX: purposeOfUseId, e2: reservationStatus, e4: resourceId, cD: resourceItemId, a8: shootingDate, cG: shootingPlanId, fi: shopName, bb: startTime, af: studioId, fq: studioName, c: uid};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Reservation$PlanInfo = F6(
	function (shootingPlan, planOptions, customFields, agreeToPrivacyPolicyAndTerms, additionalComments, hasUsedBefore) {
		return {dr: additionalComments, ds: agreeToPrivacyPolicyAndTerms, aU: customFields, d3: hasUsedBefore, eR: planOptions, fe: shootingPlan};
	});
var $author$project$Page$Reservation$decoderArrayToList = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (v) {
		return $elm$core$Result$Ok(
			$elm$core$Array$toList(v));
	});
var $author$project$Page$Reservation$decoderPlanInfoFromModel = function (isCheckAgreements) {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	var decoderAgreements = isCheckAgreements ? $author$project$Page$Reservation$decoderAgreeToPrivacyPolicyAndTerms : $arowM$elm_form_decoder$Form$Decoder$identity;
	return A2(
		field,
		A2(
			lift,
			function (v) {
				return v.G.d3;
			},
			$arowM$elm_form_decoder$Form$Decoder$identity),
		A2(
			field,
			A2(
				lift,
				function (v) {
					return v.G.dr;
				},
				$arowM$elm_form_decoder$Form$Decoder$identity),
			A2(
				field,
				A2(
					lift,
					function (v) {
						return v.G.ds;
					},
					decoderAgreements),
				A2(
					field,
					A2(
						lift,
						function (v) {
							return v.b.aU;
						},
						$author$project$Page$Reservation$decoderArrayToList),
					A2(
						field,
						A2(
							lift,
							function (v) {
								return v.b.eR;
							},
							$author$project$Page$Reservation$decoderArrayToList),
						A2(
							field,
							A2(
								lift,
								function (v) {
									return v.b.fe;
								},
								$author$project$Page$Reservation$decoderRequiredValue('shootingPlan')),
							$arowM$elm_form_decoder$Form$Decoder$top($author$project$Page$Reservation$PlanInfo)))))));
};
var $author$project$Page$Reservation$decoderReservationFromModel = function (isCheckAgreements) {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		$arowM$elm_form_decoder$Form$Decoder$always(''),
		A2(
			field,
			$arowM$elm_form_decoder$Form$Decoder$always(''),
			A2(
				field,
				$arowM$elm_form_decoder$Form$Decoder$always(''),
				A2(
					field,
					$arowM$elm_form_decoder$Form$Decoder$always(''),
					A2(
						field,
						$arowM$elm_form_decoder$Form$Decoder$always(''),
						A2(
							field,
							$arowM$elm_form_decoder$Form$Decoder$always(''),
							A2(
								field,
								A2(
									lift,
									function (v) {
										return A2(
											$elm$core$Maybe$withDefault,
											true,
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.eg;
												},
												v.R));
									},
									$arowM$elm_form_decoder$Form$Decoder$identity),
								A2(
									field,
									A2(
										lift,
										function (v) {
											return A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.i;
												},
												v.b.g.Q);
										},
										$author$project$Page$Reservation$decoderRequiredValue('shopName')),
									A2(
										field,
										A2(
											lift,
											function (v) {
												return A2(
													$elm$core$Maybe$map,
													function ($) {
														return $.i;
													},
													v.b.g.ae);
											},
											$author$project$Page$Reservation$decoderRequiredValue('shopName')),
										A2(
											field,
											$author$project$Page$Reservation$decoderPlanInfoFromModel(isCheckAgreements),
											A2(
												field,
												A2(
													lift,
													function (v) {
														return A2(
															$elm$core$Maybe$map,
															function ($) {
																return $.c;
															},
															v.b.fe);
													},
													$author$project$Page$Reservation$decoderRequiredValue('shootingPlanId')),
												A2(
													field,
													A2(
														lift,
														function (v) {
															return A2(
																$elm$core$Maybe$map,
																function ($) {
																	return $.c;
																},
																v.b.eO);
														},
														$author$project$Page$Reservation$decoderRequiredValue('planCategoryId')),
													A2(
														field,
														A2(
															lift,
															function (v) {
																return A2(
																	$elm$core$Maybe$map,
																	function ($) {
																		return $.c;
																	},
																	v.b.as);
															},
															$author$project$Page$Reservation$decoderRequiredValue('purposeOfUseId')),
														A2(
															field,
															A2(
																lift,
																function (v) {
																	return A2(
																		$elm$core$Maybe$map,
																		function ($) {
																			return $.bW;
																		},
																		v.b.g.v);
																},
																$author$project$Page$Reservation$decoderRequiredValue('selectedTimeslotEndTime')),
															A2(
																field,
																A2(
																	lift,
																	function (v) {
																		return A2(
																			$elm$core$Maybe$map,
																			function ($) {
																				return $.bb;
																			},
																			v.b.g.v);
																	},
																	$author$project$Page$Reservation$decoderRequiredValue('selectedTimeslotStartTime')),
																A2(
																	field,
																	A2(
																		lift,
																		function (v) {
																			return A2(
																				$elm$core$Maybe$map,
																				function ($) {
																					return $.bQ;
																				},
																				v.b.g.A);
																		},
																		$author$project$Page$Reservation$decoderRequiredValue('selectedDayScheduleDayOfWeek')),
																	A2(
																		field,
																		A2(
																			lift,
																			function (v) {
																				return A2(
																					$elm$core$Maybe$map,
																					function ($) {
																						return $.a8;
																					},
																					v.b.g.A);
																			},
																			$author$project$Page$Reservation$decoderRequiredValue('selectedDayScheduleShootingDate')),
																		A2(
																			field,
																			A2(
																				lift,
																				function (v) {
																					return A2(
																						$elm$core$Maybe$map,
																						function ($) {
																							return $.cD;
																						},
																						v.b.g.Q);
																				},
																				$author$project$Page$Reservation$decoderRequiredValue('studioResourceItemId')),
																			A2(
																				field,
																				A2(
																					lift,
																					function (v) {
																						return A2(
																							$elm$core$Maybe$map,
																							function ($) {
																								return $.e4;
																							},
																							v.b.g.Q);
																					},
																					$author$project$Page$Reservation$decoderRequiredValue('studioResourceId')),
																				A2(
																					field,
																					A2(
																						lift,
																						function (v) {
																							return A2(
																								$elm$core$Maybe$map,
																								function ($) {
																									return $.c;
																								},
																								v.b.g.ae);
																						},
																						$author$project$Page$Reservation$decoderRequiredValue('shopId')),
																					A2(
																						field,
																						A2(
																							lift,
																							function (v) {
																								return v.dG.c;
																							},
																							$arowM$elm_form_decoder$Form$Decoder$identity),
																						A2(
																							field,
																							A2(
																								lift,
																								function (v) {
																									return v.G.e2;
																								},
																								$arowM$elm_form_decoder$Form$Decoder$identity),
																							A2(
																								field,
																								A2(
																									lift,
																									function (v) {
																										return v.b.c;
																									},
																									$arowM$elm_form_decoder$Form$Decoder$identity),
																								$arowM$elm_form_decoder$Form$Decoder$top($author$project$Page$Reservation$Reservation))))))))))))))))))))))));
};
var $arowM$elm_form_decoder$Form$Decoder$pass = F2(
	function (d, _v0) {
		var g = _v0;
		return $arowM$elm_form_decoder$Form$Decoder$custom(
			function (a) {
				var _v1 = g(a);
				if (_v1.$ === 1) {
					var err = _v1.a;
					return $elm$core$Result$Err(err);
				} else {
					var b = _v1.a;
					return A2($arowM$elm_form_decoder$Form$Decoder$run, d, b);
				}
			});
	});
var $author$project$Page$Reservation$CompletedPostReservation = function (a) {
	return {$: 27, a: a};
};
var $author$project$Api$Data$encodeBodyPostReservationReservationPostPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'customer', $author$project$Api$Data$encodeMypageCustomer, model.dG),
			A3($author$project$Api$Data$encode, 'reservation', $author$project$Api$Data$encodeReservation, model.cC)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeBodyPostReservationReservationPost = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeBodyPostReservationReservationPostPairs);
var $author$project$Api$Request$Default$postReservation = function (bodyPostReservationReservationPost_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/reservation',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeBodyPostReservationReservationPost(bodyPostReservationReservationPost_body)),
		$author$project$Api$Data$registerReservationResultDecoder);
};
var $author$project$Page$Reservation$postReservation = F2(
	function (session, data) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Reservation$CompletedPostReservation,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$postReservation(data)));
	});
var $author$project$Page$Reservation$confirmReservation = function (model) {
	var rp = model.b;
	var decoder = (model.dG.c === '__new__') ? _Utils_Tuple2(
		A2($arowM$elm_form_decoder$Form$Decoder$run, $author$project$Page$Profile$decoderCustomer, model.dG),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$run,
			A2(
				$arowM$elm_form_decoder$Form$Decoder$pass,
				$author$project$Page$Reservation$decoderReservation,
				$author$project$Page$Reservation$decoderReservationFromModel(true)),
			model)) : _Utils_Tuple2(
		A2($arowM$elm_form_decoder$Form$Decoder$run, $author$project$Page$Reservation$decoderExistingCustomer, model.dG),
		A2(
			$arowM$elm_form_decoder$Form$Decoder$run,
			A2(
				$arowM$elm_form_decoder$Form$Decoder$pass,
				$author$project$Page$Reservation$decoderReservation,
				$author$project$Page$Reservation$decoderReservationFromModel(true)),
			model));
	if (!decoder.a.$) {
		if (!decoder.b.$) {
			var cdata = decoder.a.a;
			var rdata = decoder.b.a;
			var data = {dG: cdata, cC: rdata};
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{dT: _List_Nil}),
				A2(
					$author$project$Page$Reservation$postReservation,
					$author$project$Page$Reservation$toSession(model),
					data));
		} else {
			var cdata = decoder.a.a;
			var err = decoder.b.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{dT: err, bm: _List_Nil}),
				$elm$core$Platform$Cmd$none);
		}
	} else {
		if (!decoder.b.$) {
			var err = decoder.a.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						dT: A2($elm$core$List$map, $author$project$Page$Reservation$CustomreFormHasError, err),
						bm: _List_Nil
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			var err1 = decoder.a.a;
			var err2 = decoder.b.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						dT: $elm$core$List$concat(
							_List_fromArray(
								[
									A2($elm$core$List$map, $author$project$Page$Reservation$CustomreFormHasError, err1),
									err2
								])),
						bm: _List_Nil
					}),
				$elm$core$Platform$Cmd$none);
		}
	}
};
var $author$project$Api$Data$WaitingList = function (uid) {
	return function (studioId) {
		return function (customerId) {
			return function (email) {
				return function (planCategoryId) {
					return function (timeRequired) {
						return function (note) {
							return function (datetimes) {
								return function (customerName) {
									return function (customerNameFurigana) {
										return function (phoneNumber) {
											return {am: customerId, dH: customerName, dI: customerNameFurigana, dK: datetimes, bU: email, aa: note, aq: phoneNumber, ct: planCategoryId, af: studioId, bc: timeRequired, c: uid};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$Reservation$WlDatetimesHasError = function (a) {
	return {$: 8, a: a};
};
var $author$project$Api$Data$WaitingDateTime = F2(
	function (shootingDate, startTime) {
		return {a8: shootingDate, bb: startTime};
	});
var $author$project$Page$Reservation$WlShootingDateFormatError = {$: 9};
var $author$project$Page$Reservation$decoderWlShootingDate = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (v) {
		if (v === '') {
			return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
		} else {
			var _v1 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(v);
			if (!_v1.$) {
				var r = _v1.a;
				return $elm$core$Result$Ok(
					$elm$core$Maybe$Just(r));
			} else {
				var e = _v1.a;
				return $elm$core$Result$Err(
					_List_fromArray(
						[$author$project$Page$Reservation$WlShootingDateFormatError]));
			}
		}
	});
var $author$project$Page$Reservation$WlStartTimeFormatError = {$: 10};
var $author$project$Page$Reservation$decoderWlStartTime = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (v) {
		if (v === '') {
			return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
		} else {
			var _v1 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime('2000-01-01T' + (v + ':00.000Z'));
			if (!_v1.$) {
				return $elm$core$Result$Ok(
					$elm$core$Maybe$Just(v));
			} else {
				return $elm$core$Result$Err(
					_List_fromArray(
						[$author$project$Page$Reservation$WlStartTimeFormatError]));
			}
		}
	});
var $author$project$Page$Reservation$decoderDatetime = function () {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		A2(
			lift,
			function ($) {
				return $.bb;
			},
			$author$project$Page$Reservation$decoderWlStartTime),
		A2(
			field,
			A2(
				lift,
				function ($) {
					return $.a8;
				},
				$author$project$Page$Reservation$decoderWlShootingDate),
			$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$WaitingDateTime)));
}();
var $author$project$Page$Reservation$decoderDatetimes = A2(
	$arowM$elm_form_decoder$Form$Decoder$mapError,
	$author$project$Page$Reservation$WlDatetimesHasError,
	$arowM$elm_form_decoder$Form$Decoder$listOf($author$project$Page$Reservation$decoderDatetime));
var $author$project$Page$Reservation$decoderNullable = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (v) {
		if (v === '') {
			return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
		} else {
			return $elm$core$Result$Ok(
				$elm$core$Maybe$Just(v));
		}
	});
var $author$project$Page$Reservation$decoderTimeRequired = $arowM$elm_form_decoder$Form$Decoder$custom(
	function (v) {
		switch (v) {
			case '40データ':
				return $elm$core$Result$Ok(60);
			case '80データ':
				return $elm$core$Result$Ok(120);
			default:
				return $elm$core$Result$Err(
					_List_fromArray(
						[
							$author$project$Page$Reservation$SystemError('Unknown time required value.')
						]));
		}
	});
var $author$project$Page$Reservation$WlCustomerNameRequired = {$: 12};
var $author$project$Page$Reservation$WlCustomerNameTooLonger = {$: 13};
var $author$project$Page$Reservation$decoderWlCustomerName = A2(
	$arowM$elm_form_decoder$Form$Decoder$assert,
	A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Reservation$WlCustomerNameTooLonger, 60),
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Reservation$WlCustomerNameRequired, 1),
		$arowM$elm_form_decoder$Form$Decoder$identity));
var $author$project$Page$Reservation$WlCustomerNameFuriganaRequired = {$: 14};
var $author$project$Page$Reservation$WlCustomerNameFuriganaTooLonger = {$: 15};
var $author$project$Page$Reservation$decoderWlCustomerNameFurigana = A2(
	$arowM$elm_form_decoder$Form$Decoder$assert,
	A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Reservation$WlCustomerNameFuriganaTooLonger, 60),
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Reservation$WlCustomerNameFuriganaRequired, 1),
		$arowM$elm_form_decoder$Form$Decoder$identity));
var $author$project$Page$Reservation$WlEmailRequired = {$: 11};
var $author$project$Page$Reservation$decoderWlEmail = A2(
	$arowM$elm_form_decoder$Form$Decoder$assert,
	A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Reservation$WlEmailRequired, 1),
	$arowM$elm_form_decoder$Form$Decoder$identity);
var $author$project$Page$Reservation$WlPhoneNumberRequired = {$: 16};
var $author$project$Page$Reservation$WlPhoneNumberTooLonger = {$: 17};
var $author$project$Page$Reservation$decoderWlPhoneNumber = A2(
	$arowM$elm_form_decoder$Form$Decoder$assert,
	A2($arowM$elm_form_decoder$Form$Decoder$maxLength, $author$project$Page$Reservation$WlPhoneNumberTooLonger, 20),
	A2(
		$arowM$elm_form_decoder$Form$Decoder$assert,
		A2($arowM$elm_form_decoder$Form$Decoder$minLength, $author$project$Page$Reservation$WlPhoneNumberRequired, 1),
		$arowM$elm_form_decoder$Form$Decoder$identity));
var $author$project$Page$Reservation$decoderWaitingList = function () {
	var lift = $arowM$elm_form_decoder$Form$Decoder$lift;
	var field = $arowM$elm_form_decoder$Form$Decoder$field;
	return A2(
		field,
		A2(
			lift,
			function (m) {
				return m.s.aq;
			},
			$author$project$Page$Reservation$decoderWlPhoneNumber),
		A2(
			field,
			A2(
				lift,
				function (m) {
					return m.s.dI;
				},
				$author$project$Page$Reservation$decoderWlCustomerNameFurigana),
			A2(
				field,
				A2(
					lift,
					function (m) {
						return m.s.dH;
					},
					$author$project$Page$Reservation$decoderWlCustomerName),
				A2(
					field,
					A2(
						lift,
						function (m) {
							return m.s.dK;
						},
						$author$project$Page$Reservation$decoderDatetimes),
					A2(
						field,
						A2(
							lift,
							function (m) {
								return m.s.aa;
							},
							$arowM$elm_form_decoder$Form$Decoder$identity),
						A2(
							field,
							A2(
								lift,
								function (m) {
									return m.s.bc;
								},
								$author$project$Page$Reservation$decoderTimeRequired),
							A2(
								field,
								A2(
									lift,
									function (m) {
										return A2(
											$elm$core$Maybe$withDefault,
											'',
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.c;
												},
												m.b.eO));
									},
									$arowM$elm_form_decoder$Form$Decoder$identity),
								A2(
									field,
									A2(
										lift,
										function (m) {
											return m.s.bU;
										},
										$author$project$Page$Reservation$decoderWlEmail),
									A2(
										field,
										A2(
											lift,
											function (m) {
												return m.dG.c;
											},
											$author$project$Page$Reservation$decoderNullable),
										A2(
											field,
											A2(
												lift,
												function (m) {
													return A2(
														$elm$core$Maybe$withDefault,
														'',
														A2(
															$elm$core$Maybe$map,
															function ($) {
																return $.c;
															},
															m.b.g.ae));
												},
												$arowM$elm_form_decoder$Form$Decoder$identity),
											A2(
												field,
												A2(
													lift,
													function (m) {
														return m.s.c;
													},
													$arowM$elm_form_decoder$Form$Decoder$identity),
												$arowM$elm_form_decoder$Form$Decoder$top($author$project$Api$Data$WaitingList))))))))))));
}();
var $arowM$elm_form_decoder$Form$Decoder$errors = F2(
	function (d, a) {
		var _v0 = A2($arowM$elm_form_decoder$Form$Decoder$run, d, a);
		if (!_v0.$) {
			return _List_Nil;
		} else {
			var errs = _v0.a;
			return errs;
		}
	});
var $author$project$Page$Reservation$CompletedSearchCustomer = function (a) {
	return {$: 31, a: a};
};
var $author$project$Api$Request$Default$takeSpecificCustomer = F6(
	function (firstName_query, lastName_query, firstNameFurigana_query, lastNameFurigana_query, email_query, phoneNumber_query) {
		return A7(
			$author$project$Api$request,
			'GET',
			'/take_specific_customer',
			_List_Nil,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'first_name',
					$elm$core$Maybe$Just(
						$elm$core$Basics$identity(firstName_query))),
					_Utils_Tuple2(
					'last_name',
					$elm$core$Maybe$Just(
						$elm$core$Basics$identity(lastName_query))),
					_Utils_Tuple2(
					'first_name_furigana',
					$elm$core$Maybe$Just(
						$elm$core$Basics$identity(firstNameFurigana_query))),
					_Utils_Tuple2(
					'last_name_furigana',
					$elm$core$Maybe$Just(
						$elm$core$Basics$identity(lastNameFurigana_query))),
					_Utils_Tuple2(
					'email',
					$elm$core$Maybe$Just(
						$elm$core$Basics$identity(email_query))),
					_Utils_Tuple2(
					'phone_number',
					$elm$core$Maybe$Just(
						$elm$core$Basics$identity(phoneNumber_query)))
				]),
			_List_Nil,
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$string);
	});
var $author$project$Page$Reservation$searchCustomer = F3(
	function (session, cform, fform) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Reservation$CompletedSearchCustomer,
			A2(
				$author$project$Session$toRequest,
				session,
				A6($author$project$Api$Request$Default$takeSpecificCustomer, fform.aZ, fform.a3, fform.a_, fform.a4, fform.aY, cform.aq)));
	});
var $author$project$Page$Reservation$extendInputCmd = F2(
	function (model, field) {
		if (field.$ === 9) {
			var key = field.a;
			var checked = field.b;
			return A2($author$project$Page$Reservation$hasUsedBefore, key, checked) ? A3(
				$author$project$Page$Reservation$searchCustomer,
				$author$project$Page$Reservation$toSession(model),
				model.dG,
				$author$project$Page$Reservation$takeRepresentative(model)) : $elm$core$Platform$Cmd$none;
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Page$Reservation$CompletedLoadSchedule = function (a) {
	return {$: 24, a: a};
};
var $author$project$Api$Data$ScheduleByPlan = F2(
	function (shootingPlanId, schedules) {
		return {at: schedules, cG: shootingPlanId};
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$Api$Data$ScheduleByStudio = F2(
	function (studioId, schedules) {
		return {at: schedules, af: studioId};
	});
var $author$project$Api$Data$ScheduleByShootingStudio = F3(
	function (shootingStudioResourceId, shootingStudioResourceItemId, schedules) {
		return {at: schedules, fg: shootingStudioResourceId, fh: shootingStudioResourceItemId};
	});
var $author$project$Api$Data$ScheduleByMonth = F3(
	function (shootingMonth, shootingMonthText, schedules) {
		return {at: schedules, fc: shootingMonth, fd: shootingMonthText};
	});
var $author$project$Api$Data$ScheduleByDate = F5(
	function (shootingDate, dayOfWeek, isHoliday, timeRequired, timeSlots) {
		return {bQ: dayOfWeek, eh: isHoliday, a8: shootingDate, bc: timeRequired, fv: timeSlots};
	});
var $author$project$Api$Data$TimeSlot = F3(
	function (startTime, endTime, isReserved) {
		return {bW: endTime, ej: isReserved, bb: startTime};
	});
var $author$project$Api$Data$timeSlotDecoder = A3(
	$author$project$Api$Data$decode,
	'is_reserved',
	$elm$json$Json$Decode$bool,
	A3(
		$author$project$Api$Data$decode,
		'end_time',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'start_time',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Api$Data$TimeSlot))));
var $author$project$Api$Data$scheduleByDateDecoder = A3(
	$author$project$Api$Data$decode,
	'time_slots',
	$elm$json$Json$Decode$list($author$project$Api$Data$timeSlotDecoder),
	A3(
		$author$project$Api$Data$decode,
		'time_required',
		$elm$json$Json$Decode$int,
		A3(
			$author$project$Api$Data$decode,
			'is_holiday',
			$elm$json$Json$Decode$bool,
			A3(
				$author$project$Api$Data$decode,
				'day_of_week',
				$elm$json$Json$Decode$int,
				A3(
					$author$project$Api$Data$decode,
					'shooting_date',
					$author$project$Api$Time$dateDecoder,
					$elm$json$Json$Decode$succeed($author$project$Api$Data$ScheduleByDate))))));
var $author$project$Api$Data$scheduleByMonthDecoder = A3(
	$author$project$Api$Data$decode,
	'schedules',
	$elm$json$Json$Decode$list($author$project$Api$Data$scheduleByDateDecoder),
	A3(
		$author$project$Api$Data$decode,
		'shooting_month_text',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'shooting_month',
			$author$project$Api$Time$dateDecoder,
			$elm$json$Json$Decode$succeed($author$project$Api$Data$ScheduleByMonth))));
var $author$project$Api$Data$scheduleByShootingStudioDecoder = A3(
	$author$project$Api$Data$decode,
	'schedules',
	$elm$json$Json$Decode$list($author$project$Api$Data$scheduleByMonthDecoder),
	A3(
		$author$project$Api$Data$decode,
		'shooting_studio_resource_item_id',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'shooting_studio_resource_id',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Api$Data$ScheduleByShootingStudio))));
var $author$project$Api$Data$scheduleByStudioDecoder = A3(
	$author$project$Api$Data$decode,
	'schedules',
	$elm$json$Json$Decode$list($author$project$Api$Data$scheduleByShootingStudioDecoder),
	A3(
		$author$project$Api$Data$decode,
		'studio_id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Api$Data$ScheduleByStudio)));
var $author$project$Api$Data$scheduleByPlanDecoder = A3(
	$author$project$Api$Data$decode,
	'schedules',
	$elm$json$Json$Decode$dict($author$project$Api$Data$scheduleByStudioDecoder),
	A3(
		$author$project$Api$Data$decode,
		'shooting_plan_id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Api$Data$ScheduleByPlan)));
var $author$project$Api$Request$Default$getSchedule = function (planId_query) {
	return A7(
		$author$project$Api$request,
		'GET',
		'/schedules',
		_List_Nil,
		_List_fromArray(
			[
				_Utils_Tuple2(
				'plan_id',
				$elm$core$Maybe$Just(
					$elm$core$Basics$identity(planId_query)))
			]),
		_List_Nil,
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$list($author$project$Api$Data$scheduleByPlanDecoder));
};
var $author$project$Page$Reservation$fetchSchedules = F2(
	function (session, shootingPlanId) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Reservation$CompletedLoadSchedule,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$getSchedule(shootingPlanId)));
	});
var $author$project$Page$Reservation$CompletedLoadScheduleForCal = function (a) {
	return {$: 25, a: a};
};
var $author$project$Page$Reservation$fetchSchedulesForCal = F2(
	function (session, shootingPlanId) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Reservation$CompletedLoadScheduleForCal,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$getSchedule(shootingPlanId)));
	});
var $author$project$Page$Reservation$CompletedLoadStudios = function (a) {
	return {$: 26, a: a};
};
var $author$project$Api$Data$StudioSchedule = function (uid) {
	return function (studioId) {
		return function (displayName) {
			return function (displayOrder) {
				return function (effectiveDate) {
					return function (daySchedules) {
						return function (dateSchedules) {
							return function (imageUrl) {
								return function (note) {
									return function (instagramUrl) {
										return function (iconImageSrcset) {
											return {dJ: dateSchedules, dM: daySchedules, aV: displayName, W: displayOrder, dQ: effectiveDate, b5: iconImageSrcset, C: imageUrl, b6: instagramUrl, aa: note, af: studioId, c: uid};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$Data$StudioDateSchedule = F6(
	function (uid, startAt, endAt, isClosed, openingTime, closingTime) {
		return {aT: closingTime, bV: endAt, b9: isClosed, a5: openingTime, cI: startAt, c: uid};
	});
var $author$project$Api$Data$studioDateScheduleDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'closing_time',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'opening_time',
		$elm$json$Json$Decode$string,
		$elm$core$Maybe$Nothing,
		A3(
			$author$project$Api$Data$decode,
			'is_closed',
			$elm$json$Json$Decode$bool,
			A3(
				$author$project$Api$Data$decode,
				'end_at',
				$author$project$Api$Time$dateDecoder,
				A3(
					$author$project$Api$Data$decode,
					'start_at',
					$author$project$Api$Time$dateDecoder,
					A3(
						$author$project$Api$Data$decode,
						'uid',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Api$Data$StudioDateSchedule)))))));
var $author$project$Api$Data$StudioDaySchedule = F5(
	function (uid, dayType, isClosed, openingTime, closingTime) {
		return {aT: closingTime, dN: dayType, b9: isClosed, a5: openingTime, c: uid};
	});
var $author$project$Api$Data$studioDayScheduleDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'closing_time',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'opening_time',
		$elm$json$Json$Decode$string,
		$elm$core$Maybe$Nothing,
		A3(
			$author$project$Api$Data$decode,
			'is_closed',
			$elm$json$Json$Decode$bool,
			A3(
				$author$project$Api$Data$decode,
				'day_type',
				$elm$json$Json$Decode$int,
				A3(
					$author$project$Api$Data$decode,
					'uid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Api$Data$StudioDaySchedule))))));
var $author$project$Api$Data$studioScheduleDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'icon_image_srcset',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'instagram_url',
		$elm$json$Json$Decode$string,
		$elm$core$Maybe$Nothing,
		A4(
			$author$project$Api$Data$maybeDecode,
			'note',
			$elm$json$Json$Decode$string,
			$elm$core$Maybe$Nothing,
			A4(
				$author$project$Api$Data$maybeDecode,
				'image_url',
				$elm$json$Json$Decode$string,
				$elm$core$Maybe$Nothing,
				A3(
					$author$project$Api$Data$decode,
					'date_schedules',
					$elm$json$Json$Decode$list($author$project$Api$Data$studioDateScheduleDecoder),
					A3(
						$author$project$Api$Data$decode,
						'day_schedules',
						$elm$json$Json$Decode$list($author$project$Api$Data$studioDayScheduleDecoder),
						A3(
							$author$project$Api$Data$decode,
							'effective_date',
							$author$project$Api$Time$dateDecoder,
							A3(
								$author$project$Api$Data$decode,
								'display_order',
								$elm$json$Json$Decode$int,
								A3(
									$author$project$Api$Data$decode,
									'display_name',
									$elm$json$Json$Decode$string,
									A3(
										$author$project$Api$Data$decode,
										'studio_id',
										$elm$json$Json$Decode$string,
										A3(
											$author$project$Api$Data$decode,
											'uid',
											$elm$json$Json$Decode$string,
											$elm$json$Json$Decode$succeed($author$project$Api$Data$StudioSchedule))))))))))));
var $author$project$Api$Request$Default$getCurrentStudioSchedule = function (studioIds_query) {
	return A7(
		$author$project$Api$request,
		'GET',
		'/current_studio_schedules',
		_List_Nil,
		_List_fromArray(
			[
				_Utils_Tuple2(
				'studio_ids',
				$elm$core$Maybe$Just(
					$elm$core$Basics$identity(studioIds_query)))
			]),
		_List_Nil,
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$list($author$project$Api$Data$studioScheduleDecoder));
};
var $author$project$Page$Reservation$fetchStudios = F2(
	function (session, studio_ids) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Reservation$CompletedLoadStudios,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$getCurrentStudioSchedule(studio_ids)));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$Reservation$mapShootingPlan = function (data) {
	return {bK: data.bK, aU: data.aU, W: data.W, C: data.C, i: data.aV, aa: data.aa, cq: data.cq, cx: data.cx, c: data.c};
};
var $author$project$Page$Reservation$mapPlanCategory = function (data) {
	var _v0 = data.b8;
	if (_v0) {
		return $elm$core$Maybe$Just(
			{
				W: data.W,
				C: data.C,
				i: data.i,
				ff: A2($elm$core$List$map, $author$project$Page$Reservation$mapShootingPlan, data.ff),
				c: data.c
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Reservation$mapPurposeOfUse = function (data) {
	return {
		dw: data.dw,
		i: data.i,
		eN: A2($elm$core$List$filterMap, $author$project$Page$Reservation$mapPlanCategory, data.eN),
		c: data.c
	};
};
var $author$project$Page$Reservation$mapShop = function (data) {
	return {C: data.b5, b6: data.b6, i: data.aV, aa: data.aa, c: data.af};
};
var $author$project$Page$Reservation$WaitingDateTime = F2(
	function (shootingDate, startTime) {
		return {a8: shootingDate, bb: startTime};
	});
var $author$project$Page$Reservation$WaitingList = F8(
	function (uid, customerName, customerNameFurigana, phoneNumber, email, timeRequired, note, datetimes) {
		return {dH: customerName, dI: customerNameFurigana, dK: datetimes, bU: email, aa: note, aq: phoneNumber, bc: timeRequired, c: uid};
	});
var $author$project$Page$Reservation$posixToDateStr = function (maybePosix) {
	if (maybePosix.$ === 1) {
		return '';
	} else {
		var posix = maybePosix.a;
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(posix);
	}
};
var $author$project$Page$Reservation$mapWaitingDateTime = function (data) {
	return A2(
		$author$project$Page$Reservation$WaitingDateTime,
		A2(
			$elm$core$String$left,
			10,
			$author$project$Page$Reservation$posixToDateStr(data.a8)),
		A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				$elm$core$String$left(5),
				data.bb)));
};
var $author$project$Page$Reservation$mapWaitingList = function (data) {
	return A8(
		$author$project$Page$Reservation$WaitingList,
		data.c,
		data.dH,
		data.dI,
		data.aq,
		data.bU,
		function () {
			var _v0 = data.bc;
			if (_v0 === 60) {
				return '40データ';
			} else {
				return '80データ';
			}
		}(),
		data.aa,
		A2(
			$elm$core$List$take,
			3,
			_Utils_ap(
				A2($elm$core$List$map, $author$project$Page$Reservation$mapWaitingDateTime, data.dK),
				A2(
					$elm$core$List$map,
					function (_v1) {
						return A2($author$project$Page$Reservation$WaitingDateTime, '', '');
					},
					A2($elm$core$List$range, 0, 2)))));
};
var $author$project$Page$Reservation$CompletedPostWaitingList = function (a) {
	return {$: 29, a: a};
};
var $author$project$Api$Data$encodeWaitingDateTimePairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$maybeEncode, 'shooting_date', $author$project$Api$Time$encodeDate, model.a8),
			A3($author$project$Api$Data$maybeEncode, 'start_time', $elm$json$Json$Encode$string, model.bb)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeWaitingDateTime = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeWaitingDateTimePairs);
var $author$project$Api$Data$encodeWaitingListPairs = function (model) {
	var pairs = _List_fromArray(
		[
			A3($author$project$Api$Data$encode, 'uid', $elm$json$Json$Encode$string, model.c),
			A3($author$project$Api$Data$encode, 'studio_id', $elm$json$Json$Encode$string, model.af),
			A3($author$project$Api$Data$maybeEncode, 'customer_id', $elm$json$Json$Encode$string, model.am),
			A3($author$project$Api$Data$encode, 'email', $elm$json$Json$Encode$string, model.bU),
			A3($author$project$Api$Data$encode, 'plan_category_id', $elm$json$Json$Encode$string, model.ct),
			A3($author$project$Api$Data$encode, 'time_required', $elm$json$Json$Encode$int, model.bc),
			A3($author$project$Api$Data$encode, 'note', $elm$json$Json$Encode$string, model.aa),
			A3(
			$author$project$Api$Data$encode,
			'datetimes',
			$elm$json$Json$Encode$list($author$project$Api$Data$encodeWaitingDateTime),
			model.dK),
			A3($author$project$Api$Data$encode, 'customer_name', $elm$json$Json$Encode$string, model.dH),
			A3($author$project$Api$Data$encode, 'customer_name_furigana', $elm$json$Json$Encode$string, model.dI),
			A3($author$project$Api$Data$encode, 'phone_number', $elm$json$Json$Encode$string, model.aq)
		]);
	return pairs;
};
var $author$project$Api$Data$encodeWaitingList = A2($elm$core$Basics$composeL, $author$project$Api$Data$encodeObject, $author$project$Api$Data$encodeWaitingListPairs);
var $author$project$Api$Data$waitingDateTimeDecoder = A4(
	$author$project$Api$Data$maybeDecode,
	'start_time',
	$elm$json$Json$Decode$string,
	$elm$core$Maybe$Nothing,
	A4(
		$author$project$Api$Data$maybeDecode,
		'shooting_date',
		$author$project$Api$Time$dateDecoder,
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Api$Data$WaitingDateTime)));
var $author$project$Api$Data$waitingListDecoder = A3(
	$author$project$Api$Data$decode,
	'phone_number',
	$elm$json$Json$Decode$string,
	A3(
		$author$project$Api$Data$decode,
		'customer_name_furigana',
		$elm$json$Json$Decode$string,
		A3(
			$author$project$Api$Data$decode,
			'customer_name',
			$elm$json$Json$Decode$string,
			A3(
				$author$project$Api$Data$decode,
				'datetimes',
				$elm$json$Json$Decode$list($author$project$Api$Data$waitingDateTimeDecoder),
				A3(
					$author$project$Api$Data$decode,
					'note',
					$elm$json$Json$Decode$string,
					A3(
						$author$project$Api$Data$decode,
						'time_required',
						$elm$json$Json$Decode$int,
						A3(
							$author$project$Api$Data$decode,
							'plan_category_id',
							$elm$json$Json$Decode$string,
							A3(
								$author$project$Api$Data$decode,
								'email',
								$elm$json$Json$Decode$string,
								A4(
									$author$project$Api$Data$maybeDecode,
									'customer_id',
									$elm$json$Json$Decode$string,
									$elm$core$Maybe$Nothing,
									A3(
										$author$project$Api$Data$decode,
										'studio_id',
										$elm$json$Json$Decode$string,
										A3(
											$author$project$Api$Data$decode,
											'uid',
											$elm$json$Json$Decode$string,
											$elm$json$Json$Decode$succeed($author$project$Api$Data$WaitingList))))))))))));
var $author$project$Api$Request$Default$postWaitingList = function (waitingList_body) {
	return A7(
		$author$project$Api$request,
		'POST',
		'/waiting_list',
		_List_Nil,
		_List_Nil,
		_List_Nil,
		$elm$core$Maybe$Just(
			$author$project$Api$Data$encodeWaitingList(waitingList_body)),
		$author$project$Api$Data$waitingListDecoder);
};
var $author$project$Page$Reservation$postWaitingList = F2(
	function (session, data) {
		return A2(
			$author$project$App$callApi,
			$author$project$Page$Reservation$CompletedPostWaitingList,
			A2(
				$author$project$Session$toRequest,
				session,
				$author$project$Api$Request$Default$postWaitingList(data)));
	});
var $author$project$Page$Reservation$Studio = F7(
	function (resourceId, resourceItemId, name, schedules, imageUrl, note, instagramUrl) {
		return {C: imageUrl, b6: instagramUrl, i: name, aa: note, e4: resourceId, cD: resourceItemId, at: schedules};
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Page$Reservation$takeResource = F3(
	function (model, resourceId, resourceItemId) {
		var resources = function () {
			var _v1 = model.bs;
			if (_v1.$ === 2) {
				var rs = _v1.a;
				return rs;
			} else {
				return _List_Nil;
			}
		}();
		var _v0 = A2(
			$elm$core$List$filter,
			function (resource) {
				return _Utils_eq(resource.c, resourceId);
			},
			resources);
		if (_v0.b && (!_v0.b.b)) {
			var selectedResource = _v0.a;
			return $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (resourceItem) {
						return _Utils_eq(resourceItem.c, resourceItemId);
					},
					selectedResource.e5));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Page$Reservation$takeShootingStudios = F2(
	function (model, studioId) {
		var scheduledStudios = function () {
			var _v1 = model.at;
			if (_v1.$ === 2) {
				var schedules = _v1.a;
				var _v2 = $elm$core$List$head(schedules);
				if (_v2.$ === 1) {
					return _List_Nil;
				} else {
					var s = _v2.a;
					var _v3 = A2($elm$core$Dict$get, studioId, s.at);
					if (_v3.$ === 1) {
						return _List_Nil;
					} else {
						var r = _v3.a;
						return r.at;
					}
				}
			} else {
				return _List_Nil;
			}
		}();
		var mapStudio = function (r) {
			var resource = A3($author$project$Page$Reservation$takeResource, model, r.fg, r.fh);
			return A7(
				$author$project$Page$Reservation$Studio,
				r.fg,
				r.fh,
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.i;
						},
						resource)),
				r.at,
				A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.b5;
					},
					resource),
				A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.aa;
					},
					resource),
				A2(
					$elm$core$Maybe$andThen,
					function ($) {
						return $.b6;
					},
					resource));
		};
		if (!scheduledStudios.b) {
			return _List_Nil;
		} else {
			return A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.cD;
				},
				A2($elm$core$List$map, mapStudio, scheduledStudios));
		}
	});
var $author$project$Page$Reservation$CompletedReservationList = function (a) {
	return {$: 22, a: a};
};
var $author$project$Page$Reservation$updateReservationList = function (session) {
	return A2(
		$author$project$App$callApi,
		$author$project$Page$Reservation$CompletedReservationList,
		A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$getReservation));
};
var $author$project$Page$Reservation$updateReservationSchedule = F2(
	function (model, fn) {
		var rf = model.b.g;
		return A2(
			$author$project$Page$Reservation$updateReservationPlan,
			model,
			function (rp) {
				return _Utils_update(
					rp,
					{
						g: fn(rf)
					});
			});
	});
var $author$project$Session$updateReservations = F2(
	function (session, r) {
		if (!session.$) {
			var k = session.a;
			var v = session.b;
			var g = session.c;
			return A3(
				$author$project$Session$LoggedIn,
				k,
				v,
				_Utils_update(
					g,
					{gN: r}));
		} else {
			var k = session.a;
			var g = session.b;
			return A2(
				$author$project$Session$Guest,
				k,
				_Utils_update(
					g,
					{gN: _List_Nil}));
		}
	});
var $author$project$Page$Reservation$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 1:
					var session = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j: session}),
						$elm$core$Platform$Cmd$none);
				case 37:
					var _v1 = msg.a;
					var zone = _v1.a;
					var time = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cu: time, cO: zone}),
						$elm$core$Platform$Cmd$none);
				case 2:
					var c = msg.a;
					var customer = function () {
						var _v2 = $elm$core$List$head(c.dW);
						if (!_v2.$) {
							var m = _v2.a;
							return _Utils_update(
								c,
								{
									dW: _Utils_ap(
										c.dW,
										_List_fromArray(
											[
												A3($author$project$Page$Profile$emptyMember, c.c, m.a3, m.a4)
											]))
								});
						} else {
							return c;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dG: customer}),
						$elm$core$Platform$Cmd$none);
				case 3:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aG: 0,
								G: $author$project$Page$Reservation$emptyReservationOtherInfo(
									$author$project$Page$Reservation$toSession(model)),
								b: $author$project$Page$Reservation$emptyReservationPlan,
								e: 0
							}),
						$elm$core$Platform$Cmd$none);
				case 35:
					var idx = msg.a;
					var removeAtIndex = F2(
						function (index, list) {
							var before = A2($elm$core$List$take, index, list);
							var after = A2($elm$core$List$drop, index + 1, list);
							return _Utils_ap(before, after);
						});
					var customer = model.dG;
					var c = _Utils_update(
						customer,
						{
							dW: A2(removeAtIndex, idx, customer.dW)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dG: c}),
						$elm$core$Platform$Cmd$none);
				case 4:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a1: false}),
						$elm$core$Platform$Cmd$none);
				case 5:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bm: _List_Nil}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var reservation = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a1: false}),
						A2(
							$author$project$Page$Reservation$cancelReservation,
							$author$project$Page$Reservation$toSession(model),
							reservation));
				case 18:
					var _v3 = A2($arowM$elm_form_decoder$Form$Decoder$run, $author$project$Page$Reservation$decoderWaitingList, model);
					if (!_v3.$) {
						var data = _v3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{dT: _List_Nil, bm: _List_Nil}),
							A2(
								$author$project$Page$Reservation$postWaitingList,
								$author$project$Page$Reservation$toSession(model),
								data));
					} else {
						var err = _v3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{dT: err, bm: _List_Nil}),
							$elm$core$Platform$Cmd$none);
					}
				case 7:
					var reservation = msg.a;
					var session = $author$project$Page$Reservation$toSession(model);
					var planId = reservation.cG;
					var newModel = A2(
						$author$project$Page$Reservation$applyReservation,
						model,
						$elm$core$Maybe$Just(reservation));
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{e: 6}),
						A2($author$project$Page$Reservation$fetchSchedulesForCal, session, planId));
				case 11:
					var b = msg.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Page$Reservation$updateReservationSchedule,
							model,
							function (f) {
								return _Utils_update(
									f,
									{ca: b});
							}),
						$elm$core$Platform$Cmd$none);
				case 9:
					var step = msg.a;
					var nextStep = function () {
						switch (step) {
							case 0:
								return 1;
							case 1:
								return 2;
							case 2:
								return 3;
							case 3:
								return 4;
							case 4:
								return model.e;
							case 5:
								return 6;
							case 6:
								return 7;
							case 7:
								return 8;
							case 8:
								var _v6 = model.aG;
								if (!_v6) {
									return 9;
								} else {
									return 12;
								}
							case 9:
								return 10;
							case 10:
								return 11;
							case 11:
								return 11;
							case 12:
								return 6;
							default:
								return 13;
						}
					}();
					var toScroll = $elm$core$Maybe$Just(
						function () {
							switch (nextStep) {
								case 0:
									return 'Top';
								case 1:
									return 'Top';
								case 2:
									return 'PlanSection';
								case 3:
									return 'OptionSection';
								case 4:
									return 'Top';
								case 5:
									return 'StudioSection';
								case 6:
									return 'ScheduleSection';
								case 7:
									return 'ScheduleCalendar';
								case 8:
									return 'ScheduleTimeslot';
								case 9:
									return 'ScheduleTimeslot';
								case 10:
									return 'Top';
								case 11:
									return 'Top';
								case 12:
									return 'ScheduleSection';
								default:
									return 'Top';
							}
						}());
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: nextStep}),
						$author$project$App$doScrollIntoView(toScroll));
				case 13:
					var pou = msg.a;
					var p = function () {
						var _v7 = model.b.as;
						if (_v7.$ === 1) {
							return $elm$core$Maybe$Just(pou);
						} else {
							var c = _v7.a;
							return _Utils_eq(c, pou) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(pou);
						}
					}();
					var rp = _Utils_update(
						$author$project$Page$Reservation$emptyReservationPlan,
						{as: p});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b: rp}),
						$elm$core$Platform$Cmd$none);
				case 12:
					var pc = msg.a;
					return A2(
						$author$project$Page$Reservation$update,
						$author$project$Page$Reservation$ChoiceItem(1),
						A2(
							$author$project$Page$Reservation$updateReservationPlan,
							model,
							function (rf) {
								return _Utils_update(
									rf,
									{
										eO: $elm$core$Maybe$Just(pc)
									});
							}));
				case 14:
					var sp = msg.a;
					var session = $author$project$Page$Reservation$toSession(model);
					var selectListDefaultValueOp = function (op) {
						var _v10 = op.b_;
						if (_v10 === 3) {
							return A2(
								$elm$core$Maybe$withDefault,
								'',
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.i;
									},
									$elm$core$List$head(op.eI)));
						} else {
							return '';
						}
					};
					var selectListDefaultValueCf = function (cf) {
						var _v9 = cf.b_;
						if (_v9 === 3) {
							return A2(
								$elm$core$Maybe$withDefault,
								'',
								$elm$core$List$head(
									$elm$core$String$lines(cf.cp)));
						} else {
							return '';
						}
					};
					var mapOp = function (op) {
						return A2(
							$author$project$Page$Reservation$PlanOptionsForm,
							selectListDefaultValueOp(op),
							op);
					};
					var mapCf = function (cf) {
						return A2(
							$author$project$Page$Reservation$CustomFieldForm,
							selectListDefaultValueCf(cf),
							cf);
					};
					var _v8 = A2(
						$author$project$Page$Reservation$update,
						$author$project$Page$Reservation$ChoiceItem(2),
						A2(
							$author$project$Page$Reservation$updateReservationPlan,
							_Utils_update(
								model,
								{at: $author$project$Page$Reservation$Loading, bt: $author$project$Page$Reservation$Loading}),
							function (rf) {
								return _Utils_update(
									rf,
									{
										aU: $elm$core$Array$fromList(
											A2($elm$core$List$map, mapCf, sp.aU)),
										eR: $elm$core$Array$fromList(
											A2($elm$core$List$map, mapOp, sp.cq)),
										g: _Utils_eq(
											$elm$core$Maybe$Just(sp),
											rf.fe) ? rf.g : $author$project$Page$Reservation$emptyReservationSchedule,
										fe: $elm$core$Maybe$Just(sp)
									});
							}));
					var newmodel = _v8.a;
					var cmd = _v8.b;
					return _Utils_Tuple2(
						newmodel,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									cmd,
									A2($author$project$Page$Reservation$fetchSchedules, session, sp.c)
								])));
				case 15:
					var shop = msg.a;
					var studios = A2($author$project$Page$Reservation$takeShootingStudios, model, shop.c);
					return _Utils_Tuple2(
						function () {
							if (studios.b && (!studios.b.b)) {
								var s = studios.a;
								var selectedMonthSchedule = $elm$core$List$head(s.at);
								return A2(
									$author$project$Page$Reservation$updateReservationSchedule,
									_Utils_update(
										model,
										{e: 6}),
									function (rs) {
										return _Utils_update(
											rs,
											{
												A: $elm$core$Maybe$Nothing,
												aj: selectedMonthSchedule,
												v: $elm$core$Maybe$Nothing,
												ae: $elm$core$Maybe$Just(shop),
												Q: $elm$core$Maybe$Just(s)
											});
									});
							} else {
								return A2(
									$author$project$Page$Reservation$updateReservationSchedule,
									_Utils_update(
										model,
										{e: 5}),
									function (_v12) {
										return _Utils_update(
											$author$project$Page$Reservation$emptyReservationSchedule,
											{
												A: $elm$core$Maybe$Nothing,
												aj: $elm$core$Maybe$Nothing,
												v: $elm$core$Maybe$Nothing,
												ae: $elm$core$Maybe$Just(shop),
												Q: $elm$core$Maybe$Nothing
											});
									});
							}
						}(),
						$elm$core$Platform$Cmd$none);
				case 16:
					var studio = msg.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Page$Reservation$updateReservationSchedule,
							_Utils_update(
								model,
								{e: 6}),
							function (rs) {
								return _Utils_update(
									rs,
									{
										A: $elm$core$Maybe$Nothing,
										aj: $elm$core$List$head(studio.at),
										v: $elm$core$Maybe$Nothing,
										Q: $elm$core$Maybe$Just(studio)
									});
							}),
						$elm$core$Platform$Cmd$none);
				case 10:
					var m = msg.a;
					var $temp$msg = $author$project$Page$Reservation$ChoiceItem(6),
						$temp$model = A2(
						$author$project$Page$Reservation$updateReservationSchedule,
						model,
						function (s) {
							return _Utils_update(
								s,
								{
									A: $elm$core$Maybe$Nothing,
									aj: $elm$core$Maybe$Just(m),
									v: $elm$core$Maybe$Nothing
								});
						});
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 8:
					var d = msg.a;
					var $temp$msg = $author$project$Page$Reservation$ChoiceItem(7),
						$temp$model = A2(
						$author$project$Page$Reservation$updateReservationSchedule,
						model,
						function (s) {
							return _Utils_update(
								s,
								{
									A: $elm$core$Maybe$Just(d),
									v: $elm$core$Maybe$Nothing
								});
						});
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 17:
					var t = msg.a;
					var $temp$msg = $author$project$Page$Reservation$ChoiceItem(8),
						$temp$model = A2(
						$author$project$Page$Reservation$updateReservationSchedule,
						model,
						function (s) {
							return _Utils_update(
								s,
								{
									v: $elm$core$Maybe$Just(t)
								});
						});
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 21:
					if (!msg.a.$) {
						var data = msg.a.a;
						var reservations = A2($elm$core$List$map, $author$project$Page$Reservation$mapReservation, data);
						var reservation = A2(
							$elm$core$Maybe$map,
							$author$project$Page$Reservation$mapReservation,
							$elm$core$List$head(data));
						var mode = function () {
							if (reservation.$ === 1) {
								return 0;
							} else {
								return 1;
							}
						}();
						return _Utils_Tuple2(
							A2(
								$author$project$Page$Reservation$applyReservation,
								_Utils_update(
									model,
									{aG: mode, gN: reservations}),
								reservation),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 20:
					if (!msg.a.$) {
						var purposeOfUseList = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: _List_Nil,
									bm: _List_Nil,
									a7: $author$project$Page$Reservation$Loaded(
										A2($elm$core$List$map, $author$project$Page$Reservation$mapPurposeOfUse, purposeOfUseList))
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{a7: $author$project$Page$Reservation$Failed}),
							$elm$core$Platform$Cmd$none);
					}
				case 24:
					if (!msg.a.$) {
						var schedules = msg.a.a;
						var studio_ids = A2(
							$elm$core$String$join,
							',',
							$elm$core$List$concat(
								A2(
									$elm$core$List$map,
									$elm$core$Dict$keys,
									A2(
										$elm$core$List$map,
										function ($) {
											return $.at;
										},
										schedules))));
						var session = $author$project$Page$Reservation$toSession(model);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: _List_Nil,
									bm: _List_Nil,
									at: $author$project$Page$Reservation$Loaded(schedules)
								}),
							A2($author$project$Page$Reservation$fetchStudios, session, studio_ids));
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: _List_fromArray(
										[
											$author$project$Page$Reservation$ServerError('予約枠の取得に失敗しました。')
										]),
									at: $author$project$Page$Reservation$Failed
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 25:
					if (!msg.a.$) {
						var schedules = msg.a.a;
						var _v14 = model.R;
						if (!_v14.$) {
							var reservation = _v14.a;
							var studioId = reservation.af;
							var resorce = _Utils_Tuple2(reservation.e4, reservation.cD);
							var newModel = _Utils_update(
								model,
								{
									dT: _List_Nil,
									bm: _List_Nil,
									at: $author$project$Page$Reservation$Loaded(schedules),
									e: 6
								});
							var shootingStudios = A2($author$project$Page$Reservation$takeShootingStudios, newModel, studioId);
							var choiceShootingStudio = $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (v) {
										return _Utils_eq(
											resorce,
											_Utils_Tuple2(v.e4, v.cD));
									},
									shootingStudios));
							var m = $elm$core$List$head(
								$elm$core$List$reverse(
									A2(
										$elm$core$List$filter,
										function (s) {
											return _Utils_cmp(
												$elm$time$Time$posixToMillis(s.fc),
												$elm$time$Time$posixToMillis(reservation.a8)) < 1;
										},
										A2(
											$elm$core$Maybe$withDefault,
											_List_Nil,
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.at;
												},
												choiceShootingStudio)))));
							var d = $elm$core$List$head(
								$elm$core$List$reverse(
									A2(
										$elm$core$List$filter,
										function (s) {
											return _Utils_eq(s.a8, reservation.a8);
										},
										A2(
											$elm$core$Maybe$withDefault,
											_List_Nil,
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.at;
												},
												m)))));
							var t = $elm$core$List$head(
								$elm$core$List$reverse(
									A2(
										$elm$core$List$filter,
										function (s) {
											return _Utils_eq(s.bb, reservation.bb);
										},
										A2(
											$elm$core$Maybe$withDefault,
											_List_Nil,
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.fv;
												},
												d)))));
							return _Utils_Tuple2(
								A2(
									$author$project$Page$Reservation$updateReservationSchedule,
									newModel,
									function (rs) {
										return _Utils_update(
											rs,
											{A: d, aj: m, v: t, Q: choiceShootingStudio});
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: _List_fromArray(
										[
											$author$project$Page$Reservation$ServerError('予約枠の取得に失敗しました。')
										]),
									at: $author$project$Page$Reservation$Failed
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 19:
					if (!msg.a.$) {
						var customer = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dG: $author$project$Page$Profile$mapCustomer(customer)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{dG: $author$project$Page$Profile$emptyCustomer}),
							$elm$core$Platform$Cmd$none);
					}
				case 31:
					if (!msg.a.$) {
						var customerId = msg.a.a;
						var customer = model.dG;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dG: _Utils_update(
										customer,
										{c: customerId})
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 22:
					if (!msg.a.$) {
						var data = msg.a.a;
						var session = A2(
							$author$project$Session$updateReservations,
							$author$project$Page$Reservation$toSession(model),
							data);
						return $author$project$Page$Reservation$init(session);
					} else {
						var error = msg.a.a;
						var toErrors = function (errors) {
							return _List_fromArray(
								[
									$author$project$Page$Reservation$ServerError('サーバでエラーが発生しました')
								]);
						};
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: toErrors(error)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 30:
					if (!msg.a.$) {
						var data = msg.a.a;
						return _Utils_Tuple2(
							model,
							$author$project$Page$Reservation$updateReservationList(
								$author$project$Page$Reservation$toSession(model)));
					} else {
						var error = msg.a.a;
						var toErrors = function (errors) {
							return _List_fromArray(
								[
									$author$project$Page$Reservation$ServerError('サーバでエラーが発生しました')
								]);
						};
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: toErrors(error)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 27:
					if (!msg.a.$) {
						var data = msg.a.a;
						var toErrors = function (errors) {
							return A2($elm$core$List$map, $author$project$Page$Reservation$ServerError, errors);
						};
						var _v15 = data.dT;
						if (!_v15.b) {
							var session = function () {
								var _v17 = data.cC;
								if (!_v17.$) {
									var r = _v17.a;
									return A2(
										$author$project$Session$updateReservations,
										$author$project$Page$Reservation$toSession(model),
										_List_fromArray(
											[r]));
								} else {
									return $author$project$Page$Reservation$toSession(model);
								}
							}();
							var _v16 = A2(
								$author$project$Page$Reservation$update,
								$author$project$Page$Reservation$ChoiceItem(10),
								_Utils_update(
									model,
									{j: session}));
							var m = _v16.a;
							var c = _v16.b;
							return _Utils_Tuple2(
								m,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[c])));
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										dT: toErrors(data.dT)
									}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						var error = msg.a.a;
						var toErrors = function (errors) {
							return _List_fromArray(
								[
									$author$project$Page$Reservation$ServerError('サーバでエラーが発生しました')
								]);
						};
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: toErrors(error)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 28:
					if (!msg.a.$) {
						var data = msg.a.a;
						var toErrors = function (errors) {
							return A2($elm$core$List$map, $author$project$Page$Reservation$ServerError, errors);
						};
						var _v18 = data.dT;
						if (!_v18.b) {
							return _Utils_Tuple2(model, $elm$browser$Browser$Navigation$reload);
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										dT: toErrors(data.dT)
									}),
								$elm$core$Platform$Cmd$none);
						}
					} else {
						var error = msg.a.a;
						var toErrors = function (errors) {
							return _List_fromArray(
								[
									$author$project$Page$Reservation$ServerError('サーバでエラーが発生しました')
								]);
						};
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: toErrors(error)
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 26:
					if (!msg.a.$) {
						var studios = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: _List_Nil,
									bm: _List_Nil,
									bt: $author$project$Page$Reservation$Loaded(
										A2($elm$core$List$map, $author$project$Page$Reservation$mapShop, studios))
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: _List_fromArray(
										[
											$author$project$Page$Reservation$ServerError('予約枠の取得に失敗しました。')
										]),
									at: $author$project$Page$Reservation$Failed
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 23:
					if (!msg.a.$) {
						var resources = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: _List_Nil,
									bm: _List_Nil,
									bs: $author$project$Page$Reservation$Loaded(resources)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dT: _List_fromArray(
										[
											$author$project$Page$Reservation$ServerError('予約枠の取得に失敗しました。')
										]),
									at: $author$project$Page$Reservation$Failed
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 29:
					if (!msg.a.$) {
						var wl = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									bm: _List_fromArray(
										['予約待ちリストに登録しました']),
									s: $author$project$Page$Reservation$mapWaitingList(wl)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var error = msg.a.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 32:
					var reservation = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a1: true,
								R: $elm$core$Maybe$Just(reservation)
							}),
						$elm$core$Platform$Cmd$none);
				case 33:
					return $author$project$Page$Reservation$confirmChangeSchedule(model);
				case 34:
					return $author$project$Page$Reservation$confirmReservation(model);
				case 38:
					var rp = model.b;
					var errors = $elm$core$List$concat(
						_List_fromArray(
							[
								A2(
								$arowM$elm_form_decoder$Form$Decoder$errors,
								$author$project$Page$Reservation$decoderPlanOptions,
								$elm$core$Array$toList(rp.eR)),
								A2(
								$arowM$elm_form_decoder$Form$Decoder$errors,
								$author$project$Page$Reservation$decoderCustomFields,
								$elm$core$Array$toList(rp.aU))
							]));
					if ($elm$core$List$isEmpty(errors)) {
						var $temp$msg = $author$project$Page$Reservation$ChoiceItem(3),
							$temp$model = _Utils_update(
							model,
							{dT: errors});
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{dT: errors}),
							$elm$core$Platform$Cmd$none);
					}
				case 39:
					var _v19 = A2(
						$arowM$elm_form_decoder$Form$Decoder$run,
						$author$project$Page$Reservation$decoderReservationFromModel(false),
						model);
					if (!_v19.$) {
						var data = _v19.a;
						var $temp$msg = $author$project$Page$Reservation$ChoiceItem(9),
							$temp$model = _Utils_update(
							model,
							{
								R: $elm$core$Maybe$Just(data)
							});
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						var errors = _v19.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{dT: errors}),
							$elm$core$Platform$Cmd$none);
					}
				case 40:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a2: !model.a2,
								s: $author$project$Page$Reservation$appllyCustomerInfo(model)
							}),
						$elm$core$Platform$Cmd$none);
				default:
					var field = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$Reservation$applyInputValue, model, field),
						A2($author$project$Page$Reservation$extendInputCmd, model, field));
			}
		}
	});
var $author$project$Session$GalleryMenuItem = F4(
	function (name, dateStr, uid, titleImage) {
		return {bP: dateStr, i: name, cL: titleImage, c: uid};
	});
var $author$project$Session$updateGalleryMenu = F2(
	function (session, menuItems) {
		if (!session.$) {
			var k = session.a;
			var v = session.b;
			var g = session.c;
			return A3(
				$author$project$Session$LoggedIn,
				k,
				v,
				_Utils_update(
					g,
					{gn: menuItems}));
		} else {
			var k = session.a;
			var g = session.b;
			return A2(
				$author$project$Session$Guest,
				k,
				_Utils_update(
					g,
					{gn: _List_Nil}));
		}
	});
var $author$project$Main$updateGalleryMenu = F2(
	function (model, result) {
		var toImage = function (m) {
			return A3($author$project$ProgressiveImage$ImageSet, m.fn, m.fo, m.bI);
		};
		var menuItems = A2(
			$elm$core$List$map,
			function (item) {
				return A4(
					$author$project$Session$GalleryMenuItem,
					item.i,
					item.bP,
					item.c,
					$author$project$ProgressiveImage$initProgressiveImage(
						toImage(item.cL)));
			},
			result.dZ);
		var newSession = A2(
			$author$project$Session$updateGalleryMenu,
			$author$project$Main$toSession(model),
			menuItems);
		var newModel = function () {
			switch (model.$) {
				case 1:
					var url = model.b;
					return A2($author$project$Main$Init, newSession, url);
				case 0:
					var s = model.a;
					return $author$project$Main$Redirect(newSession);
				case 2:
					var s = model.a;
					return $author$project$Main$NotFound(newSession);
				case 3:
					var home = model.a;
					return $author$project$Main$Home(
						_Utils_update(
							home,
							{j: newSession}));
				case 4:
					var gallery = model.a;
					return $author$project$Main$Gallery(
						_Utils_update(
							gallery,
							{j: newSession}));
				case 5:
					var profile = model.a;
					return $author$project$Main$Profile(
						_Utils_update(
							profile,
							{j: newSession}));
				case 6:
					var login = model.a;
					return $author$project$Main$Login(
						_Utils_update(
							login,
							{j: newSession}));
				default:
					var reservation = model.a;
					return $author$project$Main$Reservation(
						_Utils_update(
							reservation,
							{j: newSession}));
			}
		}();
		return newModel;
	});
var $author$project$Main$updateReservationInfo = F2(
	function (model, result) {
		var newSession = A2(
			$author$project$Session$updateReservations,
			$author$project$Main$toSession(model),
			result);
		var newModel = function () {
			switch (model.$) {
				case 1:
					var url = model.b;
					return A2($author$project$Main$Init, newSession, url);
				case 0:
					var s = model.a;
					return $author$project$Main$Redirect(newSession);
				case 2:
					var s = model.a;
					return $author$project$Main$NotFound(newSession);
				case 3:
					var home = model.a;
					return $author$project$Main$Home(
						_Utils_update(
							home,
							{j: newSession}));
				case 4:
					var gallery = model.a;
					return $author$project$Main$Gallery(
						_Utils_update(
							gallery,
							{j: newSession}));
				case 5:
					var profile = model.a;
					return $author$project$Main$Profile(
						_Utils_update(
							profile,
							{j: newSession}));
				case 6:
					var login = model.a;
					return $author$project$Main$Login(
						_Utils_update(
							login,
							{j: newSession}));
				default:
					var reservation = model.a;
					return $author$project$Main$Reservation(
						_Utils_update(
							reservation,
							{j: newSession}));
			}
		}();
		return newModel;
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		switch (_v0.a.$) {
			case 1:
				var urlRequest = _v0.a.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					if (A2(
						$elm$core$String$contains,
						'ianb',
						A2($elm$core$Maybe$withDefault, '', url.eY))) {
						return $author$project$Main$toggleNavbarBurger(model);
					} else {
						var newModel = $author$project$Main$closeNavbarBurger(model);
						var _v2 = url.c3;
						if (_v2.$ === 1) {
							return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								newModel,
								A2(
									$elm$browser$Browser$Navigation$pushUrl,
									$author$project$Session$navKey(
										$author$project$Main$toSession(model)),
									$elm$url$Url$toString(url)));
						}
					}
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 0:
				var url = _v0.a.a;
				return A2(
					$author$project$Main$changeRouteTo,
					$author$project$Route$fromUrl(url),
					model);
			case 7:
				var _v3 = _v0.a;
				return _Utils_Tuple2(
					model,
					$author$project$Main$delaiedRefreshingTokenTrigger(
						$author$project$Main$toSession(model)));
			case 6:
				var expire = _v0.a.a;
				return _Utils_Tuple2(
					model,
					A2(
						$author$project$Main$delaiedRefreshingToken,
						expire,
						$author$project$Main$toSession(model)));
			case 14:
				var v = _v0.a.a;
				if (!v) {
					var expire = A2(
						$elm$core$Maybe$withDefault,
						0,
						$author$project$Session$expire(
							$author$project$Main$toSession(model)));
					return _Utils_Tuple2(
						model,
						A2(
							$author$project$Main$delaiedRefreshingToken,
							expire,
							$author$project$Main$toSession(model)));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 9:
				if (_v0.b.$ === 3) {
					var subMsg = _v0.a.a;
					var home = _v0.b.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Home,
						$author$project$Main$GotHomeMsg,
						model,
						A2($author$project$Page$Home$update, subMsg, home));
				} else {
					var subMsg = _v0.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 11:
				if (_v0.b.$ === 5) {
					var subMsg = _v0.a.a;
					var home = _v0.b.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Profile,
						$author$project$Main$GotProfileMsg,
						model,
						A2($author$project$Page$Profile$update, subMsg, home));
				} else {
					var subMsg = _v0.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 8:
				if (_v0.b.$ === 4) {
					var subMsg = _v0.a.a;
					var gallery = _v0.b.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Gallery,
						$author$project$Main$GotGalleryMsg,
						model,
						A2($author$project$Page$Gallery$update, subMsg, gallery));
				} else {
					var subMsg = _v0.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 10:
				if (_v0.b.$ === 6) {
					var subMsg = _v0.a.a;
					var login = _v0.b.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Login,
						$author$project$Main$GotLoginMsg,
						model,
						A2($author$project$Page$Login$update, subMsg, login));
				} else {
					var subMsg = _v0.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 12:
				if (_v0.b.$ === 7) {
					var subMsg = _v0.a.a;
					var reservation = _v0.b.a;
					return A4(
						$author$project$Main$updateWith,
						$author$project$Main$Reservation,
						$author$project$Main$GotReservationMsg,
						model,
						A2($author$project$Page$Reservation$update, subMsg, reservation));
				} else {
					var subMsg = _v0.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!_v0.a.a.$) {
					var galleries = _v0.a.a.a;
					return _Utils_Tuple2(
						A2($author$project$Main$updateGalleryMenu, model, galleries),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = _v0.a.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				if (!_v0.a.a.$) {
					var data = _v0.a.a.a;
					return _Utils_Tuple2(
						A2($author$project$Main$updateReservationInfo, model, data),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = _v0.a.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				if (!_v0.a.a.$) {
					var token = _v0.a.a.a;
					return _Utils_Tuple2(
						$author$project$Main$Redirect(
							$author$project$Main$toSession(model)),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Viewer$store(
									$author$project$Viewer$toViewer(token))
								])));
				} else {
					var error = _v0.a.a.a;
					return _Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$App$logout,
									A2(
									$author$project$Route$replaceUrl,
									$author$project$Session$navKey(
										$author$project$Main$toActiveSession(model)),
									$author$project$Route$Login)
								])));
				}
			case 5:
				if (!_v0.a.a.$) {
					var token = _v0.a.a.a;
					return _Utils_Tuple2(
						model,
						$author$project$Viewer$store(
							$author$project$Viewer$toViewer(token)));
				} else {
					var error = _v0.a.a.a;
					return _Utils_Tuple2(
						model,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$App$logout,
									A2(
									$author$project$Route$replaceUrl,
									$author$project$Session$navKey(
										$author$project$Main$toActiveSession(model)),
									$author$project$Route$Login)
								])));
				}
			default:
				switch (_v0.b.$) {
					case 0:
						var session = _v0.a.a;
						return _Utils_Tuple2(
							$author$project$Main$Redirect(session),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Main$fetchReservation(session),
										A2(
										$author$project$App$callApi,
										$author$project$Main$CompletedTakeGalleryInfo,
										A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$searchGallery)),
										A2(
										$author$project$Route$replaceUrl,
										$author$project$Session$navKey(session),
										$author$project$Route$Root)
									])));
					case 1:
						var session = _v0.a.a;
						var _v5 = _v0.b;
						var url = _v5.b;
						var route = $author$project$Route$fromUrl(url);
						if (!route.$) {
							var r = route.a;
							return _Utils_Tuple2(
								$author$project$Main$Redirect(session),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											$author$project$Main$fetchReservation(session),
											A2(
											$author$project$App$callApi,
											$author$project$Main$CompletedTakeGalleryInfo,
											A2($author$project$Session$toRequest, session, $author$project$Api$Request$Default$searchGallery)),
											A2(
											$author$project$Route$replaceUrl,
											$author$project$Session$navKey(session),
											r)
										])));
						} else {
							return _Utils_Tuple2(
								$author$project$Main$NotFound(session),
								$elm$core$Platform$Cmd$none);
						}
					default:
						var session = _v0.a.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Page$Gallery = {$: 2};
var $author$project$Page$HeaderInfo = F4(
	function (loginUserName, galleryMenu, isActiveNavbarBurger, hasReservation) {
		return {gn: galleryMenu, d2: hasReservation, gv: isActiveNavbarBurger, er: loginUserName};
	});
var $author$project$Page$Home = {$: 1};
var $author$project$Page$Login = {$: 4};
var $author$project$Page$Other = {$: 0};
var $author$project$Page$Profile = {$: 3};
var $author$project$Page$Reservation = function (a) {
	return {$: 5, a: a};
};
var $author$project$Session$galleryMenu = function (session) {
	if (!session.$) {
		var g = session.c;
		return g.gn;
	} else {
		return _List_Nil;
	}
};
var $author$project$Session$isActiveNavbarBurger = function (session) {
	if (!session.$) {
		var g = session.c;
		return g.gv;
	} else {
		var g = session.b;
		return g.gv;
	}
};
var $author$project$Page$Reservation$isCompletedReservation = function (model) {
	var _v0 = model.e;
	if (_v0 === 11) {
		return true;
	} else {
		return false;
	}
};
var $author$project$App$loginUserName = function (_v0) {
	var token = _v0.a;
	var _v1 = A2(
		$elm$core$Result$map,
		function (name) {
			return name;
		},
		A2(
			$simonh1000$elm_jwt$Jwt$decodeToken,
			A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
			token));
	if (!_v1.$) {
		var name = _v1.a;
		return $elm$core$Maybe$Just(name);
	} else {
		var err = _v1.a;
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Session$loginUserName = function (session) {
	return A2(
		$elm$core$Maybe$withDefault,
		'ゲスト',
		A2(
			$elm$core$Maybe$andThen,
			$author$project$App$loginUserName,
			$author$project$Session$cred(session)));
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$Page$ariaExpanded = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'aria-expanded', value);
};
var $author$project$Page$ariaHidden = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'aria-hidden', value);
};
var $author$project$Page$ariaLabel = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'aria-label', value);
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $author$project$Page$dataTarget = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'data-target', value);
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Route$href = function (targetRoute) {
	return $elm$html$Html$Attributes$href(
		$author$project$Route$routeToString(targetRoute));
};
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Page$id = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'id', value);
};
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$Page$role = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'role', value);
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Page$media = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'media', value);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Page$picture = F2(
	function (attributes, children) {
		return A3($elm$html$Html$node, 'picture', attributes, children);
	});
var $elm$html$Html$source = _VirtualDom_node('source');
var $author$project$Page$srcset = function (value) {
	return A2($elm$html$Html$Attributes$attribute, 'srcset', value);
};
var $author$project$Page$viewHeaderLogo = function (headerLogoHref) {
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('navbar-item'),
				headerLogoHref
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Page$picture,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$source,
						_List_fromArray(
							[
								$author$project$Page$media('(min-width: 768px)'),
								$author$project$Page$srcset('logo.png')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$author$project$Page$srcset('logo_xs.png, logo_xs.png 2x'),
								$elm$html$Html$Attributes$alt('Mitulle')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Page$viewHeader = F3(
	function (page, maybeViewer, headerInfo) {
		var headerLogoHref = function () {
			var _v0 = _Utils_Tuple2(maybeViewer, page);
			if ((_v0.a.$ === 1) && (_v0.b.$ === 5)) {
				if (_v0.b.a) {
					var _v1 = _v0.a;
					return $elm$html$Html$Attributes$href('https://mitulle.com');
				} else {
					var _v2 = _v0.a;
					return $author$project$Route$href($author$project$Route$Reservation);
				}
			} else {
				return $author$project$Route$href($author$project$Route$Home);
			}
		}();
		return _Utils_eq(maybeViewer, $elm$core$Maybe$Nothing) ? A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					$author$project$Page$id('navbar'),
					$elm$html$Html$Attributes$class('navbar is-fixed-top')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('navbar-brand'),
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewHeaderLogo(headerLogoHref),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('navbar-item'),
									A2($elm$html$Html$Attributes$style, 'margin-left', 'auto')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button'),
											$author$project$Route$href($author$project$Route$Login)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fas fa-user')
												]),
											_List_Nil),
											$elm$html$Html$text('ログイン')
										]))
								]))
						]))
				])) : A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					$author$project$Page$id('navbar'),
					$elm$html$Html$Attributes$class('navbar is-fixed-top')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('navbar-brand')
						]),
					_List_fromArray(
						[
							$author$project$Page$viewHeaderLogo(headerLogoHref),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('navbar-item')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(headerInfo.er),
									$elm$html$Html$text('様')
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$author$project$Page$role('button'),
									$elm$html$Html$Attributes$class('navbar-burger burger'),
									$elm$html$Html$Attributes$class(
									headerInfo.gv ? 'is-active' : ''),
									$author$project$Page$ariaLabel('menu'),
									$author$project$Page$ariaExpanded('false'),
									$author$project$Page$dataTarget('navbarMenu'),
									$elm$html$Html$Attributes$href('?ianb')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$author$project$Page$ariaHidden('true')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$author$project$Page$ariaHidden('true')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$author$project$Page$ariaHidden('true')
										]),
									_List_Nil)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('navbar-menu'),
							$elm$html$Html$Attributes$class(
							headerInfo.gv ? 'is-active' : ''),
							$author$project$Page$id('navbarMenu')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('navbar-start')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('navbar-end')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item'),
											$elm$html$Html$Attributes$href('#')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('マイページ')
										])),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item'),
											$author$project$Route$href($author$project$Route$Reservation)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											headerInfo.d2 ? 'ご予約確認' : 'ご予約')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item has-dropdown is-hoverable')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('navbar-link')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('ギャラリー')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('navbar-dropdown')
												]),
											A2(
												$elm$core$List$map,
												function (item) {
													return A2(
														$elm$html$Html$a,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('navbar-item'),
																$author$project$Route$href(
																$author$project$Route$Gallery(item.c))
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(item.bP + (' ' + item.i))
															]));
												},
												headerInfo.gn))
										])),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item'),
											$author$project$Route$href($author$project$Route$Profile)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('お客様情報')
										])),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item'),
											$author$project$Route$href($author$project$Route$Logout)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fas fa-sign-out-alt')
												]),
											_List_Nil)
										]))
								]))
						]))
				]));
	});
var $author$project$Page$view = F4(
	function (maybeViewer, page, _v0, headerInfo) {
		var title = _v0.fw;
		var content = _v0.f0;
		return {
			aA: A2(
				$elm$core$List$cons,
				A3($author$project$Page$viewHeader, page, maybeViewer, headerInfo),
				A2($elm$core$List$cons, content, _List_Nil)),
			fw: A2(
				$elm$core$String$join,
				' - ',
				A2(
					$elm$core$List$filter,
					A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
					_List_fromArray(
						[title, 'Mitulle My Page'])))
		};
	});
var $author$project$Page$Blank$view = {
	f0: $elm$html$Html$text(''),
	fw: ''
};
var $author$project$Page$Gallery$ClickDownloadArchive = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Gallery$ClickSelectAll = {$: 6};
var $author$project$Page$Gallery$ClickSelectButton = {$: 0};
var $author$project$Page$Gallery$ClickUnselectAll = {$: 8};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$html$Html$section = _VirtualDom_node('section');
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $author$project$Page$Gallery$DoneDownloadArchive = {$: 13};
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$progress = _VirtualDom_node('progress');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Page$Gallery$viewDownloader = function (model) {
	var _v0 = model.ao;
	switch (_v0.$) {
		case 0:
			var stat = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal is-active')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-background')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('notification is-info is-light')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon is-large')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$i,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('fas fa-spinner fa-spin')
															]),
														_List_Nil)
													])),
												$elm$html$Html$text('ダウンロードファイルを準備しています…'),
												function () {
												if (!stat.$) {
													var s = stat.a;
													return A2(
														$elm$html$Html$progress,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('progress is-info'),
																A2(
																$elm$html$Html$Attributes$attribute,
																'max',
																$elm$core$String$fromInt(1 + s.et)),
																$elm$html$Html$Attributes$value(
																$elm$core$String$fromInt(s.be))
															]),
														_List_Nil);
												} else {
													return $elm$html$Html$text('');
												}
											}()
											]))
									]))
							]))
					]));
		case 1:
			var res = _v0.a;
			var url = function () {
				if (!res.$) {
					var r = res.a;
					return A2($elm$core$Maybe$withDefault, '', r.fE);
				} else {
					return '';
				}
			}();
			var dlSize = function () {
				if (!res.$) {
					var r = res.a;
					return A2($elm$core$Maybe$withDefault, '不明', r.dP);
				} else {
					return '不明';
				}
			}();
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal is-active')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-background')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-card')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$section,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-card-body')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon is-large')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$i,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('fas fa-info')
															]),
														_List_Nil)
													])),
												$elm$html$Html$text('ダウンロードファイルの準備ができました'),
												$elm$html$Html$text(' （サイズ: '),
												$elm$html$Html$text(dlSize),
												$elm$html$Html$text('）')
											]))
									])),
								A2(
								$elm$html$Html$footer,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-card-foot is-pulled-right move-right')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('buttons is-right')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$a,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button is-primary'),
														$elm$html$Html$Attributes$href(url),
														$elm$html$Html$Events$onClick($author$project$Page$Gallery$DoneDownloadArchive)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('ダウンロード')
													])),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button'),
														$elm$html$Html$Events$onClick($author$project$Page$Gallery$DoneDownloadArchive)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('キャンセル')
													]))
											]))
									]))
							]))
					]));
		case 2:
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal is-active')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-background')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-card')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$section,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-card-body')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('notification is-danger is-light')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('icon is-large')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('fas fa-exclamation-circle')
																	]),
																_List_Nil)
															])),
														$elm$html$Html$text('サーバーでエラーが発生しました。')
													])),
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('ご迷惑をおかけします。')
													])),
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('しばらくお時間をおいて再度ご操作をお願いいたします。')
													]))
											]))
									])),
								A2(
								$elm$html$Html$footer,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-card-foot is-pulled-right move-right')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('buttons is-right')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button'),
														$elm$html$Html$Events$onClick($author$project$Page$Gallery$DoneDownloadArchive)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('OK')
													]))
											]))
									]))
							]))
					]));
		default:
			return $elm$html$Html$text('');
	}
};
var $elm$virtual_dom$VirtualDom$lazy = _VirtualDom_lazy;
var $elm$html$Html$Lazy$lazy = $elm$virtual_dom$VirtualDom$lazy;
var $author$project$Page$Gallery$ClickPhoto = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$lazy2 = _VirtualDom_lazy2;
var $elm$html$Html$Lazy$lazy2 = $elm$virtual_dom$VirtualDom$lazy2;
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $author$project$Page$Gallery$LoadedThumnailImage = function (a) {
	return {$: 15, a: a};
};
var $author$project$ProgressiveImage$toHtml = F3(
	function (image, preLoadHtml, loadedHtml) {
		if (!image.$) {
			var imgwb = image.a;
			return preLoadHtml(imgwb);
		} else {
			var imgwb = image.a;
			return loadedHtml(imgwb);
		}
	});
var $WhileTruu$elm_blurhash$Internal$Dimensions = F2(
	function (rows, columns) {
		return {V: columns, D: rows};
	});
var $WhileTruu$elm_blurhash$Internal$Triplet = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$Basics$pow = _Basics_pow;
var $WhileTruu$elm_blurhash$Internal$signPow = F2(
	function (value, exp) {
		return (value < 0) ? (-A2(
			$elm$core$Basics$pow,
			$elm$core$Basics$abs(value),
			exp)) : A2($elm$core$Basics$pow, value, exp);
	});
var $WhileTruu$elm_blurhash$Internal$decodeAC = F2(
	function (maximumValue, value) {
		return A3(
			$WhileTruu$elm_blurhash$Internal$Triplet,
			A2(
				$WhileTruu$elm_blurhash$Internal$signPow,
				($elm$core$Basics$floor(value / (19 * 19)) - 9.0) / 9.0,
				2.0) * maximumValue,
			A2(
				$WhileTruu$elm_blurhash$Internal$signPow,
				(A2(
					$elm$core$Basics$modBy,
					19,
					$elm$core$Basics$floor(value / 19)) - 9.0) / 9.0,
				2.0) * maximumValue,
			A2(
				$WhileTruu$elm_blurhash$Internal$signPow,
				(A2($elm$core$Basics$modBy, 19, value) - 9.0) / 9.0,
				2.0) * maximumValue);
	});
var $WhileTruu$elm_blurhash$Internal$alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#$%*+,-.:;=?@[]^_{|}~';
var $elm$core$String$foldl = _String_foldl;
var $WhileTruu$elm_blurhash$Internal$base83chars = function () {
	var folder = F2(
		function (_char, _v0) {
			var index = _v0.a0;
			var dict = _v0.bS;
			return {
				bS: A3($elm$core$Dict$insert, _char, index, dict),
				a0: index + 1
			};
		});
	return A3(
		$elm$core$String$foldl,
		folder,
		{bS: $elm$core$Dict$empty, a0: 0},
		$WhileTruu$elm_blurhash$Internal$alphabet).bS;
}();
var $WhileTruu$elm_blurhash$Internal$decodeBase83 = function (str) {
	var folder = F2(
		function (a, acc) {
			var _v0 = A2($elm$core$Dict$get, a, $WhileTruu$elm_blurhash$Internal$base83chars);
			if (!_v0.$) {
				var v = _v0.a;
				return v + (acc * 83);
			} else {
				return acc;
			}
		});
	return A3($elm$core$String$foldl, folder, 0, str);
};
var $WhileTruu$elm_blurhash$Internal$foldDimensions = F3(
	function (_v0, folder, _default) {
		var rows = _v0.D;
		var columns = _v0.V;
		var go = F3(
			function (row, column, accum) {
				go:
				while (true) {
					if (_Utils_cmp(column, columns - 1) < 0) {
						var $temp$row = row,
							$temp$column = column + 1,
							$temp$accum = A3(folder, row, column, accum);
						row = $temp$row;
						column = $temp$column;
						accum = $temp$accum;
						continue go;
					} else {
						if (_Utils_cmp(row, rows - 1) < 0) {
							var $temp$row = row + 1,
								$temp$column = 0,
								$temp$accum = A3(folder, row, column, accum);
							row = $temp$row;
							column = $temp$column;
							accum = $temp$accum;
							continue go;
						} else {
							return A3(folder, row, column, accum);
						}
					}
				}
			});
		return A3(go, 0, 0, _default);
	});
var $WhileTruu$elm_blurhash$Internal$srgbToLinear = function (srgbInt) {
	var a = srgbInt / 255;
	return (a <= 0.04045) ? (a / 12.92) : A2($elm$core$Basics$pow, (a + 0.055) / 1.055, 2.4);
};
var $WhileTruu$elm_blurhash$Internal$buildDict = F3(
	function (dimensions, maximumValue, blurhash) {
		var cache = A3(
			$WhileTruu$elm_blurhash$Internal$foldDimensions,
			dimensions,
			F3(
				function (row, column, dict) {
					var _v1 = (row * dimensions.V) + column;
					if (!_v1) {
						var bits = $WhileTruu$elm_blurhash$Internal$decodeBase83(
							A3($elm$core$String$slice, 2, 6, blurhash));
						var value = A3(
							$WhileTruu$elm_blurhash$Internal$Triplet,
							$WhileTruu$elm_blurhash$Internal$srgbToLinear(255 & (bits >> 16)),
							$WhileTruu$elm_blurhash$Internal$srgbToLinear(255 & (bits >> 8)),
							$WhileTruu$elm_blurhash$Internal$srgbToLinear(255 & bits));
						return A3($elm$core$Dict$insert, 0, value, dict);
					} else {
						var i = _v1;
						var key = A3($elm$core$String$slice, 4 + (i * 2), 4 + ((i + 1) * 2), blurhash);
						var value = A2(
							$WhileTruu$elm_blurhash$Internal$decodeAC,
							maximumValue,
							$WhileTruu$elm_blurhash$Internal$decodeBase83(key));
						return A3($elm$core$Dict$insert, i, value, dict);
					}
				}),
			$elm$core$Dict$empty);
		return function (index) {
			var _v0 = A2($elm$core$Dict$get, index, cache);
			if (!_v0.$) {
				var v = _v0.a;
				return v;
			} else {
				return A3($WhileTruu$elm_blurhash$Internal$Triplet, 0, 0, 0);
			}
		};
	});
var $elm$core$Basics$cos = _Basics_cos;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $WhileTruu$elm_blurhash$Internal$linearToSrgb = function (linearFloat) {
	var a = A3($elm$core$Basics$clamp, 0, 1, linearFloat);
	return (a <= 0.0031308) ? $elm$core$Basics$floor(((a * 12.92) * 255) + 0.5) : $elm$core$Basics$floor(
		(((1.055 * A2($elm$core$Basics$pow, a, 1 / 2.4)) - 0.055) * 255) + 0.5);
};
var $elm$core$Basics$pi = _Basics_pi;
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$scaleFrom255 = function (c) {
	return c / 255;
};
var $avh4$elm_color$Color$rgb255 = F3(
	function (r, g, b) {
		return A4(
			$avh4$elm_color$Color$RgbaSpace,
			$avh4$elm_color$Color$scaleFrom255(r),
			$avh4$elm_color$Color$scaleFrom255(g),
			$avh4$elm_color$Color$scaleFrom255(b),
			1.0);
	});
var $WhileTruu$elm_blurhash$Internal$calcPixel = F6(
	function (x, y, width, height, filter, lookup) {
		var folder = F3(
			function (row, column, _v2) {
				var pixel0 = _v2.a;
				var pixel1 = _v2.b;
				var pixel2 = _v2.c;
				var basis = $elm$core$Basics$cos((($elm$core$Basics$pi * x) * column) / width) * $elm$core$Basics$cos((($elm$core$Basics$pi * y) * row) / height);
				var _v1 = lookup((row * filter.V) + column);
				var colour0 = _v1.a;
				var colour1 = _v1.b;
				var colour2 = _v1.c;
				return A3($WhileTruu$elm_blurhash$Internal$Triplet, pixel0 + (colour0 * basis), pixel1 + (colour1 * basis), pixel2 + (colour2 * basis));
			});
		var _v0 = A3(
			$WhileTruu$elm_blurhash$Internal$foldDimensions,
			filter,
			folder,
			A3($WhileTruu$elm_blurhash$Internal$Triplet, 0, 0, 0));
		var r = _v0.a;
		var g = _v0.b;
		var b = _v0.c;
		return A3(
			$avh4$elm_color$Color$rgb255,
			$WhileTruu$elm_blurhash$Internal$linearToSrgb(r),
			$WhileTruu$elm_blurhash$Internal$linearToSrgb(g),
			$WhileTruu$elm_blurhash$Internal$linearToSrgb(b));
	});
var $WhileTruu$elm_blurhash$Internal$foldDimensionsReversed = F3(
	function (_v0, folder, _default) {
		var rows = _v0.D;
		var columns = _v0.V;
		var go = F3(
			function (row, column, accum) {
				go:
				while (true) {
					if (column > 0) {
						var $temp$row = row,
							$temp$column = column - 1,
							$temp$accum = A3(folder, row, column, accum);
						row = $temp$row;
						column = $temp$column;
						accum = $temp$accum;
						continue go;
					} else {
						if (row > 0) {
							var $temp$row = row - 1,
								$temp$column = columns - 1,
								$temp$accum = A3(folder, row, column, accum);
							row = $temp$row;
							column = $temp$column;
							accum = $temp$accum;
							continue go;
						} else {
							return A3(folder, row, column, accum);
						}
					}
				}
			});
		return A3(go, rows - 1, columns - 1, _default);
	});
var $WhileTruu$elm_blurhash$Internal$foldGrid = F6(
	function (width, height, punch, blurhash, folder, _default) {
		var sizeInfo = $WhileTruu$elm_blurhash$Internal$decodeBase83(
			A3($elm$core$String$slice, 0, 1, blurhash));
		var maximumValue = function () {
			var a = $WhileTruu$elm_blurhash$Internal$decodeBase83(
				A3($elm$core$String$slice, 1, 2, blurhash));
			return ((a + 1) / 166) * punch;
		}();
		var filter = {
			V: A2($elm$core$Basics$modBy, 9, sizeInfo) + 1,
			D: ((sizeInfo / 9) | 0) + 1
		};
		var lookup = A3($WhileTruu$elm_blurhash$Internal$buildDict, filter, maximumValue, blurhash);
		var toValue = F2(
			function (row, column) {
				return A6($WhileTruu$elm_blurhash$Internal$calcPixel, column, row, width, height, filter, lookup);
			});
		var dimensions = A2($WhileTruu$elm_blurhash$Internal$Dimensions, height, width);
		return A3(
			$WhileTruu$elm_blurhash$Internal$foldDimensionsReversed,
			dimensions,
			folder(toValue),
			_default);
	});
var $WhileTruu$elm_blurhash$Internal$folderList2d = F4(
	function (toValue, row, column, r) {
		var value = A2(toValue, row, column);
		if (!column) {
			return {
				aK: _List_Nil,
				D: A2(
					$elm$core$List$cons,
					A2($elm$core$List$cons, value, r.aK),
					r.D)
			};
		} else {
			return {
				aK: A2($elm$core$List$cons, value, r.aK),
				D: r.D
			};
		}
	});
var $avh4$elm_color$Color$toRgba = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {aQ: a, du: b, d$: g, eZ: r};
};
var $justgook$elm_image$Image$Color$colorToInt32 = function (color) {
	var record = $avh4$elm_color$Color$toRgba(color);
	var byte4 = $elm$core$Basics$round(record.eZ * 255) << 24;
	var byte3 = $elm$core$Basics$round(record.d$ * 255) << 16;
	var byte2 = $elm$core$Basics$round(record.du * 255) << 8;
	var byte1 = $elm$core$Basics$round(record.aQ * 255);
	return (byte4 | (byte3 | (byte1 | byte2))) >>> 0;
};
var $justgook$elm_image$Image$Info$FromData = function (a) {
	return {$: 3, a: a};
};
var $justgook$elm_image$Image$Info$FromDataBitDepth8 = 3;
var $justgook$elm_image$Image$Info$FromDataChannel4 = function (a) {
	return {$: 3, a: a};
};
var $justgook$elm_image$Image$Internal$ImageData$List2d = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $justgook$elm_image$Image$fromList2d = function (l) {
	return A2(
		$justgook$elm_image$Image$Internal$ImageData$List2d,
		$justgook$elm_image$Image$Info$FromData(
			{
				bM: $justgook$elm_image$Image$Info$FromDataChannel4(3),
				b2: $elm$core$List$length(l),
				fK: A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$length,
						$elm$core$List$head(l)))
			}),
		l);
};
var $justgook$elm_image$Image$Color$fromList2d = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		$elm$core$List$map($justgook$elm_image$Image$Color$colorToInt32)),
	$justgook$elm_image$Image$fromList2d);
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $danfishgold$base64_bytes$Decode$lowest6BitsMask = 63;
var $elm$core$Char$fromCode = _Char_fromCode;
var $danfishgold$base64_bytes$Decode$unsafeToChar = function (n) {
	if (n <= 25) {
		return $elm$core$Char$fromCode(65 + n);
	} else {
		if (n <= 51) {
			return $elm$core$Char$fromCode(97 + (n - 26));
		} else {
			if (n <= 61) {
				return $elm$core$Char$fromCode(48 + (n - 52));
			} else {
				switch (n) {
					case 62:
						return '+';
					case 63:
						return '/';
					default:
						return '\u0000';
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Decode$bitsToChars = F2(
	function (bits, missing) {
		var s = $danfishgold$base64_bytes$Decode$unsafeToChar(bits & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var r = $danfishgold$base64_bytes$Decode$unsafeToChar((bits >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var q = $danfishgold$base64_bytes$Decode$unsafeToChar((bits >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var p = $danfishgold$base64_bytes$Decode$unsafeToChar(bits >>> 18);
		switch (missing) {
			case 0:
				return A2(
					$elm$core$String$cons,
					p,
					A2(
						$elm$core$String$cons,
						q,
						A2(
							$elm$core$String$cons,
							r,
							$elm$core$String$fromChar(s))));
			case 1:
				return A2(
					$elm$core$String$cons,
					p,
					A2(
						$elm$core$String$cons,
						q,
						A2($elm$core$String$cons, r, '=')));
			case 2:
				return A2(
					$elm$core$String$cons,
					p,
					A2($elm$core$String$cons, q, '=='));
			default:
				return '';
		}
	});
var $danfishgold$base64_bytes$Decode$bitsToCharSpecialized = F4(
	function (bits1, bits2, bits3, accum) {
		var z = $danfishgold$base64_bytes$Decode$unsafeToChar((bits3 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var y = $danfishgold$base64_bytes$Decode$unsafeToChar((bits3 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var x = $danfishgold$base64_bytes$Decode$unsafeToChar(bits3 >>> 18);
		var w = $danfishgold$base64_bytes$Decode$unsafeToChar(bits3 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var s = $danfishgold$base64_bytes$Decode$unsafeToChar(bits1 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var r = $danfishgold$base64_bytes$Decode$unsafeToChar((bits1 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var q = $danfishgold$base64_bytes$Decode$unsafeToChar((bits1 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var p = $danfishgold$base64_bytes$Decode$unsafeToChar(bits1 >>> 18);
		var d = $danfishgold$base64_bytes$Decode$unsafeToChar(bits2 & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var c = $danfishgold$base64_bytes$Decode$unsafeToChar((bits2 >>> 6) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var b = $danfishgold$base64_bytes$Decode$unsafeToChar((bits2 >>> 12) & $danfishgold$base64_bytes$Decode$lowest6BitsMask);
		var a = $danfishgold$base64_bytes$Decode$unsafeToChar(bits2 >>> 18);
		return A2(
			$elm$core$String$cons,
			x,
			A2(
				$elm$core$String$cons,
				y,
				A2(
					$elm$core$String$cons,
					z,
					A2(
						$elm$core$String$cons,
						w,
						A2(
							$elm$core$String$cons,
							a,
							A2(
								$elm$core$String$cons,
								b,
								A2(
									$elm$core$String$cons,
									c,
									A2(
										$elm$core$String$cons,
										d,
										A2(
											$elm$core$String$cons,
											p,
											A2(
												$elm$core$String$cons,
												q,
												A2(
													$elm$core$String$cons,
													r,
													A2($elm$core$String$cons, s, accum))))))))))));
	});
var $danfishgold$base64_bytes$Decode$decode18Help = F5(
	function (a, b, c, d, e) {
		var combined6 = ((255 & d) << 16) | e;
		var combined5 = d >>> 8;
		var combined4 = 16777215 & c;
		var combined3 = ((65535 & b) << 8) | (c >>> 24);
		var combined2 = ((255 & a) << 16) | (b >>> 16);
		var combined1 = a >>> 8;
		return A4(
			$danfishgold$base64_bytes$Decode$bitsToCharSpecialized,
			combined3,
			combined2,
			combined1,
			A4($danfishgold$base64_bytes$Decode$bitsToCharSpecialized, combined6, combined5, combined4, ''));
	});
var $elm$bytes$Bytes$Decode$map5 = F6(
	function (func, _v0, _v1, _v2, _v3, _v4) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		var decodeD = _v3;
		var decodeE = _v4;
		return F2(
			function (bites, offset) {
				var _v5 = A2(decodeA, bites, offset);
				var aOffset = _v5.a;
				var a = _v5.b;
				var _v6 = A2(decodeB, bites, aOffset);
				var bOffset = _v6.a;
				var b = _v6.b;
				var _v7 = A2(decodeC, bites, bOffset);
				var cOffset = _v7.a;
				var c = _v7.b;
				var _v8 = A2(decodeD, bites, cOffset);
				var dOffset = _v8.a;
				var d = _v8.b;
				var _v9 = A2(decodeE, bites, dOffset);
				var eOffset = _v9.a;
				var e = _v9.b;
				return _Utils_Tuple2(
					eOffset,
					A5(func, a, b, c, d, e));
			});
	});
var $elm$bytes$Bytes$Decode$unsignedInt16 = function (endianness) {
	return _Bytes_read_u16(!endianness);
};
var $danfishgold$base64_bytes$Decode$u16BE = $elm$bytes$Bytes$Decode$unsignedInt16(1);
var $elm$bytes$Bytes$Decode$unsignedInt32 = function (endianness) {
	return _Bytes_read_u32(!endianness);
};
var $danfishgold$base64_bytes$Decode$u32BE = $elm$bytes$Bytes$Decode$unsignedInt32(1);
var $danfishgold$base64_bytes$Decode$decode18Bytes = A6($elm$bytes$Bytes$Decode$map5, $danfishgold$base64_bytes$Decode$decode18Help, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u32BE, $danfishgold$base64_bytes$Decode$u16BE);
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $elm$bytes$Bytes$Decode$map2 = F3(
	function (func, _v0, _v1) {
		var decodeA = _v0;
		var decodeB = _v1;
		return F2(
			function (bites, offset) {
				var _v2 = A2(decodeA, bites, offset);
				var aOffset = _v2.a;
				var a = _v2.b;
				var _v3 = A2(decodeB, bites, aOffset);
				var bOffset = _v3.a;
				var b = _v3.b;
				return _Utils_Tuple2(
					bOffset,
					A2(func, a, b));
			});
	});
var $elm$bytes$Bytes$Decode$map3 = F4(
	function (func, _v0, _v1, _v2) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		return F2(
			function (bites, offset) {
				var _v3 = A2(decodeA, bites, offset);
				var aOffset = _v3.a;
				var a = _v3.b;
				var _v4 = A2(decodeB, bites, aOffset);
				var bOffset = _v4.a;
				var b = _v4.b;
				var _v5 = A2(decodeC, bites, bOffset);
				var cOffset = _v5.a;
				var c = _v5.b;
				return _Utils_Tuple2(
					cOffset,
					A3(func, a, b, c));
			});
	});
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $elm$bytes$Bytes$Decode$unsignedInt8 = _Bytes_read_u8;
var $danfishgold$base64_bytes$Decode$loopHelp = function (_v0) {
	var remaining = _v0.cA;
	var string = _v0.cJ;
	if (remaining >= 18) {
		return A2(
			$elm$bytes$Bytes$Decode$map,
			function (result) {
				return $elm$bytes$Bytes$Decode$Loop(
					{
						cA: remaining - 18,
						cJ: _Utils_ap(string, result)
					});
			},
			$danfishgold$base64_bytes$Decode$decode18Bytes);
	} else {
		if (remaining >= 3) {
			var helper = F3(
				function (a, b, c) {
					var combined = ((a << 16) | (b << 8)) | c;
					return $elm$bytes$Bytes$Decode$Loop(
						{
							cA: remaining - 3,
							cJ: _Utils_ap(
								string,
								A2($danfishgold$base64_bytes$Decode$bitsToChars, combined, 0))
						});
				});
			return A4($elm$bytes$Bytes$Decode$map3, helper, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8);
		} else {
			if (!remaining) {
				return $elm$bytes$Bytes$Decode$succeed(
					$elm$bytes$Bytes$Decode$Done(string));
			} else {
				if (remaining === 2) {
					var helper = F2(
						function (a, b) {
							var combined = (a << 16) | (b << 8);
							return $elm$bytes$Bytes$Decode$Done(
								_Utils_ap(
									string,
									A2($danfishgold$base64_bytes$Decode$bitsToChars, combined, 1)));
						});
					return A3($elm$bytes$Bytes$Decode$map2, helper, $elm$bytes$Bytes$Decode$unsignedInt8, $elm$bytes$Bytes$Decode$unsignedInt8);
				} else {
					return A2(
						$elm$bytes$Bytes$Decode$map,
						function (a) {
							return $elm$bytes$Bytes$Decode$Done(
								_Utils_ap(
									string,
									A2($danfishgold$base64_bytes$Decode$bitsToChars, a << 16, 2)));
						},
						$elm$bytes$Bytes$Decode$unsignedInt8);
				}
			}
		}
	}
};
var $danfishgold$base64_bytes$Decode$decoder = function (width) {
	return A2(
		$elm$bytes$Bytes$Decode$loop,
		{cA: width, cJ: ''},
		$danfishgold$base64_bytes$Decode$loopHelp);
};
var $danfishgold$base64_bytes$Decode$fromBytes = function (bytes) {
	return A2(
		$elm$bytes$Bytes$Decode$decode,
		$danfishgold$base64_bytes$Decode$decoder(
			$elm$bytes$Bytes$width(bytes)),
		bytes);
};
var $danfishgold$base64_bytes$Base64$fromBytes = $danfishgold$base64_bytes$Decode$fromBytes;
var $justgook$elm_image$Image$Info$FromDataChannel3 = function (a) {
	return {$: 2, a: a};
};
var $justgook$elm_image$Image$Internal$ImageData$bytesPerPixel = function (meta) {
	switch (meta.$) {
		case 0:
			var color = meta.a.bM;
			switch (color.$) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				case 3:
					return 4;
				default:
					return 4;
			}
		case 1:
			var bitsPerPixel = meta.a.fT;
			switch (bitsPerPixel) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				default:
					return 4;
			}
		case 3:
			var color = meta.a.bM;
			switch (color.$) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				default:
					return 4;
			}
		default:
			return 1;
	}
};
var $justgook$elm_image$Image$Internal$ImageData$getInfo = function (image) {
	switch (image.$) {
		case 4:
			var meta = image.a;
			return meta;
		case 3:
			var meta = image.a;
			return meta;
		case 1:
			var meta = image.a;
			return meta;
		case 2:
			var meta = image.a;
			return meta;
		default:
			var meta = image.a;
			return meta;
	}
};
var $justgook$elm_image$Image$Internal$Encode$unsignedInt24 = F2(
	function (endian, num) {
		return (!endian) ? $elm$bytes$Bytes$Encode$sequence(
			_List_fromArray(
				[
					$elm$bytes$Bytes$Encode$unsignedInt8(num & 255),
					$elm$bytes$Bytes$Encode$unsignedInt8((num & 65280) >> 8),
					$elm$bytes$Bytes$Encode$unsignedInt8((num & 16711680) >> 16)
				])) : $elm$bytes$Bytes$Encode$sequence(
			_List_fromArray(
				[
					$elm$bytes$Bytes$Encode$unsignedInt8((num & 16711680) >> 16),
					$elm$bytes$Bytes$Encode$unsignedInt8((num & 65280) >> 8),
					$elm$bytes$Bytes$Encode$unsignedInt8(num & 255)
				]));
	});
var $elm$bytes$Bytes$Encode$U32 = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$unsignedInt32 = $elm$bytes$Bytes$Encode$U32;
var $justgook$elm_image$Image$Internal$BMP$intToBytes = function (bpp) {
	switch (bpp) {
		case 1:
			return $elm$bytes$Bytes$Encode$unsignedInt8;
		case 2:
			return $elm$bytes$Bytes$Encode$unsignedInt16(0);
		case 3:
			return $justgook$elm_image$Image$Internal$Encode$unsignedInt24(0);
		default:
			return $elm$bytes$Bytes$Encode$unsignedInt32(0);
	}
};
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $justgook$elm_image$Image$Info$dimensions = function (meta) {
	switch (meta.$) {
		case 0:
			var width = meta.a.fK;
			var height = meta.a.b2;
			return {b2: height, fK: width};
		case 1:
			var width = meta.a.fK;
			var height = meta.a.b2;
			return {b2: height, fK: width};
		case 2:
			var width = meta.a.fK;
			var height = meta.a.b2;
			return {b2: height, fK: width};
		default:
			var width = meta.a.fK;
			var height = meta.a.b2;
			return {b2: height, fK: width};
	}
};
var $justgook$elm_image$Image$Internal$ImageData$dimensions = function (image) {
	return $justgook$elm_image$Image$Info$dimensions(
		$justgook$elm_image$Image$Internal$ImageData$getInfo(image));
};
var $justgook$elm_image$Image$Internal$ImageData$width = function (image) {
	return $justgook$elm_image$Image$Internal$ImageData$dimensions(image).fK;
};
var $justgook$elm_image$Image$Internal$BMP$encodeConfig = function (image) {
	var width = $justgook$elm_image$Image$Internal$ImageData$width(image);
	var bytesPerPixel = $justgook$elm_image$Image$Internal$ImageData$bytesPerPixel(
		$justgook$elm_image$Image$Internal$ImageData$getInfo(image));
	var encoder = $justgook$elm_image$Image$Internal$BMP$intToBytes(bytesPerPixel);
	var extraBytes = ((4 - ((width * bytesPerPixel) & bytesPerPixel)) & bytesPerPixel) % 4;
	return {
		bJ: bytesPerPixel,
		cZ: A2(
			$elm$core$List$repeat,
			width,
			encoder(0)),
		c0: encoder,
		c1: extraBytes,
		c4: bytesPerPixel === 4,
		cr: true,
		dd: false,
		fK: width
	};
};
var $justgook$elm_image$Image$Internal$BMP$addRowPadding = F2(
	function (n, acc) {
		switch (n) {
			case 1:
				return A2(
					$elm$core$List$cons,
					$elm$bytes$Bytes$Encode$unsignedInt8(0),
					acc);
			case 2:
				return A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 0),
					acc);
			case 3:
				return A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 0),
					A2(
						$elm$core$List$cons,
						$elm$bytes$Bytes$Encode$unsignedInt8(0),
						acc));
			default:
				return A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
					acc);
		}
	});
var $justgook$elm_image$Image$Internal$BMP$encodeRow = F4(
	function (f, items, i, acc) {
		encodeRow:
		while (true) {
			if (items.b) {
				var px = items.a;
				var rest = items.b;
				var $temp$f = f,
					$temp$items = rest,
					$temp$i = i + 1,
					$temp$acc = A2(
					$elm$core$List$cons,
					f(px),
					acc);
				f = $temp$f;
				items = $temp$items;
				i = $temp$i;
				acc = $temp$acc;
				continue encodeRow;
			} else {
				return {aK: acc, fK: i};
			}
		}
	});
var $justgook$elm_image$Image$Internal$BMP$header16_24 = F5(
	function (bitsPerPixel, w, h, dataSize, accum) {
		return A2(
			$elm$core$List$cons,
			A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, 16973),
			A2(
				$elm$core$List$cons,
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 54 + dataSize),
				A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
					A2(
						$elm$core$List$cons,
						A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 14 + 40),
						A2(
							$elm$core$List$cons,
							A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 40),
							A2(
								$elm$core$List$cons,
								A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, w),
								A2(
									$elm$core$List$cons,
									A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, h),
									A2(
										$elm$core$List$cons,
										A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 1),
										A2(
											$elm$core$List$cons,
											A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, bitsPerPixel),
											A2(
												$elm$core$List$cons,
												A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
												A2(
													$elm$core$List$cons,
													A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, dataSize),
													A2(
														$elm$core$List$cons,
														A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 2835),
														A2(
															$elm$core$List$cons,
															A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 2835),
															A2(
																$elm$core$List$cons,
																A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
																A2(
																	$elm$core$List$cons,
																	A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
																	accum)))))))))))))));
	});
var $elm$bytes$Bytes$Encode$Bytes = function (a) {
	return {$: 10, a: a};
};
var $elm$bytes$Bytes$Encode$bytes = $elm$bytes$Bytes$Encode$Bytes;
var $justgook$elm_image$Image$Internal$BMP$staticHeaderPart = A3(
	$elm$core$Basics$composeR,
	$elm$bytes$Bytes$Encode$sequence,
	$elm$bytes$Bytes$Encode$encode,
	_List_fromArray(
		[
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 2835),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 2835),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 4278190080),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 16711680),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 65280),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 255),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 544106839),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
			A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0)
		]));
var $justgook$elm_image$Image$Internal$BMP$header32 = F4(
	function (w, h, dataSize, accum) {
		return A2(
			$elm$core$List$cons,
			A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, 16973),
			A2(
				$elm$core$List$cons,
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 122 + dataSize),
				A2(
					$elm$core$List$cons,
					A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 0),
					A2(
						$elm$core$List$cons,
						A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 122),
						A2(
							$elm$core$List$cons,
							A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 108),
							A2(
								$elm$core$List$cons,
								A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, w),
								A2(
									$elm$core$List$cons,
									A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, h),
									A2(
										$elm$core$List$cons,
										A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 1),
										A2(
											$elm$core$List$cons,
											A2($elm$bytes$Bytes$Encode$unsignedInt16, 0, 32),
											A2(
												$elm$core$List$cons,
												A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, 3),
												A2(
													$elm$core$List$cons,
													A2($elm$bytes$Bytes$Encode$unsignedInt32, 0, dataSize),
													A2(
														$elm$core$List$cons,
														$elm$bytes$Bytes$Encode$bytes($justgook$elm_image$Image$Internal$BMP$staticHeaderPart),
														accum))))))))))));
	});
var $justgook$elm_image$Image$Internal$BMP$encodeFolder = F5(
	function (config, remaining, height, totalBytes, acc) {
		encodeFolder:
		while (true) {
			var width = config.fK;
			var extraBytes = config.c1;
			if (remaining.b) {
				var row = remaining.a;
				var rest = remaining.b;
				var initial = A4($justgook$elm_image$Image$Internal$BMP$encodeRow, config.c0, row, 0, _List_Nil);
				var encoded = function () {
					var padding = width - initial.fK;
					if (!padding) {
						return initial.aK;
					} else {
						return _Utils_ap(
							A2($elm$core$List$take, padding, config.cZ),
							initial.aK);
					}
				}();
				var withRow = function () {
					if (!extraBytes) {
						return config.cr ? A2(
							$elm$core$List$cons,
							$elm$bytes$Bytes$Encode$sequence(
								$elm$core$List$reverse(encoded)),
							acc) : A2(
							$elm$core$List$cons,
							$elm$bytes$Bytes$Encode$sequence(encoded),
							acc);
					} else {
						return config.cr ? A2(
							$elm$core$List$cons,
							$elm$bytes$Bytes$Encode$sequence(
								$elm$core$List$reverse(
									A2($justgook$elm_image$Image$Internal$BMP$addRowPadding, extraBytes, encoded))),
							acc) : A2(
							$elm$core$List$cons,
							$elm$bytes$Bytes$Encode$sequence(
								_Utils_ap(
									encoded,
									A2($justgook$elm_image$Image$Internal$BMP$addRowPadding, extraBytes, _List_Nil))),
							acc);
					}
				}();
				var $temp$config = config,
					$temp$remaining = rest,
					$temp$height = height + 1,
					$temp$totalBytes = (totalBytes + (width * config.bJ)) + extraBytes,
					$temp$acc = withRow;
				config = $temp$config;
				remaining = $temp$remaining;
				height = $temp$height;
				totalBytes = $temp$totalBytes;
				acc = $temp$acc;
				continue encodeFolder;
			} else {
				var body = config.dd ? $elm$core$List$reverse(acc) : acc;
				return config.c4 ? A4($justgook$elm_image$Image$Internal$BMP$header32, width, height, totalBytes, body) : A5($justgook$elm_image$Image$Internal$BMP$header16_24, 8 * config.bJ, width, height, totalBytes, body);
			}
		}
	});
var $justgook$elm_image$Image$Internal$ImageData$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($justgook$elm_image$Image$Internal$ImageData$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $justgook$elm_image$Image$Internal$ImageData$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($justgook$elm_image$Image$Internal$ImageData$greedyGroupsOfWithStep, size, size, xs);
	});
var $justgook$elm_image$Image$Internal$ImageData$toList2d = function (info) {
	toList2d:
	while (true) {
		switch (info.$) {
			case 0:
				var meta = info.a;
				var l = info.b;
				return A2(
					$justgook$elm_image$Image$Internal$ImageData$greedyGroupsOf,
					$justgook$elm_image$Image$Info$dimensions(meta).fK,
					l);
			case 1:
				var l = info.b;
				return l;
			case 2:
				var meta = info.a;
				var arr = info.b;
				return A2(
					$justgook$elm_image$Image$Internal$ImageData$greedyGroupsOf,
					$justgook$elm_image$Image$Info$dimensions(meta).fK,
					$elm$core$Array$toList(arr));
			case 3:
				var arr = info.b;
				return A3(
					$elm$core$Array$foldr,
					F2(
						function (line, acc1) {
							return function (l) {
								return A2($elm$core$List$cons, l, acc1);
							}(
								A3(
									$elm$core$Array$foldr,
									F2(
										function (px, acc2) {
											return A2($elm$core$List$cons, px, acc2);
										}),
									_List_Nil,
									line));
						}),
					_List_Nil,
					arr);
			default:
				var d = info.b;
				var b = info.c;
				var _v1 = A2($elm$bytes$Bytes$Decode$decode, d, b);
				if (!_v1.$) {
					if (_v1.a.$ === 4) {
						var _v2 = _v1.a;
						return _List_Nil;
					} else {
						var newData = _v1.a;
						var $temp$info = newData;
						info = $temp$info;
						continue toList2d;
					}
				} else {
					return _List_Nil;
				}
		}
	}
};
var $justgook$elm_image$Image$Internal$BMP$encode = function (image) {
	return $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			A5(
				$justgook$elm_image$Image$Internal$BMP$encodeFolder,
				$justgook$elm_image$Image$Internal$BMP$encodeConfig(image),
				$justgook$elm_image$Image$Internal$ImageData$toList2d(image),
				0,
				0,
				_List_Nil)));
};
var $justgook$elm_image$Image$Internal$ImageData$Array = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $justgook$elm_image$Image$Internal$ImageData$Array2d = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $justgook$elm_image$Image$Internal$ImageData$List = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justgook$elm_image$Image$Internal$ImageData$toFromData = F2(
	function (color, meta) {
		var dim = $justgook$elm_image$Image$Info$dimensions(meta);
		return $justgook$elm_image$Image$Info$FromData(
			{bM: color, b2: dim.b2, fK: dim.fK});
	});
var $justgook$elm_image$Image$Internal$ImageData$forceColor = F2(
	function (color, image) {
		forceColor:
		while (true) {
			switch (image.$) {
				case 0:
					var meta = image.a;
					var im = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$List,
						A2($justgook$elm_image$Image$Internal$ImageData$toFromData, color, meta),
						im);
				case 1:
					var meta = image.a;
					var im = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$List2d,
						A2($justgook$elm_image$Image$Internal$ImageData$toFromData, color, meta),
						im);
				case 2:
					var meta = image.a;
					var im = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$Array,
						A2($justgook$elm_image$Image$Internal$ImageData$toFromData, color, meta),
						im);
				case 3:
					var meta = image.a;
					var im = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$Array2d,
						A2($justgook$elm_image$Image$Internal$ImageData$toFromData, color, meta),
						im);
				default:
					var d = image.b;
					var b = image.c;
					var _v1 = A2($elm$bytes$Bytes$Decode$decode, d, b);
					if (!_v1.$) {
						if (_v1.a.$ === 4) {
							var _v2 = _v1.a;
							return image;
						} else {
							var newData = _v1.a;
							var $temp$color = color,
								$temp$image = newData;
							color = $temp$color;
							image = $temp$image;
							continue forceColor;
						}
					} else {
						return image;
					}
			}
		}
	});
var $justgook$elm_image$Image$Internal$ImageData$Bytes = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $elm$core$Elm$JsArray$map = _JsArray_map;
var $elm$core$Array$map = F2(
	function (func, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = function (node) {
			if (!node.$) {
				var subTree = node.a;
				return $elm$core$Array$SubTree(
					A2($elm$core$Elm$JsArray$map, helper, subTree));
			} else {
				var values = node.a;
				return $elm$core$Array$Leaf(
					A2($elm$core$Elm$JsArray$map, func, values));
			}
		};
		return A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A2($elm$core$Elm$JsArray$map, helper, tree),
			A2($elm$core$Elm$JsArray$map, func, tail));
	});
var $justgook$elm_image$Image$Internal$ImageData$map = F2(
	function (fn, image) {
		map:
		while (true) {
			switch (image.$) {
				case 0:
					var meta = image.a;
					var l = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$List,
						meta,
						A2($elm$core$List$map, fn, l));
				case 1:
					var meta = image.a;
					var l = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$List2d,
						meta,
						A2(
							$elm$core$List$map,
							$elm$core$List$map(fn),
							l));
				case 2:
					var meta = image.a;
					var arr = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$Array,
						meta,
						A2($elm$core$Array$map, fn, arr));
				case 3:
					var meta = image.a;
					var arr = image.b;
					return A2(
						$justgook$elm_image$Image$Internal$ImageData$Array2d,
						meta,
						A2(
							$elm$core$Array$map,
							$elm$core$Array$map(fn),
							arr));
				default:
					var meta = image.a;
					var d = image.b;
					var b = image.c;
					var _v1 = A2($elm$bytes$Bytes$Decode$decode, d, b);
					if (!_v1.$) {
						if (_v1.a.$ === 4) {
							var _v2 = _v1.a;
							return A3($justgook$elm_image$Image$Internal$ImageData$Bytes, meta, d, b);
						} else {
							var newData = _v1.a;
							var $temp$fn = fn,
								$temp$image = newData;
							fn = $temp$fn;
							image = $temp$image;
							continue map;
						}
					} else {
						return A3($justgook$elm_image$Image$Internal$ImageData$Bytes, meta, d, b);
					}
			}
		}
	});
var $justgook$elm_image$Image$Internal$Pixel$dropChannel = $justgook$elm_image$Image$Internal$ImageData$map(
	$elm$core$Bitwise$shiftRightZfBy(8));
var $justgook$elm_image$Image$Internal$Pixel$px8AlphaTo24 = function (px_) {
	var px = px_ >>> 8;
	return px + ((px + (px << 8)) << 8);
};
var $justgook$elm_image$Image$Internal$Pixel$px8to24 = function (px) {
	return px + ((px + ((px + (px << 8)) << 8)) << 8);
};
var $justgook$elm_image$Image$Internal$Pixel$toBit24 = function (image) {
	var _v0 = $justgook$elm_image$Image$Internal$ImageData$getInfo(image);
	switch (_v0.$) {
		case 0:
			var color = _v0.a.bM;
			switch (color.$) {
				case 0:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
				case 2:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
				case 4:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
				case 1:
					return A2($justgook$elm_image$Image$Internal$ImageData$map, $justgook$elm_image$Image$Internal$Pixel$px8AlphaTo24, image);
				default:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
			}
		case 1:
			var bitsPerPixel = _v0.a.fT;
			switch (bitsPerPixel) {
				case 0:
					return A2($justgook$elm_image$Image$Internal$ImageData$map, $justgook$elm_image$Image$Internal$Pixel$px8to24, image);
				case 1:
					return image;
				case 2:
					return image;
				default:
					return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
			}
		case 2:
			return image;
		default:
			var color = _v0.a.bM;
			switch (color.$) {
				case 0:
					return image;
				case 1:
					return image;
				case 2:
					return image;
				default:
					if (color.a === 3) {
						var _v4 = color.a;
						return $justgook$elm_image$Image$Internal$Pixel$dropChannel(image);
					} else {
						return image;
					}
			}
	}
};
var $justgook$elm_image$Image$Advanced$toBmp24 = A2(
	$elm$core$Basics$composeR,
	$justgook$elm_image$Image$Internal$Pixel$toBit24,
	A2(
		$elm$core$Basics$composeR,
		$justgook$elm_image$Image$Internal$ImageData$forceColor(
			$justgook$elm_image$Image$Info$FromDataChannel3(3)),
		$justgook$elm_image$Image$Internal$BMP$encode));
var $justgook$elm_image$Image$toBmpUrl = A2(
	$elm$core$Basics$composeR,
	$justgook$elm_image$Image$Advanced$toBmp24,
	A2(
		$elm$core$Basics$composeR,
		$danfishgold$base64_bytes$Base64$fromBytes,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(''),
			$elm$core$Basics$append('data:image/bmp;base64,'))));
var $WhileTruu$elm_blurhash$Internal$toUri = F3(
	function (_v0, punch, blurhash) {
		var width = _v0.fK;
		var height = _v0.b2;
		return $justgook$elm_image$Image$toBmpUrl(
			$justgook$elm_image$Image$Color$fromList2d(
				A6(
					$WhileTruu$elm_blurhash$Internal$foldGrid,
					width,
					height,
					punch,
					blurhash,
					$WhileTruu$elm_blurhash$Internal$folderList2d,
					{aK: _List_Nil, D: _List_Nil}).D));
	});
var $WhileTruu$elm_blurhash$BlurHash$toUri = $WhileTruu$elm_blurhash$Internal$toUri;
var $author$project$Page$Gallery$thumnailImage = F2(
	function (idx, image) {
		return A3(
			$author$project$ProgressiveImage$toHtml,
			image,
			function (imgwb) {
				return A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$Attributes$attribute,
							'src',
							A3(
								$WhileTruu$elm_blurhash$BlurHash$toUri,
								{b2: 20, fK: 20},
								0.5,
								imgwb.bI)),
							A2(
							$elm$html$Html$Events$on,
							'load',
							$elm$json$Json$Decode$succeed(
								$author$project$Page$Gallery$LoadedThumnailImage(idx))),
							A2($elm$html$Html$Attributes$attribute, 'width', '220px')
						]),
					_List_Nil);
			},
			function (imgwb) {
				return A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'srcset', imgwb.fo),
							A2($elm$html$Html$Attributes$attribute, 'src', imgwb.fn),
							$elm$html$Html$Attributes$class('loaded'),
							A2($elm$html$Html$Attributes$attribute, 'width', '220px')
						]),
					_List_Nil);
			});
	});
var $author$project$Page$Gallery$renderThumnailItem = F3(
	function (selectedPhotos, idx, imageForm) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('thumbnail-item'),
					$elm$html$Html$Attributes$class(
					A2($elm$core$Set$member, idx, selectedPhotos) ? 'is-selected' : ''),
					$elm$html$Html$Events$onClick(
					$author$project$Page$Gallery$ClickPhoto(idx))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fas fa-check-circle')
										]),
									_List_Nil)
								])),
							A3(
							$elm$html$Html$Keyed$node,
							'div',
							_List_Nil,
							_List_fromArray(
								[
									_Utils_Tuple2(
									imageForm.c,
									A3($elm$html$Html$Lazy$lazy2, $author$project$Page$Gallery$thumnailImage, idx, imageForm.bw))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('index')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$elm$core$String$fromInt(idx + 1))
								]))
						]))
				]));
	});
var $author$project$Page$Gallery$viewGallery = F2(
	function (selectedPhotos, form) {
		return A2(
			$elm$core$List$indexedMap,
			A2(
				$elm$core$Basics$composeL,
				$elm$html$Html$Lazy$lazy,
				$author$project$Page$Gallery$renderThumnailItem(selectedPhotos)),
			$elm$core$Array$toList(form.l));
	});
var $author$project$Page$Gallery$ClickClosePhotoViewer = {$: 2};
var $author$project$Page$Gallery$ClickLeftChevron = {$: 4};
var $author$project$Page$Gallery$ClickRightChevron = {$: 5};
var $author$project$Page$Gallery$LoadedSlideImage = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Gallery$slideImage = F2(
	function (idx, image) {
		if (!image.$) {
			var v = image.a;
			return A3(
				$author$project$ProgressiveImage$toHtml,
				v,
				function (imgwb) {
					return A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$Attributes$attribute,
								'src',
								A3(
									$WhileTruu$elm_blurhash$BlurHash$toUri,
									{b2: 20, fK: 20},
									0.5,
									imgwb.bI)),
								A2(
								$elm$html$Html$Events$on,
								'load',
								$elm$json$Json$Decode$succeed(
									$author$project$Page$Gallery$LoadedSlideImage(idx))),
								A2($elm$html$Html$Attributes$attribute, 'width', '2400px')
							]),
						_List_Nil);
				},
				function (imgwb) {
					return A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'srcset', imgwb.fo),
								A2($elm$html$Html$Attributes$attribute, 'src', imgwb.fn),
								$elm$html$Html$Attributes$class('loaded'),
								A2($elm$html$Html$Attributes$attribute, 'width', '2400px')
							]),
						_List_Nil);
				});
		} else {
			return $elm$html$Html$text('');
		}
	});
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Page$Gallery$viewPhotoViewer = F2(
	function (model, form) {
		var photoId = function () {
			var _v2 = A2($elm$core$Array$get, model.B, form.l);
			if (!_v2.$) {
				var imgForm = _v2.a;
				return imgForm.c;
			} else {
				return '';
			}
		}();
		var mySlideImage = function () {
			var _v1 = A2($elm$core$Array$get, model.B, form.l);
			if (!_v1.$) {
				var imgForm = _v1.a;
				return $elm$core$Maybe$Just(imgForm.aL);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var myDownloadUrl = function () {
			var _v0 = A2($elm$core$Array$get, model.B, form.l);
			if (!_v0.$) {
				var imgForm = _v0.a;
				return imgForm.ez;
			} else {
				return '';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal photo-viewer'),
					$elm$html$Html$Attributes$class(
					model.bk ? 'is-active' : '')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-background')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('photo-frame')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button-bar'),
											$elm$html$Html$Events$onClick($author$project$Page$Gallery$ClickLeftChevron)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon is-large')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('fas fa-lg fa-chevron-left')
														]),
													_List_Nil)
												]))
										])),
									A3(
									$elm$html$Html$Keyed$node,
									'div',
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('photo')
										]),
									_List_fromArray(
										[
											_Utils_Tuple2(
											photoId,
											A2($author$project$Page$Gallery$slideImage, model.B, mySlideImage))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button-bar'),
											$elm$html$Html$Events$onClick($author$project$Page$Gallery$ClickRightChevron)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon is-large')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('fas fa-lg fa-chevron-right')
														]),
													_List_Nil)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('navbar is-fixed-bottom')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('button')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('icon')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$a,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('fas fa-lg fa-cloud-download-alt'),
																	$elm$html$Html$Attributes$href(myDownloadUrl),
																	$elm$html$Html$Attributes$target('_blank')
																]),
															_List_Nil)
														]))
												]))
										]))
								]))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-label', 'close'),
							$elm$html$Html$Attributes$class('modal-close is-large'),
							$elm$html$Html$Events$onClick($author$project$Page$Gallery$ClickClosePhotoViewer)
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$Gallery$view = function (model) {
	return {
		f0: function () {
			var _v0 = model.w;
			switch (_v0.$) {
				case 2:
					var galleryform = _v0.a;
					var selectedPhotoCount = $elm$core$String$fromInt(
						$elm$core$Set$size(model.H));
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('container gallery'),
								$elm$html$Html$Attributes$class(
								model.ap ? 'is-select-mode' : '')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('thumbnail')
									]),
								A2($author$project$Page$Gallery$viewGallery, model.H, galleryform)),
								A2(
								$elm$html$Html$nav,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'aria-label', 'main navigation'),
										$elm$html$Html$Attributes$class('navbar is-fixed-bottom is-dark'),
										A2($elm$html$Html$Attributes$attribute, 'role', 'navigation')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('navbar-item')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('buttons')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-small is-light select-mode-only'),
																$elm$html$Html$Events$onClick($author$project$Page$Gallery$ClickSelectAll)
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon is-small')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$i,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('far fa-lg fa-check-circle')
																			]),
																		_List_Nil)
																	]))
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-small is-light select-mode-only'),
																$elm$html$Html$Events$onClick($author$project$Page$Gallery$ClickUnselectAll)
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon is-small')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$i,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('fas fa-lg fa-ban')
																			]),
																		_List_Nil)
																	]))
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-small is-light select-mode-only'),
																$elm$html$Html$Events$onClick(
																$author$project$Page$Gallery$ClickDownloadArchive(galleryform)),
																$elm$html$Html$Attributes$disabled(
																!$elm$core$Set$size(model.H))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon is-small')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$i,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('fas fa-lg fa-cloud-download-alt')
																			]),
																		_List_Nil)
																	]))
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-small is-light'),
																$elm$html$Html$Events$onClick($author$project$Page$Gallery$ClickSelectButton)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																model.ap ? 'キャンセル' : '選択')
															]))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('navbar-item')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('info')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																model.ap ? $elm$html$Html$text(selectedPhotoCount + '個の写真を選択中　') : $elm$html$Html$text(galleryform.bP + (' ' + galleryform.i))
															]))
													]))
											]))
									])),
								A2($author$project$Page$Gallery$viewPhotoViewer, model, galleryform),
								$author$project$Page$Gallery$viewDownloader(model)
							]));
				case 0:
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('')
							]));
				case 1:
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('')
							]));
				case 3:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal is-active')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-background')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-card')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$section,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-card-body')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('notification is-danger is-light')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon is-large')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$i,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('fas fa-exclamation-circle')
																			]),
																		_List_Nil)
																	])),
																$elm$html$Html$text('サーバーでエラーが発生しました。')
															])),
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('ご迷惑をおかけします。')
															])),
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('しばらくお時間をおいて再度ご操作をお願いいたします。')
															]))
													]))
											]))
									]))
							]));
				default:
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('')
							]));
			}
		}(),
		fw: 'ギャラリー'
	};
};
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$Home$viewDownloadMovieStatus = function (status) {
	if (status.$ === 1) {
		var url = status.a;
		return A2(
			$elm$html$Html$iframe,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(url),
					A2($elm$html$Html$Attributes$style, 'display', 'none'),
					A2($elm$html$Html$Attributes$style, 'visibility', 'hidden'),
					$elm$html$Html$Attributes$height(0),
					$elm$html$Html$Attributes$width(0)
				]),
			_List_Nil);
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal is-active')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-background')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-card')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-card-body')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('notification is-danger is-light')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('icon is-large')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$i,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('fas fa-exclamation-circle')
																]),
															_List_Nil)
														])),
													$elm$html$Html$text('サーバーでエラーが発生しました。')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('ご迷惑をおかけします。')
												])),
											A2(
											$elm$html$Html$p,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('しばらくお時間をおいて再度ご操作をお願いいたします。')
												]))
										]))
								]))
						]))
				]));
	}
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Page$Home$toGalleryMenuItems = function (model) {
	return $author$project$Session$galleryMenu(model.j);
};
var $elm$html$Html$article = _VirtualDom_node('article');
var $author$project$Page$Home$LoadedGalleryTitleImage = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Home$progImg = F2(
	function (key, image) {
		return A3(
			$author$project$ProgressiveImage$toHtml,
			image,
			function (imgwb) {
				return A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$Attributes$attribute,
							'src',
							A3(
								$WhileTruu$elm_blurhash$BlurHash$toUri,
								{b2: 20, fK: 20},
								0.5,
								imgwb.bI)),
							A2($elm$html$Html$Attributes$attribute, 'width', '700px'),
							A2($elm$html$Html$Attributes$attribute, 'height', '700px'),
							A2(
							$elm$html$Html$Events$on,
							'load',
							$elm$json$Json$Decode$succeed(
								$author$project$Page$Home$LoadedGalleryTitleImage(key)))
						]),
					_List_Nil);
			},
			function (imgwb) {
				return A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'srcset', imgwb.fo),
							A2($elm$html$Html$Attributes$attribute, 'src', imgwb.fn),
							A2($elm$html$Html$Attributes$attribute, 'width', '700px'),
							A2($elm$html$Html$Attributes$attribute, 'height', '700px'),
							$elm$html$Html$Attributes$class('loaded')
						]),
					_List_Nil);
			});
	});
var $elm_community$list_extra$List$Extra$splitAt = F2(
	function (n, xs) {
		return _Utils_Tuple2(
			A2($elm$core$List$take, n, xs),
			A2($elm$core$List$drop, n, xs));
	});
var $author$project$Page$Home$viewGalleryItems = function (items) {
	var _v0 = A2($elm_community$list_extra$List$Extra$splitAt, 3, items);
	var h = _v0.a;
	var t = _v0.b;
	return $elm$core$List$isEmpty(h) ? _List_Nil : A2(
		$elm$core$List$cons,
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tile is-ancestor')
				]),
			A2(
				$elm$core$List$map,
				function (item) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tile is-parent')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$article,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('tile is-child box')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$author$project$Route$href(
												$author$project$Route$Gallery(item.c))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('title is-4')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(item.i)
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('title is-4')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(item.bP)
													])),
												A3(
												$elm$html$Html$Keyed$node,
												'div',
												_List_Nil,
												_List_fromArray(
													[
														_Utils_Tuple2(
														item.c,
														A2($author$project$Page$Home$progImg, item.c, item.cL))
													]))
											]))
									]))
							]));
				},
				$elm$core$List$reverse(h))),
		$author$project$Page$Home$viewGalleryItems(t));
};
var $author$project$Page$Home$viewGallery = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('section gallery')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container')
					]),
				A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('title')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('ギャラリー')
							])),
					$elm$core$List$reverse(
						$author$project$Page$Home$viewGalleryItems(
							$elm$core$List$reverse(
								$author$project$Page$Home$toGalleryMenuItems(model))))))
			]));
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Page$Home$viewBanner = function (banner) {
	return A3(
		$elm$html$Html$Keyed$node,
		'div',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('banner')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2(
				banner.c,
				A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(banner.fE)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(banner.C)
								]),
							_List_Nil)
						])))
			]));
};
var $author$project$Page$Home$viewInfo = function (model) {
	var banners = model.aw;
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('section banners')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('banners-container'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('start-animation', !model.aR)
									]))
							]),
						A2($elm$core$List$map, $author$project$Page$Home$viewBanner, banners))
					]))
			]));
};
var $author$project$Page$Home$DownlodMovie = function (a) {
	return {$: 4, a: a};
};
var $basti1302$elm_human_readable_filesize$Filesize$Base10 = 0;
var $basti1302$elm_human_readable_filesize$Filesize$defaultSettings = {cY: 2, bR: '.', bB: 0};
var $basti1302$elm_human_readable_filesize$Filesize$base10UnitList = _List_fromArray(
	[
		{t: 'B', q: 1},
		{t: 'kB', q: 1000},
		{t: 'MB', q: 1000000},
		{t: 'GB', q: 1000000000},
		{t: 'TB', q: 1000000000000},
		{t: 'PB', q: 1000000000000000},
		{t: 'EB', q: 1000000000000000000}
	]);
var $basti1302$elm_human_readable_filesize$Filesize$base2UnitList = _List_fromArray(
	[
		{t: 'B', q: 1},
		{t: 'KiB', q: 1024},
		{t: 'MiB', q: 1048576},
		{t: 'GiB', q: 1073741824},
		{t: 'TiB', q: 1099511627776},
		{t: 'PiB', q: 1125899906842624}
	]);
var $basti1302$elm_human_readable_filesize$Filesize$getUnitDefinitionList = function (units) {
	if (!units) {
		return $basti1302$elm_human_readable_filesize$Filesize$base10UnitList;
	} else {
		return $basti1302$elm_human_readable_filesize$Filesize$base2UnitList;
	}
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {a0: index, gy: match, gC: number, gX: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{fV: false, gB: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $basti1302$elm_human_readable_filesize$Filesize$decimalSeparatorRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\.'));
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$floor = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('0' === _v0.a.a) {
					var _v1 = _v0.a;
					var rest = _v1.b;
					return signed && A2(
						$elm$core$List$any,
						$elm$core$Basics$neq('0'),
						$elm$core$String$toList(rest));
				} else {
					return signed;
				}
			}
		}));
var $basti1302$elm_human_readable_filesize$Filesize$removeTrailingZeroesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^(\\d+\\.[^0]*)(0+)$'));
var $elm$regex$Regex$replaceAtMost = _Regex_replaceAtMost;
var $basti1302$elm_human_readable_filesize$Filesize$roundToDecimalPlaces = F2(
	function (settings, num) {
		var rounded = A2($myrho$elm_round$Round$floor, settings.cY, num);
		var withoutTrailingZeroes = A4(
			$elm$regex$Regex$replaceAtMost,
			1,
			$basti1302$elm_human_readable_filesize$Filesize$removeTrailingZeroesRegex,
			function (_v1) {
				var submatches = _v1.gX;
				return A2(
					$elm$core$String$join,
					'',
					A2(
						$elm$core$List$map,
						$elm$core$Maybe$withDefault(''),
						A2($elm$core$List$take, 1, submatches)));
			},
			rounded);
		var withoutTrailingDot = A2($elm$core$String$endsWith, '.', withoutTrailingZeroes) ? A2($elm$core$String$dropRight, 1, withoutTrailingZeroes) : withoutTrailingZeroes;
		return (settings.bR === '.') ? withoutTrailingDot : A4(
			$elm$regex$Regex$replaceAtMost,
			1,
			$basti1302$elm_human_readable_filesize$Filesize$decimalSeparatorRegex,
			function (_v0) {
				return settings.bR;
			},
			withoutTrailingDot);
	});
var $basti1302$elm_human_readable_filesize$Filesize$unknownUnit = {t: '?', q: 1};
var $basti1302$elm_human_readable_filesize$Filesize$formatWithSplit = F2(
	function (settings, num) {
		if (!num) {
			return _Utils_Tuple2('0', 'B');
		} else {
			var unitDefinitionList = $basti1302$elm_human_readable_filesize$Filesize$getUnitDefinitionList(settings.bB);
			var _v0 = (num < 0) ? _Utils_Tuple2(-num, '-') : _Utils_Tuple2(num, '');
			var num2 = _v0.a;
			var negativePrefix = _v0.b;
			var unitDefinition = A2(
				$elm$core$Maybe$withDefault,
				$basti1302$elm_human_readable_filesize$Filesize$unknownUnit,
				$elm$core$List$head(
					$elm$core$List$reverse(
						A2(
							$elm$core$List$filter,
							function (unitDef) {
								return _Utils_cmp(num2, unitDef.q) > -1;
							},
							unitDefinitionList))));
			var formattedNumber = A2($basti1302$elm_human_readable_filesize$Filesize$roundToDecimalPlaces, settings, num2 / unitDefinition.q);
			return _Utils_Tuple2(
				_Utils_ap(negativePrefix, formattedNumber),
				unitDefinition.t);
		}
	});
var $basti1302$elm_human_readable_filesize$Filesize$format = function (num) {
	var _v0 = A2($basti1302$elm_human_readable_filesize$Filesize$formatWithSplit, $basti1302$elm_human_readable_filesize$Filesize$defaultSettings, num);
	var size = _v0.a;
	var unit = _v0.b;
	return size + (' ' + unit);
};
var $author$project$Page$Home$viewMovieItem = function (movie) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tile is-ancestor')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tile is-parent')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$article,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tile is-child box'),
								A2($elm$html$Html$Attributes$style, 'background', 'linear-gradient(0.25turn, rgba(0,0,0,0.1), rgba(255,255,255,1), rgba(0,0,0,0.1)), url(' + (movie.fm + ' )')),
								A2($elm$html$Html$Attributes$style, 'background-size', 'cover'),
								A2($elm$html$Html$Attributes$style, 'background-position', 'center center')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('title is-4')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(movie.fb)
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('button'),
										$elm$html$Html$Events$onClick(
										$author$project$Page$Home$DownlodMovie(movie.c))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('icon')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('far fa-file-video')
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('ダウンロード'),
												$elm$html$Html$text(' ('),
												$elm$html$Html$text(
												$basti1302$elm_human_readable_filesize$Filesize$format(movie.cH)),
												$elm$html$Html$text(')')
											]))
									])),
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('help')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(movie.fF),
										$elm$html$Html$text('まで')
									]))
							]))
					]))
			]));
};
var $author$project$Page$Home$viewMovie = function (model) {
	var g = $elm$core$List$head(
		$author$project$Page$Home$toGalleryMenuItems(model));
	if (g.$ === 1) {
		return $elm$html$Html$text('');
	} else {
		var item = g.a;
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('section gallery')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container')
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('ムービー')
								])),
						A2($elm$core$List$map, $author$project$Page$Home$viewMovieItem, model.bn)))
				]));
	}
};
var $author$project$Page$Home$view = function (model) {
	return {
		f0: A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container')
				]),
			_List_fromArray(
				[
					$author$project$Page$Home$viewInfo(model),
					function () {
					var _v0 = model.bn;
					if (!_v0.b) {
						return $elm$html$Html$text('');
					} else {
						return $author$project$Page$Home$viewMovie(model);
					}
				}(),
					$author$project$Page$Home$viewGallery(model),
					function () {
					var _v1 = model.bi;
					if (_v1.$ === 1) {
						return $elm$html$Html$text('');
					} else {
						var status = _v1.a;
						return $author$project$Page$Home$viewDownloadMovieStatus(status);
					}
				}()
				])),
		fw: ''
	};
};
var $author$project$Page$Login$SubmittedForm = {$: 1};
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Page$Login$EnteredEmail = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Page$Login$viewForm = function (form) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('')
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$input,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder('メールアドレス'),
									$elm$html$Html$Events$onInput(
									$author$project$Page$Login$EnteredEmail(form)),
									$elm$html$Html$Attributes$value(form.bU),
									$elm$html$Html$Attributes$class('input'),
									A2($elm$html$Html$Attributes$attribute, 'autocomplete', 'Email')
								]),
							_List_Nil)
						]))
				]))
		]);
};
var $author$project$Page$Login$viewGuideText = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('has-text-left')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('ご登録済のメールアドレスをご入力いただくと、'),
						$elm$html$Html$text('マイページへのログインURLをお送りいたします。'),
						$elm$html$Html$text('マイページでは撮影データ、ご予約確認、'),
						$elm$html$Html$text('お客様情報の変更等が可能です。'),
						$elm$html$Html$text('メールボックスを確認してください！')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('has-text-left')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('※受信されたメール内のリンク有効期間は24時間です。')
					]))
			]))
	]);
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Page$Login$viewMessage = function (message) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('is-success')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(message)
			]));
};
var $author$project$Page$Login$viewProblem = function (problem) {
	var errorMessage = function () {
		if (!problem.$) {
			var str = problem.b;
			return str;
		} else {
			var str = problem.a;
			return str;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('is-danger')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(errorMessage)
			]));
};
var $author$project$Page$Login$view = function (model) {
	return {
		f0: function () {
			var _v0 = model.a$;
			if (!_v0.$) {
				var form = _v0.a;
				return A2(
					$elm$html$Html$form,
					_List_fromArray(
						[
							$elm$html$Html$Events$onSubmit($author$project$Page$Login$SubmittedForm)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal is-active')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-background')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-card')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$header,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-card-head')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('modal-card-title')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('マイページログイン')
														]))
												])),
											A2(
											$elm$html$Html$section,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-card-body')
												]),
											_Utils_ap(
												$author$project$Page$Login$viewGuideText,
												_Utils_ap(
													_List_fromArray(
														[
															A2(
															$elm$html$Html$ul,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('has-text-success')
																]),
															A2($elm$core$List$map, $author$project$Page$Login$viewMessage, model.bm))
														]),
													_Utils_ap(
														_List_fromArray(
															[
																A2(
																$elm$html$Html$ul,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('has-text-danger')
																	]),
																A2($elm$core$List$map, $author$project$Page$Login$viewProblem, model.aI))
															]),
														$author$project$Page$Login$viewForm(form))))),
											A2(
											$elm$html$Html$footer,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('modal-card-foot field is-grouped is-grouped-centered')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('control')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('button is-primary')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('ログインURLを送信')
																]))
														]))
												]))
										]))
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal is-active')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-background')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('notification is-success')
								]),
							A2($elm$core$List$map, $elm$html$Html$text, model.bm))
						]));
			}
		}(),
		fw: 'ログイン'
	};
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$NotFound$view = {
	f0: A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('content'),
				$elm$html$Html$Attributes$class('container'),
				$elm$html$Html$Attributes$tabindex(-1)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Not Found')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_Nil)
			])),
	fw: 'Page Not Found'
};
var $author$project$Page$Profile$AddFamilyMember = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Profile$Address1 = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Profile$Address1Required = {$: 6};
var $author$project$Page$Profile$Address2 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Profile$IsAcceptDm = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Profile$OnCheck = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Profile$OnInput = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Profile$PhoneNumber = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Profile$PostalCode = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Profile$PostalCodeRequired = {$: 2};
var $author$project$Page$Profile$Prefecture = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Profile$PrefectureTooLonger = {$: 5};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Page$Profile$hasErrors = F2(
	function (model, errors) {
		return A3(
			$elm$core$List$foldl,
			$elm$core$Basics$or,
			false,
			A2(
				$elm$core$List$map,
				function (v) {
					return A2($elm$core$List$member, v, model.dT);
				},
				errors));
	});
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$Page$Profile$prefectureSelector = F2(
	function (msg, prefecture) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('select')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput(msg)
						]),
					A2(
						$elm$core$List$map,
						function (p) {
							return A2(
								$elm$html$Html$option,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(p),
										$elm$html$Html$Attributes$selected(
										_Utils_eq(p, prefecture))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(p)
									]));
						},
						_List_fromArray(
							['都道府県を選択…', '東京都', '千葉県', '埼玉県', '神奈川県', '--------', '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県', '--------', '海外'])))
				]));
	});
var $author$project$Page$Profile$showErrorMessage = F3(
	function (errors, error, html) {
		return A2($elm$core$List$member, error, errors) ? html : $elm$html$Html$text('');
	});
var $author$project$Page$Profile$ClearErrors = {$: 2};
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, $elm$core$Set$empty, list, _List_Nil);
};
var $author$project$Page$Profile$takeErrorFamilyMemberLines = function (errors) {
	return $elm_community$list_extra$List$Extra$unique(
		A2(
			$elm$core$List$filterMap,
			function (e) {
				if (e.$ === 33) {
					var _v1 = e.a;
					var line = _v1.a;
					return $elm$core$Maybe$Just(line);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			errors));
};
var $author$project$Page$Profile$showErrors = function (errors) {
	if ($elm$core$List$isEmpty(errors)) {
		return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	} else {
		var messages = $elm$core$List$isEmpty(
			$author$project$Page$Profile$takeErrorFamilyMemberLines(errors)) ? _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('入力にエラーがあります。')
					])),
				A3(
				$author$project$Page$Profile$showErrorMessage,
				errors,
				$author$project$Page$Profile$RepresentativeIdRequired,
				A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('代表連絡先をご家族からお一人選択ください')
						])))
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('入力にエラーがあります。')
					])),
				A3(
				$author$project$Page$Profile$showErrorMessage,
				errors,
				$author$project$Page$Profile$RepresentativeIdRequired,
				A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('代表連絡先をご家族からお一人選択ください')
						]))),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('ご家族情報にエラーがあります。')
					]))
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('notification is-danger is-light')
				]),
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('delete'),
								$elm$html$Html$Events$onClick($author$project$Page$Profile$ClearErrors)
							]),
						_List_Nil)
					]),
				messages));
	}
};
var $author$project$Page$Profile$ClearMessages = {$: 3};
var $author$project$Page$Profile$showMessages = function (messages) {
	return $elm$core$List$isEmpty(messages) ? A2($elm$html$Html$div, _List_Nil, _List_Nil) : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('notification is-success is-light')
			]),
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('delete'),
							$elm$html$Html$Events$onClick($author$project$Page$Profile$ClearMessages)
						]),
					_List_Nil)
				]),
			A2(
				$elm$core$List$map,
				function (s) {
					return A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'style', 'white-space: pre-line;')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(s)
							]));
				},
				messages)));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Page$Profile$CancelForm = {$: 1};
var $author$project$Page$Profile$SaveCustomer = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Profile$viewControlPanel = function (c) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('field is-grouped is-grouped-right')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('control')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('button'),
								$elm$html$Html$Events$onClick($author$project$Page$Profile$CancelForm)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('キャンセル')
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('control')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('button is-link'),
								$elm$html$Html$Events$onClick(
								$author$project$Page$Profile$SaveCustomer(c))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('　更新　')
							]))
					]))
			]));
};
var $author$project$Page$Profile$Birthday = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$Profile$Emails = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Profile$FirstName = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$Profile$FirstNameFurigana = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$Profile$Height = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$Profile$HeightRequired = {$: 26};
var $author$project$Page$Profile$LastName = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Page$Profile$LastNameFurigana = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Page$Profile$Relationship = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Page$Profile$RepresentativeId = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Profile$ShoeSiseRequired = {$: 29};
var $author$project$Page$Profile$ShoeSize = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Page$Profile$ToggleShowFamilyMemberDetails = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Page$Profile$dateSelector = F6(
	function (msg, currentYear, label_, _v0, errorMessage, errorClass) {
		var yy = _v0.a;
		var mm = _v0.b;
		var dd = _v0.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field is-danger')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label required')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label_)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control is-expanded')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('select'),
									errorClass
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$select,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput(
											function (v) {
												return msg(
													_Utils_Tuple3(v, mm, dd));
											})
										]),
									A2(
										$elm$core$List$map,
										function (p) {
											return A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(p),
														$elm$html$Html$Attributes$selected(
														_Utils_eq(p, yy))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(p)
													]));
										},
										A2(
											$elm$core$List$cons,
											'----',
											A2(
												$elm$core$List$map,
												$elm$core$String$fromInt,
												$elm$core$List$reverse(
													A2($elm$core$List$range, currentYear - 120, currentYear))))))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('select'),
									errorClass
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$select,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput(
											function (v) {
												return msg(
													_Utils_Tuple3(yy, v, dd));
											})
										]),
									A2(
										$elm$core$List$map,
										function (p) {
											return A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(p),
														$elm$html$Html$Attributes$selected(
														_Utils_eq(p, mm))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(p)
													]));
										},
										A2(
											$elm$core$List$cons,
											'--',
											A2(
												$elm$core$List$map,
												A2($elm$core$String$padLeft, 2, '0'),
												A2(
													$elm$core$List$map,
													$elm$core$String$fromInt,
													A2($elm$core$List$range, 1, 12))))))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('select'),
									errorClass
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$select,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput(
											function (v) {
												return msg(
													_Utils_Tuple3(yy, mm, v));
											})
										]),
									A2(
										$elm$core$List$map,
										function (p) {
											return A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(p),
														$elm$html$Html$Attributes$selected(
														_Utils_eq(p, dd))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(p)
													]));
										},
										A2(
											$elm$core$List$cons,
											'--',
											A2(
												$elm$core$List$map,
												A2($elm$core$String$padLeft, 2, '0'),
												A2(
													$elm$core$List$map,
													$elm$core$String$fromInt,
													A2($elm$core$List$range, 1, 31))))))
								]))
						])),
					errorMessage
				]));
	});
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $author$project$Page$Profile$relationshipOption = function (value_) {
	var options_ = _List_fromArray(
		['本人', '妻', '夫', '長女', '長男', '次女', '次男', '三女', '三男', '四女', '四男', '父', '母', 'その他']);
	var options = A2(
		$elm$core$List$append,
		($elm$core$String$isEmpty(value_) || A2($elm$core$List$member, value_, options_)) ? _List_Nil : _List_fromArray(
			[value_]),
		options_);
	return A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value('')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('続柄を選択…')
					]))
			]),
		A2(
			$elm$core$List$map,
			function (v) {
				return A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value(v),
							$elm$html$Html$Attributes$selected(
							_Utils_eq(value_, v))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(v)
						]));
			},
			options));
};
var $author$project$Page$Profile$viewFamilyMemberInfo = F4(
	function (model, c, idx, m) {
		var sem = function (error) {
			return A2(
				$author$project$Page$Profile$showErrorMessage,
				model.dT,
				$author$project$Page$Profile$FamilyMembersHasError(
					_Utils_Tuple2(idx, error)));
		};
		var isDanger = function (v) {
			return A2(
				$author$project$Page$Profile$hasErrors,
				model,
				A2(
					$elm$core$List$map,
					function (e) {
						return $author$project$Page$Profile$FamilyMembersHasError(
							_Utils_Tuple2(idx, e));
					},
					v)) ? 'is-danger' : '';
		};
		var hasErrorLines = $elm$core$Set$fromList(
			$author$project$Page$Profile$takeErrorFamilyMemberLines(model.dT));
		var isShowDetails = A2($elm$core$Set$member, idx, hasErrorLines) || m.a9;
		var cyear = A2($elm$time$Time$toYear, model.cO, model.cu);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('card')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$header,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(' card-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('card-header-title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									_Utils_ap(m.a3, m.aZ))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('card-header-icon'),
									A2($elm$html$Html$Attributes$attribute, 'aria-label', 'show details'),
									$elm$html$Html$Events$onClick(
									A2($author$project$Page$Profile$ToggleShowFamilyMemberDetails, idx, c))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fas fa-angle-down'),
													A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true')
												]),
											_List_Nil)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('card-content'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('is-deactive', !isShowDetails)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('field-body')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('control')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('label required')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('お名前（姓）')
																])),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('input'),
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$value(m.a3),
																	$elm$html$Html$Attributes$class(
																	isDanger(
																		_List_fromArray(
																			[$author$project$Page$Profile$LastNameRequired, $author$project$Page$Profile$LastNameTooLonger]))),
																	$elm$html$Html$Events$onInput(
																	A2(
																		$elm$core$Basics$composeL,
																		$author$project$Page$Profile$OnInput,
																		$author$project$Page$Profile$LastName(idx)))
																]),
															_List_Nil),
															A2(
															sem,
															$author$project$Page$Profile$LastNameRequired,
															A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help is-danger')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('お名前（姓）は必須です')
																	]))),
															A2(
															sem,
															$author$project$Page$Profile$LastNameTooLonger,
															A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help is-danger')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('お名前（姓）は30文字までです')
																	])))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('control')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('label required')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('お名前（名）')
																])),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('input'),
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$value(m.aZ),
																	$elm$html$Html$Attributes$class(
																	isDanger(
																		_List_fromArray(
																			[$author$project$Page$Profile$FirstNameRequired, $author$project$Page$Profile$FirstNameTooLonger]))),
																	$elm$html$Html$Events$onInput(
																	A2(
																		$elm$core$Basics$composeL,
																		$author$project$Page$Profile$OnInput,
																		$author$project$Page$Profile$FirstName(idx)))
																]),
															_List_Nil),
															A2(
															sem,
															$author$project$Page$Profile$FirstNameRequired,
															A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help is-danger')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('お名前（名）は必須です')
																	]))),
															A2(
															sem,
															$author$project$Page$Profile$FirstNameTooLonger,
															A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help is-danger')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('お名前（名）は30文字までです')
																	])))
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('field-body')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('control')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('label required')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('フリガナ（姓）')
																])),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('input'),
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$value(m.a4),
																	$elm$html$Html$Attributes$class(
																	isDanger(
																		_List_fromArray(
																			[$author$project$Page$Profile$LastNameFuriganaRequired, $author$project$Page$Profile$LastNameFuriganaTooLonger]))),
																	$elm$html$Html$Events$onInput(
																	A2(
																		$elm$core$Basics$composeL,
																		$author$project$Page$Profile$OnInput,
																		$author$project$Page$Profile$LastNameFurigana(idx)))
																]),
															_List_Nil),
															A2(
															sem,
															$author$project$Page$Profile$LastNameFuriganaRequired,
															A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help is-danger')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('フリガナ（姓）は必須です')
																	]))),
															A2(
															sem,
															$author$project$Page$Profile$LastNameFuriganaTooLonger,
															A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help is-danger')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('フリガナ（姓）は30文字までです')
																	])))
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('control')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$label,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('label required')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('フリガナ（名）')
																])),
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('input'),
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$value(m.a_),
																	$elm$html$Html$Attributes$class(
																	isDanger(
																		_List_fromArray(
																			[$author$project$Page$Profile$FirstNameFuriganaRequired, $author$project$Page$Profile$FirstNameFuriganaTooLonger]))),
																	$elm$html$Html$Events$onInput(
																	A2(
																		$elm$core$Basics$composeL,
																		$author$project$Page$Profile$OnInput,
																		$author$project$Page$Profile$FirstNameFurigana(idx)))
																]),
															_List_Nil),
															A2(
															sem,
															$author$project$Page$Profile$FirstNameFuriganaRequired,
															A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help is-danger')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('フリガナ（名）は必須です')
																	]))),
															A2(
															sem,
															$author$project$Page$Profile$FirstNameFuriganaTooLonger,
															A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help is-danger')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('フリガナ（名）は30文字までです')
																	])))
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('control')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('label required')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('続柄')
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('content')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('select'),
															$elm$html$Html$Attributes$class(
															isDanger(
																_List_fromArray(
																	[$author$project$Page$Profile$RelationshipTooLonger, $author$project$Page$Profile$RelationshipRequired])))
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$select,
															_List_fromArray(
																[
																	$elm$html$Html$Events$onInput(
																	A2(
																		$elm$core$Basics$composeL,
																		$author$project$Page$Profile$OnInput,
																		$author$project$Page$Profile$Relationship(idx)))
																]),
															$author$project$Page$Profile$relationshipOption(m.cz))
														]))
												]))
										])),
									A2(
									sem,
									$author$project$Page$Profile$RelationshipTooLonger,
									A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('help is-danger')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('続柄は4文字までです。')
											]))),
									A2(
									sem,
									$author$project$Page$Profile$RelationshipRequired,
									A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('help is-danger')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('続柄は必須です。')
											])))
								])),
							$author$project$Page$Profile$isChild(m) ? A6(
							$author$project$Page$Profile$dateSelector,
							A2(
								$elm$core$Basics$composeL,
								$author$project$Page$Profile$OnInput,
								$author$project$Page$Profile$Birthday(idx)),
							cyear,
							'生年月日',
							_Utils_Tuple3(m.az, m.ay, m.ax),
							A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										sem,
										$author$project$Page$Profile$BirthdayParseError,
										A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('help is-danger')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('日付が正しくありません。')
												]))),
										A2(
										sem,
										$author$project$Page$Profile$BirthdayRequired,
										A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('help is-danger')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('生年月日は必須入力です。')
												])))
									])),
							$elm$html$Html$Attributes$class(
								isDanger(
									_List_fromArray(
										[$author$project$Page$Profile$BirthdayParseError, $author$project$Page$Profile$BirthdayRequired])))) : $elm$html$Html$text(''),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('control')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$label,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('radio')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('radio'),
															$elm$html$Html$Attributes$name('representativeId'),
															$elm$html$Html$Attributes$value(m.c),
															$elm$html$Html$Attributes$checked(
															_Utils_eq(m.c, c.cB)),
															$elm$html$Html$Events$onInput(
															A2($elm$core$Basics$composeL, $author$project$Page$Profile$OnInput, $author$project$Page$Profile$RepresentativeId))
														]),
													_List_Nil),
													$elm$html$Html$text(' 代表連絡先')
												]))
										])),
									A3(
									$author$project$Page$Profile$showErrorMessage,
									model.dT,
									$author$project$Page$Profile$RepresentativeIdRequired,
									A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('help is-danger')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('代表連絡先をご家族からお一人選択ください')
											])))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('label')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('メールアドレス')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('control  has-icons-left has-icons-right')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('input'),
													$elm$html$Html$Attributes$placeholder('your-email@example.com'),
													$elm$html$Html$Attributes$type_('email'),
													$elm$html$Html$Attributes$value(m.aY),
													$elm$html$Html$Events$onInput(
													A2(
														$elm$core$Basics$composeL,
														$author$project$Page$Profile$OnInput,
														$author$project$Page$Profile$Emails(idx)))
												]),
											_List_Nil),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon is-small is-left')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('fas fa-envelope')
														]),
													_List_Nil)
												]))
										])),
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('help is-info')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('複数のアドレスを , で区切って入力できます。')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('field-body')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('label required')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('身長(cm)')
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('control')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('input'),
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$value(m.b2),
																	$elm$html$Html$Events$onInput(
																	A2(
																		$elm$core$Basics$composeL,
																		$author$project$Page$Profile$OnInput,
																		$author$project$Page$Profile$Height(idx))),
																	$elm$html$Html$Attributes$class(
																	isDanger(
																		_List_fromArray(
																			[$author$project$Page$Profile$HeightRequired, $author$project$Page$Profile$HeightTooTaller, $author$project$Page$Profile$HeightInvalidFloatValue])))
																]),
															_List_Nil)
														])),
													A2(
													sem,
													$author$project$Page$Profile$HeightRequired,
													A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('help is-danger')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('身長は必須です。')
															]))),
													A2(
													sem,
													$author$project$Page$Profile$HeightTooTaller,
													A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('help is-danger')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('身長は272.0cmまでです。')
															]))),
													A2(
													sem,
													$author$project$Page$Profile$HeightInvalidFloatValue,
													A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('help is-danger')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('身長は数値を入力します。')
															])))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('label required')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('足のサイズ(cm)')
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('control')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$input,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('input'),
																	$elm$html$Html$Attributes$placeholder(''),
																	$elm$html$Html$Attributes$type_('text'),
																	$elm$html$Html$Attributes$value(m.cF),
																	$elm$html$Html$Events$onInput(
																	A2(
																		$elm$core$Basics$composeL,
																		$author$project$Page$Profile$OnInput,
																		$author$project$Page$Profile$ShoeSize(idx))),
																	$elm$html$Html$Attributes$class(
																	isDanger(
																		_List_fromArray(
																			[$author$project$Page$Profile$ShoeSiseRequired, $author$project$Page$Profile$ShoesizeTooBigger, $author$project$Page$Profile$ShoesizeTooSmaller, $author$project$Page$Profile$ShoeSiseInvalidFloatValue])))
																]),
															_List_Nil)
														])),
													A2(
													sem,
													$author$project$Page$Profile$ShoeSiseRequired,
													A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('help is-danger')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('足のサイズは必須です。')
															]))),
													A2(
													sem,
													$author$project$Page$Profile$ShoesizeTooSmaller,
													A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('help is-danger')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('足のサイズは8cm以上です。')
															]))),
													A2(
													sem,
													$author$project$Page$Profile$ShoesizeTooBigger,
													A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('help is-danger')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('足のサイズは30.0cmまでです。')
															]))),
													A2(
													sem,
													$author$project$Page$Profile$ShoeSiseInvalidFloatValue,
													A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('help is-danger')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('足のサイズは数値を入力します。')
															])))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Profile$view = function (model) {
	var sem = $author$project$Page$Profile$showErrorMessage(model.dT);
	var isDanger = function (v) {
		return A2($author$project$Page$Profile$hasErrors, model, v) ? 'is-danger' : '';
	};
	return {
		f0: function () {
			var _v0 = model.dG;
			switch (_v0.$) {
				case 2:
					var c = _v0.a;
					return A2(
						$elm$html$Html$section,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('section profile')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('container')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$h1,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('お客様情報')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('has-text-left')
											]),
										_List_fromArray(
											[
												$author$project$Page$Profile$showErrors(model.dT),
												$author$project$Page$Profile$showMessages(model.bm),
												A2(
												$elm$html$Html$h2,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('subtitle')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('ご家族情報')
													])),
												$author$project$Page$Profile$viewControlPanel(c),
												A2(
												$elm$html$Html$button,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button'),
														$elm$html$Html$Events$onClick(
														$author$project$Page$Profile$AddFamilyMember(c))
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('ご家族を追加')
															])),
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('icon')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$i,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('fas fa-plus')
																	]),
																_List_Nil)
															]))
													])),
												A2(
												$elm$html$Html$fieldset,
												_List_Nil,
												A2(
													$elm$core$List$indexedMap,
													A2($author$project$Page$Profile$viewFamilyMemberInfo, model, c),
													c.dW)),
												A2(
												$elm$html$Html$fieldset,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$h2,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('subtitle')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('ご世帯情報')
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('label required')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('電話番号')
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('control has-icons-left has-icons-right')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('input'),
																				$elm$html$Html$Attributes$placeholder('0362049860'),
																				$elm$html$Html$Attributes$type_(''),
																				$elm$html$Html$Events$onInput(
																				A2($elm$core$Basics$composeL, $author$project$Page$Profile$OnInput, $author$project$Page$Profile$PhoneNumber)),
																				$elm$html$Html$Attributes$class(
																				isDanger(
																					_List_fromArray(
																						[$author$project$Page$Profile$PhoneNumberRequired, $author$project$Page$Profile$PhoneNumberTooLonger]))),
																				$elm$html$Html$Attributes$value(c.aq)
																			]),
																		_List_Nil),
																		A2(
																		$elm$html$Html$span,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('icon is-small is-left')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$i,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('fas fa-phone')
																					]),
																				_List_Nil)
																			]))
																	])),
																A2(
																sem,
																$author$project$Page$Profile$PhoneNumberRequired,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('電話番号は必須です')
																		]))),
																A2(
																sem,
																$author$project$Page$Profile$PhoneNumberTooLonger,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('電話番号は20文字までです')
																		])))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('label required')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('郵便番号')
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('control has-icons-left')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('input'),
																				$elm$html$Html$Attributes$placeholder('1040052'),
																				$elm$html$Html$Attributes$type_('text'),
																				$elm$html$Html$Events$onInput(
																				A2($elm$core$Basics$composeL, $author$project$Page$Profile$OnInput, $author$project$Page$Profile$PostalCode)),
																				$elm$html$Html$Attributes$value(c.cv)
																			]),
																		_List_Nil),
																		A2(
																		$elm$html$Html$span,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('icon is-small is-left')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('〒')
																			]))
																	])),
																A2(
																sem,
																$author$project$Page$Profile$PostalCodeRequired,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('郵便番号は必須です')
																		]))),
																A2(
																sem,
																$author$project$Page$Profile$PostalCodeTooLonger,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('郵便番号は8文字までです')
																		])))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('label required')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('都道府県')
																	])),
																A2(
																$author$project$Page$Profile$prefectureSelector,
																A2($elm$core$Basics$composeL, $author$project$Page$Profile$OnInput, $author$project$Page$Profile$Prefecture),
																c.cw),
																A2(
																sem,
																$author$project$Page$Profile$PrefectureRequired,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('都道府県名は必須です')
																		]))),
																A2(
																sem,
																$author$project$Page$Profile$PrefectureTooLonger,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('都道府県名は8文字までです')
																		])))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('label required')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('市区町村・番地')
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('control')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('input'),
																				$elm$html$Html$Attributes$placeholder(''),
																				$elm$html$Html$Attributes$type_('text'),
																				$elm$html$Html$Events$onInput(
																				A2($elm$core$Basics$composeL, $author$project$Page$Profile$OnInput, $author$project$Page$Profile$Address1)),
																				$elm$html$Html$Attributes$value(c.bF)
																			]),
																		_List_Nil)
																	])),
																A2(
																sem,
																$author$project$Page$Profile$Address1Required,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('市区町村・番地は必須です')
																		]))),
																A2(
																sem,
																$author$project$Page$Profile$Address1TooLonger,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('市区町村・番地は70文字までです')
																		])))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$label,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('label')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('ビル・マンション名')
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('control')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('input'),
																				$elm$html$Html$Attributes$placeholder(''),
																				$elm$html$Html$Attributes$type_('text'),
																				$elm$html$Html$Events$onInput(
																				A2($elm$core$Basics$composeL, $author$project$Page$Profile$OnInput, $author$project$Page$Profile$Address2)),
																				$elm$html$Html$Attributes$value(c.bG)
																			]),
																		_List_Nil)
																	])),
																A2(
																sem,
																$author$project$Page$Profile$Address2TooLonger,
																A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('help is-danger')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('ビル・マンション名は70文字までです')
																		])))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('control')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$label,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('radio')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$input,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$type_('checkbox'),
																						$elm$html$Html$Attributes$checked(c.b7),
																						$elm$html$Html$Events$onCheck(
																						A2($elm$core$Basics$composeL, $author$project$Page$Profile$OnCheck, $author$project$Page$Profile$IsAcceptDm))
																					]),
																				_List_Nil),
																				$elm$html$Html$text(' DMを希望する')
																			]))
																	]))
															]))
													])),
												$author$project$Page$Profile$viewControlPanel(c)
											]))
									]))
							]));
				case 3:
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal is-active')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-background')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-card')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$section,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-card-body')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('notification is-danger is-light')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon is-large')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$i,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('fas fa-exclamation-circle')
																			]),
																		_List_Nil)
																	])),
																$elm$html$Html$text('サーバーでエラーが発生しました。')
															])),
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('ご迷惑をおかけします。')
															])),
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('しばらくお時間をおいて再度ご操作をお願いいたします。')
															]))
													]))
											]))
									]))
							]));
				default:
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('')
							]));
			}
		}(),
		fw: 'お客様情報'
	};
};
var $author$project$Page$Reservation$AddReservation = {$: 3};
var $author$project$Page$Reservation$ConfirmChangeSchedule = {$: 33};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Page$Reservation$ChoiceDate = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Reservation$ChoiceMonth = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Reservation$ChoiceTimeslot = function (a) {
	return {$: 17, a: a};
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Page$Reservation$isHidden = F2(
	function (pastSteps, step) {
		return $elm$html$Html$Attributes$class(
			A2($elm$core$List$member, step, pastSteps) ? '' : 'is-hidden');
	});
var $author$project$Page$Reservation$splitEvery = F2(
	function (n, list) {
		var _v0 = A2($elm_community$list_extra$List$Extra$splitAt, n, list);
		var first = _v0.a;
		var rest = _v0.b;
		if (!first.b) {
			return _List_Nil;
		} else {
			return A2(
				$elm$core$List$cons,
				first,
				A2($author$project$Page$Reservation$splitEvery, n, rest));
		}
	});
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Page$Reservation$calendar = function (model) {
	var rf = model.b;
	var monthScheules = function () {
		var _v10 = model.b.g.Q;
		if (!_v10.$) {
			var studio = _v10.a;
			return studio.at;
		} else {
			return _List_Nil;
		}
	}();
	var selectedMonthSchedule = function () {
		var _v9 = model.b.g.aj;
		if (!_v9.$) {
			var v = _v9.a;
			return $elm$core$Maybe$Just(v);
		} else {
			return $elm$core$List$head(monthScheules);
		}
	}();
	if (selectedMonthSchedule.$ === 1) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon is-large')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fas fa-spinner fa-spin')
								]),
							_List_Nil)
						])),
					$elm$html$Html$text('予約枠を検索中...')
				]));
	} else {
		var schedules = selectedMonthSchedule.a;
		var splitWeeks = function (s) {
			var _v8 = $elm$core$List$head(s);
			if (_v8.$ === 1) {
				return _List_Nil;
			} else {
				var firstDay = _v8.a;
				var dayOfWeek = firstDay.bQ;
				var days = $elm$core$List$length(s) + dayOfWeek;
				var endDays = A2(
					$elm$core$Basics$modBy,
					7,
					7 - A2($elm$core$Basics$modBy, 7, days));
				return A2(
					$author$project$Page$Reservation$splitEvery,
					7,
					$elm$core$List$concat(
						_List_fromArray(
							[
								A2($elm$core$List$repeat, dayOfWeek, $elm$core$Maybe$Nothing),
								A2($elm$core$List$map, $elm$core$Maybe$Just, s),
								A2($elm$core$List$repeat, endDays, $elm$core$Maybe$Nothing)
							])));
			}
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('calendar'),
					A2(
					$author$project$Page$Reservation$isHidden,
					_List_fromArray(
						[12, 6, 7, 8, 9]),
					model.e)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('month-list fbstart has-text-link')
						]),
					A2(
						$elm$core$List$map,
						function (v) {
							return A2(
								$elm$html$Html$li,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('month-list__item'),
										A2($elm$html$Html$Attributes$attribute, 'data-index', '0'),
										A2($elm$html$Html$Attributes$attribute, 'style', 'margin-top: 0px;margin-right: 16px'),
										$elm$html$Html$Events$onClick(
										$author$project$Page$Reservation$ChoiceMonth(v))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(v.fd)
									]));
						},
						monthScheules)),
					A2(
					$elm$html$Html$h3,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('ScheduleCalendar'),
							$elm$html$Html$Attributes$class('subtitle')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(schedules.fd)
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('schedule')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tbody,
							_List_Nil,
							_Utils_ap(
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('日')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('月')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('火')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('水')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('木')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('金')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('土')
													]))
											]))
									]),
								A2(
									$elm$core$List$map,
									function (week) {
										return A2(
											$elm$html$Html$tr,
											_List_Nil,
											A2(
												$elm$core$List$map,
												function (day) {
													if (day.$ === 1) {
														return A2(
															$elm$html$Html$td,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('disabled')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(' ')
																]));
													} else {
														var d = day.a;
														var isSelected = $elm$html$Html$Attributes$class(
															_Utils_eq(
																$elm$core$Maybe$Just(d),
																model.b.g.A) ? 'selected' : '');
														var isHoliday = function () {
															var _v6 = d.eh;
															if (_v6) {
																return $elm$html$Html$Attributes$class('holiday');
															} else {
																return $elm$html$Html$Attributes$class('');
															}
														}();
														var isDisabled = function () {
															var _v5 = d.fv;
															if (!_v5.b) {
																return $elm$html$Html$Attributes$class('disabled');
															} else {
																return $elm$html$Html$Attributes$class('');
															}
														}();
														var isAllReserved = A2(
															$elm$core$List$all,
															function ($) {
																return $.ej;
															},
															d.fv);
														var status = function () {
															var _v3 = d.fv;
															if (!_v3.b) {
																return '-';
															} else {
																if (!isAllReserved) {
																	return '◯';
																} else {
																	return '×';
																}
															}
														}();
														var date = $elm$core$String$fromInt(
															A2($elm$time$Time$toDay, $elm$time$Time$utc, d.a8));
														return A2(
															$elm$html$Html$td,
															_List_fromArray(
																[
																	isDisabled,
																	isHoliday,
																	isSelected,
																	function () {
																	if (status === '◯') {
																		return $elm$html$Html$Events$onClick(
																			$author$project$Page$Reservation$ChoiceDate(d));
																	} else {
																		return $elm$html$Html$Attributes$class('');
																	}
																}()
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('day')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text(date)
																		])),
																	A2(
																	$elm$html$Html$p,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('status')
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text(status)
																		]))
																]));
													}
												},
												week));
									},
									splitWeeks(schedules.at))))
						])),
					function () {
					var _v7 = model.b.g.A;
					if (_v7.$ === 1) {
						return $elm$html$Html$text('');
					} else {
						var d = _v7.a;
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('ScheduleTimeslot'),
									$elm$html$Html$Attributes$class('time_table'),
									A2(
									$author$project$Page$Reservation$isHidden,
									_List_fromArray(
										[12, 8, 9]),
									model.e)
								]),
							A2(
								$elm$core$List$indexedMap,
								F2(
									function (idx, timeslot) {
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('t_wrap'),
													$elm$html$Html$Attributes$class(
													(!idx) ? '' : 'clear'),
													$elm$html$Html$Attributes$class(
													_Utils_eq(
														$elm$core$Maybe$Just(timeslot),
														model.b.g.v) ? 'selected' : ''),
													timeslot.ej ? $elm$html$Html$Attributes$class('') : $elm$html$Html$Events$onClick(
													$author$project$Page$Reservation$ChoiceTimeslot(timeslot))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('time')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($elm$core$String$dropRight, 3, timeslot.bb))
														])),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('status'),
															$elm$html$Html$Attributes$class(
															timeslot.ej ? 'ng' : 'ok')
														]),
													_List_Nil)
												]));
									}),
								d.fv));
					}
				}()
				]));
	}
};
var $author$project$Page$Reservation$ChangeSchedule = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Reservation$ConfirmCancelReservation = function (a) {
	return {$: 32, a: a};
};
var $author$project$Page$Reservation$regex = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString(str));
};
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $author$project$Page$Reservation$addThousandsDelimiter = function (word) {
	return A3(
		$elm$regex$Regex$replace,
		$author$project$Page$Reservation$regex('(\\d)(?=(\\d{3})+(?!\\d))'),
		function (_v0) {
			var match = _v0.gy;
			return match + ',';
		},
		word);
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $author$project$Page$Reservation$formatPosix = function (posix) {
	var toString = $elm$core$String$fromInt;
	var toIntMonth = function (m) {
		switch (m) {
			case 0:
				return 1;
			case 1:
				return 2;
			case 2:
				return 3;
			case 3:
				return 4;
			case 4:
				return 5;
			case 5:
				return 6;
			case 6:
				return 7;
			case 7:
				return 8;
			case 8:
				return 9;
			case 9:
				return 10;
			case 10:
				return 11;
			default:
				return 12;
		}
	};
	var time = posix;
	var weekday = A2($elm$time$Time$toWeekday, $elm$time$Time$utc, time);
	var weekdayString = function () {
		switch (weekday) {
			case 0:
				return '（月）';
			case 1:
				return '（火）';
			case 2:
				return '（水）';
			case 3:
				return '（木）';
			case 4:
				return '（金）';
			case 5:
				return '（土）';
			default:
				return '（日）';
		}
	}();
	var year = A2($elm$time$Time$toYear, $elm$time$Time$utc, time);
	var month = toIntMonth(
		A2($elm$time$Time$toMonth, $elm$time$Time$utc, time));
	var day = A2($elm$time$Time$toDay, $elm$time$Time$utc, time);
	var dateString = toString(year) + (' 年 ' + (toString(month) + (' 月 ' + (toString(day) + ' 日 '))));
	return _Utils_ap(dateString, weekdayString);
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Page$Reservation$totalPrice = function (r) {
	var values = function (option) {
		return A2(
			$elm$core$List$map,
			$elm$core$Maybe$withDefault(''),
			A2(
				$elm$core$List$map,
				$elm$core$List$head,
				A2(
					$elm$core$List$map,
					$elm$core$String$split('@'),
					$elm$core$String$lines(option))));
	};
	var planPrice = r.eP.fe.cx;
	var lookupPrice = function (option) {
		return $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function (o) {
					return A2($elm$core$Maybe$withDefault, 0, o.fC);
				},
				A2(
					$elm$core$List$filter,
					function (o) {
						return A2(
							$elm$core$List$member,
							o.i,
							values(option.be));
					},
					option.eQ.eI)));
	};
	var optionPrices = A3(
		$elm$core$List$foldl,
		F2(
			function (poForm, acc) {
				var optionPrice = lookupPrice(poForm);
				return optionPrice + acc;
			}),
		0,
		r.eP.eR);
	var clothesPrice = 0;
	return (planPrice + optionPrices) + clothesPrice;
};
var $elm$html$Html$caption = _VirtualDom_node('caption');
var $author$project$Page$Reservation$viewOptionPrices = function (options) {
	var values = function (option) {
		return A2(
			$elm$core$List$map,
			$elm$core$Maybe$withDefault(''),
			A2(
				$elm$core$List$map,
				$elm$core$List$head,
				A2(
					$elm$core$List$map,
					$elm$core$String$split('@'),
					$elm$core$String$lines(option))));
	};
	var lookupOption = function (option) {
		return A2(
			$elm$core$List$filter,
			function (o) {
				return A2(
					$elm$core$List$member,
					o.i,
					values(option.be));
			},
			option.eQ.eI);
	};
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table is-narrow'),
					A2($elm$html$Html$Attributes$attribute, 'style', 'margin-top: 1em;')
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A2(
							$elm$html$Html$caption,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('heading')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$strong,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('オプション')
										]))
								]))
						]),
						A2(
						$elm$core$List$map,
						function (_v0) {
							var name = _v0.a;
							var optionItems = _v0.b;
							return A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(name),
												$elm$html$Html$text(': '),
												$elm$html$Html$text(
												A2(
													$elm$core$String$join,
													', ',
													A2(
														$elm$core$List$map,
														function ($) {
															return $.i;
														},
														optionItems)))
											]))
									]));
						},
						A2(
							$elm$core$List$map,
							function (o) {
								return _Utils_Tuple2(
									o.eQ.aV,
									lookupOption(o));
							},
							options))
					])))
		]);
};
var $author$project$Page$Reservation$viewReservationInfo = F3(
	function (model, r, step) {
		if (r.$ === 1) {
			return $elm$html$Html$text('予約情報が未入力です');
		} else {
			var reservation = r.a;
			var showAllowedEditButton = function (button) {
				return (step === 11) ? $elm$html$Html$text('') : button;
			};
			var reservationShopAndStudio = function (reservationSchedule) {
				var studioName = reservation.fq;
				var shopName = reservation.fi;
				return $elm$core$String$concat(
					_List_fromArray(
						[shopName, ' ', studioName]));
			};
			var reservationPlanText = function (reservationPlan) {
				var planCategory = reservation.eP.fe.bK;
				var plan = reservation.eP.fe.i;
				return _List_fromArray(
					[
						$elm$html$Html$text(planCategory),
						A2($elm$html$Html$br, _List_Nil, _List_Nil),
						$elm$html$Html$text(plan)
					]);
			};
			var reservationDatetime = function () {
				var startTime = A2($elm$core$String$dropRight, 3, reservation.bb);
				return $elm$core$String$concat(
					_List_fromArray(
						[
							$author$project$Page$Reservation$formatPosix(reservation.a8),
							startTime
						]));
			}();
			var isUpdateMode = model.aG === 1;
			var isEditable = reservation.eg;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('reservation')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('panel-heading')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('ご予約情報')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('panel-block')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('level')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('level-left')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('level-item')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('heading')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$strong,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$elm$html$Html$text('ご予約日時')
																			]))
																	])),
																A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('subtitle')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(reservationDatetime)
																	]))
															]))
													]))
											])),
										showAllowedEditButton(
										A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('level-right')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('level-item')
														]),
													_List_fromArray(
														[
															isUpdateMode ? (isEditable ? A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('button is-info'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Page$Reservation$ChangeSchedule(reservation))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('変更する ≫')
																])) : $elm$html$Html$text('')) : A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('button is-info'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Page$Reservation$ChoiceItem(5))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('変更する ≫')
																]))
														]))
												])))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('panel-block')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('level')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('level-left')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('level-item')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('heading')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$strong,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$elm$html$Html$text('店舗')
																			]))
																	])),
																A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('subtitle')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(
																		reservationShopAndStudio(reservation))
																	]))
															]))
													]))
											])),
										showAllowedEditButton(
										A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('level-right')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('level-item')
														]),
													_List_fromArray(
														[
															isUpdateMode ? $elm$html$Html$text('') : A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('button is-info'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Page$Reservation$ChoiceItem(3))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('変更する ≫')
																]))
														]))
												])))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('panel-block')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('level')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('level-left')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('level-item')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_Nil,
														$elm$core$List$concat(
															_List_fromArray(
																[
																	_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$p,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('heading')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$strong,
																				_List_Nil,
																				_List_fromArray(
																					[
																						$elm$html$Html$text('ご予約内容')
																					]))
																			])),
																		A2(
																		$elm$html$Html$p,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('subtitle')
																			]),
																		reservationPlanText(reservation))
																	]),
																	$author$project$Page$Reservation$viewOptionPrices(reservation.eP.eR)
																])))
													]))
											])),
										showAllowedEditButton(
										A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('level-right')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('level-item')
														]),
													_List_fromArray(
														[
															isUpdateMode ? $elm$html$Html$text('') : A2(
															$elm$html$Html$button,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('button is-info'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Page$Reservation$ChoiceItem(2))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('変更する ≫')
																]))
														]))
												])))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('panel-block')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('level')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('level-left')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('level-item')
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('level-right')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('level-item')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('heading')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('料金(税込)')
																	])),
																A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('title')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('¥ '),
																		$elm$html$Html$text(
																		$author$project$Page$Reservation$addThousandsDelimiter(
																			$elm$core$String$fromInt(
																				$author$project$Page$Reservation$totalPrice(reservation))))
																	]))
															]))
													]))
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('panel-block')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('level')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('level-left')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('level-item')
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('level-right')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('level-item')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('※各種割引・キャンペーンはこちらの金額に反映されておりません。')
																	])),
																A2(
																$elm$html$Html$p,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('help')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('※休日料金・シーズン料金は加算されておりません。')
																	]))
															]))
													]))
											]))
									]))
							])),
						isUpdateMode ? (isEditable ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('panel-block')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('level')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('level-left')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('level-item')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-danger'),
																$elm$html$Html$Events$onClick(
																$author$project$Page$Reservation$ConfirmCancelReservation(reservation))
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('この予約をキャンセルする')
															]))
													]))
											]))
									]))
							])) : $elm$html$Html$text('')) : $elm$html$Html$text('')
					]));
		}
	});
var $author$project$Page$Reservation$completeReservationSection = function (model) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('section complete'),
				$elm$html$Html$Attributes$class(
				A2(
					$elm$core$List$member,
					model.e,
					_List_fromArray(
						[11])) ? '' : 'is-hidden')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('subtitle')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('予約を確定いたしました')
					])),
				A2(
				$elm$html$Html$article,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('panel is-info')
					]),
				_List_fromArray(
					[
						A3($author$project$Page$Reservation$viewReservationInfo, model, model.R, model.e)
					]))
			]));
};
var $author$project$Page$Reservation$CancelDoDelete = {$: 4};
var $author$project$Page$Reservation$CommitDoDelete = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Reservation$confirmDeleteDialog = F2(
	function (model, reservation) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('is-active', model.a1)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-background')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('modal-card')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$header,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-card-head')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('modal-card-title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('予約キャンセル確認')
										]))
								])),
							A2(
							$elm$html$Html$section,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-card-body')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$p,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('予約をキャンセルしますか')
										]))
								])),
							A2(
							$elm$html$Html$footer,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('modal-card-foot')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button is-danger'),
											$elm$html$Html$Events$onClick(
											$author$project$Page$Reservation$CommitDoDelete(reservation))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('予約をキャンセルする')
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button'),
											$elm$html$Html$Events$onClick($author$project$Page$Reservation$CancelDoDelete)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('予約をキャンセルしない')
										]))
								]))
						]))
				]));
	});
var $author$project$Session$conversionQuery = function (session) {
	if (!session.$) {
		var g = session.c;
		return g.f2;
	} else {
		var g = session.b;
		return g.f2;
	}
};
var $author$project$Page$Reservation$AddFamilyMember = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Reservation$AdditionalComments = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Reservation$AgreeToPrivacyPolicyAndTerms = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Reservation$ConfirmReservation = {$: 34};
var $author$project$Page$Reservation$NoOp = {$: 0};
var $author$project$Page$Reservation$OnInput = function (a) {
	return {$: 36, a: a};
};
var $elm$html$Html$dd = _VirtualDom_node('dd');
var $author$project$Form$Checkbox$defaultOptions = function (msg) {
	return {
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cd: '',
		i: '',
		cj: function (_v0) {
			return msg;
		},
		cK: function (e) {
			return '';
		},
		be: false
	};
};
var $author$project$Form$TextArea$defaultOptions = function (msg) {
	return {
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cb: true,
		cd: '',
		i: '',
		cm: function (_v0) {
			return msg;
		},
		cy: false,
		cK: function (e) {
			return '';
		},
		be: ''
	};
};
var $elm$html$Html$dl = _VirtualDom_node('dl');
var $elm$html$Html$dt = _VirtualDom_node('dt');
var $author$project$Form$Message$showErrorHelpMessages = F3(
	function (stringFromError, errors, activeErrors) {
		var show = function (error) {
			return A2($elm$core$List$member, error, errors) ? A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('help is-danger')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						stringFromError(error))
					])) : $elm$html$Html$text('');
		};
		return A2($elm$core$List$map, show, activeErrors);
	});
var $author$project$Form$Message$showHelpMessages = function (messages) {
	var show = function (message) {
		return A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('help')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(message)
				]));
	};
	return A2($elm$core$List$map, show, messages);
};
var $author$project$Form$Checkbox$new = function (opt) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('field')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('control')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('checkbox'),
										$elm$html$Html$Attributes$name(opt.i),
										$elm$html$Html$Attributes$checked(opt.be),
										$elm$html$Html$Events$onCheck(opt.cj)
									]),
								_List_Nil),
								$elm$html$Html$text(opt.cd)
							]))
					]),
					$author$project$Form$Message$showHelpMessages(opt.b3),
					A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT)
				])));
};
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Form$TextArea$new = function (opt) {
	var hasErrors_ = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$or,
		false,
		A2(
			$elm$core$List$map,
			function (v) {
				return A2($elm$core$List$member, v, opt.dT);
			},
			opt.bZ));
	var isDanger = function (v) {
		return hasErrors_ ? 'is-danger' : '';
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('field')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('label')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(opt.cd)
							]))
					]),
					A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT),
					$author$project$Form$Message$showHelpMessages(opt.b3),
					_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('control')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$textarea,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('textarea'),
										$elm$html$Html$Events$onInput(opt.cm),
										$elm$html$Html$Attributes$class(
										isDanger(opt.dT)),
										$elm$html$Html$Attributes$value(opt.be),
										$elm$html$Html$Attributes$name(opt.i),
										$elm$html$Html$Attributes$readonly(opt.cy)
									]),
								_List_Nil)
							]))
					])
				])));
};
var $author$project$Page$Reservation$privacy_policy_text = '※お問い合わせ\n\n■組織の名称\n　有限会社和光\n\n■個人情報保護管理者の職名、所属及び連絡先\n　取締役　TEL: 03-6550-9911\n\n■個人情報の利用目的について\n当社は、以下の利用目的の範囲内でのみ、皆様から収集致しました個人情報を利用致します。\n（１）予約管理\n（２）予約いただいた件についてのご連絡（電話・メール）\n（３）商品のお渡し\n\n■個人情報の第三者提供及び委託について\n当社は、法令に定める場合を除き、事前に本人の同意を得ることなく、個人情報を第三者に提供することはありません。\nまた、当該情報の取扱いを委託することがございます。\n\n■個人情報の開示等について\n当社は、本人が自己の個人情報について、利用目的の通知・開示・訂正・追加・削除・利用停止・提供停止、第三者提供記録の開示のご希望がございましたら、下記「お問合せ窓口」にご連絡下さい。\n\n■個人情報提供の任意性及び知っておいて戴きたいこと。\n個人情報の提供は任意でございますが、お問合せに必要な個人情報をご提供いただけなかった場合は、質問に対するご回答を差し控えさせていただく場合がありますのでご了承頂きます様お願い申し上げます。\n\nお問合せ窓口\n有限会社和光\n個人情報保護管理者　取締役\n電話：03-6550-9911\n    ';
var $author$project$Page$Reservation$stringFromError = function (error) {
	switch (error.$) {
		case 0:
			var msg = error.a;
			return 'システムエラーが発生しました: ' + msg;
		case 1:
			var msg = error.a;
			return 'サーバーでエラーが発生しました: ' + msg;
		case 2:
			return '個人情報の取り扱い・利用規約に同意するにはチェックを入れてください';
		case 3:
			var _v1 = error.a;
			var index = _v1.a;
			return 'オプションにエラーがあります';
		case 6:
			var _v2 = error.a;
			var index = _v2.a;
			return 'オプションにエラーがあります';
		case 4:
			return 'この項目は必須です';
		case 7:
			return 'この項目は必須です';
		case 11:
			return 'メールアドレスは必須です';
		case 12:
			return 'お名前は必須です';
		case 13:
			return 'お名前は必須です';
		case 14:
			return 'フリガナは必須です';
		case 15:
			return 'フリガナは必須です';
		case 16:
			return '電話番号は必須です';
		case 17:
			return '電話番号は20文字までです';
		case 8:
			var _v3 = error.a;
			var idx = _v3.a;
			var err = _v3.b;
			return '';
		case 9:
			return '日付が正しくありません';
		case 10:
			return '時刻が正しくありません';
		default:
			var err = error.a;
			switch (err.$) {
				case 0:
					return '電話番号は必須です';
				case 1:
					return '電話番号は20文字までです';
				case 2:
					return '郵便番号は必須です';
				case 3:
					return '郵便番号は8文字までです';
				case 4:
					return '都道府県名は必須です';
				case 5:
					return '都道府県名は8文字までです';
				case 6:
					return '市区町村・番地は必須です';
				case 7:
					return '市区町村・番地は70文字までです';
				case 8:
					return 'ビル・マンション名は70文字までです';
				case 20:
					return 'お名前（姓）は必須です';
				case 21:
					return 'お名前（姓）は30文字までです';
				case 16:
					return 'お名前（名）は必須です';
				case 17:
					return 'お名前（名）は30文字までです';
				case 22:
					return 'フリガナ（姓）は必須です';
				case 23:
					return 'フリガナ（姓）は30文字までです';
				case 18:
					return 'フリガナ（名）は必須です';
				case 19:
					return 'フリガナ（名）は30文字までです';
				case 12:
					return '日付が正しくありません。';
				case 13:
					return '生年月日は必須入力です。';
				case 26:
					return '身長は必須です。';
				case 28:
					return '身長は272.0cmまでです。';
				case 27:
					return '身長は数値を入力します。';
				case 29:
					return '足のサイズは必須です。';
				case 31:
					return '足のサイズは8cm以上です。';
				case 32:
					return '足のサイズは30.0cmまでです。';
				case 30:
					return '足のサイズは数値を入力します。';
				case 24:
					return '続柄は必須です。';
				case 25:
					return '続柄は4文字までです。';
				case 33:
					var _v5 = err.a;
					var line = _v5.a;
					var e = _v5.b;
					return $author$project$Page$Reservation$stringFromError(
						$author$project$Page$Reservation$CustomreFormHasError(e));
				default:
					return '';
			}
	}
};
var $author$project$Page$Reservation$showErrors = function (errors) {
	return $elm$core$List$isEmpty(errors) ? $elm$html$Html$text('') : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('notification is-danger is-light')
			]),
		A2(
			$elm$core$List$map,
			function (s) {
				return A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'style', 'white-space: pre-line;')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(s)
						]));
			},
			$elm$core$Set$toList(
				$elm$core$Set$fromList(
					A2(
						$elm$core$List$map,
						function (e) {
							switch (e.$) {
								case 0:
									return $author$project$Page$Reservation$stringFromError(e);
								case 1:
									return $author$project$Page$Reservation$stringFromError(e);
								default:
									return '入力にエラーがあります。';
							}
						},
						errors)))));
};
var $author$project$Page$Reservation$terms_and_conditions_text = '※予約のキャンセル・日程・プラン変更をご希望される場合は、予約日より【11日】前までにマイページ内の“予約確認”よりお手続きをお願い致します。\nご予約日より【10日】前以降の予約のキャンセル・日程・プラン変更等はお電話にてご連絡ください。\n※ご予約日より【10日】前を過ぎますと、キャンセル料が発生致します。\n人員配置の都合上のため、ご了承下さいませ。\n※詳しいご料金につきましては、\n下記リンクの《撮影プラン・よくある質問ページ》よりご確認下さい。\n《撮影プラン》https://mitulle.com/price.html\n《よくある質問》https://mitulle.com/faq.html\n※予約のキャンセル/変更をご希望される場合は、お手数をお掛けしますが、マイページより【11日】前までにお手続きください。\n期間を過ぎますとマイページからのキャンセルを承れません。\n    ';
var $author$project$Page$Reservation$Address1 = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Reservation$Address2 = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Reservation$CustomerForm = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Reservation$IsAcceptDm = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Reservation$PostalCode = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Reservation$Prefecture = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Reservation$customerFormErrorWraper = function (errors) {
	return A2($elm$core$List$map, $author$project$Page$Reservation$CustomreFormHasError, errors);
};
var $author$project$Form$TextField$defaultOptions = function (msg) {
	return {
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cb: true,
		cd: $elm$core$Maybe$Just(''),
		i: '',
		cm: function (_v0) {
			return msg;
		},
		cK: function (e) {
			return '';
		},
		be: ''
	};
};
var $author$project$Form$TextField$new = function (opt) {
	var required = function () {
		var _v0 = opt.cb;
		if (_v0) {
			return $elm$html$Html$Attributes$class('required');
		} else {
			return $elm$html$Html$Attributes$class('');
		}
	}();
	var hasErrors_ = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$or,
		false,
		A2(
			$elm$core$List$map,
			function (v) {
				return A2($elm$core$List$member, v, opt.dT);
			},
			opt.bZ));
	var isDanger = function (v) {
		return hasErrors_ ? 'is-danger' : '';
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('field')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$elm$html$Html$text(''),
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('label'),
											required
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(v)
										]));
							},
							opt.cd)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('control')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('input'),
										$elm$html$Html$Attributes$value(opt.be),
										$elm$html$Html$Attributes$name(opt.i),
										$elm$html$Html$Attributes$type_('text'),
										$elm$html$Html$Events$onInput(opt.cm),
										$elm$html$Html$Attributes$class(
										isDanger(opt.bZ))
									]),
								_List_Nil)
							]))
					]),
					$author$project$Form$Message$showHelpMessages(opt.b3),
					A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT)
				])));
};
var $author$project$Page$Reservation$prefectureSelector = F2(
	function (msg, prefecture) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('select')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							$elm$html$Html$Events$onInput(msg)
						]),
					A2(
						$elm$core$List$map,
						function (p) {
							return A2(
								$elm$html$Html$option,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(p),
										$elm$html$Html$Attributes$selected(
										_Utils_eq(p, prefecture))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(p)
									]));
						},
						_List_fromArray(
							['都道府県を選択…', '東京都', '千葉県', '埼玉県', '神奈川県', '--------', '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県', '--------', '海外'])))
				]));
	});
var $author$project$Form$TextField$withErrorMessages = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{bZ: e});
	});
var $author$project$Form$TextField$withErrors = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{dT: e});
	});
var $author$project$Form$Checkbox$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cd: s});
	});
var $author$project$Form$TextField$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{
				cd: $elm$core$Maybe$Just(s)
			});
	});
var $author$project$Form$Checkbox$withOnCheckHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cj: f});
	});
var $author$project$Form$TextField$withOnInputHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cm: f});
	});
var $author$project$Form$TextField$withRequired = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cb: s});
	});
var $author$project$Form$TextField$withStringFromError = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cK: f});
	});
var $author$project$Form$Checkbox$withValue = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{be: s});
	});
var $author$project$Form$TextField$withValue = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{be: s});
	});
var $author$project$Page$Reservation$wrapdOnInputHdl = F3(
	function (constructor, subConstructor, inputValue) {
		return $author$project$Page$Reservation$OnInput(
			constructor(
				subConstructor(inputValue)));
	});
var $author$project$Page$Reservation$viewCustomerExtendForm = function (model) {
	var textFieldOptions = A2(
		$author$project$Form$TextField$withErrors,
		model.dT,
		A2(
			$author$project$Form$TextField$withStringFromError,
			$author$project$Page$Reservation$stringFromError,
			$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp)));
	var c = model.dG;
	return _List_fromArray(
		[
			$author$project$Form$TextField$new(
			A2(
				$author$project$Form$TextField$withErrorMessages,
				$author$project$Page$Reservation$customerFormErrorWraper(
					_List_fromArray(
						[$author$project$Page$Profile$PostalCodeRequired, $author$project$Page$Profile$PostalCodeTooLonger])),
				A2(
					$author$project$Form$TextField$withRequired,
					false,
					A2(
						$author$project$Form$TextField$withValue,
						c.cv,
						A2(
							$author$project$Form$TextField$withOnInputHandler,
							A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$CustomerForm, $author$project$Page$Reservation$PostalCode),
							A2($author$project$Form$TextField$withLabel, '郵便番号', textFieldOptions)))))),
			A2(
			$author$project$Page$Reservation$prefectureSelector,
			A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$CustomerForm, $author$project$Page$Reservation$Prefecture),
			c.cw),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			A3(
				$author$project$Form$Message$showErrorHelpMessages,
				$author$project$Page$Reservation$stringFromError,
				$author$project$Page$Reservation$customerFormErrorWraper(
					_List_fromArray(
						[$author$project$Page$Profile$PrefectureRequired, $author$project$Page$Profile$PrefectureTooLonger])),
				model.dT)),
			$author$project$Form$TextField$new(
			A2(
				$author$project$Form$TextField$withErrorMessages,
				$author$project$Page$Reservation$customerFormErrorWraper(
					_List_fromArray(
						[$author$project$Page$Profile$Address1Required, $author$project$Page$Profile$Address1TooLonger])),
				A2(
					$author$project$Form$TextField$withRequired,
					false,
					A2(
						$author$project$Form$TextField$withValue,
						c.bF,
						A2(
							$author$project$Form$TextField$withOnInputHandler,
							A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$CustomerForm, $author$project$Page$Reservation$Address1),
							A2($author$project$Form$TextField$withLabel, '市区町村・番地', textFieldOptions)))))),
			$author$project$Form$TextField$new(
			A2(
				$author$project$Form$TextField$withErrorMessages,
				$author$project$Page$Reservation$customerFormErrorWraper(
					_List_fromArray(
						[$author$project$Page$Profile$Address2TooLonger])),
				A2(
					$author$project$Form$TextField$withRequired,
					false,
					A2(
						$author$project$Form$TextField$withValue,
						c.bG,
						A2(
							$author$project$Form$TextField$withOnInputHandler,
							A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$CustomerForm, $author$project$Page$Reservation$Address2),
							A2($author$project$Form$TextField$withLabel, 'ビル・マンション名', textFieldOptions)))))),
			$author$project$Form$Checkbox$new(
			A2(
				$author$project$Form$Checkbox$withValue,
				c.b7,
				A2(
					$author$project$Form$Checkbox$withOnCheckHandler,
					A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$CustomerForm, $author$project$Page$Reservation$IsAcceptDm),
					A2(
						$author$project$Form$Checkbox$withLabel,
						'DMを希望する',
						$author$project$Form$Checkbox$defaultOptions($author$project$Page$Reservation$NoOp)))))
		]);
};
var $author$project$Page$Reservation$Emails = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Reservation$FirstName = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Reservation$FirstNameFurigana = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Reservation$HasUsedBefore = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Page$Reservation$LastName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Reservation$LastNameFurigana = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Reservation$PhoneNumber = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Reservation$Representative = function (a) {
	return {$: 6, a: a};
};
var $author$project$Form$RadioList$defaultOptions = function (msg) {
	return {
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cb: true,
		cd: '',
		ex: msg,
		i: $elm$core$Maybe$Nothing,
		ci: _List_Nil,
		cj: function (_v0) {
			return function (_v1) {
				return msg;
			};
		},
		cK: function (e) {
			return '';
		},
		be: ''
	};
};
var $author$project$Page$Reservation$familyMemberFormErrorWraper = F2(
	function (idx, errors) {
		return $author$project$Page$Reservation$customerFormErrorWraper(
			A2(
				$elm$core$List$map,
				function (e) {
					return $author$project$Page$Profile$FamilyMembersHasError(
						_Utils_Tuple2(idx, e));
				},
				errors));
	});
var $author$project$Form$RadioButton$defaultOptions = function (msg) {
	return {
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cd: '',
		i: '',
		cj: function (_v0) {
			return msg;
		},
		cK: function (e) {
			return '';
		},
		be: false
	};
};
var $elm$html$Html$legend = _VirtualDom_node('legend');
var $author$project$Form$RadioButton$new = function (opt) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('field')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$elm$html$Html$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('control')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('radio'),
										$elm$html$Html$Attributes$name(opt.i),
										$elm$html$Html$Attributes$value(opt.cd),
										$elm$html$Html$Attributes$checked(opt.be),
										$elm$html$Html$Events$onCheck(opt.cj)
									]),
								_List_Nil),
								$elm$html$Html$text(opt.cd)
							]))
					]),
					$author$project$Form$Message$showHelpMessages(opt.b3),
					A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT)
				])));
};
var $author$project$Form$RadioButton$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cd: s});
	});
var $author$project$Form$RadioButton$withName = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{i: s});
	});
var $author$project$Form$RadioButton$withOnCheckHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cj: f});
	});
var $author$project$Form$RadioButton$withValue = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{be: s});
	});
var $author$project$Form$RadioList$new = function (opt) {
	var required = function () {
		var _v1 = opt.cb;
		if (_v1) {
			return $elm$html$Html$Attributes$class('required');
		} else {
			return $elm$html$Html$Attributes$class('');
		}
	}();
	var hasErrors_ = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$or,
		false,
		A2(
			$elm$core$List$map,
			function (v) {
				return A2($elm$core$List$member, v, opt.dT);
			},
			opt.bZ));
	var isDanger = $elm$html$Html$Attributes$class(
		hasErrors_ ? 'is-danger' : '');
	var checkboxOptions = $author$project$Form$RadioButton$defaultOptions(opt.ex);
	return A2(
		$elm$html$Html$fieldset,
		_List_fromArray(
			[isDanger]),
		_Utils_ap(
			_List_fromArray(
				[
					function () {
					var _v0 = opt.cd;
					if (_v0 === '') {
						return $elm$html$Html$text('');
					} else {
						return A2(
							$elm$html$Html$legend,
							_List_fromArray(
								[required]),
							_List_fromArray(
								[
									$elm$html$Html$text(opt.cd)
								]));
					}
				}()
				]),
			_Utils_ap(
				A2(
					$elm$core$List$map,
					function (name) {
						return $author$project$Form$RadioButton$new(
							A2(
								$author$project$Form$RadioButton$withValue,
								_Utils_eq(opt.be, name),
								A2(
									$author$project$Form$RadioButton$withOnCheckHandler,
									opt.cj(name),
									A2(
										$author$project$Form$RadioButton$withName,
										A2($elm$core$Maybe$withDefault, opt.cd, opt.i),
										A2($author$project$Form$RadioButton$withLabel, name, checkboxOptions)))));
					},
					opt.ci),
				_Utils_ap(
					$author$project$Form$Message$showHelpMessages(opt.b3),
					A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT)))));
};
var $author$project$Form$RadioList$withHelpMsgs = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{b3: s});
	});
var $author$project$Form$TextField$withHelpMsgs = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{b3: s});
	});
var $author$project$Form$RadioList$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cd: s});
	});
var $author$project$Form$RadioList$withNames = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{ci: s});
	});
var $author$project$Form$RadioList$withOnCheckHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cj: f});
	});
var $author$project$Form$RadioList$withRequired = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cb: s});
	});
var $author$project$Form$RadioList$withValue = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{be: s});
	});
var $author$project$Page$Reservation$viewCustomerForm = function (model) {
	var textFieldOptions = A2(
		$author$project$Form$TextField$withErrors,
		model.dT,
		A2(
			$author$project$Form$TextField$withStringFromError,
			$author$project$Page$Reservation$stringFromError,
			$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp)));
	var representative = $author$project$Page$Reservation$takeRepresentative(model);
	var ewraper = $author$project$Page$Reservation$familyMemberFormErrorWraper(0);
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field-body')
						]),
					_List_fromArray(
						[
							$author$project$Form$TextField$new(
							A2(
								$author$project$Form$TextField$withErrorMessages,
								ewraper(
									_List_fromArray(
										[$author$project$Page$Profile$LastNameRequired, $author$project$Page$Profile$LastNameTooLonger])),
								A2(
									$author$project$Form$TextField$withRequired,
									true,
									A2(
										$author$project$Form$TextField$withValue,
										representative.a3,
										A2(
											$author$project$Form$TextField$withOnInputHandler,
											A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$Representative, $author$project$Page$Reservation$LastName),
											A2($author$project$Form$TextField$withLabel, 'お名前（姓）', textFieldOptions)))))),
							$author$project$Form$TextField$new(
							A2(
								$author$project$Form$TextField$withErrorMessages,
								ewraper(
									_List_fromArray(
										[$author$project$Page$Profile$FirstNameRequired, $author$project$Page$Profile$FirstNameTooLonger])),
								A2(
									$author$project$Form$TextField$withRequired,
									true,
									A2(
										$author$project$Form$TextField$withValue,
										representative.aZ,
										A2(
											$author$project$Form$TextField$withOnInputHandler,
											A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$Representative, $author$project$Page$Reservation$FirstName),
											A2($author$project$Form$TextField$withLabel, 'お名前（名）', textFieldOptions))))))
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field-body')
						]),
					_List_fromArray(
						[
							$author$project$Form$TextField$new(
							A2(
								$author$project$Form$TextField$withErrorMessages,
								ewraper(
									_List_fromArray(
										[$author$project$Page$Profile$LastNameFuriganaRequired, $author$project$Page$Profile$LastNameFuriganaRequired])),
								A2(
									$author$project$Form$TextField$withRequired,
									true,
									A2(
										$author$project$Form$TextField$withValue,
										representative.a4,
										A2(
											$author$project$Form$TextField$withOnInputHandler,
											A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$Representative, $author$project$Page$Reservation$LastNameFurigana),
											A2($author$project$Form$TextField$withLabel, 'フリガナ（姓）', textFieldOptions)))))),
							$author$project$Form$TextField$new(
							A2(
								$author$project$Form$TextField$withErrorMessages,
								ewraper(
									_List_fromArray(
										[$author$project$Page$Profile$FirstNameFuriganaRequired, $author$project$Page$Profile$FirstNameFuriganaTooLonger])),
								A2(
									$author$project$Form$TextField$withRequired,
									true,
									A2(
										$author$project$Form$TextField$withValue,
										representative.a_,
										A2(
											$author$project$Form$TextField$withOnInputHandler,
											A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$Representative, $author$project$Page$Reservation$FirstNameFurigana),
											A2($author$project$Form$TextField$withLabel, 'フリガナ（名）', textFieldOptions))))))
						]))
				])),
			$author$project$Form$TextField$new(
			A2(
				$author$project$Form$TextField$withErrorMessages,
				$author$project$Page$Reservation$customerFormErrorWraper(
					_List_fromArray(
						[$author$project$Page$Profile$PhoneNumberRequired, $author$project$Page$Profile$PhoneNumberTooLonger])),
				A2(
					$author$project$Form$TextField$withRequired,
					true,
					A2(
						$author$project$Form$TextField$withValue,
						model.dG.aq,
						A2(
							$author$project$Form$TextField$withOnInputHandler,
							A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$CustomerForm, $author$project$Page$Reservation$PhoneNumber),
							A2($author$project$Form$TextField$withLabel, '電話番号', textFieldOptions)))))),
			$author$project$Form$TextField$new(
			A2(
				$author$project$Form$TextField$withHelpMsgs,
				_List_fromArray(
					['複数のアドレスを , で区切って入力できます。']),
				A2(
					$author$project$Form$TextField$withErrorMessages,
					ewraper(
						_List_fromArray(
							[$author$project$Page$Profile$FirstNameRequired, $author$project$Page$Profile$FirstNameTooLonger])),
					A2(
						$author$project$Form$TextField$withRequired,
						true,
						A2(
							$author$project$Form$TextField$withValue,
							representative.aY,
							A2(
								$author$project$Form$TextField$withOnInputHandler,
								A2($author$project$Page$Reservation$wrapdOnInputHdl, $author$project$Page$Reservation$Representative, $author$project$Page$Reservation$Emails),
								A2($author$project$Form$TextField$withLabel, 'メールアドレス', textFieldOptions))))))),
			$author$project$Form$RadioList$new(
			A2(
				$author$project$Form$RadioList$withHelpMsgs,
				_List_fromArray(
					['以前にもミチュール（※全店舗を含みます）をご利用頂いたことがありましたら「はい」をお選びください']),
				A2(
					$author$project$Form$RadioList$withRequired,
					true,
					A2(
						$author$project$Form$RadioList$withValue,
						model.G.d3 ? 'はい' : 'いいえ',
						A2(
							$author$project$Form$RadioList$withOnCheckHandler,
							function (key) {
								return A2(
									$elm$core$Basics$composeL,
									$author$project$Page$Reservation$OnInput,
									$author$project$Page$Reservation$HasUsedBefore(key));
							},
							A2(
								$author$project$Form$RadioList$withNames,
								_List_fromArray(
									['はい', 'いいえ']),
								A2(
									$author$project$Form$RadioList$withLabel,
									'以前にもご利用はありますか',
									$author$project$Form$RadioList$defaultOptions($author$project$Page$Reservation$NoOp))))))))
		]);
};
var $author$project$Page$Reservation$viewCustomerInfo = function (model) {
	var representative = $author$project$Page$Reservation$takeRepresentative(model);
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('お名前')
								])),
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(representative.a3),
									$elm$html$Html$text(' '),
									$elm$html$Html$text(representative.aZ)
								]))
						])),
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('フリガナ')
								])),
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(representative.a4),
									$elm$html$Html$text(' '),
									$elm$html$Html$text(representative.a_)
								]))
						])),
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('電話番号')
								])),
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(model.dG.aq)
								]))
						])),
					A2(
					$elm$html$Html$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$th,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('メールアドレス')
								])),
							A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(representative.aY)
								]))
						]))
				]))
		]);
};
var $author$project$Page$Reservation$Birthday = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Reservation$DeleteFamilyMember = function (a) {
	return {$: 35, a: a};
};
var $author$project$Page$Reservation$FamilyMemberForm = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Page$Reservation$Height = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Reservation$Relationship = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Reservation$ShoeSize = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Reservation$dateSelector = F6(
	function (msg, currentYear, label_, _v0, errorMessage, errorClass) {
		var yy = _v0.a;
		var mm = _v0.b;
		var dd = _v0.c;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field is-danger')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label required')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label_)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control is-expanded')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('select'),
									errorClass
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$select,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput(
											function (v) {
												return msg(
													_Utils_Tuple3(v, mm, dd));
											})
										]),
									A2(
										$elm$core$List$map,
										function (p) {
											return A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(p),
														$elm$html$Html$Attributes$selected(
														_Utils_eq(p, yy))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(p)
													]));
										},
										A2(
											$elm$core$List$cons,
											'----',
											A2(
												$elm$core$List$map,
												$elm$core$String$fromInt,
												$elm$core$List$reverse(
													A2($elm$core$List$range, currentYear - 120, currentYear))))))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('select'),
									errorClass
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$select,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput(
											function (v) {
												return msg(
													_Utils_Tuple3(yy, v, dd));
											})
										]),
									A2(
										$elm$core$List$map,
										function (p) {
											return A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(p),
														$elm$html$Html$Attributes$selected(
														_Utils_eq(p, mm))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(p)
													]));
										},
										A2(
											$elm$core$List$cons,
											'--',
											A2(
												$elm$core$List$map,
												A2($elm$core$String$padLeft, 2, '0'),
												A2(
													$elm$core$List$map,
													$elm$core$String$fromInt,
													A2($elm$core$List$range, 1, 12))))))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('select'),
									errorClass
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$select,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput(
											function (v) {
												return msg(
													_Utils_Tuple3(yy, mm, v));
											})
										]),
									A2(
										$elm$core$List$map,
										function (p) {
											return A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$value(p),
														$elm$html$Html$Attributes$selected(
														_Utils_eq(p, dd))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(p)
													]));
										},
										A2(
											$elm$core$List$cons,
											'--',
											A2(
												$elm$core$List$map,
												A2($elm$core$String$padLeft, 2, '0'),
												A2(
													$elm$core$List$map,
													$elm$core$String$fromInt,
													A2($elm$core$List$range, 1, 31))))))
								]))
						])),
					errorMessage
				]));
	});
var $author$project$Page$Reservation$isChild = function (form) {
	var relationship = form.cz;
	return A2($elm$core$String$endsWith, '男', relationship) || A2($elm$core$String$endsWith, '女', relationship);
};
var $author$project$Page$Reservation$relationshipOption = function (value_) {
	var options_ = _List_fromArray(
		['本人', '妻', '夫', '長女', '長男', '次女', '次男', '三女', '三男', '四女', '四男', '父', '母', 'その他']);
	var options = A2(
		$elm$core$List$append,
		($elm$core$String$isEmpty(value_) || A2($elm$core$List$member, value_, options_)) ? _List_Nil : _List_fromArray(
			[value_]),
		options_);
	return A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value('')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('続柄を選択…')
					]))
			]),
		A2(
			$elm$core$List$map,
			function (v) {
				return A2(
					$elm$html$Html$option,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$value(v),
							$elm$html$Html$Attributes$selected(
							_Utils_eq(value_, v))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(v)
						]));
			},
			options));
};
var $author$project$Page$Reservation$viewFamilyMemberForm = F3(
	function (model, idx, form) {
		var textFieldOptions = A2(
			$author$project$Form$TextField$withErrors,
			model.dT,
			A2(
				$author$project$Form$TextField$withStringFromError,
				$author$project$Page$Reservation$stringFromError,
				$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp)));
		var ewraper = $author$project$Page$Reservation$familyMemberFormErrorWraper(idx);
		var cyear = A2($elm$time$Time$toYear, model.cO, model.cu);
		return A2(
			$elm$html$Html$dd,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('family')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field-body')
								]),
							_List_fromArray(
								[
									$author$project$Form$TextField$new(
									A2(
										$author$project$Form$TextField$withErrorMessages,
										ewraper(
											_List_fromArray(
												[$author$project$Page$Profile$LastNameRequired, $author$project$Page$Profile$LastNameTooLonger])),
										A2(
											$author$project$Form$TextField$withRequired,
											true,
											A2(
												$author$project$Form$TextField$withValue,
												form.a3,
												A2(
													$author$project$Form$TextField$withOnInputHandler,
													A2(
														$author$project$Page$Reservation$wrapdOnInputHdl,
														$author$project$Page$Reservation$FamilyMemberForm(idx),
														$author$project$Page$Reservation$LastName),
													A2($author$project$Form$TextField$withLabel, '主役のお名前（姓）', textFieldOptions)))))),
									$author$project$Form$TextField$new(
									A2(
										$author$project$Form$TextField$withErrorMessages,
										ewraper(
											_List_fromArray(
												[$author$project$Page$Profile$FirstNameRequired, $author$project$Page$Profile$FirstNameTooLonger])),
										A2(
											$author$project$Form$TextField$withRequired,
											true,
											A2(
												$author$project$Form$TextField$withValue,
												form.aZ,
												A2(
													$author$project$Form$TextField$withOnInputHandler,
													A2(
														$author$project$Page$Reservation$wrapdOnInputHdl,
														$author$project$Page$Reservation$FamilyMemberForm(idx),
														$author$project$Page$Reservation$FirstName),
													A2($author$project$Form$TextField$withLabel, '主役のお名前（名）', textFieldOptions))))))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field-body')
								]),
							_List_fromArray(
								[
									$author$project$Form$TextField$new(
									A2(
										$author$project$Form$TextField$withErrorMessages,
										ewraper(
											_List_fromArray(
												[$author$project$Page$Profile$LastNameFuriganaRequired, $author$project$Page$Profile$LastNameFuriganaTooLonger])),
										A2(
											$author$project$Form$TextField$withRequired,
											true,
											A2(
												$author$project$Form$TextField$withValue,
												form.a4,
												A2(
													$author$project$Form$TextField$withOnInputHandler,
													A2(
														$author$project$Page$Reservation$wrapdOnInputHdl,
														$author$project$Page$Reservation$FamilyMemberForm(idx),
														$author$project$Page$Reservation$LastNameFurigana),
													A2($author$project$Form$TextField$withLabel, 'フリガナ（姓）', textFieldOptions)))))),
									$author$project$Form$TextField$new(
									A2(
										$author$project$Form$TextField$withErrorMessages,
										ewraper(
											_List_fromArray(
												[$author$project$Page$Profile$FirstNameFuriganaRequired, $author$project$Page$Profile$FirstNameFuriganaTooLonger])),
										A2(
											$author$project$Form$TextField$withRequired,
											true,
											A2(
												$author$project$Form$TextField$withValue,
												form.a_,
												A2(
													$author$project$Form$TextField$withOnInputHandler,
													A2(
														$author$project$Page$Reservation$wrapdOnInputHdl,
														$author$project$Page$Reservation$FamilyMemberForm(idx),
														$author$project$Page$Reservation$FirstNameFurigana),
													A2($author$project$Form$TextField$withLabel, 'フリガナ（名）', textFieldOptions))))))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('control')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('label required')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('続柄')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('select')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$select,
													_List_fromArray(
														[
															$elm$html$Html$Events$onInput(
															A2(
																$author$project$Page$Reservation$wrapdOnInputHdl,
																$author$project$Page$Reservation$FamilyMemberForm(idx),
																$author$project$Page$Reservation$Relationship))
														]),
													$author$project$Page$Reservation$relationshipOption(form.cz))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									A3(
										$author$project$Form$Message$showErrorHelpMessages,
										$author$project$Page$Reservation$stringFromError,
										ewraper(
											_List_fromArray(
												[$author$project$Page$Profile$RelationshipTooLonger, $author$project$Page$Profile$RelationshipRequired])),
										model.dT))
								]))
						])),
					$author$project$Page$Reservation$isChild(form) ? A6(
					$author$project$Page$Reservation$dateSelector,
					A2(
						$author$project$Page$Reservation$wrapdOnInputHdl,
						$author$project$Page$Reservation$FamilyMemberForm(idx),
						$author$project$Page$Reservation$Birthday),
					cyear,
					'生年月日',
					_Utils_Tuple3(form.az, form.ay, form.ax),
					A2($elm$html$Html$div, _List_Nil, _List_Nil),
					$elm$html$Html$Attributes$class('')) : $elm$html$Html$text(''),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A3(
						$author$project$Form$Message$showErrorHelpMessages,
						$author$project$Page$Reservation$stringFromError,
						ewraper(
							_List_fromArray(
								[$author$project$Page$Profile$BirthdayParseError, $author$project$Page$Profile$BirthdayRequired])),
						model.dT)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('field-body')
								]),
							_List_fromArray(
								[
									$author$project$Form$TextField$new(
									A2(
										$author$project$Form$TextField$withErrorMessages,
										ewraper(
											_List_fromArray(
												[$author$project$Page$Profile$HeightRequired, $author$project$Page$Profile$HeightTooTaller, $author$project$Page$Profile$HeightInvalidFloatValue])),
										A2(
											$author$project$Form$TextField$withRequired,
											false,
											A2(
												$author$project$Form$TextField$withValue,
												form.b2,
												A2(
													$author$project$Form$TextField$withOnInputHandler,
													A2(
														$author$project$Page$Reservation$wrapdOnInputHdl,
														$author$project$Page$Reservation$FamilyMemberForm(idx),
														$author$project$Page$Reservation$Height),
													A2($author$project$Form$TextField$withLabel, '身長(cm)', textFieldOptions)))))),
									$author$project$Form$TextField$new(
									A2(
										$author$project$Form$TextField$withErrorMessages,
										ewraper(
											_List_fromArray(
												[$author$project$Page$Profile$ShoeSiseRequired, $author$project$Page$Profile$ShoesizeTooBigger, $author$project$Page$Profile$ShoesizeTooSmaller, $author$project$Page$Profile$ShoeSiseInvalidFloatValue])),
										A2(
											$author$project$Form$TextField$withRequired,
											false,
											A2(
												$author$project$Form$TextField$withValue,
												form.cF,
												A2(
													$author$project$Form$TextField$withOnInputHandler,
													A2(
														$author$project$Page$Reservation$wrapdOnInputHdl,
														$author$project$Page$Reservation$FamilyMemberForm(idx),
														$author$project$Page$Reservation$ShoeSize),
													A2($author$project$Form$TextField$withLabel, '足のサイズ(cm)', textFieldOptions))))))
								]))
						])),
					(0 < idx) ? A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('field')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('button'),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Reservation$DeleteFamilyMember(idx))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('この情報を削除')
								]))
						])) : $elm$html$Html$text('')
				]));
	});
var $author$project$Form$Checkbox$withErrorMessages = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{bZ: e});
	});
var $author$project$Form$Checkbox$withErrors = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{dT: e});
	});
var $author$project$Form$TextArea$withHelpMsgs = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{b3: s});
	});
var $author$project$Form$TextArea$withOnInputHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cm: f});
	});
var $author$project$Form$TextArea$withReadonly = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cy: s});
	});
var $author$project$Form$Checkbox$withStringFromError = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cK: f});
	});
var $author$project$Form$TextArea$withValue = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{be: s});
	});
var $author$project$Page$Reservation$customerSection = function (model) {
	var maybeViewer = $author$project$Session$viewer(
		$author$project$Page$Reservation$toSession(model));
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('section customer'),
				$elm$html$Html$Attributes$class(
				A2(
					$elm$core$List$member,
					model.e,
					_List_fromArray(
						[10])) ? '' : 'is-hidden')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('subtitle is-size-6')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('▼ご予約情報をご確認ください')
					])),
				A2(
				$elm$html$Html$article,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('panel is-info')
					]),
				_List_fromArray(
					[
						A3($author$project$Page$Reservation$viewReservationInfo, model, model.R, model.e)
					])),
				function () {
				if (maybeViewer.$ === 1) {
					return A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('subtitle is-size-6')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('▼お客様情報を入力してください')
							]));
				} else {
					return A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('subtitle is-size-6')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('▼お客様情報を確認してください')
							]));
				}
			}(),
				A2(
				$elm$html$Html$dl,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$dt,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('ご連絡先')
							])),
						A2(
						$elm$html$Html$dd,
						_List_Nil,
						function () {
							if (maybeViewer.$ === 1) {
								return $author$project$Page$Reservation$viewCustomerForm(model);
							} else {
								return $author$project$Page$Reservation$viewCustomerInfo(model);
							}
						}()),
						A2(
						$elm$html$Html$dd,
						_List_Nil,
						function () {
							var _v2 = model.dG.c;
							if (_v2 === '__new__') {
								return $author$project$Page$Reservation$viewCustomerExtendForm(model);
							} else {
								return _List_fromArray(
									[
										$elm$html$Html$text('')
									]);
							}
						}())
					])),
				function () {
				var _v3 = model.dG.c;
				if (_v3 === '__new__') {
					return A2(
						$elm$html$Html$dl,
						_List_Nil,
						$elm$core$List$concat(
							_List_fromArray(
								[
									A2(
									$elm$core$List$indexedMap,
									F2(
										function (idx, f) {
											return (!idx) ? $elm$html$Html$text('') : A3($author$project$Page$Reservation$viewFamilyMemberForm, model, idx, f);
										}),
									model.dG.dW),
									_List_fromArray(
									[
										A2(
										$elm$html$Html$dd,
										_List_Nil,
										function () {
											var _v4 = $elm$core$List$head(model.dG.dW);
											if (!_v4.$) {
												return _List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button'),
																$elm$html$Html$Events$onClick(
																$author$project$Page$Reservation$AddFamilyMember(model.dG))
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('お子様を追加')
																	])),
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$i,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('fas fa-plus')
																			]),
																		_List_Nil)
																	]))
															]))
													]);
											} else {
												return _List_fromArray(
													[
														$elm$html$Html$text('')
													]);
											}
										}())
									])
								])));
				} else {
					return $elm$html$Html$text('');
				}
			}(),
				A2(
				$elm$html$Html$dl,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$dt,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('ご要望など')
							])),
						A2(
						$elm$html$Html$dd,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Form$TextArea$new(
								A2(
									$author$project$Form$TextArea$withOnInputHandler,
									A2($elm$core$Basics$composeL, $author$project$Page$Reservation$OnInput, $author$project$Page$Reservation$AdditionalComments),
									A2(
										$author$project$Form$TextArea$withHelpMsgs,
										_List_fromArray(
											['素敵なお写真を残すためにご要望などございましたらご記入ください♪']),
										A2(
											$author$project$Form$TextArea$withValue,
											model.G.dr,
											$author$project$Form$TextArea$defaultOptions($author$project$Page$Reservation$NoOp)))))
							]))
					])),
				A2(
				$elm$html$Html$dl,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$dt,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('個人情報の取り扱いについて')
							])),
						A2(
						$elm$html$Html$dd,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('info_text')
							]),
						_List_fromArray(
							[
								$author$project$Form$TextArea$new(
								A2(
									$author$project$Form$TextArea$withValue,
									$author$project$Page$Reservation$privacy_policy_text,
									A2(
										$author$project$Form$TextArea$withReadonly,
										true,
										$author$project$Form$TextArea$defaultOptions($author$project$Page$Reservation$NoOp))))
							]))
					])),
				A2(
				$elm$html$Html$dl,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$dt,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('ご利用規約について')
							])),
						A2(
						$elm$html$Html$dd,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('info_text')
							]),
						_List_fromArray(
							[
								$author$project$Form$TextArea$new(
								A2(
									$author$project$Form$TextArea$withValue,
									$author$project$Page$Reservation$terms_and_conditions_text,
									A2(
										$author$project$Form$TextArea$withReadonly,
										true,
										$author$project$Form$TextArea$defaultOptions($author$project$Page$Reservation$NoOp))))
							]))
					])),
				$author$project$Form$Checkbox$new(
				A2(
					$author$project$Form$Checkbox$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					A2(
						$author$project$Form$Checkbox$withErrors,
						model.dT,
						A2(
							$author$project$Form$Checkbox$withErrorMessages,
							_List_fromArray(
								[$author$project$Page$Reservation$AgreeToPrivacyPolicyAndTermsMustTrue]),
							A2(
								$author$project$Form$Checkbox$withOnCheckHandler,
								A2($elm$core$Basics$composeL, $author$project$Page$Reservation$OnInput, $author$project$Page$Reservation$AgreeToPrivacyPolicyAndTerms),
								A2(
									$author$project$Form$Checkbox$withValue,
									model.G.ds,
									A2(
										$author$project$Form$Checkbox$withLabel,
										'個人情報の取り扱い・利用規約について同意する',
										$author$project$Form$Checkbox$defaultOptions($author$project$Page$Reservation$NoOp)))))))),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button is-link'),
						$elm$html$Html$Events$onClick($author$project$Page$Reservation$ConfirmReservation)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('予約を確定する ≫')
					])),
				$author$project$Page$Reservation$showErrors(model.dT)
			]));
};
var $author$project$Page$Reservation$isShowErrors = function (errors) {
	var wantToDisplay = _List_Nil;
	return A2(
		$elm$core$List$any,
		function (x) {
			return A2($elm$core$List$member, x, wantToDisplay);
		},
		errors);
};
var $author$project$Page$Reservation$ChoicePurposeOfUseType = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Reservation$ValidateReservationPlan = {$: 38};
var $elm$core$Array$isEmpty = function (_v0) {
	var len = _v0.a;
	return !len;
};
var $author$project$Page$Reservation$ChoicePlanCategory = function (a) {
	return {$: 12, a: a};
};
var $elm$html$Html$figure = _VirtualDom_node('figure');
var $author$project$Page$Reservation$planCategorySelector = F2(
	function (choicePlanCategoty, items) {
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (idx, pcf) {
					var selected = function () {
						if (choicePlanCategoty.$ === 1) {
							return $elm$html$Html$Attributes$class('');
						} else {
							var cpc = choicePlanCategoty.a;
							return _Utils_eq(pcf.c, cpc.c) ? $elm$html$Html$Attributes$class('selected') : $elm$html$Html$Attributes$class('');
						}
					}();
					var image = _List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$Attributes$attribute,
									'srcset',
									A2(
										$elm$core$String$join,
										' ',
										_List_fromArray(
											[pcf.C, '56w,', pcf.C, '120w']))),
									A2($elm$html$Html$Attributes$attribute, 'sizes', '(min-width: 768px) 120px, 56px')
								]),
							_List_Nil)
						]);
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('card-content choice-purpose-of-use'),
								selected,
								$elm$html$Html$Events$onClick(
								$author$project$Page$Reservation$ChoicePlanCategory(pcf))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('media')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('media-left')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$figure,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('image')
													]),
												image)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('media-content')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('title is-5')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(pcf.i)
													]))
											]))
									]))
							]));
				}),
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.W;
				},
				items));
	});
var $author$project$Page$Reservation$ChoiceShootingPlan = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Reservation$planSelector = F2(
	function (choiceShootingPlan, items) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('panel-selector')
				]),
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (idx, plan) {
						var selected = function () {
							if (choiceShootingPlan.$ === 1) {
								return $elm$html$Html$Attributes$class('');
							} else {
								var csc = choiceShootingPlan.a;
								return _Utils_eq(plan.c, csc.c) ? $elm$html$Html$Attributes$class('selected') : $elm$html$Html$Attributes$class('');
							}
						}();
						var note = function () {
							var _v1 = plan.aa;
							if (!_v1.$) {
								var t = _v1.a;
								return t;
							} else {
								return '';
							}
						}();
						var image = function (url) {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'srcset', url),
											A2($elm$html$Html$Attributes$attribute, 'sizes', '(min-width: 768px) 120px, 56px')
										]),
									_List_Nil)
								]);
						};
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('card-content'),
									selected,
									$elm$html$Html$Events$onClick(
									$author$project$Page$Reservation$ChoiceShootingPlan(plan))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('media')
										]),
									_List_fromArray(
										[
											function () {
											var _v0 = plan.C;
											if (_v0.$ === 1) {
												return $elm$html$Html$text('');
											} else {
												var v = _v0.a;
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('media-left')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$figure,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('image')
																]),
															image(v))
														]));
											}
										}(),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('media-content')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('title is-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(plan.i)
														])),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('is-size-7')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(note)
														]))
												]))
										]))
								]));
					}),
				items));
	});
var $author$project$Page$Reservation$CustomField = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Reservation$CustomFieldChecked = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$Reservation$CustomFieldMultiChecked = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $author$project$Form$CheckboxList$defaultOptions = function (msg) {
	return {
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cb: true,
		cd: '',
		ex: msg,
		ci: _List_Nil,
		cj: function (_v0) {
			return function (_v1) {
				return msg;
			};
		},
		cK: function (e) {
			return '';
		},
		cM: _List_Nil
	};
};
var $author$project$Form$DateField$defaultOptions = function (msg) {
	return {
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cb: true,
		cd: $elm$core$Maybe$Just(''),
		i: '',
		cm: function (_v0) {
			return msg;
		},
		cK: function (e) {
			return '';
		},
		be: ''
	};
};
var $author$project$Form$SelectList$defaultOptions = function (msg) {
	return {
		bX: _List_Nil,
		bY: function (_v0) {
			return '';
		},
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cb: true,
		cc: function (_enum) {
			return false;
		},
		cd: '',
		i: '',
		cm: function (_v1) {
			return msg;
		},
		cK: function (_v2) {
			return '';
		}
	};
};
var $author$project$Form$CheckboxList$new = function (opt) {
	var required = function () {
		var _v0 = opt.cb;
		if (_v0) {
			return $elm$html$Html$Attributes$class('required');
		} else {
			return $elm$html$Html$Attributes$class('');
		}
	}();
	var hasErrors_ = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$or,
		false,
		A2(
			$elm$core$List$map,
			function (v) {
				return A2($elm$core$List$member, v, opt.dT);
			},
			opt.bZ));
	var isDanger = $elm$html$Html$Attributes$class(
		hasErrors_ ? 'is-danger' : '');
	var checkboxOptions = $author$project$Form$Checkbox$defaultOptions(opt.ex);
	return A2(
		$elm$html$Html$fieldset,
		_List_fromArray(
			[isDanger]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$legend,
					_List_fromArray(
						[required]),
					_List_fromArray(
						[
							$elm$html$Html$text(opt.cd)
						]))
				]),
			_Utils_ap(
				A2(
					$elm$core$List$map,
					function (name) {
						return $author$project$Form$Checkbox$new(
							A2(
								$author$project$Form$Checkbox$withValue,
								A2($elm$core$List$member, name, opt.cM),
								A2(
									$author$project$Form$Checkbox$withOnCheckHandler,
									opt.cj(name),
									A2($author$project$Form$Checkbox$withLabel, name, checkboxOptions))));
					},
					opt.ci),
				_Utils_ap(
					$author$project$Form$Message$showHelpMessages(opt.b3),
					A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT)))));
};
var $author$project$Form$DateField$new = function (opt) {
	var required = function () {
		var _v0 = opt.cb;
		if (_v0) {
			return $elm$html$Html$Attributes$class('required');
		} else {
			return $elm$html$Html$Attributes$class('');
		}
	}();
	var hasErrors_ = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$or,
		false,
		A2(
			$elm$core$List$map,
			function (v) {
				return A2($elm$core$List$member, v, opt.dT);
			},
			opt.bZ));
	var isDanger = function (v) {
		return hasErrors_ ? 'is-danger' : '';
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('field')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$elm$html$Html$text(''),
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('label'),
											required
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(v)
										]));
							},
							opt.cd)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('control')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('input'),
										$elm$html$Html$Attributes$value(opt.be),
										$elm$html$Html$Attributes$name(opt.i),
										$elm$html$Html$Attributes$type_('date'),
										$elm$html$Html$Events$onInput(opt.cm),
										$elm$html$Html$Attributes$class(
										isDanger(opt.bZ))
									]),
								_List_Nil)
							]))
					]),
					$author$project$Form$Message$showHelpMessages(opt.b3),
					A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT)
				])));
};
var $author$project$Form$SelectList$new = function (opt) {
	var required = function () {
		var _v0 = opt.cb;
		if (_v0) {
			return $elm$html$Html$Attributes$class('required');
		} else {
			return $elm$html$Html$Attributes$class('');
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('field')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('label'),
							required
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(opt.cd)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('select')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$select,
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput(opt.cm)
										]),
									A2(
										$elm$core$List$map,
										function (s) {
											return A2(
												$elm$html$Html$option,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$selected(
														opt.cc(s))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														opt.bY(s))
													]));
										},
										opt.bX))
								]))
						]))
				]),
			_Utils_ap(
				$author$project$Form$Message$showHelpMessages(opt.b3),
				A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT))));
};
var $author$project$Form$SelectList$withEnumList = F2(
	function (enumList, o) {
		return _Utils_update(
			o,
			{bX: enumList});
	});
var $author$project$Form$SelectList$withEnumToString = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{bY: f});
	});
var $author$project$Form$CheckboxList$withErrorMessages = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{bZ: e});
	});
var $author$project$Form$DateField$withErrorMessages = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{bZ: e});
	});
var $author$project$Form$RadioList$withErrorMessages = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{bZ: e});
	});
var $author$project$Form$CheckboxList$withErrors = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{dT: e});
	});
var $author$project$Form$DateField$withErrors = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{dT: e});
	});
var $author$project$Form$RadioList$withErrors = F2(
	function (e, o) {
		return _Utils_update(
			o,
			{dT: e});
	});
var $author$project$Form$SelectList$withIsSelect = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cc: f});
	});
var $author$project$Form$CheckboxList$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cd: s});
	});
var $author$project$Form$DateField$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{
				cd: $elm$core$Maybe$Just(s)
			});
	});
var $author$project$Form$SelectList$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cd: s});
	});
var $author$project$Form$CheckboxList$withNames = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{ci: s});
	});
var $author$project$Form$CheckboxList$withOnCheckHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cj: f});
	});
var $author$project$Form$DateField$withOnInputHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cm: f});
	});
var $author$project$Form$SelectList$withOnInputHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cm: f});
	});
var $author$project$Form$CheckboxList$withRequired = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cb: s});
	});
var $author$project$Form$DateField$withRequired = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cb: s});
	});
var $author$project$Form$SelectList$withRequired = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cb: s});
	});
var $author$project$Form$CheckboxList$withStringFromError = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cK: f});
	});
var $author$project$Form$DateField$withStringFromError = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cK: f});
	});
var $author$project$Form$RadioList$withStringFromError = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cK: f});
	});
var $author$project$Form$DateField$withValue = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{be: s});
	});
var $author$project$Form$CheckboxList$withValues = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cM: s});
	});
var $author$project$Page$Reservation$viewCustomField = F3(
	function (errors_, idx, cff) {
		var value = cff.be;
		var selectListOptions = A2(
			$author$project$Form$SelectList$withEnumToString,
			function (s) {
				return s;
			},
			$author$project$Form$SelectList$defaultOptions($author$project$Page$Reservation$NoOp));
		var errors = A2(
			$elm$core$List$filterMap,
			function (e) {
				if (e.$ === 3) {
					var _v2 = e.a;
					var i = _v2.a;
					var error = _v2.b;
					return _Utils_eq(i, idx) ? $elm$core$Maybe$Just(error) : $elm$core$Maybe$Nothing;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			errors_);
		var radioListOptions = A2(
			$author$project$Form$RadioList$withErrors,
			errors,
			A2(
				$author$project$Form$RadioList$withErrorMessages,
				_List_fromArray(
					[$author$project$Page$Reservation$CustomFieldRequired]),
				A2(
					$author$project$Form$RadioList$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					$author$project$Form$RadioList$defaultOptions($author$project$Page$Reservation$NoOp))));
		var textFieldOptions = A2(
			$author$project$Form$TextField$withErrors,
			errors,
			A2(
				$author$project$Form$TextField$withErrorMessages,
				_List_fromArray(
					[$author$project$Page$Reservation$CustomFieldRequired]),
				A2(
					$author$project$Form$TextField$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp))));
		var dateFieldOptions = A2(
			$author$project$Form$DateField$withErrors,
			errors,
			A2(
				$author$project$Form$DateField$withErrorMessages,
				_List_fromArray(
					[$author$project$Page$Reservation$CustomFieldRequired]),
				A2(
					$author$project$Form$DateField$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					$author$project$Form$DateField$defaultOptions($author$project$Page$Reservation$NoOp))));
		var checkboxListOptions = A2(
			$author$project$Form$CheckboxList$withErrors,
			errors,
			A2(
				$author$project$Form$CheckboxList$withErrorMessages,
				_List_fromArray(
					[$author$project$Page$Reservation$CustomFieldRequired]),
				A2(
					$author$project$Form$CheckboxList$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					$author$project$Form$CheckboxList$defaultOptions($author$project$Page$Reservation$NoOp))));
		var cf = cff.dF;
		var _v0 = cf.b_;
		switch (_v0) {
			case 2:
				return $author$project$Form$CheckboxList$new(
					A2(
						$author$project$Form$CheckboxList$withRequired,
						cf.cb,
						A2(
							$author$project$Form$CheckboxList$withValues,
							$elm$core$String$lines(value),
							A2(
								$author$project$Form$CheckboxList$withOnCheckHandler,
								function (key) {
									return A2(
										$elm$core$Basics$composeL,
										$author$project$Page$Reservation$OnInput,
										A2($author$project$Page$Reservation$CustomFieldMultiChecked, idx, key));
								},
								A2(
									$author$project$Form$CheckboxList$withNames,
									$elm$core$String$lines(cf.cp),
									A2($author$project$Form$CheckboxList$withLabel, cf.i, checkboxListOptions))))));
			case 1:
				return $author$project$Form$RadioList$new(
					A2(
						$author$project$Form$RadioList$withRequired,
						cf.cb,
						A2(
							$author$project$Form$RadioList$withValue,
							value,
							A2(
								$author$project$Form$RadioList$withOnCheckHandler,
								function (key) {
									return A2(
										$elm$core$Basics$composeL,
										$author$project$Page$Reservation$OnInput,
										A2($author$project$Page$Reservation$CustomFieldChecked, idx, key));
								},
								A2(
									$author$project$Form$RadioList$withNames,
									$elm$core$String$lines(cf.cp),
									A2($author$project$Form$RadioList$withLabel, cf.i, radioListOptions))))));
			case 3:
				return $author$project$Form$SelectList$new(
					A2(
						$author$project$Form$SelectList$withRequired,
						cf.cb,
						A2(
							$author$project$Form$SelectList$withIsSelect,
							$elm$core$Basics$eq(value),
							A2(
								$author$project$Form$SelectList$withOnInputHandler,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Page$Reservation$OnInput,
									$author$project$Page$Reservation$CustomField(idx)),
								A2(
									$author$project$Form$SelectList$withEnumToString,
									function (s) {
										return s;
									},
									A2(
										$author$project$Form$SelectList$withEnumList,
										$elm$core$String$lines(cf.cp),
										A2($author$project$Form$SelectList$withLabel, cf.i, selectListOptions)))))));
			case 4:
				return $author$project$Form$DateField$new(
					A2(
						$author$project$Form$DateField$withRequired,
						cf.cb,
						A2(
							$author$project$Form$DateField$withValue,
							value,
							A2(
								$author$project$Form$DateField$withOnInputHandler,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Page$Reservation$OnInput,
									$author$project$Page$Reservation$CustomField(idx)),
								A2($author$project$Form$DateField$withLabel, cf.i, dateFieldOptions)))));
			default:
				return $author$project$Form$TextField$new(
					A2(
						$author$project$Form$TextField$withRequired,
						cf.cb,
						A2(
							$author$project$Form$TextField$withValue,
							value,
							A2(
								$author$project$Form$TextField$withOnInputHandler,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Page$Reservation$OnInput,
									$author$project$Page$Reservation$CustomField(idx)),
								A2($author$project$Form$TextField$withLabel, cf.i, textFieldOptions)))));
		}
	});
var $author$project$Page$Reservation$viewCustomFields = F2(
	function (errors, cfs) {
		return A2(
			$elm$core$List$indexedMap,
			$author$project$Page$Reservation$viewCustomField(errors),
			$elm$core$Array$toList(cfs));
	});
var $author$project$Page$Reservation$PlanOption = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Reservation$PlanOptionChecked = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Page$Reservation$PlanOptionMultiChecked = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Page$Reservation$viewOption = F3(
	function (errors_, idx, op) {
		var value = op.be;
		var selectListOptions = $author$project$Form$SelectList$defaultOptions($author$project$Page$Reservation$NoOp);
		var option = op.eQ;
		var errors = A2(
			$elm$core$List$filterMap,
			function (e) {
				if (e.$ === 6) {
					var _v2 = e.a;
					var i = _v2.a;
					var error = _v2.b;
					return _Utils_eq(i, idx) ? $elm$core$Maybe$Just(error) : $elm$core$Maybe$Nothing;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			errors_);
		var radioListOptions = A2(
			$author$project$Form$RadioList$withErrors,
			errors,
			A2(
				$author$project$Form$RadioList$withErrorMessages,
				_List_fromArray(
					[$author$project$Page$Reservation$PlanOptionRequired]),
				A2(
					$author$project$Form$RadioList$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					$author$project$Form$RadioList$defaultOptions($author$project$Page$Reservation$NoOp))));
		var textFieldOptions = A2(
			$author$project$Form$TextField$withErrors,
			errors,
			A2(
				$author$project$Form$TextField$withErrorMessages,
				_List_fromArray(
					[$author$project$Page$Reservation$PlanOptionRequired]),
				A2(
					$author$project$Form$TextField$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp))));
		var dateFieldOptions = A2(
			$author$project$Form$DateField$withErrors,
			errors,
			A2(
				$author$project$Form$DateField$withErrorMessages,
				_List_fromArray(
					[$author$project$Page$Reservation$PlanOptionRequired]),
				A2(
					$author$project$Form$DateField$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					$author$project$Form$DateField$defaultOptions($author$project$Page$Reservation$NoOp))));
		var checkboxListOptions = A2(
			$author$project$Form$CheckboxList$withErrors,
			errors,
			A2(
				$author$project$Form$CheckboxList$withErrorMessages,
				_List_fromArray(
					[$author$project$Page$Reservation$PlanOptionRequired]),
				A2(
					$author$project$Form$CheckboxList$withStringFromError,
					$author$project$Page$Reservation$stringFromError,
					$author$project$Form$CheckboxList$defaultOptions($author$project$Page$Reservation$NoOp))));
		var _v0 = option.b_;
		switch (_v0) {
			case 2:
				return $author$project$Form$CheckboxList$new(
					A2(
						$author$project$Form$CheckboxList$withRequired,
						option.cb,
						A2(
							$author$project$Form$CheckboxList$withValues,
							$elm$core$String$lines(value),
							A2(
								$author$project$Form$CheckboxList$withOnCheckHandler,
								function (key) {
									return A2(
										$elm$core$Basics$composeL,
										$author$project$Page$Reservation$OnInput,
										A2($author$project$Page$Reservation$PlanOptionMultiChecked, idx, key));
								},
								A2(
									$author$project$Form$CheckboxList$withNames,
									A2(
										$elm$core$List$map,
										function ($) {
											return $.i;
										},
										option.eI),
									A2($author$project$Form$CheckboxList$withLabel, option.aV, checkboxListOptions))))));
			case 1:
				return $author$project$Form$RadioList$new(
					A2(
						$author$project$Form$RadioList$withRequired,
						option.cb,
						A2(
							$author$project$Form$RadioList$withValue,
							value,
							A2(
								$author$project$Form$RadioList$withOnCheckHandler,
								function (key) {
									return A2(
										$elm$core$Basics$composeL,
										$author$project$Page$Reservation$OnInput,
										A2($author$project$Page$Reservation$PlanOptionChecked, idx, key));
								},
								A2(
									$author$project$Form$RadioList$withNames,
									A2(
										$elm$core$List$map,
										function ($) {
											return $.i;
										},
										option.eI),
									A2($author$project$Form$RadioList$withLabel, option.aV, radioListOptions))))));
			case 3:
				return $author$project$Form$SelectList$new(
					A2(
						$author$project$Form$SelectList$withRequired,
						option.cb,
						A2(
							$author$project$Form$SelectList$withIsSelect,
							$elm$core$Basics$eq(value),
							A2(
								$author$project$Form$SelectList$withOnInputHandler,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Page$Reservation$OnInput,
									$author$project$Page$Reservation$PlanOption(idx)),
								A2(
									$author$project$Form$SelectList$withEnumToString,
									function (s) {
										return s;
									},
									A2(
										$author$project$Form$SelectList$withEnumList,
										A2(
											$elm$core$List$map,
											function ($) {
												return $.i;
											},
											option.eI),
										A2($author$project$Form$SelectList$withLabel, option.aV, selectListOptions)))))));
			case 4:
				return $author$project$Form$DateField$new(
					A2(
						$author$project$Form$DateField$withRequired,
						option.cb,
						A2(
							$author$project$Form$DateField$withValue,
							value,
							A2(
								$author$project$Form$DateField$withOnInputHandler,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Page$Reservation$OnInput,
									$author$project$Page$Reservation$PlanOption(idx)),
								A2($author$project$Form$DateField$withLabel, option.aV, dateFieldOptions)))));
			default:
				return $author$project$Form$TextField$new(
					A2(
						$author$project$Form$TextField$withRequired,
						option.cb,
						A2(
							$author$project$Form$TextField$withValue,
							value,
							A2(
								$author$project$Form$TextField$withOnInputHandler,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Page$Reservation$OnInput,
									$author$project$Page$Reservation$PlanOption(idx)),
								A2($author$project$Form$TextField$withLabel, option.aV, textFieldOptions)))));
		}
	});
var $author$project$Page$Reservation$viewOptions = F2(
	function (errors, options) {
		return A2(
			$elm$core$List$indexedMap,
			$author$project$Page$Reservation$viewOption(errors),
			$elm$core$Array$toList(options));
	});
var $author$project$Page$Reservation$topSelector = F2(
	function (model, purposeOfUse) {
		var isShowDetail = _Utils_eq(
			model.b.as,
			$elm$core$Maybe$Just(purposeOfUse));
		var isShow = _Utils_eq(model.b.fe, $elm$core$Maybe$Nothing) || _Utils_eq(
			model.b.as,
			$elm$core$Maybe$Just(purposeOfUse));
		return (!isShow) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('card top-selector')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('card-header'),
								$elm$html$Html$Events$onClick(
								$author$project$Page$Reservation$ChoicePurposeOfUseType(purposeOfUse))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('card-header-title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(purposeOfUse.i)
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('card-header-icon')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('icon')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class(
														isShowDetail ? 'fas fa-angle-up' : 'fas fa-angle-down')
													]),
												_List_Nil)
											]))
									]))
							]))
					]),
				_Utils_ap(
					isShowDetail ? A2($author$project$Page$Reservation$planCategorySelector, model.b.eO, purposeOfUse.eN) : _List_fromArray(
						[
							$elm$html$Html$text('')
						]),
					function () {
						var _v0 = model.b.eO;
						if (_v0.$ === 1) {
							return _List_fromArray(
								[
									$elm$html$Html$text('')
								]);
						} else {
							var cpc = _v0.a;
							return isShowDetail ? _List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('card-content')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$dl,
											_List_fromArray(
												[
													A2(
													$author$project$Page$Reservation$isHidden,
													_List_fromArray(
														[2, 3]),
													model.e),
													$elm$html$Html$Attributes$id('PlanSection')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$dt,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('プラン')
														])),
													A2(
													$elm$html$Html$dd,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$author$project$Page$Reservation$planSelector,
															model.b.fe,
															A2(
																$elm$core$List$filter,
																function (x) {
																	return !A2($elm$core$String$startsWith, '【有明', x.i);
																},
																cpc.ff))
														]))
												])),
											function () {
											var _v1 = model.b.fe;
											if (_v1.$ === 1) {
												return $elm$html$Html$text('');
											} else {
												var _v2 = _Utils_Tuple2(
													$elm$core$Array$isEmpty(model.b.eR),
													$elm$core$Array$isEmpty(model.b.aU));
												if (_v2.a && _v2.b) {
													return $elm$html$Html$text('');
												} else {
													return A2(
														$elm$html$Html$dl,
														_List_fromArray(
															[
																A2(
																$author$project$Page$Reservation$isHidden,
																_List_fromArray(
																	[3]),
																model.e),
																$elm$html$Html$Attributes$id('OptionSection')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$dt,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('オプション')
																	])),
																A2(
																$elm$html$Html$dd,
																_List_Nil,
																A2($author$project$Page$Reservation$viewOptions, model.dT, model.b.eR)),
																A2(
																$elm$html$Html$dd,
																_List_Nil,
																A2($author$project$Page$Reservation$viewCustomFields, model.dT, model.b.aU))
															]));
												}
											}
										}()
										])),
									A2(
									$elm$html$Html$footer,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('card-footer'),
											A2(
											$author$project$Page$Reservation$isHidden,
											_List_fromArray(
												[3]),
											model.e)
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('button is-link'),
													$elm$html$Html$Events$onClick($author$project$Page$Reservation$ValidateReservationPlan)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('店舗・日時を選択する≫')
												]))
										])),
									$author$project$Page$Reservation$showErrors(model.dT)
								]) : _List_fromArray(
								[
									$elm$html$Html$text('')
								]);
						}
					}())));
	});
var $author$project$Page$Reservation$planSection = F2(
	function (model, purposeOfUseList) {
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('section plan'),
					$elm$html$Html$Attributes$id('Plan'),
					A2(
					$author$project$Page$Reservation$isHidden,
					_List_fromArray(
						[0, 1, 2, 3]),
					model.e)
				]),
			A2(
				$elm$core$List$map,
				$author$project$Page$Reservation$topSelector(model),
				purposeOfUseList));
	});
var $author$project$Page$Reservation$ValidateReservationSchedule = {$: 39};
var $author$project$Page$Reservation$WatingCancelReservation = {$: 40};
var $author$project$Page$Reservation$ChoiceShop = function (a) {
	return {$: 15, a: a};
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $author$project$Page$Reservation$shopSelector = F3(
	function (name_, model, shops) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('panel-selector')
				]),
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (idx, shop) {
						var note = function () {
							var _v2 = shop.aa;
							if (!_v2.$) {
								var t = _v2.a;
								return t;
							} else {
								return '';
							}
						}();
						var instagramUrl = shop.b6;
						var image = function (url) {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'srcset', url),
											A2($elm$html$Html$Attributes$attribute, 'sizes', '(min-width: 768px) 120px, 56px')
										]),
									_List_Nil)
								]);
						};
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('card-content'),
									$elm$html$Html$Attributes$class(
									_Utils_eq(
										$elm$core$Maybe$Just(shop),
										model.b.g.ae) ? 'selected' : ''),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Reservation$ChoiceShop(shop))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('media')
										]),
									_List_fromArray(
										[
											function () {
											var _v0 = shop.C;
											if (_v0.$ === 1) {
												return $elm$html$Html$text('');
											} else {
												var v = _v0.a;
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('media-left')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$figure,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('image')
																]),
															image(v))
														]));
											}
										}(),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('media-content')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('title is-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(shop.i)
														])),
													function () {
													if (!instagramUrl.$) {
														var instaUrl = instagramUrl.a;
														return A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('subtitle is-6')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$a,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$href(instaUrl),
																			$elm$html$Html$Attributes$target('_blank'),
																			$elm$html$Html$Attributes$rel('noreferrer'),
																			$elm$html$Html$Attributes$rel('noopener')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$span,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('icon-text')
																				]),
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('icon')
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$i,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('fab fa-instagram')
																								]),
																							_List_Nil)
																						])),
																					A2(
																					$elm$html$Html$span,
																					_List_Nil,
																					_List_fromArray(
																						[
																							$elm$html$Html$text('インスタで確認')
																						]))
																				]))
																		]))
																]));
													} else {
														return $elm$html$Html$text('');
													}
												}(),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('is-size-7')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(note)
														]))
												]))
										]))
								]));
					}),
				shops));
	});
var $author$project$Page$Reservation$ClearMessages = {$: 5};
var $author$project$Page$Reservation$showMessages = function (messages) {
	return $elm$core$List$isEmpty(messages) ? $elm$html$Html$text('') : A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('notification is-success is-light')
			]),
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('delete'),
							$elm$html$Html$Events$onClick($author$project$Page$Reservation$ClearMessages)
						]),
					_List_Nil)
				]),
			A2(
				$elm$core$List$map,
				function (s) {
					return A2(
						$elm$html$Html$p,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'style', 'white-space: pre-line;')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(s)
							]));
				},
				messages)));
};
var $author$project$Page$Reservation$ChoiceStudio = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Reservation$studioSelector = F3(
	function (name_, model, items) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('panel-selector')
				]),
			A2(
				$elm$core$List$indexedMap,
				F2(
					function (idx, n) {
						var note = function () {
							var _v2 = n.aa;
							if (!_v2.$) {
								var t = _v2.a;
								return t;
							} else {
								return '';
							}
						}();
						var instagramUrl = n.b6;
						var image = function (url) {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'srcset', url),
											A2($elm$html$Html$Attributes$attribute, 'sizes', '(min-width: 768px) 120px, 56px')
										]),
									_List_Nil)
								]);
						};
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('card-content'),
									$elm$html$Html$Attributes$class(
									_Utils_eq(
										$elm$core$Maybe$Just(n),
										model.b.g.Q) ? 'selected' : ''),
									$elm$html$Html$Events$onClick(
									$author$project$Page$Reservation$ChoiceStudio(n))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('media')
										]),
									_List_fromArray(
										[
											function () {
											var _v0 = n.C;
											if (_v0.$ === 1) {
												return $elm$html$Html$text('');
											} else {
												var v = _v0.a;
												return A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('media-left')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$figure,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('image')
																]),
															image(v))
														]));
											}
										}(),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('media-content')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('title is-4')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(n.i)
														])),
													function () {
													if (!instagramUrl.$) {
														var instaUrl = instagramUrl.a;
														return A2(
															$elm$html$Html$p,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('subtitle is-6')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$a,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$href(instaUrl),
																			$elm$html$Html$Attributes$target('_blank'),
																			$elm$html$Html$Attributes$rel('noreferrer'),
																			$elm$html$Html$Attributes$rel('noopener')
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$span,
																			_List_fromArray(
																				[
																					$elm$html$Html$Attributes$class('icon-text')
																				]),
																			_List_fromArray(
																				[
																					A2(
																					$elm$html$Html$span,
																					_List_fromArray(
																						[
																							$elm$html$Html$Attributes$class('icon')
																						]),
																					_List_fromArray(
																						[
																							A2(
																							$elm$html$Html$i,
																							_List_fromArray(
																								[
																									$elm$html$Html$Attributes$class('fab fa-instagram')
																								]),
																							_List_Nil)
																						])),
																					A2(
																					$elm$html$Html$span,
																					_List_Nil,
																					_List_fromArray(
																						[
																							$elm$html$Html$text('インスタで確認')
																						]))
																				]))
																		]))
																]));
													} else {
														return $elm$html$Html$text('');
													}
												}(),
													A2(
													$elm$html$Html$p,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('is-size-7')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(note)
														]))
												]))
										]))
								]));
					}),
				items));
	});
var $author$project$Page$Reservation$CommitWaitingList = {$: 18};
var $author$project$Page$Reservation$WlCustomerName = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$Reservation$WlCustomerNameFurigana = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Reservation$WlEmail = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Reservation$WlNote = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Reservation$WlPhoneNumber = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Reservation$WlTimeRequired = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Page$Reservation$WlShootingDate = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$Page$Reservation$WlStartTime = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$Form$TimeField$defaultOptions = function (msg) {
	return {
		bZ: _List_Nil,
		dT: _List_Nil,
		b3: _List_Nil,
		cb: true,
		cd: $elm$core$Maybe$Just(''),
		i: '',
		cm: function (_v0) {
			return msg;
		},
		cK: function (e) {
			return '';
		},
		be: ''
	};
};
var $author$project$Form$TimeField$new = function (opt) {
	var required = function () {
		var _v0 = opt.cb;
		if (_v0) {
			return $elm$html$Html$Attributes$class('required');
		} else {
			return $elm$html$Html$Attributes$class('');
		}
	}();
	var hasErrors_ = A3(
		$elm$core$List$foldl,
		$elm$core$Basics$or,
		false,
		A2(
			$elm$core$List$map,
			function (v) {
				return A2($elm$core$List$member, v, opt.dT);
			},
			opt.bZ));
	var isDanger = function (v) {
		return hasErrors_ ? 'is-danger' : '';
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('field')
			]),
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$elm$html$Html$text(''),
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('label'),
											required
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(v)
										]));
							},
							opt.cd)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('control')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$input,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('input'),
										$elm$html$Html$Attributes$value(opt.be),
										$elm$html$Html$Attributes$name(opt.i),
										$elm$html$Html$Attributes$type_('time'),
										$elm$html$Html$Events$onInput(opt.cm),
										$elm$html$Html$Attributes$class(
										isDanger(opt.bZ))
									]),
								_List_Nil)
							]))
					]),
					$author$project$Form$Message$showHelpMessages(opt.b3),
					A3($author$project$Form$Message$showErrorHelpMessages, opt.cK, opt.bZ, opt.dT)
				])));
};
var $author$project$Form$TimeField$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{
				cd: $elm$core$Maybe$Just(s)
			});
	});
var $author$project$Form$TimeField$withOnInputHandler = F2(
	function (f, o) {
		return _Utils_update(
			o,
			{cm: f});
	});
var $author$project$Form$TimeField$withRequired = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cb: s});
	});
var $author$project$Form$TimeField$withValue = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{be: s});
	});
var $author$project$Page$Reservation$viewRequestDateTime = F2(
	function (idx, dt) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field is-grouped')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							$author$project$Form$DateField$new(
							A2(
								$author$project$Form$DateField$withValue,
								dt.a8,
								A2(
									$author$project$Form$DateField$withRequired,
									false,
									A2(
										$author$project$Form$DateField$withLabel,
										'日付',
										A2(
											$author$project$Form$DateField$withOnInputHandler,
											A2(
												$elm$core$Basics$composeL,
												$author$project$Page$Reservation$OnInput,
												$author$project$Page$Reservation$WlShootingDate(idx)),
											$author$project$Form$DateField$defaultOptions($author$project$Page$Reservation$NoOp))))))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							$author$project$Form$TimeField$new(
							A2(
								$author$project$Form$TimeField$withValue,
								dt.bb,
								A2(
									$author$project$Form$TimeField$withRequired,
									false,
									A2(
										$author$project$Form$TimeField$withLabel,
										'時間',
										A2(
											$author$project$Form$TimeField$withOnInputHandler,
											A2(
												$elm$core$Basics$composeL,
												$author$project$Page$Reservation$OnInput,
												$author$project$Page$Reservation$WlStartTime(idx)),
											$author$project$Form$TimeField$defaultOptions($author$project$Page$Reservation$NoOp))))))
						]))
				]));
	});
var $author$project$Form$TextArea$withLabel = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{cd: s});
	});
var $author$project$Form$RadioList$withName = F2(
	function (s, o) {
		return _Utils_update(
			o,
			{
				i: $elm$core$Maybe$Just(s)
			});
	});
var $author$project$Page$Reservation$viewWatingList = F2(
	function (wl, errors) {
		return A2(
			$elm$html$Html$fieldset,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$legend,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('ご希望の日時をご入力ください')
							])),
						$author$project$Form$TextField$new(
						A2(
							$author$project$Form$TextField$withStringFromError,
							$author$project$Page$Reservation$stringFromError,
							A2(
								$author$project$Form$TextField$withErrorMessages,
								_List_fromArray(
									[$author$project$Page$Reservation$WlCustomerNameRequired, $author$project$Page$Reservation$WlCustomerNameTooLonger]),
								A2(
									$author$project$Form$TextField$withErrors,
									errors,
									A2(
										$author$project$Form$TextField$withValue,
										wl.dH,
										A2(
											$author$project$Form$TextField$withLabel,
											'お名前',
											A2(
												$author$project$Form$TextField$withOnInputHandler,
												A2($elm$core$Basics$composeL, $author$project$Page$Reservation$OnInput, $author$project$Page$Reservation$WlCustomerName),
												$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp)))))))),
						$author$project$Form$TextField$new(
						A2(
							$author$project$Form$TextField$withStringFromError,
							$author$project$Page$Reservation$stringFromError,
							A2(
								$author$project$Form$TextField$withErrorMessages,
								_List_fromArray(
									[$author$project$Page$Reservation$WlCustomerNameFuriganaRequired, $author$project$Page$Reservation$WlCustomerNameFuriganaTooLonger]),
								A2(
									$author$project$Form$TextField$withErrors,
									errors,
									A2(
										$author$project$Form$TextField$withValue,
										wl.dI,
										A2(
											$author$project$Form$TextField$withLabel,
											'フリガナ',
											A2(
												$author$project$Form$TextField$withOnInputHandler,
												A2($elm$core$Basics$composeL, $author$project$Page$Reservation$OnInput, $author$project$Page$Reservation$WlCustomerNameFurigana),
												$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp)))))))),
						$author$project$Form$TextField$new(
						A2(
							$author$project$Form$TextField$withStringFromError,
							$author$project$Page$Reservation$stringFromError,
							A2(
								$author$project$Form$TextField$withErrorMessages,
								_List_fromArray(
									[$author$project$Page$Reservation$WlEmailRequired]),
								A2(
									$author$project$Form$TextField$withErrors,
									errors,
									A2(
										$author$project$Form$TextField$withValue,
										wl.bU,
										A2(
											$author$project$Form$TextField$withLabel,
											'メールアドレス',
											A2(
												$author$project$Form$TextField$withOnInputHandler,
												A2($elm$core$Basics$composeL, $author$project$Page$Reservation$OnInput, $author$project$Page$Reservation$WlEmail),
												$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp)))))))),
						$author$project$Form$TextField$new(
						A2(
							$author$project$Form$TextField$withStringFromError,
							$author$project$Page$Reservation$stringFromError,
							A2(
								$author$project$Form$TextField$withErrorMessages,
								_List_fromArray(
									[$author$project$Page$Reservation$WlPhoneNumberRequired, $author$project$Page$Reservation$WlPhoneNumberTooLonger]),
								A2(
									$author$project$Form$TextField$withErrors,
									errors,
									A2(
										$author$project$Form$TextField$withValue,
										wl.aq,
										A2(
											$author$project$Form$TextField$withLabel,
											'電話番号',
											A2(
												$author$project$Form$TextField$withOnInputHandler,
												A2($elm$core$Basics$composeL, $author$project$Page$Reservation$OnInput, $author$project$Page$Reservation$WlPhoneNumber),
												$author$project$Form$TextField$defaultOptions($author$project$Page$Reservation$NoOp)))))))),
						$author$project$Form$RadioList$new(
						A2(
							$author$project$Form$RadioList$withRequired,
							false,
							A2(
								$author$project$Form$RadioList$withName,
								'timeRequired',
								A2(
									$author$project$Form$RadioList$withValue,
									wl.bc,
									A2(
										$author$project$Form$RadioList$withNames,
										_List_fromArray(
											['40データ', '80データ']),
										A2(
											$author$project$Form$RadioList$withOnCheckHandler,
											function (key) {
												return A2(
													$elm$core$Basics$composeL,
													$author$project$Page$Reservation$OnInput,
													$author$project$Page$Reservation$WlTimeRequired(key));
											},
											$author$project$Form$RadioList$defaultOptions($author$project$Page$Reservation$NoOp)))))))
					]),
				_Utils_ap(
					A2($elm$core$List$indexedMap, $author$project$Page$Reservation$viewRequestDateTime, wl.dK),
					_List_fromArray(
						[
							$author$project$Form$TextArea$new(
							A2(
								$author$project$Form$TextArea$withValue,
								wl.aa,
								A2(
									$author$project$Form$TextArea$withLabel,
									'ご要望',
									A2(
										$author$project$Form$TextArea$withOnInputHandler,
										A2($elm$core$Basics$composeL, $author$project$Page$Reservation$OnInput, $author$project$Page$Reservation$WlNote),
										$author$project$Form$TextArea$defaultOptions($author$project$Page$Reservation$NoOp))))),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('button is-primary'),
									$elm$html$Html$Events$onClick($author$project$Page$Reservation$CommitWaitingList)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('キャンセル待ち登録')
								]))
						]))));
	});
var $author$project$Page$Reservation$shopSection = function (model) {
	var _v0 = model.bt;
	if (_v0.$ === 2) {
		var shops = _v0.a;
		var studios = function () {
			var _v3 = model.b.g.ae;
			if (!_v3.$) {
				var shop = _v3.a;
				var _v4 = A2($author$project$Page$Reservation$takeShootingStudios, model, shop.c);
				if (_v4.b && (!_v4.b.b)) {
					return _List_Nil;
				} else {
					var a = _v4;
					return a;
				}
			} else {
				return _List_Nil;
			}
		}();
		var isShowStudio = A2(
			$author$project$Page$Reservation$isHidden,
			$elm$core$List$isEmpty(studios) ? _List_Nil : _List_fromArray(
				[5, 6, 7, 8, 9]),
			model.e);
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('section shop'),
					$elm$html$Html$Attributes$class(
					A2(
						$elm$core$List$member,
						model.e,
						_List_fromArray(
							[4, 5, 6, 7, 8, 9])) ? '' : 'is-hidden')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('subtitle is-size-6'),
							$elm$html$Html$Attributes$id('ShopSection')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('▼店舗・日時をお選びください')
						])),
					A2(
					$elm$html$Html$dl,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id('shopSection')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$dt,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('店舗')
								])),
							A2(
							$elm$html$Html$dd,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Page$Reservation$ChoiceItem(4))
								]),
							_List_fromArray(
								[
									A3($author$project$Page$Reservation$shopSelector, 'shop', model, shops)
								]))
						])),
					A2(
					$elm$html$Html$dl,
					_List_fromArray(
						[
							isShowStudio,
							$elm$html$Html$Attributes$id('StudioSection')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$dt,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('スタジオ')
								])),
							A2(
							$elm$html$Html$dd,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(
									$author$project$Page$Reservation$ChoiceItem(5))
								]),
							_List_fromArray(
								[
									A3($author$project$Page$Reservation$studioSelector, 'studio', model, studios)
								]))
						])),
					A2(
					$elm$html$Html$dl,
					_List_fromArray(
						[
							A2(
							$author$project$Page$Reservation$isHidden,
							_List_fromArray(
								[6, 7, 8, 9]),
							model.e),
							$elm$html$Html$Attributes$id('ScheduleSection')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$dt,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('日時')
								])),
							A2(
							$elm$html$Html$dd,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$Page$Reservation$calendar(model)
								]))
						])),
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('button'),
							$elm$html$Html$Events$onClick($author$project$Page$Reservation$WatingCancelReservation),
							A2(
							$author$project$Page$Reservation$isHidden,
							_List_fromArray(
								[6, 8, 9]),
							model.e)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('キャンセル待ち'),
							model.a2 ? $elm$html$Html$text('≪') : $elm$html$Html$text('≫')
						])),
					$elm$html$Html$text(' '),
					function () {
					var _v1 = model.a2;
					if (!_v1) {
						return $elm$html$Html$text('');
					} else {
						return A2($author$project$Page$Reservation$viewWatingList, model.s, model.dT);
					}
				}(),
					$author$project$Page$Reservation$showMessages(model.bm),
					function () {
					var _v2 = model.b.g.v;
					if (_v2.$ === 1) {
						return $elm$html$Html$text('');
					} else {
						return A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('button is-link'),
									$elm$html$Html$Events$onClick($author$project$Page$Reservation$ValidateReservationSchedule),
									A2(
									$author$project$Page$Reservation$isHidden,
									_List_fromArray(
										[8, 9]),
									model.e)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('予約内容を確認する≫')
								]));
					}
				}()
				]));
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					A2(
						$elm$core$List$member,
						model.e,
						_List_fromArray(
							[4, 5, 6, 7, 8, 9])) ? '' : 'is-hidden')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon is-large')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fas fa-spinner fa-spin')
								]),
							_List_Nil)
						])),
					$elm$html$Html$text('予約枠を検索中...')
				]));
	}
};
var $author$project$Page$Reservation$viewConversion = F2(
	function (step, query) {
		var buildUrl = F2(
			function (path, q) {
				return A2(
					$elm$core$String$join,
					'?',
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$elm$core$Maybe$Just(path),
								q
							])));
			});
		var source = function () {
			if (step === 11) {
				return A2(buildUrl, '/CompleteRegistration.html', query);
			} else {
				return A2(buildUrl, '/PageView.html', query);
			}
		}();
		return A2(
			$elm$html$Html$iframe,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(source),
					A2($elm$html$Html$Attributes$style, 'display', 'none'),
					A2($elm$html$Html$Attributes$style, 'visibility', 'hidden'),
					$elm$html$Html$Attributes$height(0),
					$elm$html$Html$Attributes$width(0)
				]),
			_List_Nil);
	});
var $author$project$Page$Reservation$viewBanner = function (banner) {
	return A3(
		$elm$html$Html$Keyed$node,
		'div',
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('banner')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2(
				banner.c,
				A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(banner.fE)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(banner.C)
								]),
							_List_Nil)
						])))
			]));
};
var $author$project$Page$Reservation$viewInfo = function (model) {
	var banners = model.aw;
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('section banners'),
				A2(
				$author$project$Page$Reservation$isHidden,
				_List_fromArray(
					[0, 1, 2, 3]),
				model.e)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('banners-container'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('start-animation', !model.aR)
									]))
							]),
						A2($elm$core$List$map, $author$project$Page$Reservation$viewBanner, banners))
					]))
			]));
};
var $author$project$Page$Reservation$viewModalError = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal is-active')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-background')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('modal-card')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$header,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-card-head')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal-card-title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('エラー')
									]))
							])),
						A2(
						$elm$html$Html$section,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-card-body')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$footer,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-card-foot field is-grouped is-grouped-centered')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('control')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('閉じる')
											]))
									]))
							]))
					]))
			]));
};
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $author$project$Page$Reservation$viewStepper = function (model) {
	var step4 = _List_fromArray(
		[11]);
	var step3 = _List_fromArray(
		[10, 11]);
	var step2 = _List_fromArray(
		[4, 5, 6, 7, 8, 9]);
	var step1 = _List_fromArray(
		[0, 1, 2, 3]);
	return A2(
		$elm$html$Html$ol,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('c-stepper')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-stepper__item'),
						$elm$html$Html$Attributes$class(
						A2($elm$core$List$member, model.e, step1) ? 'active' : ''),
						$elm$html$Html$Attributes$class(
						A2($elm$core$List$member, model.e, step2) ? 'post-active' : '')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$dl,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$dt,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('STEP1')
									])),
								A2(
								$elm$html$Html$dd,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('ご利用目的'),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('separator')
											]),
										_List_Nil),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										$elm$html$Html$text('プラン')
									]))
							]))
					])),
				A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-stepper__item'),
						$elm$html$Html$Attributes$class(
						A2($elm$core$List$member, model.e, step2) ? 'active' : ''),
						$elm$html$Html$Attributes$class(
						A2($elm$core$List$member, model.e, step3) ? 'post-active' : '')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$dl,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$dt,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('STEP2')
									])),
								A2(
								$elm$html$Html$dd,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('店舗・日時')
									]))
							]))
					])),
				A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-stepper__item has-text-danger'),
						$elm$html$Html$Attributes$class(
						A2($elm$core$List$member, model.e, step3) ? 'active' : '')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$dl,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$dt,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('STEP3')
									])),
								A2(
								$elm$html$Html$dd,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('お見積り'),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('separator')
											]),
										_List_Nil),
										A2($elm$html$Html$br, _List_Nil, _List_Nil),
										$elm$html$Html$text('予約内容確認')
									]))
							]))
					]))
			]));
};
var $author$project$Page$Reservation$view = function (model) {
	var canMultipleReservations = function (uid) {
		var _v9 = model.a7;
		if (_v9.$ === 2) {
			var purposeOfUseList = _v9.a;
			return A2(
				$elm$core$List$all,
				function (r) {
					return r.dw;
				},
				A2(
					$elm$core$List$filter,
					function (r) {
						return _Utils_eq(r.c, uid);
					},
					purposeOfUseList));
		} else {
			return false;
		}
	};
	var pouFilter = function () {
		var _v4 = model.gN;
		if (!_v4.b) {
			return $elm$core$List$filter(
				function (_v5) {
					return true;
				});
		} else {
			if (_v4.b.b) {
				if (!_v4.b.b.b) {
					var _v6 = _v4.b;
					return $elm$core$List$filter(
						function (_v7) {
							return false;
						});
				} else {
					return $elm$core$List$filter(
						function (_v8) {
							return false;
						});
				}
			} else {
				var r = _v4.a;
				return $elm$core$List$filter(
					function (p) {
						return !_Utils_eq(
							p.dw,
							canMultipleReservations(r.eX));
					});
			}
		}
	}();
	var _v0 = model.aG;
	if (!_v0) {
		return {
			f0: function () {
				var _v1 = model.a7;
				switch (_v1.$) {
					case 0:
						return $elm$html$Html$text('');
					case 1:
						return $elm$html$Html$text('');
					case 3:
						return $elm$html$Html$text('');
					default:
						var purposeOfUseList = _v1.a;
						return A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$Page$Reservation$viewStepper(model),
									$author$project$Page$Reservation$viewInfo(model),
									A2(
									$elm$html$Html$section,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section reservation')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$id('Top')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$h2,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('subtitle is-size-6'),
													A2(
													$author$project$Page$Reservation$isHidden,
													_List_fromArray(
														[0, 1, 2, 3]),
													model.e)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('▼ご利用目的・プランをお選びください。')
												])),
											A2(
											$author$project$Page$Reservation$planSection,
											model,
											pouFilter(purposeOfUseList)),
											$author$project$Page$Reservation$shopSection(model),
											$author$project$Page$Reservation$customerSection(model),
											$author$project$Page$Reservation$completeReservationSection(model)
										])),
									$author$project$Page$Reservation$isShowErrors(model.dT) ? $author$project$Page$Reservation$viewModalError(model) : $elm$html$Html$text(''),
									A2(
									$author$project$Page$Reservation$viewConversion,
									model.e,
									$author$project$Session$conversionQuery(
										$author$project$Page$Reservation$toSession(model)))
								]));
				}
			}(),
			fw: '予約'
		};
	} else {
		return {
			f0: A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('Top')
					]),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('subtitle is-size-6')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('ご予約情報をご確認ください')
								])),
							function () {
							var _v2 = model.R;
							if (!_v2.$) {
								var r = _v2.a;
								return A2($author$project$Page$Reservation$confirmDeleteDialog, model, r);
							} else {
								return $elm$html$Html$text('');
							}
						}()
						]),
					_Utils_ap(
						A2(
							$elm$core$List$map,
							function (reservation) {
								return A2(
									$elm$html$Html$section,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('section')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class(''),
													$elm$html$Html$Attributes$class(
													A2(
														$elm$core$List$member,
														model.e,
														_List_fromArray(
															[13, 12, 6, 7, 8, 9])) ? '' : 'is-hidden')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$article,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('panel is-info')
														]),
													_List_fromArray(
														[
															A3(
															$author$project$Page$Reservation$viewReservationInfo,
															model,
															$elm$core$Maybe$Just(reservation),
															model.e)
														]))
												])),
											_Utils_eq(
											model.R,
											$elm$core$Maybe$Just(reservation)) ? A2(
											$elm$html$Html$section,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('section customer'),
													$elm$html$Html$Attributes$class(
													A2(
														$elm$core$List$member,
														model.e,
														_List_fromArray(
															[12, 6, 7, 8, 9])) ? '' : 'is-hidden')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$h2,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('subtitle is-size-6'),
															$elm$html$Html$Attributes$id('ShopSection')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('▼日時をお選びください')
														])),
													A2(
													$elm$html$Html$dl,
													_List_fromArray(
														[
															A2(
															$author$project$Page$Reservation$isHidden,
															_List_fromArray(
																[12, 6, 7, 8, 9]),
															model.e),
															$elm$html$Html$Attributes$id('ScheduleSection')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$dt,
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$text('日時')
																])),
															A2(
															$elm$html$Html$dd,
															_List_Nil,
															_List_fromArray(
																[
																	$author$project$Page$Reservation$calendar(model)
																])),
															A2(
															$elm$html$Html$dd,
															_List_fromArray(
																[
																	A2(
																	$author$project$Page$Reservation$isHidden,
																	_List_fromArray(
																		[12]),
																	model.e)
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$button,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('button is-link'),
																			$elm$html$Html$Events$onClick($author$project$Page$Reservation$ConfirmChangeSchedule)
																		]),
																	_List_fromArray(
																		[
																			$elm$html$Html$text('予約日時の変更を確定する ≫')
																		]))
																]))
														]))
												])) : $elm$html$Html$text('')
										]));
							},
							model.gN),
						_List_fromArray(
							[
								function () {
								var _v3 = model.gN;
								if (_v3.b && (!_v3.b.b)) {
									var r = _v3.a;
									return canMultipleReservations(r.eX) ? A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button is-primary'),
												$elm$html$Html$Events$onClick($author$project$Page$Reservation$AddReservation)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('撮影予約を追加する')
											])) : A2(
										$elm$html$Html$button,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button is-primary'),
												$elm$html$Html$Events$onClick($author$project$Page$Reservation$AddReservation)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('見学予約を追加する')
											]));
								} else {
									return $elm$html$Html$text('');
								}
							}()
							])))),
			fw: 'ご予約確認'
		};
	}
};
var $author$project$Main$view = function (model) {
	var viewer = $author$project$Session$viewer(
		$author$project$Main$toSession(model));
	var isActiveNavbarBurger = $author$project$Session$isActiveNavbarBurger(
		$author$project$Main$toSession(model));
	var headerInfo = A4(
		$author$project$Page$HeaderInfo,
		$author$project$Session$loginUserName(
			$author$project$Main$toSession(model)),
		$author$project$Session$galleryMenu(
			$author$project$Main$toSession(model)),
		$author$project$Session$isActiveNavbarBurger(
			$author$project$Main$toSession(model)),
		!$elm$core$List$isEmpty(
			$author$project$Session$reservations(
				$author$project$Main$toSession(model))));
	var viewPage = F3(
		function (page, toMsg, config) {
			var _v1 = A4($author$project$Page$view, viewer, page, config, headerInfo);
			var title = _v1.fw;
			var body = _v1.aA;
			return {
				aA: A2(
					$elm$core$List$map,
					$elm$html$Html$map(toMsg),
					body),
				fw: title
			};
		});
	switch (model.$) {
		case 1:
			return A4($author$project$Page$view, viewer, $author$project$Page$Other, $author$project$Page$Blank$view, headerInfo);
		case 0:
			return A4($author$project$Page$view, viewer, $author$project$Page$Other, $author$project$Page$Blank$view, headerInfo);
		case 2:
			return A4($author$project$Page$view, viewer, $author$project$Page$Other, $author$project$Page$NotFound$view, headerInfo);
		case 3:
			var home = model.a;
			return A3(
				viewPage,
				$author$project$Page$Home,
				$author$project$Main$GotHomeMsg,
				$author$project$Page$Home$view(home));
		case 4:
			var gallery = model.a;
			return A3(
				viewPage,
				$author$project$Page$Gallery,
				$author$project$Main$GotGalleryMsg,
				$author$project$Page$Gallery$view(gallery));
		case 5:
			var profile = model.a;
			return A3(
				viewPage,
				$author$project$Page$Profile,
				$author$project$Main$GotProfileMsg,
				$author$project$Page$Profile$view(profile));
		case 6:
			var login = model.a;
			return A3(
				viewPage,
				$author$project$Page$Login,
				$author$project$Main$GotLoginMsg,
				$author$project$Page$Login$view(login));
		default:
			var reservation = model.a;
			var isCompletedReservation = $author$project$Page$Reservation$isCompletedReservation(reservation);
			return A3(
				viewPage,
				$author$project$Page$Reservation(isCompletedReservation),
				$author$project$Main$GotReservationMsg,
				$author$project$Page$Reservation$view(reservation));
	}
};
var $author$project$Main$main = A2(
	$author$project$App$application,
	$author$project$Viewer$decoder,
	{ec: $author$project$Main$init, eG: $author$project$Main$ChangedUrl, eH: $author$project$Main$ClickedLink, ft: $author$project$Main$subscriptions, fD: $author$project$Main$update, fG: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));