import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';


var storageKey = "mpstore";
var flags =
    { viewer: localStorage.getItem(storageKey)
    , endpoint: process.env.ELM_APP_API_ENDPOINT
    };
console.log("endpoint: " + process.env.ELM_APP_API_ENDPOINT);

var app = Elm.Main.init({
    node: document.getElementById('root'),
    flags: flags
});


app.ports.storeCache.subscribe(function(val) {
    console.log("storeCache: " + val)
    if (val === null) {
        localStorage.removeItem(storageKey);
    } else {
        localStorage.setItem(storageKey, JSON.stringify(val));
    }

    // Report that the new session was stored successfully.
    setTimeout(function() { app.ports.onStoreChange.send(val); }, 0);
});


app.ports.scrollIntoView.subscribe(function(val) {
    console.log("scrollIntoView?" + val.elementId);
    var doScroll = function(element) {
        console.log("doScroll?" + val.elementId);
        element.scrollIntoView({behavior: "smooth"});
        if ( val.elementId == "Top" ) {
            window.scrollTo(0, 0);
            console.log("move top?" + val.elementId);
        }
    }
    var elementId = val.elementId;
    var element = document.getElementById(elementId);
    console.log("element?" + val.elementId);
    if ( element != null ) {
        doScroll(element)
    } else {
        var maxTime = 1000
        var elapsedTime = 0
        var intevalId = setInterval(
            function() {
                elapsedTime += 100;
                var element = document.getElementById(elementId);
                console.log("element?" + val.elementId);
                if ( element != null ) {
                    clearInterval(intevalId)
                    doScroll(element)
                } else if (elapsedTime >= maxTime) {
                    clearInterval(intevalId)
                    console.log("element not found:" + val.elementId);
                }
            }
            , 100);
    }
});


// Whenever localStorage changes in another tab, report it if necessary.
window.addEventListener("storage", function(event) {
    if (event.storageArea === localStorage && event.key === storageKey) {
        console.log("event.newValue: " + event.newValue);
        app.ports.onStoreChange.send(JSON.parse(event.newValue));
    }
}, false);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

